<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="服务列表" name="third">
        <el-row>
          <el-col :span="12">
            <div style="position: relative;display: inline-block;">
              <span>服务名称: </span>
              <el-input style="width: 200px;" @keydown.enter.native="phoneSelect" placeholder="请输入标题" v-model="itemName">
              </el-input>&nbsp;&nbsp;
            </div>
            <el-button style="margin:10px;" size="mini" type="primary" icon="document" @click="phoneSelect">查询
            </el-button>
            <el-button style="margin:10px;" size="mini" type="primary" icon="document" @click="cleans2">重置
            </el-button>&nbsp;&nbsp;
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button style="margin: 10px 0;" :disabled="!isAuth('locality:add')" size="mini" type="primary"
            icon="document" @click="choiaddNotice()">添加服务</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
          </el-col>
        </el-row>
        <el-table v-loading="tableDataLoading" :data="choicenData.list" height="600px">
          <!-- @selection-change="handleSelectionChange" -->
          <!-- <el-table-column type="selection">
					</el-table-column> -->
          <el-table-column prop="id" label="服务id" fixed="left">
          </el-table-column>
          <el-table-column prop="itemName" label="服务名称" fixed="left">
          </el-table-column>
          <el-table-column prop="itemUrl" label="封面图"  width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.itemUrls && scope.row.itemUrls.length">
                <el-image  v-for="item in scope.row.itemUrls" :key="item" :src="item" style="width: 40px; height:40px;" :preview-src-list="[item]" lazy></el-image>
              </span>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="itemTypeName" label="服务类型" width="120">
          </el-table-column>
          <el-table-column prop="itemUnitPrice" label="预约单价" width="120">
          </el-table-column>
          <el-table-column prop="isEnable" label="上架状态" width="100">
            <template slot-scope="scope">
              <el-switch :value="scope.row.isEnable == 0"  @change="handleServiceChange(scope.row)" />
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" width="180"> </el-table-column>
          <!-- <el-table-column prop="detailsImg" label="服务详情" width="180">
            <template slot-scope="scope">
              <el-button style="margin: 10px 0;" size="mini" plain type="primary" icon="document"
                @click="look(scope.row.detailsImg)">查看</el-button>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="homepageImg" label="详情图" width="180">
						<template slot-scope="scope">
							<div v-if="scope.row.detailsImg">
								<img v-for="(item,index) in scope.row.detailsImg.split(',')" :key="index" :src="item" width="40"
									height="40" style="margin:3px" />
							</div>
						</template>
					</el-table-column> -->
          <!-- <el-table-column prop="voiceIntroduce" label="语音介绍" width="350">
						<template slot-scope="scope">
							<audio :src="scope.row.voiceIntroduce" type="audio/wav" controls="controls"></audio>
						</template>
					</el-table-column> -->
          <!-- <el-table-column prop="classify" label="服务类型" width="150">
						<template slot-scope="scope">
							<span v-if="scope.row.classify==1">线上</span>
							<span v-if="scope.row.classify==2">线下</span>
						</template>
					</el-table-column> -->
          <el-table-column label="操作" prop="id" width="260" fixed='right' align="center">
            <template slot-scope="scope">
              <!-- <el-button size="mini" type="primary" v-if="scope.row.status === 1 "
                @click="shenhe( scope.row)">审核</el-button>
              <el-button size="mini" type="primary" v-if="scope.row.status != 1 " @click="plCompile(scope.row)">评论
              </el-button> -->
              <el-button size="mini" class="mr-5" style="margin-right: 5px;" type="primary" :disabled="!isAuth('locality:update')"
                @click="choiCompile(scope.$index, scope.row)">修改
              </el-button>
              <el-popconfirm
                popper-class="height-100"
                confirm-button-text='确定'
                cancel-button-text='取消'
                title="确定删除该服务吗？"
                @confirm="choidelete(scope.row)"
              >
                <el-button class="ml-5" size="mini" type="danger" slot="reference" :disabled="!isAuth('locality:delete')">删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;margin-top: 10px;float:right">
          <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
            :page-sizes="[10, 20, 30, 40]" :page-size="size" :current-page="page"
            layout="total,sizes, prev, pager, next,jumper" :total="choicenData.totalCount">
          </el-pagination>
        </div>
      </el-tab-pane>
      <!-- 服务分类 -->
      <el-tab-pane label="服务分类" name="first">
        <div style="width: 100%;text-align: right;">
          <el-button style="margin:10px;" size="mini" type="primary" icon="document" :disabled="!isAuth('locality:add')"
            @click="classAdd(0)">添加
          </el-button>
        </div>
        <el-table v-loading="tableDataLoading" :data="tableData.records" row-key="id">
          <el-table-column prop="id" label="编号" width="80">
          </el-table-column>
          <el-table-column prop="gameName" label="服务名称">
          </el-table-column>
          <el-table-column prop="gameImg" label="服务图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.gameImg &&scope.row.gameImg!=''" :src="scope.row.gameImg" style="width: 40px; height:40px;" :preview-src-list="[scope.row.gameImg]" lazy></el-image>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160">
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" @change="change(scope.row.id,scope.row.status)"
                :disabled="!isAuth('locality:update')" :active-value="openValue2" :inactive-value="closeValue2"
                active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" prop="id" width="240" fixed='right' align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" :disabled="!isAuth('locality:update')"
                @click="classAdd(scope.row)">修改
              </el-button>
              <el-button size="mini" type="danger" :disabled="!isAuth('locality:delete')"
                @click="classdelete(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;margin-top: 10px;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30, 40]" :page-size="limit" :current-page="page"
            layout="total,sizes, prev, pager, next,jumper" :total="tableData.total">
          </el-pagination>
        </div>
      </el-tab-pane>
      <!-- 用户列表 -->
      <el-dialog title="商家列表" :visible.sync="dialogFormVisible3" center>
        <div style="margin:2% 0;display: inline-block;">
          <span>手机号：</span>
          <el-input style="width: 150px;" @keydown.enter.native="userclick" clearable placeholder="请输入手机号"
            v-model="phone1"></el-input>&nbsp;&nbsp;&nbsp;&nbsp;
          <span>昵称：</span>
          <el-input style="width: 150px;" @keydown.enter.native="userclick" clearable placeholder="请输入昵称"
            v-model="nickName2"></el-input>
          <el-button style='margin-left:15px;' size="mini" type="primary" icon="document" @click="userclick">
            查询
          </el-button>
          <el-button style='margin-left:15px;' size="mini" type="primary" icon="document" @click="userclose">
            重置
          </el-button>
        </div>
        <el-table width="780px" v-loading="tableDataLoading4" :data="userIds.records">
          <el-table-column prop="nickName" label="昵称">
            <template slot-scope="scope">
              <span style="color: #f56c6c;">{{scope.row.userName ? scope.row.userName : '未设置'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="shopName" label="商家名称">
          </el-table-column>

          <el-table-column prop="phone" label="手机号">
            <template slot-scope="scope">
              <span>{{scope.row.phone ? scope.row.phone : '未绑定'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirm(scope.row)">选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;margin-top: 10px;">
          <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
            :page-sizes="[5, 10, 15, 20]" :page-size="size1" :current-page="page1"
            layout="total,sizes, prev, pager, next,jumper" :total="userIds.total">
          </el-pagination>
        </div>
      </el-dialog>
      <!--    图片展示-->
      <el-dialog title="图片" :visible.sync="dialogVisible" width="800px">
        <span v-if="imageUrl.length>0" style="">
          <!-- <el-image style = "width: 100px; height: 100px" :src = "item" :preview-src-list="imageUrl"></el-image>-->
          <img :src="item" v-for="(item, index) in imageUrl" :key="index" alt="" style="width: 45%; height: 100%;margin: 15px;display:inline-block;">
        </span>
        <span v-else>暂无图片</span>
      </el-dialog>
      <!-- 服务审核弹框 -->
      <el-dialog title="服务审核" :visible.sync="dialogFormVisible8" center>
        <el-radio-group v-model="radio" style="width: 100%;margin-bottom: 30px;margin-left: 200px;">
          <el-radio :label="0">通过</el-radio>
          <el-radio :label="3">拒绝</el-radio>
        </el-radio-group>
        <div style="margin-bottom: 10px;">
          <span style="width: 200px;display: inline-block;text-align: right;">审核理由：</span>
          <el-input style="width:50%;" v-model="contents" type="textarea" :rows="5" placeholder="请输入审核理由"></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible8 = false">取 消</el-button>
          <el-button type="primary" @click="refuseto()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 添加、修改服务分类 -->
      <el-dialog :title="titles" :visible.sync="dialogFormVisible9" center>
        <div style="margin-bottom: 10px;">
          <span style="width: 200px;display: inline-block;text-align: right;">分类类型：</span>
          <el-select v-model="parentIdnum" placeholder="请选择分类类型" style="width:50%;" @change="animeDatF()">
            <el-option v-for="item in classnum" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 10px;" v-if="parentIdnum==2">
          <span style="width: 200px;display: inline-block;text-align: right;">上级分类：</span>
          <el-select v-model="parentId" style="width:50%;">
            <el-option v-for="item in classDatas" :key="item.id" :label="item.gameName" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 10px;">
          <span style="width: 200px;display: inline-block;text-align: right;">服务名称：</span>
          <el-input style="width:50%;" v-model="gameName" type="text" placeholder="请输入服务名称"></el-input>
        </div>
        <div style="margin-bottom: 10px;">
          <span style="width: 200px;display: inline-block;text-align: right;">排序：</span>
          <el-input style="width:50%;" v-model="sort" type="number" min="0" placeholder="请输入排序"></el-input>
        </div>

        <div style="margin-bottom: 10px;display:flex;">
          <span style="width: 200px;display: inline-block;text-align: right;">服务图片：</span>
          <div
            style=" width:148px;height:148px;border: 1px dashed #c0ccda;border-radius: 6px;text-align: center;line-height: 148px;">
            <el-upload class="avatar-uploader" v-model="gameImg" :action="$http.adornUrl('oss/upload')"
              :show-file-list="false" :on-success="handleAvatarSuccess1" :on-progress="onprogress2">
              <!-- <el-progress type="circle" :percentage="100" status="success"></el-progress> -->
              <img v-if="gameImg!=''&&percentage2==100" :src="gameImg" class="avatar"
                style="border-radius: 6px;width: 148px;height: 148px;" />
              <i v-else class="el-icon-plus avatar-uploader-icon iconss"></i>
              <el-progress v-if="percentage2>0&&percentage2<100" type="circle" :percentage="percentage2">
              </el-progress>
            </el-upload>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible9 = false">取 消</el-button>
          <el-button type="primary" @click="refuseto1()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 评论列表 -->
      <el-dialog title="评论列表" :visible.sync="dialogFormVisible10" center>
        <el-table v-loading="tableDataLoadingPl" :data="pinglunData.records">
          <!-- <el-table-column prop="id" label="编号" width="80">
					</el-table-column>
					<el-table-column prop="userName" label="评论用户" width="120">
						<template slot-scope="scope">
							　<span style="color: #4f9dec;cursor: pointer;" @click="updatesvideo(scope.row)"
								v-text="scope.row.userName"></span>
						</template>
					</el-table-column>
					<el-table-column prop="avatar" label="用户头像" width="100">
						<template slot-scope="scope">
							　　<img v-if="scope.row.avatar && scope.row.avatar!=''" :src="scope.row.avatar" width="40"
								height="40" />
							<span v-else>暂无图片</span>
						</template>
					</el-table-column>
					<el-table-column prop="content" label="评论内容">
					</el-table-column>
					<el-table-column prop="createTime" label="评论时间" width="180">
					</el-table-column> -->
          <el-table-column prop="evaluateId" label="编号" width="80">
          </el-table-column>
          <el-table-column prop="userName" label="评论用户" width="120">
            <template slot-scope="scope">
              　<span style="color: #4f9dec;cursor: pointer;" @click="updatesvideo(scope.row)"
                v-text="scope.row.userName"></span>
            </template>
          </el-table-column>
          <el-table-column prop="evaluateMessage" label="评论内容">
          </el-table-column>
          <el-table-column prop="evaluateImg" label="评论图片" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.evaluateImg">
                <span v-for="(item,index) in scope.row.evaluateImg.split(',')" :key="index">
                  <el-popover placement="top-start" title="" trigger="hover">
                    <img style="width: 50px; height: 50px;margin: 3px;" :src="item" alt="" slot="reference">
                    <img style="width: 200px; height: 200px" :src="item" alt="">
                  </el-popover>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="satisfactionFlag" label="评分" width="80">
            <template slot-scope="scope">
              <span style="color: red;cursor: pointer;" v-if="scope.row.satisfactionFlag==1">非常差</span>
              <span style="color: orange;cursor: pointer;" v-if="scope.row.satisfactionFlag==2">差</span>
              <span style="color: #999;cursor: pointer;" v-if="scope.row.satisfactionFlag==3">一般</span>
              <span style="color: #4f9dec;cursor: pointer;" v-if="scope.row.satisfactionFlag==4">满意</span>
              <span style="color: #4f9dec;cursor: pointer;" v-if="scope.row.satisfactionFlag==5">非常满意</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="评论时间" width="180">
          </el-table-column>
          <el-table-column label="操作" prop="id" width="100" fixed='right' align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" :disabled="!isAuth('locality:delete')"
                @click="pinglundelete(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;margin-top: 10px;">
          <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
            :page-sizes="[10, 20, 30, 40]" :page-size="size2" :current-page="page2"
            layout="total,sizes, prev, pager, next,jumper" :total="pinglunData.total">
          </el-pagination>
        </div>
      </el-dialog>
      <!-- 服务详情 -->
      <el-dialog title="服务详情" :visible.sync="dialogFormVisibleDe" center>
        <div v-html="texts" class="texts"></div>
      </el-dialog>
      <ModifyService @saveSuccess="hadleServiceSaveSuccess" ref="modifyServiceRef" />
    </el-tabs>
  </div>
</template>

<script>
  import {
    quillEditor
  } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import quillConfig from './quill-config.js';
  import ModifyService from './components/modifyService';
  import axios from 'axios';
  import {
    jsonp
  } from 'vue-jsonp'
  import {
    provinceAndCityData,
    regionData,
    provinceAndCityDataPlus,
    regionDataPlus,
    CodeToText,
    TextToCode
  } from 'element-china-area-data'
import { CompanyAudit } from '@/api/auth/index.js'
import { deleteService, getServiceList, serviceItemDisable } from '@/api/serviced/index.js'
import { Message } from 'element-ui'
  var cityOptions = []
  var geocoder, map, markersArray = [];
  export default {
    components: {
      quillEditor,
      ModifyService
    },
    data() {
      return {
        // 服务列表数据
        listServiceData: [],
        itemName: '',
        dialogWidth: 0,
        size: 10,
        page: 1,
        size1: 10,
        page1: 1,
        size2: 10,
        page2: 1,
        state: '',
        limit: 10,
        classifys: 6,
        openValue: 0,
        closeValue: 2,
        openValue1: '0',
        closeValue1: '1',
        openValue2: 0,
        closeValue2: 1,
        type: '',
        name: '',
        keyword: '',
        describes: '',
        checkBoxData: [], //多选框选择的值
        method: 'false',
        formLabelWidth: '200px',
        activeName: 'third',
        tableDataLoading: true,
        tableDataLoading4: false,
        dialogFormVisible3: false,
        dialogFormVisible1: false,
        dialogFormVisible2: false,
        dialogFormVisible5: false,
        dialogFormVisible6: false,
        dialogFormVisible7: false,
        dialogFormVisible8: false,
        dialogFormVisible9: false,
        dialogFormVisible10: false,
        homeData: [],
        homeData1: [{
          id: 0,
          name: '全部'
        }],
        classify: 2,
        classifyList: [{
            id: '',
            name: '全部'
          },
          {
            id: 0,
            name: '是'
          },
          {
            id: 1,
            name: '否'
          }
        ],
        choicenData: [],
        pinglunData: {},
        renwuId: '',
        url: '',
        imageUrl: '',
        id: '',
        userId: '',
        search: '',
        myPhone: '',
        classifyIds: '全部',
        classifyId: [],
        isRecommendT: '',
        titleT:'',
        statusId: '',
        statuss: [{
            id: '',
            title: '全部'
          },
          {
            id: 0,
            title: '进行中'
          },
          {
            id: 1,
            title: '待审核'
          },
          {
            id: 2,
            title: '已取消'
          },
          {
            id: 3,
            title: '拒绝'
          }
        ],
        // 发布信息
        myLevel: '', //标题
        userIdss: '', //用户id
        orderTakingArea: '', //接单大区
        homepageImg: '', //封面图
        percentage: 0, //进度条
        percentage2: 0, //进度条
        voiceIntroduce: '', //语音介绍
        gameId: '', //服务id
        oldMoney: '', //发布价格
        money: '', //普通用户价格
        memberMoney: '', //会员价格
        latitude: '', //纬度
        longitude: '', //经度
        city: '', //市
        orderTakingTime: '',
        status: '', //状态0待审核  1上架  2下架 3拒绝 (修改添加不传)
        minNum: '', //最低服务时间
        unit: '小时',
        phone: '', //电话
        phone1: '',
        pickeroptions: {
          selectableRange: '00:00:01 - 23:59:59'
        },
        value1: '',
        value2: '',

        options: regionData,
        storeAddress: [],
        storeAddre: '请选择城市',
        province: '', //省
        district: '', //区
        ids: '', //信息id
        nickName: '',
        nickName1: '',
        nickName2: '',
        userIds: {}, //用户列表
        bq: '', //输入的标签名
        missions: 0,
        shenheId: 0, //审核id
        contents: '', //拒绝理由
        dialogVisible: false,
        radio: 0,
        multipleSelection: [],
        campus: '',
        campus1: '',
        campusName: '',
        homeData2: [],
        quillOption: quillConfig,
        statusIdd: 1,
        tableData: {},
        titles: '添加服务分类',
        renwuTitles: '添加服务',
        gameName: '',
        gameIds: '',
        gameImg: '',
        gameStatus: '',
        checkList: [], //多选 选中集合
        detailsImg: '', //轮播图
        hideUpload: false,
        percentage1: 0,
        region1: '',
        region: '',
        detailadd: '',
        address: '',
        // 分类
        parentId: 0,
        parentIdnum: '',
        classnum: [{
            value: 1,
            label: '一级分类'
          },
          {
            value: 2,
            label: '二级分类'
          }
        ],
        classDatas: [],
        sort: 0,
        tableDataLoadingPl: false,
        orderLevel: '',
        twoGame: [],
        gameNames: '',
        value: [],
        classTypes: '',
        danwei: [],
        dialogFormVisibleDe: false,
        texts: '',
      }
    },
    methods: {
      //查看照片
      refund(e) {
        console.log(e)
        this.imageUrl = []
        if (e.img != null) {
          let img = e.img.split(',')
          if (img.length != 0) {
            this.imageUrl = img
          }
        }
        this.dialogVisible = true
      },
      // 保存服务成功
      hadleServiceSaveSuccess() {
        // 重新请求列表
        this.InformationSelect();
      },
      // 多选
      changeFun(val) {
        this.checkBoxData = val
      },
      handleSizeChange(val) {
        this.limit = val
        this.classSelect()
      },
      handleCurrentChange(val) {
        this.page = val
        this.classSelect()
      },
      handleSizeChange1(val) {
        this.limit = val
        this.InformationSelect()
      },
      handleCurrentChange1(val) {
        this.page = val
        this.InformationSelect()
      },
      handleSizeChange2(val) {
        this.size1 = val
        this.tableDataLoading4 = true
        this.userClass()
      },
      handleCurrentChange2(val) {
        this.page1 = val
        this.tableDataLoading4 = true
        this.userClass()
      },
      handleSizeChange3(val) {
        this.size2 = val
        this.pinglunSelect(this.renwuId)
      },
      handleCurrentChange3(val) {
        this.page2 = val
        this.pinglunSelect(this.renwuId)
      },
      handleClick(tab, event) {
        this.campus = ''
        this.campus1 = ''
        this.page = 1
        if (tab._props.label == '服务列表') {
          this.type = 2
          this.InformationSelect()
        }
        if (tab._props.label == '服务分类') {

          this.classSelect()
        }
      },

      //添加精选商品
      choiaddNotice() {
        console.log(this.$refs.modifyServiceRef)
        this.$refs.modifyServiceRef.showAdd();

      },
      // 查询
      phoneSelect() {
        this.page = 1
        this.InformationSelect()
      },
      // 重置
      cleans2() {
        this.status = ''
        this.statusId = ''
        this.titleT = ''
        this.isRecommendT = ''
        this.search = ''
        this.region1 = ''
        this.classifyId = []
        this.classifyIds = '全部'
        this.page = 1
        this.itemName = '';
        this.InformationSelect()
      },
      // 获取服务分类启用列表
      homeSelect() {
        this.tableDataLoading = true
        this.$http({
          url: this.$http.adornUrl('game/selectClassifyList'),
          method: 'get',
          params: this.$http.adornParams({
            'parentId': ''
          })
        }).then(({
          data
        }) => {
          this.tableDataLoading = false
          let returnData = data.data
          this.homeData1 = [{
            id: '',
            gameName: '全部'
          }]
          if (data.data) {
            for (var i in data.data) {
              this.homeData1.push(data.data[i])
              data.data[i].state = Number(data.data[i].status)
            }
          }
          this.homeData = data.data
        })
      },
      // 响应服务状态改变
      async handleServiceChange(row) {
        const data = row.isEnable == 0? 2 : 0;
        const res = await serviceItemDisable(row.id, data);
        if(res) {
          Message({
            message: '提交成功',
            type: 'success',
          });
          this.InformationSelect();
        }
      },
      // 信息数据
      async InformationSelect() {
        this.tableDataLoading = true;
        // 处理服务列表分页查询
        const res = await getServiceList({page: this.page, limit: this.limit}, {itemName: this.itemName});
        this.choicenData = res;
        this.tableDataLoading = false;
      },
      // 封面图片上传
      handleAvatarSuccess5(file) {
        this.homepageImg = file.data;
      },
      onprogress(event, file, fileList) {
        console.log('event, file, fileList', parseInt(event.percent))
        this.percentage = parseInt(event.percent)
      },
      // 封面图片上传
      handleAvatarSuccess1(file) {
        this.gameImg = file.data;
      },
      onprogress2(event, file, fileList) {
        console.log('event, file, fileList', parseInt(event.percent))
        this.percentage2 = parseInt(event.percent)
      },
      // // 详情图片上传
      // handleRemove(file, ) {
      //   console.log(file, voiceIntroduce)
      //   this.voiceIntroduce = '';
      // },
      handleSuccess(file) {
        console.log(file)
        this.voiceIntroduce = file.data;
        console.log(file.data, this.voiceIntroduce)
        this.tableDataLoading4 = false
      },
      handlePreview(file) {
        this.tableDataLoading4 = true
      },
      // 验证只能上传音频
      beforeAvatarUpload(file) {
        var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const extension = testmsg === 'mp3'
        if (!extension) {
          this.$message({
            message: "上传文件只能是mp3格式！",
            type: 'error'
          })
        }
        return extension;
      },
      // 删除详情图
      dels(index) {
        this.img.splice(index, 1);
        console.log(this.img)
      },
      // 获取分类id
      onChang(e) {
        console.log(e)
        this.gameId = e.toString()
        this.checkList = e
        var twoId = []
        for (var i in this.homeData2) {
          for (var j in e) {
            if (e[j] == this.homeData2[i].gameName) {
              twoId.push(this.homeData2[i].id)
            }
          }
        }
        this.twoGame = twoId
        console.log('this.twoGame', this.twoGame)
        console.log('twoId', twoId)

      },
      // 获取社区
      onChang3(e) {
        console.log(e)
        this.checkList = []
        this.gameNames = e.gameName
        this.orderLevel = e.id
        this.homeData2 = e.children
        // this.campus1 = e
      },
      // 获取省市区
      handleChange55(value) {
        console.log('value', value, this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels)
        // value = this.$refs['cascaderAddr'].currentLabels
        value = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
        this.province = value[0]
        this.region = value[1]
        this.district = value[2]
        // this.form.city = value[1]
        if (this.region == '市辖区') {
          this.region = this.province
          this.city = this.province
        }
        // if (this.form.city == '市辖区') {
        // 	this.region = this.province
        // }
        // var regions = this.region
        // regions = regions.slice(0, regions.length - 1)
        // this.region = regions
        // console.log('regions', regions)
        console.log(this.province + this.city + this.district)
      },
      //定位获得当前位置信息
      getMyLocation() {
        var geolocation = new qq.maps.Geolocation("DSQBZ-5MM3P-HEODO-VG6IX-SBRJE-PSBNX", "渝高智维服务");
        geolocation.getIpLocation(this.showPosition, this.showErr);
        // geolocation.getLocation(this.showPosition, this.showErr);//或者用getLocation精确度比较高
      },
      showPosition(position) {
        console.log(position);
        // this.latitude = position.lat;
        // this.longitude = position.lng;
        // this.city = position.city;
        this.setMap();
      },
      showErr(e) {
        console.log("定位失败", e);
        this.getMyLocation(); //定位失败再请求定位，测试使用
      },
      //位置信息在地图上展示
      setMap() {
        //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
        //设置地图中心点
        var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
        //定义工厂模式函数
        var myOptions = {
          zoom: 13, //设置地图缩放级别
          center: myLatlng, //设置中心点样式
          mapTypeId: qq.maps.MapTypeId.ROADMAP //设置地图样式详情参见MapType
        }
        // //获取dom元素添加地图信息
        var map = new qq.maps.Map(document.getElementById("container1"), myOptions);
        //给地图添加点击事件

        //给定位的位置添加图片标注
        var marker = new qq.maps.Marker({
          position: myLatlng,
          map: map
        });
        // `````````````
        var that = this;
        if (that.longitude == '') {
          var center = new qq.maps.LatLng(34.34281541842994, 108.93970884382725);
        } else {
          var center = new qq.maps.LatLng(that.latitude, that.longitude);
        }

        var map = new qq.maps.Map(document.getElementById("container1"), {
          center: center,
          zoom: 13
        });
        var marker = new qq.maps.Marker({
          position: center,
          map: map
        });
        var latlngBounds = new qq.maps.LatLngBounds();
        qq.maps.event.addListener(map, "click", function(event) {
          console.log(event, qq.maps);
          that.longitude = event.latLng.getLng(); // 经度
          that.latitude = event.latLng.getLat(); // 纬度

          jsonp('https://apis.map.qq.com/ws/geocoder/v1/?location=' + event.latLng.getLat() + ',' + event
            .latLng.getLng() + '&key=DSQBZ-5MM3P-HEODO-VG6IX-SBRJE-PSBNX&get_poi=1&output=jsonp', {
              myCustomUrlParam: 'veryNice'
            }).then(response => {
            console.log('response', response, response.result.address_component.city)
            that.detailadd = response.result.address
            that.city = response.result.address_component.city
          }).catch(error => {
            // handle error
          }).then(() => {
            // always executed
          });
          if (markersArray) {
            for (let i in markersArray) {
              markersArray[i].setMap(null);
            }
          }
          if (!marker) {
            marker = new qq.maps.Marker({
              map: map,
              position: event.latLng
            });
          } else {
            marker.setPosition(event.latLng)
          }

          // markersArray.push(marker);

        });
        geocoder = new qq.maps.Geocoder({
          complete: function(result) {
            console.log(result);
            that.longitude = result.detail.location.lng;
            that.latitude = result.detail.location.lat;
            map.setCenter(result.detail.location);
            var marker = new qq.maps.Marker({
              map: map,
              position: result.detail.location
            });
            markersArray.push(marker);
          }
        });
      },
      // 地图定位
      select() {
        // console.log(this.detailadd, this.detailadd.replace(/^\s+|\s+$/gm, ''))
        console.log(this.province + this.city + this.district + this.detailadd)

        if (this.detailadd == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入详细地址',
            type: 'warning'
          });
          return
        } else {
          var add = this.province + this.city + this.district + this.detailadd
          let that = this
          jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address==' + add +
            '&key=DSQBZ-5MM3P-HEODO-VG6IX-SBRJE-PSBNX&get_poi=1&output=jsonp', {
              myCustomUrlParam: 'veryNice'
            }).then(response => {
            // handle success
            if (response.message == '查询无结果') {
              this.$notify({
                title: '提示',
                duration: 1800,
                message: '详细地址输入有误，请重新输入',
                type: 'warning'
              });
              return
            }
            console.log('response', response)
            that.longitude = response.result.location.lng; // 经度
            that.latitude = response.result.location.lat; // 纬度
            that.city = response.result.address_components.city;
            // that.detailadd = response.result.address_components.street
            // that.detailadd = response.result.address_components.province + response.result
            // 	.address_components.city + response.result.address_components.district + response.result.title
            that.setMap()
          }).catch(error => {
            // handle error
          }).then(() => {
            // always executed
          });
        }

      },
      // 获取用户列表弹框
      userselect() {
        this.page1 = 1
        this.dialogFormVisible3 = true
        this.tableDataLoading4 = true
        this.userClass()
      },
      // 获取用户列表
      userClass() {
        let phone = -1
        if (this.phone) {
          phone = this.phone
        }
        let nickName = -1
        if (this.nickName) {
          nickName = this.nickName
        }
        this.$http({
          url: this.$http.adornUrl('userCertification/queryUserCertification'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.page1,
            'limit': this.size1,
            'name': this.nickName2,
            'phone': this.phone1,
            'classify': 2
          })
        }).then(({
          data
        }) => {
          this.tableDataLoading4 = false
          let returnData = data.data
          this.userIds = returnData
        })
      },
      // 查询用户列表
      userclick() {
        this.page1 = 1
        this.tableDataLoading4 = true
        this.userClass()
      },
      // 重置用户列表数据
      userclose() {
        this.page1 = 1
        this.phone1 = ''
        this.nickName2 = ''
        this.tableDataLoading4 = true

        this.userClass()
      },
      // 确定用户
      confirm(row) {
        this.userIdss = row.userId
        this.nickName1 = row.userName
        this.phone = row.phone
        // if (this.nickName == '' || this.nickName == null) {
        // 	this.nickName = row.phone
        // }
        this.dialogFormVisible3 = false
      },
      // 添加标签
      btnTj() {
        if (this.bq == '' || this.bq == ' ') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入标签名',
            type: 'warning'
          });
          return
        } else {
          console.log('this.bq', this.bq)
          this.labels.push(this.bq)
          this.bq = ''
        }

      },
      // 删除标签
      dels1(index) {
        this.labels.splice(index, 1);
        console.log(this.labels)
      },
      // 关闭添加信息弹框
      prev() {
        this.dialogFormVisible5 = false
        this.gameId = ''
        this.myLevel = ''
        this.orderTakingTime = ''
        this.value1 = ''
        this.value2 = ''
        this.orderTakingArea = ''
        this.homepageImg = ''
        this.oldMoney = ''
        this.money = ''
        this.memberMoney = ''
        this.minNum = ''
        this.sort = ''
        this.unit = '小时'
        this.latitude = ''
        this.longitude = ''
        this.voiceIntroduce = ''
        this.userIdss = ''
        this.city = ''
        this.region = ''
        this.nickName1 = ''
        this.nickName2 = ''
        this.phone = ''
        this.orderLevel = ''
        this.detailsImg = ''
        this.storeAddress = []
        this.storeAddre = '请选择城市'
        this.detailadd = ''
        this.twoGame = []
        this.gameNames = ''
        this.classTypes = ''
        console.log('this.detailsImg ', this.detailsImg)
      },
      // 审核
      shenhe(row) {
        this.shenheId = row.id
        this.dialogFormVisible8 = true
      },
      // 提交审核
      refuseto(row) {
        if (this.radio == 3 && this.contents == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入拒绝理由',
            type: 'warning'
          })
          return
        }
        if (this.contents.length > 100) {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '拒绝理由不能超过100字',
            type: 'warning'
          })
          return
        }
        let ids = this.shenheId
        let status = this.radio
        let content = this.contents
        this.$http({
          url: this.$http.adornUrl(`orderTaking/auditorOrderTaking`),
          method: 'get',
          params: this.$http.adornParams({
            'id': ids,
            'status': status,
            'content': content
          })
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.dialogFormVisible8 = false
            this.$message({
              message: '审核完成',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.radio = 0
                this.contents = ''
                this.InformationSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {}
            })
          }

        })

      },
      // 修改信息弹框
      choiCompile(index, row) {
        this.$refs.modifyServiceRef.showEdit(row);
      },
      // 查看评论
      plCompile(row) {
        console.log(row)
        this.renwuId = row.id
        this.dialogFormVisible10 = true
        this.pinglunSelect(row.id)
      },
      // 删除信息
      async choidelete(row) {
        console.log('22', row);
        let delid = row.id
        const rs = await deleteService(row.id);
        this.$message({
          message: '删除成功',
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.InformationSelect()
          }
        })
      },
      // 筛选信息
      animeDat2(value) {
        console.log(value)
        this.value = value
        this.value.forEach(element => {
          this.classifyIds = element;
        });
        // this.InformationSelect()
      },
      // 筛选信息
      animeDat4(state) {
        this.InformationSelect()
      },
      animeDat3(state) {
        this.InformationSelect()
      },
      animeDatF() {
        if (this.parentIdnum == 2) {
          this.parentId = ''
        } else {
          this.parentId = 0
        }
      },
      // 多选
      handleSelectionChange(val) {

        var arr = []
        for (var i in val) {
          arr.push(val[i].id)
        }
        this.multipleSelection = arr;
        console.log('val', val, this.multipleSelection)
      },
      // 批量删除
      choideletes() {
        console.log(this.multipleSelection)
        let delid = this.multipleSelection.toString()
        this.$confirm(`确定删除信息?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(`information/deleteInformationById?ids=${delid}`),
            method: 'post',
            params: this.$http.adornData({})
          }).then(({
            data
          }) => {
            if (data.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.InformationSelect()
                }
              })
            } else {
              this.$message({
                message: data.msg,
                type: 'error',
                duration: 1500,
                onClose: () => {}
              })
            }

          })
        }).catch(() => {})
      },
      // 服务推荐
      change2(val, row) {
        this.$http({
          url: this.$http.adornUrl(`orderTaking/updateOrderTakingRecommend/${row.id}`),
          method: 'post',
          data: this.$http.adornData({})
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          }

        })
      },
      // 服务上下架
      change3(val, row) {
        this.$http({
          url: this.$http.adornUrl(`orderTaking/updateOrderTakingStatus/${row.id}`),
          method: 'post',
          data: this.$http.adornData({})
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          }

        })
      },
      // 详情跳转
      updatesvideo(row) {
        this.$router.push({
          path: '/userDetail',
          query: {
            userId: row.userId
          }
        })
      },
      changeaa(e) {
        console.log('eeee', e)
        this.value1 = e
      },
      changeaa1(e) {
        console.log('eeee', e)
        this.value2 = e
      },
      // 获取服务分类数据
      classSelect() {
        this.$http({
          url: this.$http.adornUrl('game/queryGameName'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.page,
            'limit': this.limit,
            'parentId': 0
          })
        }).then(({
          data
        }) => {
          console.log('', data)
          if (data.code == 0) {
            this.tableDataLoading = false
            let returnData = data.data
            this.tableData = returnData
          } else {
            this.$notify({
              title: '提示',
              duration: 1800,
              message: data.msg,
              type: 'warning'
            });
          }


        })
      },
      // 添加服务分类
      classAdd(row) {
        console.log('row', row)
        this.classSelectList()
        if (row != 0) {
          this.titles = '修改服务分类'
          this.gameName = row.gameName
          this.gameIds = row.id
          this.gameImg = row.gameImg
          this.gameStatus = row.status
          this.parentId = row.parentId
          this.sort = row.sort
          if (row.parentId == 0) {
            this.parentIdnum = 1
          } else {
            this.parentIdnum = 2

          }


          this.percentage2 = 100
        } else {
          this.titles = '添加服务分类'
          this.parentId = ''
          this.gameName = ''
          this.gameIds = ''
          this.gameImg = ''
          this.gameStatus = ''
          this.parentIdnum = ''
          this.sort = 0
        }
        this.dialogFormVisible9 = true

      },
      // 启用与否
      change(id, status) {
        this.$http({
          url: this.$http.adornUrl(`game/enableGameName`),
          method: 'get',
          params: this.$http.adornParams({
            'id': id,
            'status': status
          })
        }).then(({
          data
        }) => {
          if (date.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.classSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.classSelect()
              }
            })
          }

        })
      },
      // 提交修改、添加服务分类
      refuseto1() {
        if (this.parentIdnum == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请选择分类类型',
            type: 'warning'
          })
          return
        }

        if (this.parentIdnum == 1) {
          this.parentId = 0
        } else {
          if (this.parentId == '') {
            this.$notify({
              title: '提示',
              duration: 1800,
              message: '请选择上级分类',
              type: 'warning'
            })
            return
          }
        }
        if (this.gameName == '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '请输入服务名称',
            type: 'warning'
          })
          return
        }
        // if (this.gameImg == '') {
        //   this.$notify({
        //     title: '提示',
        //     duration: 1800,
        //     message: '请上传服务图片',
        //     type: 'warning'
        //   })
        //   return
        // }
        if (this.titles == '添加服务分类') {
          var urls = 'game/addGameName'
        } else {
          var urls = 'game/updateGameName'
        }
        this.$http({
          url: this.$http.adornUrl(urls),
          method: 'get',
          params: this.$http.adornParams({
            'gameName': this.gameName,
            'status': this.gameStatus,
            'gameImg': this.gameImg,
            'id': this.gameIds,
            'parentId': this.parentId,
            'sort': this.sort
          })
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.dialogFormVisible9 = false
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.gameName = ''
                this.classSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'warning',
              duration: 1500,
              onClose: () => {}
            })
          }

        })
      },
      // s删除服务分类
      classdelete(row) {
        let delid = row.id
        this.$confirm(`确定删除此条信息?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(`game/deleteGameName?id=${delid}`),
            method: 'get',
            params: this.$http.adornData({})
          }).then(({
            data
          }) => {
            if (data.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.classSelect()
                }
              })
            } else {
              this.$message({
                message: data.msg,
                type: 'error',
                duration: 1500,
                onClose: () => {}
              })
            }

          })
        }).catch(() => {})
      },
      // 用户评论列表
      pinglunSelect(id) {
        this.userId = this.$store.state.user.id
        this.tableDataLoadingPl = true
        this.$http({
          url: this.$http.adornUrl('admin/evaluate/getUserEvaluate'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.page2,
            'limit': this.size2,
            'orderTakingId': id,
            'evaluateType': 2
          })
        }).then(({
          data
        }) => {
          this.tableDataLoadingPl = false
          let returnData = data.data
          this.pinglunData = returnData


        })
      },
      // 删除评论信息
      pinglundelete(row) {
        let delid = row.id
        this.$confirm(`确定删除此条信息?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(`takingComment/deleteOrderTakingComment/${delid}`),
            method: 'post',
            params: this.$http.adornData({})
          }).then(({
            data
          }) => {
            if (data.code == 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.pinglunSelect(this.renwuId)
                }
              })
            } else {
              this.$message({
                message: data.msg,
                type: 'error',
                duration: 1500,
                onClose: () => {}
              })
            }
          })
        }).catch(() => {})
      },
      handleChange(file, fileList) {
        this.hideUpload = fileList.length >= this.limit;
      },
      handleRemove(file, fileList) {
        this.hideUpload = fileList.length >= this.limit;
      },
      handleAvatarSuccess2(file, fileList) {
        this.detailsImg = file.data
      },
      // 删除
      clear(index) {
        this.detailsImg.splice(index, 1);
      },
      //上传成功
      handleUploadSuccess(file, fileList) {
        this.detailsImg.push(file.data)
        console.log('this.detailsImg', this.detailsImg)
        // this.detailsImg += file.data + ','
      },
      onprogress1(event, file, fileList) {
        console.log('详情图上传进度', parseInt(event.percent))
        this.percentage1 = parseInt(event.percent)
      },
      // 获取分类数据
      classSelectList() {
        this.tableDataLoading = true
        this.$http({
          url: this.$http.adornUrl('game/queryGameName'),
          method: 'get',
          params: this.$http.adornParams({
            'parentId': 0
          })
        }).then(({
          data
        }) => {
          this.tableDataLoading = false
          let returnData = data.data;
          this.classDatas = returnData
        })
      },
      tionSelect() {

        if (this.$route.query.userName) {
          this.search = this.$route.query.userName
        } else {
          this.search = ''
        }
        this.InformationSelect()
      },
      // 获取单位
      xianshi() {
        this.$http({
          url: this.$http.adornUrl('common/type/615'),
          method: 'get',
          data: this.$http.adornData({})
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.danwei = data.data.value.split(',')
          }
        })
      },
      // 修改排序
      sortBtn(row) {
        if (row.sort == null || row.sort === '') {
          this.$notify({
            title: '提示',
            duration: 1800,
            message: '排序不能为空',
            type: 'warning'
          })
          return
        }
        this.$http({
          url: this.$http.adornUrl(`orderTaking/updateOrderTakingSort/${row.id}`),
          method: 'post',
          params: this.$http.adornParams({
            'sort': row.sort
          })
        }).then(({
          data
        }) => {
          if (data.code == 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          } else {
            this.$message({
              message: data.msg,
              type: 'warning',
              duration: 1500,
              onClose: () => {
                this.InformationSelect()
              }
            })
          }

        })
      },
      // 查看服务详情
      look(text) {
        this.texts = text
        this.dialogFormVisibleDe = true

      },
    },
    mounted() {
      console.log('this.$route.query.userName', this.$route.query.userName)
      if (this.$route.query.userName) {
        this.search = this.$route.query.userName
      } else {
        this.search = ''
      }
      this.InformationSelect()
      this.homeSelect()
      this.xianshi()
    },
    watch: {
      '$route': 'tionSelect'
    }
  }
</script>

<style>
  .el-popover.height-100 {
    height: 100px;
  }
  .customWidth {
    width: 80% !important;
  }

  .adver_main.box {
    display: block;
    max-width: 100%;
    text-align: center;
    border: 1px dotted rgba(67, 79, 103, .4);
  }

  .cards {
    padding: 0 8px;
    margin-bottom: 15px;
  }

  .adver_main.box a {
    display: flex;
    justify-content: center;
    height: 150px;
    line-height: 150px;
    text-decoration: none
  }

  .bannerManin {
    border: 1px solid #e8e8e8;
    font-size: 14px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 113px;
    color: rgba(0, 0, 0, .65);
  }

  .bannerManin span {
    display: inline-block;
    margin-left: 5px;
  }

  .bannerManin img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .bannerbtn {
    display: flex;
    border-top: none !important;
    border: 1px solid #e8e8e8;
    padding: 11px;
    font-size: 14px;
    color: #3E8EF7;
  }

  .bannerbtn a {
    flex: 1;
    text-align: center;
    color: #3E8EF7 !important;
    text-decoration: none;
  }

  .imgs {
    position: relative;
    border-radius: 6px;
    width: 148px;
    height: 148px;
    margin-right: 10px;
    display: inline-block;
  }

  .dels {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  .dels .el-icon-delete {
    line-height: 148px;
    padding-left: 58px;
    font-size: 25px;
    color: #fff;
  }

  .imgs:hover .dels {
    width: 100%;
    height: 100%;
    background: #000;
    display: block;
    opacity: 0.5;
  }

  .bqList {
    padding: 4px 14px;
    margin: 4px;
    border: 1px solid #efefef;
    font-size: 12px;
    color: #999;
    border-radius: 4px;
    margin-right: 15px;
  }

  .delss {
    display: none;
    position: relative;
  }

  .delss .el-icon-delete {
    position: absolute;
    top: 0;
  }

  .bqList:hover .delss {
    display: initial;
    opacity: 0.5;

  }

  .tj {
    padding: 6px !important;
    margin: 4px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .ql-editor {
    word-break: break-all;
  }

  .texts {
    width: 100%;
  }

  .texts img {
    max-width: 100% !important;
  }
</style>
