<template>
  <el-collapse-item name="8" style="margin-top: 4px">
    <template #title>
      <h2 class="details_title"><span></span>结算信息</h2>
    </template>
    <TableCustom
      ref="tableCustomRef"
      border
      :operate="false"
      :columns="tableColumns"
      :get-page-list="getPageList"
      :pagination="false"
    >
      <template #settleAccount="{ scopeRow }">
        <div>进度款：￥{{ scopeRow.progressFee || 0 }}</div>
        <div>质保金：￥{{ scopeRow.periodSettleAccount || 0 }}</div>
        <div>管理费：￥{{ scopeRow.manageFee || 0 }}</div>
      </template>
      <template #fileList="{ scopeRow }">
        <el-row type="flex" align="middle" v-for="item in scopeRow.fileList" :key="item.id">
          <el-col :span="16">
            <span>{{ item.name }}</span>
          </el-col>
          <el-col :span="8" align="end">
            <el-button type="text" @click="handleDownload(scopeRow)">查看</el-button>
            <el-button type="text" @click="handleDownload(scopeRow)">下载</el-button>
          </el-col>
        </el-row>
      </template>
    </TableCustom>
  </el-collapse-item>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TableCustom,
  },
  data() {
    return {
      tableColumns: [
        {
          label: '发起结算时间',
          prop: 'createTime',
          minWidth: '180',
        },
        {
          label: '审核状态',
          prop: 'stateValue',
          minWidth: '180',
        },
        {
          label: '结算金额',
          prop: 'settleAccount',
          minWidth: '180',
        },
        {
          label: '结算说明',
          prop: 'contents',
          minWidth: '180',
        },
        {
          label: '结算资料',
          prop: 'fileList',
          minWidth: '180',
        },
      ],
      // 结算信息
      settleInfo: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.settleInfo = val?.settleInfo || []
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    // 列表接口
    async getPageList() {
      return { list: [this.settleInfo] }
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 下载附件
    handleDownload(file) {
      download(file.id, file.name);
    },
  }
}
</script>

<style>
</style>