export const AuditStatus = {
  // 审核状态：0待提交，1:待审核企业信息 2:已驳回 3:待确认保证金 4:已打款待审核 5:已认证
  // 待提交
  PendingSubmission: 0,
  // 待审核企业信息
  AuditingEnterpriseInfo: 1,
  // 已驳回
  Rejected: 2,
  // 待确认保证金
  WaitForConfirmationDeposit: 3,
  // 已打款待审核
  RemittancePendingAudit: 4,
  // 已认证
  Certified: 5,
};

export const AuditStatusDic = [
  {
    value: AuditStatus.AuditingEnterpriseInfo,
    label: '待审核企业信息',
  },
  {
    value: AuditStatus.Rejected,
    label: '已驳回',
  },
  {
    value: AuditStatus.WaitForConfirmationDeposit,
    label: '待确认保证金',
  },
  {
    value: AuditStatus.RemittancePendingAudit,
    label: '已打款待审核',
  },
  {
    value: AuditStatus.Certified,
    label: '已认证',
  },
];
