<template>
  <div>
    <FormRender
      ref="reportForm"
      :form-config="formConfig"
      :form-data.sync="formData"
      form-type="submit"
      label-width="100px"
      inline
      formType="search"
      @onSearch="getList"
      @onReset="onReset"
    >
    </FormRender>
    <TableCustom
      ref="tableCustomRef"
      :operate="false"
      border
      :columns="tableColumns"
      :get-page-list="getPageList"
    >
    </TableCustom>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import {AccountStatus} from '@/const/accountStatus';
import { getCompanyUserList } from '@/api/auth/index';

export default {
  components: {
    TableCustom,
    FormRender,
  },
  data() {
    return {
      // form配置
      formConfig: [
        {
          label: '姓名',
          prop: 'name',
          type: 'input',
        },
        {
          label: '登录手机号',
          prop: 'phone',
          type: 'input',
        },
      ],
      // 搜索值
      formData: {},
      tableColumns: [
        {
          label: '所属企业',
          prop: 'companyName',
          minWidth: '180',
        },
        {
          label: '姓名',
          prop: 'name',
          minWidth: '180',
        },
        {
          label: '人员类型',
          prop: 'userTypeDescription',
          minWidth: '180',
        },
        {
          label: '登录手机号',
          prop: 'phone',
          minWidth: '120',
        },
        {
          label: '状态',
          prop: 'statusDescription',
          minWidth: '120',
        },
        {
          label: '创建时间',
          prop: 'lastLoginTime',
          minWidth: '120',
        },
      ],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 处理状态改变
    handleStatusChange(row) {},
    // 列表接口
    async getPageList(pageInfo) {
      const res = await getCompanyUserList(pageInfo, this.formData);
      console.log(res);
      res.list = res.list.map(el => {
        return {...el, enable: el.status===AccountStatus.Enable}
      });
      return res;
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.getList()
    },
    handleModifySuccess() {
      this.getList();
    }
  }
}
</script>

<style lang='less' scoped>
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>