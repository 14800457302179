<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h2 class="dispatch_title"><span></span>当前订单状态：{{ basicInfo.ordersStateDesc }}</h2>
      </el-col>
      <el-col :span="12" align="end">
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button @click="handleOpenRemark">备注订单</el-button>
        <el-button v-if="basicInfo.ordersState === 1 || basicInfo.ordersState === 2" @click="handleOpenReservation"
          >修改预约信息</el-button
        >
        <el-button
          v-if="basicInfo.ordersState === 1 || basicInfo.ordersState === 2"
          type="primary"
          plain
          @click="handleOpenConsultant"
          >{{ basicInfo.ordersState === 2 ? '重新指派服务顾问' : '指派服务顾问' }}</el-button
        >
      </el-col>
    </el-row>
    <!-- 备注订单弹窗 -->
    <OrderRemarkDialog ref="orderRemarkDialogRef" @refresh="refreshRemark"></OrderRemarkDialog>
    <!-- 修改预约信息弹窗 -->
    <ReservationInformationDialog ref="reservationInformationDialogRef" @refresh="refresh">
    </ReservationInformationDialog>
    <!-- 指派服务顾问弹窗 -->
    <ServiceConsultantDialog ref="serviceConsultantDialogRef"></ServiceConsultantDialog>
  </div>
</template>

<script>
import OrderRemarkDialog from '../components/orderRemarkDialog.vue'
import ReservationInformationDialog from '../components/reservationInformationDialog.vue'
import ServiceConsultantDialog from '../components/serviceConsultantDialog.vue'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    OrderRemarkDialog,
    ReservationInformationDialog,
    ServiceConsultantDialog
  },
  methods: {
    // 打开备注订单弹窗
    handleOpenRemark() {
      this.$refs.orderRemarkDialogRef?.handleOpen(this.basicInfo)
    },
    // 打开修改预约信息弹窗
    handleOpenReservation() {
      this.$refs.reservationInformationDialogRef?.handleOpen(this.basicInfo)
    },
    // 分配服务顾问
    handleOpenConsultant() {
      this.$refs.serviceConsultantDialogRef?.handleOpen([{ id: this.basicInfo.id }])
    },
    // 更新接口
    refresh() {
      this.$emit('refresh')
    },
    // 备注更新
    refreshRemark() {
      this.$emit('refreshRemark')
    }
  }
}
</script>

<style lang='less' scoped>
.dispatch_title {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #95a3fc;
  margin: 0 0 40px;

  span {
    display: inline-block;
    width: 6px;
    height: 30px;
    background-color: #3181ff;
    margin-right: 4px;
  }
}
</style>