<template>
  <el-dialog title="添加附件" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-upload
      class="upload-demo"
      :action="$http.adornUrl('oss/upload')"
      :show-file-list="false"
      multiple
      :on-success="handleAvatarSuccess"
    >
      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
    </el-upload>
    <el-row type="flex" align="middle" v-for="(item, i) in fileList" :key="item.url">
      <span>{{ item.name }}</span>
      <i class="el-icon-delete file_del" @click="handleDelete(i)"></i>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      // 文件列表
      fileList: []
    }
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.fileList = []
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.fileList.push({
        name: file.name,
        url: res.data
      })
    },
    // 删除
    handleDelete(i) {
      this.fileList.splice(i, 1)
    },
    // 确定
    handleOk() {
      this.$emit('handleOk', this.fileList)
      this.handleClose()
    }
  }
}
</script>

<style lang='less' scoped>
.el-icon-delete {
  cursor: pointer;
  color: red;
  &:hover {
    color: #57afef;
  }
}
.file_del {
  margin-left: 10px;
}
</style>