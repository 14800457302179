<template>
  <el-dialog title="添加服务" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" inline class="demo-ruleForm">
      <el-form-item>
        <el-cascader
          :props="{ multiple: true }"
          collapse-tags
          @change="getList"
          placeholder="请选择服务分类"
          v-model="ruleForm.itemCategorys"
          :options="serviceTypeList"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-select @change="getList" v-model="ruleForm.itemType" placeholder="请选择服务类型" clearable>
          <el-option v-for="item in itemTypeOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input type="text" v-model="ruleForm.itemName" placeholder="请输入关键字查询"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">确定</el-button>
      </el-form-item>
    </el-form>
    <TableCustom
      ref="tableCustomRef"
      border
      :operate="false"
      :columns="tableColumns"
      :get-page-list="getPageList"
      :pagination="false"
      selection
      @onSelection="selectionTable"
    >
      <template #itemLogoUrl="{ scopeRow }">
        <el-image :src="scopeRow.itemLogoUrl"></el-image>
      </template>
    </TableCustom>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import { getDict } from '@/api/common/index'
import { saveServe, serviceQueryList } from '@/api/maintenance/index'
import { selectClassifyTree } from '@/api/serviced/index.js'
export default {
  components: {
    TableCustom,
  },
  data() {
    return {
      dialogVisible: false,
      // 预约单id
      id: '',
      ruleForm: {},
      // 服务分类选项
      serviceTypeList: [],
      // 服务类型选项
      itemTypeOptions: [],
      tableColumns: [
        {
          label: '服务图片',
          prop: 'itemLogoUrl',
          width: '120',
        },
        {
          label: '服务名称',
          prop: 'itemName',
          minWidth: '180',
        },
        {
          label: '服务类型',
          prop: 'itemTypeName',
          minWidth: '180',
        },
        {
          label: '服务分类',
          prop: 'itemCategoryPath',
          minWidth: '180',
        },
      ],
      // 选中的行
      selectRow: []
    }
  },
  methods: {
    handleOpen(row) {
      this.dialogVisible = true
      this.id = row.id
      this.getList();
      this.getServiceClassify()
      this.getType()
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    // 列表接口
    async getPageList() {
      let params = { ...this.ruleForm }
      if (this.ruleForm?.itemCategorys?.length) {
        params.itemCategorys = this.ruleForm.itemCategorys.map(item => item[item.length - 1]).join(',')
      } else {
        params.itemCategorys = ''
      }
      try {
        const res = await serviceQueryList(params)
        return { list: res }
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
    // 初始化服务分类下拉框
    getServiceClassify() {
      selectClassifyTree().then(res => {
        this.serviceTypeList = res;
      })
    },
    // 获取服务类型
    async getType() {
      const res = await getDict('itemType')
      this.itemTypeOptions = res
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 搜索
    handleSearch() {
      this.getList()
    },
    // 选中的复选框
    selectionTable(row) {
      this.selectRow = row
    },
    // 确定
    async handleOk() {
      if (!this.selectRow?.length) {
        this.$message.error('请选择服务')
        return
      }
      const res = await saveServe({
        preOrdersId: this.id,
        saveServeItemReqs: this.selectRow.map(item => ({ serveId: item.id, serveName: item.itemName, itemUnitPrice: item.itemUnitPrice }))
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('操作成功!')
      this.handleClose()
      this.$emit('refresh')
    }
  }
}
</script>

<style>
</style>