var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "18px",
            "margin-bottom": "15px",
          },
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("活动详情")]),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.tableData.list },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "nickName", label: "昵称", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      { staticStyle: { color: "#4f9dec", cursor: "pointer" } },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.nickName ? scope.row.nickName : "未绑定"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "integralName", label: "活动名称", width: "150" },
          }),
          _c("el-table-column", {
            attrs: { prop: "describes", label: "活动描述" },
          }),
          _c("el-table-column", {
            attrs: { prop: "money", label: "活动金额" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间", width: "160" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 15, 20, 25],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next,jumper",
              total: _vm.tableData.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }