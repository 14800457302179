var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      !!_vm.fileList?.length
        ? _c(
            "ul",
            { staticClass: "maintain_content" },
            _vm._l(_vm.fileList, function (item) {
              return _c(
                "li",
                { key: item.id },
                [_c("el-image", { attrs: { src: item.url } })],
                1
              )
            }),
            0
          )
        : _c("el-empty", { attrs: { "image-size": 160 } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "details_title" }, [
      _c("span"),
      _vm._v("维修部位照片"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }