<template>
	<el-tabs v-model="activeName" @tab-click="handleClick">
		<el-tab-pane label="用户反馈" name="first">
			<el-table v-loading="tableDataLoading" :data="tableData.list">
				<el-table-column prop="id" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="title" label="联系方式">
				</el-table-column>
				<el-table-column prop="content" label="内容">
				</el-table-column>
				<el-table-column prop="createAt" label="创建时间" width="160">
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 10px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30, 40]" :page-size="limit" :current-page="page"
					layout="total,sizes, prev, pager, next,jumper" :total="tableData.totalCount">
				</el-pagination>
			</div>
		</el-tab-pane>
		<el-tab-pane label="消息公告" name="four">
			<div style="text-align: right;margin: 10px;">
				<el-button size="mini" type="primary"  :disabled="!isAuth('message:add')"
					@click="addClick()">添加
				</el-button>
			</div>
			<el-table v-loading="tableDataLoading" :data="tableDataG.list">
				<el-table-column prop="id" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="title" label="公告内容">
				</el-table-column>
				<el-table-column prop="createAt" label="创建时间" width="160">
				</el-table-column>
				<el-table-column label="操作" width="160">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" :disabled="!isAuth('message:update')"
							@click="addClick(scope.row)">修改
						</el-button>
						<el-button size="mini" type="danger" :disabled="!isAuth('message:delete')"
							@click="deleteClick(scope.row)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 10px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30, 40]" :page-size="limit" :current-page="page"
					layout="total,sizes, prev, pager, next,jumper" :total="tableDataG.totalCount">
				</el-pagination>
			</div>
		</el-tab-pane>
		<!-- <el-tab-pane label="用户消息" name="fourth">
			<el-table
			v-loading="tableDataLoading"
				:data="tableData.list">
				<el-table-column
				 prop="id"
				 label="编号"
				 width="80">
				 </el-table-column>
			 <el-table-column
				prop="userName"
				label="用户名称">
				</el-table-column>
				<el-table-column
				 prop="title"
				 label="消息标题">
				 </el-table-column>
				<el-table-column
				prop="content"
				label="消息内容">
				</el-table-column>
				<el-table-column
				prop="createAt"
				label="创建时间">
				</el-table-column>
				<el-table-column
					label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="updataDetails(scope.row)">用户详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 10px;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30, 40]"
					:page-size="limit"
					:current-page="page"
					layout="total,sizes, prev, pager, next,jumper"
					:total="tableData.totalCount">
				</el-pagination>
			</div>
		</el-tab-pane>
		<el-tab-pane label="消息推送" name="seventh">
			<div style="float: right;margin-right:2%;">
				<el-button style='margin: 10px 0;' :disabled="!isAuth('message:push')" size="mini"  type="primary" icon="document" @click="magNotice">消息推送</el-button>
			</div>
			<el-table
			v-loading="tableDataLoading"
				:data="tableData.list">
			<el-table-column
				fixed
			 prop="id"
			 label="编号"
			 width="80">
			 </el-table-column>
			 <el-table-column
				prop="userName"
				label="用户名称">
				</el-table-column>
				<el-table-column
				 prop="title"
				 label="消息标题">
				 </el-table-column>
				<el-table-column
				prop="content"
				label="消息内容"
				width="280">
				</el-table-column>
				<el-table-column
				prop="createAt"
				label="创建时间">
				</el-table-column>
				<el-table-column
					label="操作"
				width="120">
					<template slot-scope="scope">
						<el-button
							size="mini"
							type="primary"
							@click="updataDetails(scope.row)">用户详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 10px;">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:page-sizes="[5, 10, 15, 20]"
					:page-size="limit"
					:current-page="page"
					layout="total,sizes, prev, pager, next,jumper"
					:total="tableData.totalCount">
				</el-pagination>
			</div> -->
		<!-- 消息推送 -->
		<!-- <el-dialog title="消息推送" :visible.sync="dialogFormVisible2" center>
				<div style="margin-bottom: 10px;">
					<span style="width: 200px;display: inline-block;text-align: right;">选择：</span>
					<el-select v-model="flag" placeholder="请选择类型" style="width:50%" >
					  <el-option v-for="item in flags" :key="item.value" :label="item.label" :value="item.value">
					  </el-option>
					</el-select>&nbsp;&nbsp;&nbsp;&nbsp;
				</div>
				<div style="margin-bottom: 10px;" v-if="flag == 1">
					<span style="width: 200px;display: inline-block;text-align: right;">用户手机号：</span>
					<el-input style="width: 50%;" v-model="phone" placeholder="请输入用户手机号"></el-input>
				</div>
				<div style="margin-bottom: 10px;">
					<span style="width: 200px;display: inline-block;text-align: right;">消息标题：</span>
					<el-input style="width: 50%;" v-model="title" placeholder="请输入消息标题"></el-input>
				</div>
				<div style="margin-bottom: 10px;">
					<span style="width: 200px;display: inline-block;text-align: right;position: relative;top: -65px;">消息内容：</span>
					<el-input style="width: 50%;" type="textarea" rows="4" v-model="content" placeholder="请输入消息内容"></el-input>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogFormVisible2 = false">取 消</el-button>
					<el-button type="primary" @click="magNoticeTo()">确 定</el-button>
				</div>
			</el-dialog>
		</el-tab-pane> -->
		<el-dialog title="投诉处理" :visible.sync="dialogFormVisible" center>
			<div style="margin-bottom: 10px;">
				<span style="width: 200px;display: inline-block;text-align: right;">审核结果：</span>
				<el-radio-group v-model="radio">
					<el-radio :label="0">通过</el-radio>
					<el-radio :label="1">驳回</el-radio>
				</el-radio-group>
			</div>
			<div style="margin-bottom: 10px;" v-if="radio===1">
				<span
					style="width: 200px;display: inline-block;text-align: right;position: relative;top: -70px;">驳回理由：</span>
				<el-input style="width:50%;" v-model="content" type="textarea" :rows="4" placeholder="请输入驳回理由">
				</el-input>
			</div>
			<div style="margin-bottom: 10px;" v-if="radio===0">
				<span style="width: 200px;display: inline-block;text-align: right;">处理结果：</span>
				<el-radio-group v-model="clStatus">
					<el-radio :label="0">封号</el-radio>
					<!-- <el-radio :label="1" v-if="platform==3">删除</el-radio> -->
					<el-radio :label="3">处理</el-radio>
					<el-radio :label="2">不处理</el-radio>
				</el-radio-group>
			</div>
			<div style="margin-bottom: 10px;" v-if="radio===0">
				<span
					style="width: 200px;display: inline-block;text-align: right;position: relative;top: -70px;">处理说明：</span>
				<el-input style="width:50%;" v-model="content" type="textarea" :rows="4" placeholder="请输入处理说明">
				</el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="refuseto()">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="titles" :visible.sync="dialogFormVisibleG" center>

			<div style="margin-bottom: 10px;">
				<span
					style="width: 200px;display: inline-block;text-align: right;position: relative;top: -70px;">公告内容：</span>
				<el-input style="width:50%;" v-model="title" type="textarea" :rows="4" placeholder="请输入公告内容">
				</el-input>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisibleG = false">取 消</el-button>
				<el-button type="primary" @click="refusetoBtn()">确 定</el-button>
			</div>
		</el-dialog>
	</el-tabs>
</template>

<script>
	export default {
		data() {
			return {
				limit: 10,
				page: 1,
				state: 2,
				url: '',
				title: '',
				type: '',
				id: '',
				types: [{
						value: 'word',
						label: '文字'
					},
					{
						value: 'url',
						label: '链接'
					}
				],
				form: {
					id: '',
					title: '',
					url: '',
					type: '',
					state: '',
					types: [{
							value: 'word',
							label: '文字'
						},
						{
							value: 'url',
							label: '链接'
						}
					],
				},
				phone: '',
				content: '',
				flag: '',
				flags: [{
						value: 1,
						label: '单个用户'
					},
					{
						value: 2,
						label: '全部用户'
					}
				],
				formLabelWidth: '200px',
				activeName: 'first',
				tableDataLoading: false,
				dialogFormVisible1: false,
				dialogFormVisible2: false,
				dialogFormVisible: false,
				tableData: [],
				tableDataTs:{},
				tableDataG:{},
				radio: '',
				clStatus: '',
				messageId: '',
				platform: '',
				platform1: '',
				platformList: [{
						id: '',
						name: '全部'
					},
					{
						id: 1,
						name: '聊天投诉'
					},
					{
						id: 2,
						name: '用户投诉'
					},
					{
						id: 3,
						name: '任务投诉'
					},
					{
						id: 4,
						name: '服务投诉'
					}
				],
				status:'',
        statusT:'',
				statusList: [{
						id: '',
						name: '全部'
					},
					{
							id: 0,
							name: '待处理'
						},
					{
						id: 1,
						name: '已驳回'
					},
					{
						id: 2,
						name: '已封号'
					},
					{
						id: 3,
						name: '已删除'
					},
					{
						id: 4,
						name: '不处理'
					},
					{
						id: 5,
						name: '已处理'
					}
				],
				dialogFormVisibleG:false,
				titles:'',
			}
		},
		methods: {
			// 详情跳转
			updataDetails(userId) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: userId
					}
				});
			},
			handleSizeChange(val) {
				this.limit = val;
				this.dataSelect()
			},
			handleCurrentChange(val) {
				this.page = val;
				this.dataSelect()
			},
			handleSizeChange1(val) {
				this.limit = val;
				this.dataSelect1()
			},
			handleCurrentChange1(val) {
				this.page = val;
				this.dataSelect1()
			},
			handleClick(tab, event) {
				this.status = ''
        this.statusT = ''
				if (tab._props.label == '用户反馈') {
					this.page = 1
					this.limit = 10
					this.state = 2
					this.dataSelect()
				}
				// if (tab._props.label == '用户消息') {
				// 	this.page = 1
				// 	this.limit = 5
				// 	this.state = 5
				// 	this.dataSelect()
				// }
				if (tab._props.label == '消息公告') {
					this.page = 1
					this.limit = 10
					this.state = 1
					this.dataSelect()
				}
				// if (tab._props.label == '消息推送') {
				// 	this.page = 1
				// 	this.limit = 5
				// 	this.state = 8
				// 	this.dataSelect()
				// }
				if (tab._props.label == '投诉消息') {
					this.page = 1
					this.limit = 10
					this.state = 3
					this.platform1 = ''
					this.dataSelect1()
				}
			},
			// 消息推送
			magNotice() {
				this.dialogFormVisible2 = true
			},
			// 消息推送
			magNoticeTo() {
				if (this.flag == '') {
					this.$notify({
						title: '提示',
						duration: 1800,
						message: '请选择类型',
						type: 'warning'
					});
					return
				}
				if (this.title == '') {
					this.$notify({
						title: '提示',
						duration: 1800,
						message: '请输入标题',
						type: 'warning'
					});
					return
				}
				if (this.content == '') {
					this.$notify({
						title: '提示',
						duration: 1800,
						message: '请输入内容',
						type: 'warning'
					});
					return
				}
				if (this.flag == 1) {
					if (this.phone == '') {
						this.$notify({
							title: '提示',
							duration: 1800,
							message: '请输入用户手机号',
							type: 'warning'
						});
						return
					}
					this.$http({
						url: this.$http.adornUrl('/cash/sendMsg'),
						method: 'get',
						params: this.$http.adornParams({
							'title': this.title,
							'content': this.content,
							'phone': this.phone,
							'flag': this.flag
						})
					}).then(({
						data
					}) => {
						this.dialogFormVisible2 = false
						if (data.code == -100) {
							this.$message({
								message: data.msg,
								type: 'error',
								duration: 1500,
								onClose: () => {
									this.title = ''
									this.content = ''
									this.phone = ''
									this.flag = ''
									this.dataSelect()
								}
							})
						}
						if (data.code == 0) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.title = ''
									this.content = ''
									this.phone = ''
									this.flag = ''
									this.dataSelect()
								}
							})
						}
					})
				}
				if (this.flag == 2) {
					this.$http({
						url: this.$http.adornUrl('/cash/sendMsg'),
						method: 'get',
						params: this.$http.adornParams({
							'title': this.title,
							'content': this.content,
							'flag': this.flag,
						})
					}).then(({
						data
					}) => {
						this.dialogFormVisible2 = false
						if (data.code == 0) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.title = ''
									this.content = ''
									this.flag = ''
									this.dataSelect()
								}
							})
						}
					})
				}
			},
			// 获取数据列表
			dataSelect() {
				this.tableDataLoading = true
				this.$http({
					url: this.$http.adornUrl(`message/page/${this.state}/${this.page}/${this.limit}`),
					method: 'get',
					params: this.$http.adornParams({})
				}).then(({
					data
				}) => {
					this.tableDataLoading = false
					if(this.state==1){
						this.tableDataG =  data.data;
					}else{
						for (var i in data.data.list) {
							var a = data.data.list[i].content.toString()
							var b = JSON.parse(a)
							data.data.list[i].content = b.content
							// console.log('key', b.content)
						}
						let returnData = data.data;
						this.tableData = returnData
					}


				})
			},
			// 获取数据列表
			dataSelect1() {
				this.tableDataLoading = true
				this.$http({
					url: this.$http.adornUrl(`message/selectMessageByType`),
					method: 'get',
					params: this.$http.adornParams({
						'page': this.page,
						'limit': this.limit,
						'state': this.state,
						'platform': this.platform1,
						'status':this.statusT
					})
				}).then(({
					data
				}) => {
					this.tableDataLoading = false
					if (this.state == 2) {
						for (var i in data.data.list) {
							var a = data.data.list[i].content.toString()
							var b = JSON.parse(a)
							data.data.list[i].content = b.content
							// console.log('key', b.content)
						}
					}
					for (var i in data.data.list) {
						if (data.data.list[i].image) {
							data.data.list[i].image = data.data.list[i].image.split(',')
						}

					}

					let returnData = data.data;
					this.tableDataTs = returnData

				})
			},
			//审核弹框
			refuseClick(rows) {
				this.radio = ''
				this.clStatus = ''
				this.content = ''
				this.messageId = rows.id
				this.platform = rows.platform
				this.dialogFormVisible = true
			},
			//提交审核
			refuseto() {
				if (this.radio === '') {
					this.$notify({
						title: '提示',
						duration: 1800,
						message: '请选择审核结果',
						type: 'warning'
					});
					return
				}
				if (this.radio === 1) {
					if (this.content == '') {
						this.$notify({
							title: '提示',
							duration: 1800,
							message: '请输入拒绝理由',
							type: 'warning'
						});
						return
					}
				}
				if (this.radio === 0) {
					if (this.clStatus === '') {
						this.$notify({
							title: '提示',
							duration: 1800,
							message: '请选择处理结果',
							type: 'warning'
						});
						return
					}
				}
				if (this.radio === 0) {
					if (this.content === '') {
						this.$notify({
							title: '提示',
							duration: 1800,
							message: '请输入处理说明',
							type: 'warning'
						});
						return
					}
				}
				var status = ''
				if (this.radio === 1) {
					status = 1
				} else {
					if (this.clStatus === 0) {
						status = 2
					}
					if (this.clStatus === 1) {
						status = 3
					}
					if (this.clStatus === 2) {
						status = 4
					}
					if (this.clStatus === 3) {
						status = 5
					}
				}

				this.$http({
					url: this.$http.adornUrl('message/auditMessage'),
					method: 'post',
					params: this.$http.adornParams({
						'messageId': this.messageId,
						'status': status,
						'auditContent': this.content
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.dialogFormVisible = false
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.content = ''
								this.status = ''
								this.dataSelect1()
							}
						})
					} else {
						this.$message({
							message: data.msg,
							type: 'warning',
							duration: 1500,
							onClose: () => {}
						})
					}

				})
			},
			animeDat4() {
				this.page = 1
				this.dataSelect1()
			},
			// 添加/修改弹框
			addClick(row){
				if(row){
					this.titles = '修改公告'
					this.title = row.title
					this.id = row.id
				}else{
					this.titles = '添加公告'
					this.title = ''
					this.id = ''
				}
				this.dialogFormVisibleG = true
			},
			refusetoBtn(){
				if (this.title == '') {
					this.$notify({
						title: '提示',
						duration: 1800,
						message: '请输入公告内容',
						type: 'warning'
					});
					return
				}
				if(this.titles == '添加公告'){
					var url = 'message/add'
				}else{
					var url = 'message/update'
				}
				this.$http({
					url: this.$http.adornUrl(url),
					method: 'post',
					// params: this.$http.adornParams({
						data: this.$http.adornData({
						'title': this.title,
						'id': this.id,
						'state': 1
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.dialogFormVisibleG = false
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.dataSelect()
							}
						})
					} else {
						this.$message({
							message: data.msg,
							type: 'warning',
							duration: 1500,
							onClose: () => {}
						})
					}

				})
			},
			// 删除信息
			deleteClick(row) {
				let delid = row.id
				this.$confirm(`确定删除此条信息?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: this.$http.adornUrl(`message/delete/${delid}`),
						method: 'get',
						params: this.$http.adornData({})
					}).then(({
						data
					}) => {
						this.$message({
							message: '删除成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.dataSelect()
							}
						})
					})
				}).catch(() => {})
			},

		},
		mounted() {
			this.dataSelect()
		}
	};
</script>

<style>

</style>
