var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    [
      _c("h2", [_vm._v("布局设置")]),
      _c(
        "el-form-item",
        { attrs: { label: "导航条类型" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.navbarLayoutType,
                callback: function ($$v) {
                  _vm.navbarLayoutType = $$v
                },
                expression: "navbarLayoutType",
              },
            },
            [
              _c("el-radio", { attrs: { label: "default", border: "" } }, [
                _vm._v("default"),
              ]),
              _c("el-radio", { attrs: { label: "inverse", border: "" } }, [
                _vm._v("inverse"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "侧边栏皮肤" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.sidebarLayoutSkin,
                callback: function ($$v) {
                  _vm.sidebarLayoutSkin = $$v
                },
                expression: "sidebarLayoutSkin",
              },
            },
            [
              _c("el-radio", { attrs: { label: "light", border: "" } }, [
                _vm._v("light"),
              ]),
              _c("el-radio", { attrs: { label: "dark", border: "" } }, [
                _vm._v("dark"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }