export const config = {
  // 获取服务分类启用列表
  selectClassifyList: 'game/selectClassifyList',
  // 服务类型
  serviceTypes: 'sys/dict/getlist/itemType',
  // 新增服务
  serviceItemAdd: '/serviceItem/add',
  // /serviceItem/delete 删除数据,
  serviceItemDelete: '/serviceItem/delete',
  // /serviceItem/edit 修改数据
  serviceItemEdit: '/serviceItem/edit',
  // /serviceItem/get 获取详情
  serviceItemGet: '/serviceItem/get',
  // /serviceItem/list 获取列表
  serviceItemList: '/serviceItem/list',
  // 上下架
  serviceItemDisable: '/serviceItem/disable',
};
