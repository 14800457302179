var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h2", { staticClass: "dispatch_title" }, [
              _c("span"),
              _vm._v("当前订单状态：" + _vm._s(_vm.basicInfo.ordersStateDesc)),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
              _c("el-button", { on: { click: _vm.handleOpenRemark } }, [
                _vm._v("备注订单"),
              ]),
              _vm.basicInfo.ordersState === 1 || _vm.basicInfo.ordersState === 2
                ? _c(
                    "el-button",
                    { on: { click: _vm.handleOpenReservation } },
                    [_vm._v("修改预约信息")]
                  )
                : _vm._e(),
              _vm.basicInfo.ordersState === 1 || _vm.basicInfo.ordersState === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.handleOpenConsultant },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.basicInfo.ordersState === 2
                            ? "重新指派服务顾问"
                            : "指派服务顾问"
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrderRemarkDialog", {
        ref: "orderRemarkDialogRef",
        on: { refresh: _vm.refreshRemark },
      }),
      _c("ReservationInformationDialog", {
        ref: "reservationInformationDialogRef",
        on: { refresh: _vm.refresh },
      }),
      _c("ServiceConsultantDialog", { ref: "serviceConsultantDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }