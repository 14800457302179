var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "售后处理记录",
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.afterSalesInfo?.length
        ? _c(
            "el-timeline",
            { attrs: { reverse: false } },
            _vm._l(_vm.afterSalesInfo, function (item) {
              return _c(
                "el-timeline-item",
                {
                  key: item.id,
                  attrs: { timestamp: item.createTime, placement: "top" },
                },
                [
                  _c("div", { staticClass: "check_operate" }, [
                    _vm._v(_vm._s(item.nodeName)),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticClass: "check_user", attrs: { span: 24 } },
                        [
                          _c("span", [_vm._v(_vm._s(item.createUserTypeDesc))]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(item.createUserName))]
                          ),
                        ]
                      ),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "check_content" }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                        item?.fileInfoList?.length
                          ? _c(
                              "ul",
                              { staticClass: "maintain_content" },
                              _vm._l(item?.fileInfoList, function (file) {
                                return _c(
                                  "li",
                                  { key: file.id },
                                  [
                                    _c("el-image", {
                                      attrs: { src: file.url },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("关 闭"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }