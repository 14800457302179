var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "font-size": "18px", "margin-bottom": "15px" } },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("收入统计")]),
        ]
      ),
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "150px", "margin-left": "10px" },
              on: { change: _vm.orderfenxi },
              model: {
                value: _vm.flag,
                callback: function ($$v) {
                  _vm.flag = $$v
                },
                expression: "flag",
              },
            },
            _vm._l(_vm.flags, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v("    "),
          _vm.flag == 1
            ? _c("el-date-picker", {
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择开始时间",
                },
                on: { change: _vm.animeOrder },
                model: {
                  value: _vm.info.stockDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "stockDate", $$v)
                  },
                  expression: "info.stockDate",
                },
              })
            : _vm._e(),
          _vm.flag == 2
            ? _c("el-date-picker", {
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "date",
                  format: "yyyy-MM",
                  "value-format": "yyyy-MM",
                  placeholder: "选择开始时间",
                },
                on: { change: _vm.animeOrder },
                model: {
                  value: _vm.info.stockDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "stockDate", $$v)
                  },
                  expression: "info.stockDate",
                },
              })
            : _vm._e(),
          _vm.flag == 3
            ? _c("el-date-picker", {
                staticStyle: { width: "200px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "date",
                  format: "yyyy",
                  "value-format": "yyyy",
                  placeholder: "选择开始时间",
                },
                on: { change: _vm.animeOrder },
                model: {
                  value: _vm.info.stockDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "stockDate", $$v)
                  },
                  expression: "info.stockDate",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading1,
                  expression: "tableDataLoading1",
                },
              ],
            },
            [
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [_vm._v("总金额")]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeMoney
                              ? _vm.serviceMoney.helpTakeMoney
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("元"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [
                      _vm._v("总订单数"),
                    ]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeCount
                              ? _vm.serviceMoney.helpTakeCount
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("笔"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [
                      _vm._v("待服务订单数"),
                    ]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeDaiFuWuCount
                              ? _vm.serviceMoney.helpTakeDaiFuWuCount
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("笔"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [
                      _vm._v("服务中订单数"),
                    ]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeFuWuZhongCount
                              ? _vm.serviceMoney.helpTakeFuWuZhongCount
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("笔"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [
                      _vm._v("已完成订单数"),
                    ]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeYiWanChengCount
                              ? _vm.serviceMoney.helpTakeYiWanChengCount
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("笔"),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "box_num" }, [
                    _c("div", { staticClass: "box_color" }, [
                      _vm._v("已取消订单数"),
                    ]),
                    _c("div", { staticClass: "text_color" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.serviceMoney.helpTakeYiQuXiaoCountCount
                              ? _vm.serviceMoney.helpTakeYiQuXiaoCountCount
                              : 0
                          )
                        ),
                      ]),
                      _vm._v("笔"),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务拒绝",
            visible: _vm.dialogFormVisible7,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible7 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("拒绝理由：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "text", placeholder: "请输入拒绝理由" },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible7 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.refuseto()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片",
            visible: _vm.dialogVisible,
            width: "35%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        _vm._l(_vm.imageUrl, function (item, index) {
          return _vm.imageUrl.length > 0
            ? _c(
                "span",
                {
                  key: index,
                  staticStyle: {
                    width: "100px",
                    height: "100px",
                    display: "inline-block",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: item, alt: "" },
                  }),
                ]
              )
            : _c("span", [_vm._v("暂无图片")])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }