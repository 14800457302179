var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "10px",
            display: "inline-block",
            width: "100%",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "3px",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("城市：")]
              ),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入城市" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleChange55.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.city,
                  callback: function ($$v) {
                    _vm.city = $$v
                  },
                  expression: "city",
                },
              }),
              _vm._v("   "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "3px",
              },
            },
            [
              _c("span", [_vm._v("师傅姓名：")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入师傅姓名" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleChange55.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              }),
              _vm._v("   "),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.handleChange55 },
            },
            [_vm._v(" 查询")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.cleans },
            },
            [_vm._v(" 重置")]
          ),
        ],
        1
      ),
    ]),
    _vm._m(0),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            staticStyle: { "z-index": "1000" },
            attrs: {
              title: "待服务订单",
              visible: _vm.dialogFormVisible2,
              center: "",
              width: "70%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible2 = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: { display: "inline-block", "text-align": "right" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "15px" },
                    attrs: { size: "mini", type: "primary", icon: "document" },
                    on: { click: _vm.animeDat },
                  },
                  [_vm._v("刷新 ")]
                ),
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoading,
                    expression: "tableDataLoading",
                  },
                ],
                attrs: { data: _vm.tableData.list },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    fixed: "",
                    prop: "helpOrderId",
                    label: "编号",
                    width: "80",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "",
                    prop: "userName",
                    label: "发单用户",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#4f9dec",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updates(scope.row.userId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.userName
                                      ? scope.row.userName
                                      : "未绑定"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "task_type", label: "服务类型" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.task_type == 1
                            ? _c("span", [_vm._v("悬赏任务")])
                            : _vm._e(),
                          scope.row.task_type == 2
                            ? _c("span", [_vm._v("报价任务")])
                            : _vm._e(),
                          scope.row.task_type == 3
                            ? _c("span", [_vm._v("一口价任务")])
                            : _vm._e(),
                          scope.row.task_type == 4
                            ? _c("span", [_vm._v("师傅任务")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "下单人姓名", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "phone", label: "下单人手机号", width: "120" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "province",
                    label: "下单人地址",
                    width: "200",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.province) +
                                _vm._s(scope.row.city) +
                                _vm._s(scope.row.district) +
                                _vm._s(scope.row.detailsAddress)
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "content", label: "提交内容" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "money", label: "接单价格", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "startTaskAddress", label: "服务开始地址" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "endTaskAddress", label: "服务结束地址" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "startTime", label: "服务开始时间" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "endTime", label: "服务结束时间" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "startTaskImg",
                    label: "服务开始前图片",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.startTaskImg
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                  },
                                },
                                _vm._l(
                                  scope.row.startTaskImg.split(","),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              title: "",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px",
                                                margin: "3px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                src: item,
                                                alt: "",
                                              },
                                              slot: "reference",
                                            }),
                                            _c("img", {
                                              staticStyle: {
                                                width: "300px",
                                                height: "auto",
                                              },
                                              attrs: { src: item, alt: "" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "endTaskImg",
                    label: "服务结束后图片",
                    width: "180",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.endTaskImg
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                  },
                                },
                                _vm._l(
                                  scope.row.endTaskImg.split(","),
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              title: "",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px",
                                                margin: "3px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                src: item,
                                                alt: "",
                                              },
                                              slot: "reference",
                                            }),
                                            _c("img", {
                                              staticStyle: {
                                                width: "300px",
                                                height: "auto",
                                              },
                                              attrs: { src: item, alt: "" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "startAddress",
                    label: "服务开始地址",
                    width: "200",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "endAddress",
                    label: "服务结束地址",
                    width: "200",
                  },
                }),
                _c("el-table-column", {
                  attrs: { prop: "startTaskLongitude", label: "服务开始经度" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "startTaskLatitude", label: "服务开始纬度" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "endTaskLongitude", label: "服务结束经度" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "endTaskLatitude", label: "服务结束纬度" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "code", label: "确认码" },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "serviceTime",
                    label: "预约时间",
                    fixed: "right",
                    width: "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "font-weight": "600",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.deliveryTime))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "remarks", label: "订单备注", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.remarks ? scope.row.remarks : "未填写"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    prop: "status",
                    label: "状态",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.status === 1
                            ? _c(
                                "span",
                                { staticStyle: { color: "#4f9dec" } },
                                [_vm._v("待服务")]
                              )
                            : _vm._e(),
                          scope.row.status === 2
                            ? _c(
                                "span",
                                { staticStyle: { color: "#4f9dec" } },
                                [_vm._v("服务中")]
                              )
                            : _vm._e(),
                          scope.row.status === 3
                            ? _c(
                                "span",
                                { staticStyle: { color: "#4f9dec" } },
                                [_vm._v("已完成")]
                              )
                            : _vm._e(),
                          scope.row.status === 4
                            ? _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("已取消"),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "10px" } },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.limit,
                    "current-page": _vm.page,
                    layout: "total,sizes, prev, pager, next",
                    total: _vm.tableData.totalCount,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "加钟记录",
              visible: _vm.dialogFormVisible,
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableDataLoadingU,
                    expression: "tableDataLoadingU",
                  },
                ],
                attrs: { data: _vm.userData.list },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "chatContentId", label: "编号", width: "80" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "content", label: "信息内容" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "创建时间" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticStyle: { width: "95%", height: "80vh", "margin-left": "2.5%" },
        attrs: { id: "container" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }