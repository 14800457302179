<template>
  <div>
    <h2 class="details_title"><span></span>服务信息</h2>
    <div style="text-align: right; margin-bottom: 10px">
      <el-button v-if="basicInfo.isShowAddServeBtn === 1" icon="el-icon-plus" @click="handleAddService"
        >添加服务</el-button
      >
    </div>
    <TableCustom ref="tableCustomRef" border :columns="tableColumns" :get-page-list="getPageList" :pagination="false">
      <template #itemLogoUrl="{ scopeRow }">
        <el-image :src="scopeRow.itemLogoUrl"></el-image>
      </template>
      <template #constructionPlan="{ scopeRow }">
        <el-button
          v-if="!scopeRow.schemeState || scopeRow.schemeState === 1 || scopeRow.schemeState === 3"
          type="primary"
          @click="eidtScheme(scopeRow, 1)"
          >编辑施工方案</el-button
        >
        <el-button v-else type="success" @click="eidtScheme(scopeRow, 2)">查看施工方案</el-button>
      </template>
      <!-- 方案状态 -->
      <template #schemeStateDesc="{ scopeRow }">
        <el-tag
          v-if="scopeRow.schemeStateDesc"
          :type="schemeStateOptions.find((item) => item.value === scopeRow.schemeState)?.type"
          >{{ scopeRow.schemeStateDesc }}</el-tag
        >
      </template>
      <!-- 服务状态 -->
      <template #orderStateDesc="{ scopeRow }">
        <el-tag :type="serviceStateOptions.find((item) => item.value === scopeRow.orderState)?.type">{{
          scopeRow.orderStateDesc
        }}</el-tag>
      </template>
      <!-- 验收状态 -->
      <template #checkAcceptDesc="{ scopeRow }">
        <el-tag :type="checkAcceptOptions.find((item) => item.value === scopeRow.checkAccept)?.type">{{
          scopeRow.checkAcceptDesc
        }}</el-tag>
      </template>
      <template #operate="{ scopeRow }">
        <el-button
          v-if="scopeRow.generateType !== 1 && (scopeRow.schemeState === 1 || scopeRow.schemeState === 3)"
          type="text"
          style="color: #f56c6c"
          @click="handleDelService(scopeRow)"
          >删除</el-button
        >
        <el-button v-if="scopeRow.orderState === 2" type="text" @click="pcCancelServeOrder(scopeRow)"
          >取消订单</el-button
        >
      </template>
    </TableCustom>
    <div class="service_total">
      <span
        >预估总额：<i>￥{{ preOrdersSerInfo?.predictAmount || 0 }}</i></span
      >
    </div>
    <AddServiceDialog ref="addServiceDialogRef" @refresh="refresh"></AddServiceDialog>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import AddServiceDialog from '../components/addServiceDialog.vue'
import { schemeStateOptions, serviceStateOptions, checkAcceptOptions } from '@/const/dispatchOrderDict'
import { pcCancelServeOrder, logicDelServeOrder } from '@/api/maintenance/index'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TableCustom,
    AddServiceDialog
  },
  data() {
    return {
      // 是否隐藏四个字段（在待沟通情况下隐藏）
      isHidden: false,
      tableColumns: [
        {
          label: '服务单号',
          prop: 'serveNo',
          minWidth: '120',
        },
        {
          label: '服务图片',
          prop: 'itemLogoUrl',
          minWidth: '120',
        },
        {
          label: '服务分类',
          prop: 'itemCategoryPath',
          minWidth: '120',
        },
        {
          label: '服务名称',
          prop: 'itemName',
          minWidth: '120',
        },
        {
          label: '服务类型',
          prop: 'itemTypeName',
          minWidth: '120',
        },
        {
          label: '预约金额',
          prop: 'itemUnitPrice',
          minWidth: '120',
        },
        {
          label: '方案',
          prop: 'constructionPlan',
          minWidth: '150',
        },
        {
          label: '方案金额',
          prop: 'schemeAmount',
          minWidth: '120',
        },
        {
          label: '方案状态',
          prop: 'schemeStateDesc',
          minWidth: '120',
        },
        {
          label: '指派的服务商家',
          prop: 'merchantName',
          minWidth: '120',
          hidden: this.isHidden
        },
        {
          label: '服务状态',
          prop: 'orderStateDesc',
          minWidth: '120',
        },
        {
          label: '验收状态',
          prop: 'checkAcceptDesc',
          minWidth: '120',
          hidden: this.isHidden
        },
        {
          label: '派单时间',
          prop: 'assignTime',
          minWidth: '120',
          hidden: this.isHidden
        },
        {
          label: '创建时间',
          prop: 'createTime',
          minWidth: '120',
          hidden: this.isHidden
        },
      ],
      // 数据
      preOrdersSerInfo: {},
      // 服务状态选项
      serviceStateOptions,
      // 方案状态选项
      schemeStateOptions,
      // 验收状态选项
      checkAcceptOptions
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.preOrdersSerInfo = val.preOrdersSerInfo || {}
        this.getList();
        this.isHidden = val.ordersState === 1
        this.tableColumns = this.tableColumns.filter(item => !item.hidden)
      },
      deep: true
    }
  },
  methods: {
    // 列表接口
    async getPageList() {
      if (this.preOrdersSerInfo?.list) {
        return { list: [...this.preOrdersSerInfo?.list] }
      } else {
        return { list: [] }
      }
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 添加服务
    handleAddService() {
      this.$refs.addServiceDialogRef?.handleOpen(this.basicInfo)
    },
    // 删除服务
    handleDelService(row) {
      this.$confirm(`是否删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await logicDelServeOrder({ id: row.id })
          this.$message.success('操作成功!')
          this.$emit('refresh')
        } catch (error) {
          console.log(error);
          this.$message.error(error.msg)
        }
      }).catch(() => { })
    },
    // 取消订单
    pcCancelServeOrder(row) {
      this.$confirm(`是否取消?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await pcCancelServeOrder({ id: row.id })
          this.$message.success('操作成功!')
          this.$emit('refresh')
        } catch (error) {
          console.log(error);
          this.$message.error(error.msg)
        }
      }).catch(() => { })
    },
    // 编辑方案详情
    eidtScheme(row, type) {
      if (type === 1) {
        this.$router.push({
          path: '/dispatchOrderScheme',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          path: '/dispatchOrderSchemeDetails',
          query: {
            id: row.id
          }
        })
      }
    },
    // 刷新
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='less' scoped>
.service_total {
  text-align: right;
  padding: 20px;
  background-color: #f9f9f9;
  font-size: 18px;
  i {
    font-style: normal;
    color: #557efc;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>