var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Pagination", {
    attrs: {
      "current-page": _vm.currentPage,
      "page-sizes": [10, 20, 50, 100, 200, 300, 400],
      "page-size": _vm.pageSize,
      layout: _vm.paginationLayout,
      total: _vm.total,
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }