var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "4px" } },
    [
      _c(
        "el-collapse-item",
        {
          attrs: { name: "10" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("备注信息"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("TableCustom", {
            ref: "tableCustomRef",
            attrs: {
              border: "",
              operate: false,
              columns: _vm.tableColumns,
              "get-page-list": _vm.getPageList,
              pagination: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }