var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "record_date" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("总用户数")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.totalUsers
                            ? _vm.tableData.totalUsers
                            : 0
                        )
                      ),
                    ]),
                    _vm._v("人 "),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("今日新增")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.newToday ? _vm.tableData.newToday : 0
                        )
                      ),
                    ]),
                    _vm._v("人"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("本月新增")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.newMonth ? _vm.tableData.newMonth : 0
                        )
                      ),
                    ]),
                    _vm._v("人"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("本年新增")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.newYear ? _vm.tableData.newYear : 0
                        )
                      ),
                    ]),
                    _vm._v("人"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("总产值 ")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.totalRevenue
                            ? _vm.tableData.totalRevenue
                            : 0
                        )
                      ),
                    ]),
                    _vm._v("元"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [
                    _vm._v("平台收益(已入账的收益)"),
                  ]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.todayRevenue
                            ? _vm.tableData.todayRevenue
                            : 0
                        )
                      ),
                    ]),
                    _vm._v("元"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("待收款 ")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.monthRevenue
                            ? _vm.tableData.monthRevenue
                            : 0
                        )
                      ),
                    ]),
                    _vm._v("元"),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "box_num" }, [
                  _c("div", { staticClass: "box_color" }, [_vm._v("已付款")]),
                  _c("div", { staticClass: "text_color" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.yearRevenue
                            ? _vm.tableData.yearRevenue
                            : 0
                        )
                      ),
                    ]),
                    _vm._v("元 "),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "150px", "margin-left": "10px" },
                on: { change: _vm.orderfenxi },
                model: {
                  value: _vm.flag,
                  callback: function ($$v) {
                    _vm.flag = $$v
                  },
                  expression: "flag",
                },
              },
              _vm._l(_vm.flags, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
            _vm._v("    "),
            _c("el-date-picker", {
              staticStyle: { width: "200px", "margin-left": "10px" },
              attrs: {
                align: "right",
                type: "date",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
                placeholder: "选择开始时间",
              },
              on: { change: _vm.animeOrder },
              model: {
                value: _vm.info.stockDate,
                callback: function ($$v) {
                  _vm.$set(_vm.info, "stockDate", $$v)
                },
                expression: "info.stockDate",
              },
            }),
          ],
          1
        ),
        _c(
          "el-tabs",
          {
            staticStyle: { "margin-left": "10px" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "服务分析", name: "first" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务发单总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatF.takeOrderSum
                                    ? _vm.taskStatF.takeOrderSum
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务接单总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatF.sendOrderSum
                                    ? _vm.taskStatF.sendOrderSum
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务发单数量"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatF.takeOrderCount
                                    ? _vm.taskStatF.takeOrderCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务接单数量"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatF.sendOrderCount
                                    ? _vm.taskStatF.sendOrderCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "服务收入分析", name: "fourth" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableDataLoading,
                        expression: "tableDataLoading",
                      },
                    ],
                    attrs: { data: _vm.tableData2.list },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "orderId", label: "编号" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "nickName1", label: "用户名" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#4f9dec",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updates1(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.userName
                                        ? scope.row.userName
                                        : "null"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "payMoney", label: "支付金额" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "money", label: "派单人金额" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "profit", label: "平台收入" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    parseFloat(
                                      scope.row.payMoney - scope.row.money
                                    ).toFixed(2)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "createTime", label: "创建时间" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                      float: "right",
                    },
                  },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [5, 10, 15, 20],
                        "page-size": _vm.limit,
                        "current-page": _vm.page,
                        layout: "total,sizes, prev, pager, next,jumper",
                        total: _vm.tableData2.totalCount,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "订单统计(总)", name: "dingdanTj" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务订单总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.serviceMoney
                                    ? _vm.taskStatTj.serviceMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务订单总数量"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.serviceCount
                                    ? _vm.taskStatTj.serviceCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("单 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待服务(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceDaiFuWuCount
                                    ? _vm.taskStat.serviceDaiFuWuCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("进行中(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.serviceJinXingCount
                                    ? _vm.taskStatTj.serviceJinXingCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已完成(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.serviceWanChengCount
                                    ? _vm.taskStatTj.serviceWanChengCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已退款(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.serviceTuiKuanCount
                                    ? _vm.taskStatTj.serviceTuiKuanCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("万能任务总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderMoney
                                    ? _vm.taskStatTj.HelpOrderMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("万能任务总数量"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderCount
                                    ? _vm.taskStatTj.HelpOrderCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待审核(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderDaiShenHeCount
                                    ? _vm.taskStatTj.HelpOrderDaiShenHeCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待接单(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderDaiJieDanCount
                                    ? _vm.taskStatTj.HelpOrderDaiJieDanCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待送达(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderDaiSongDaCount
                                    ? _vm.taskStatTj.HelpOrderDaiSongDaCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已完成(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderYiWanChengCount
                                    ? _vm.taskStatTj.HelpOrderYiWanChengCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已取消(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatTj.HelpOrderYiQuXiaoCount
                                    ? _vm.taskStatTj.HelpOrderYiQuXiaoCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "订单分析", name: "dingdan" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务订单总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceMoney
                                    ? _vm.taskStat.serviceMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待服务(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceDaiFuWuCount
                                    ? _vm.taskStat.serviceDaiFuWuCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("进行中(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceJinXingCount
                                    ? _vm.taskStat.serviceJinXingCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已完成(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceWanChengCount
                                    ? _vm.taskStat.serviceWanChengCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已退款(服务订单)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.serviceTuiKuanCount
                                    ? _vm.taskStat.serviceTuiKuanCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("万能任务总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderMoney
                                    ? _vm.taskStat.HelpOrderMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待审核(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderDaiShenHeCount
                                    ? _vm.taskStat.HelpOrderDaiShenHeCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待接单(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderDaiJieDanCount
                                    ? _vm.taskStat.HelpOrderDaiJieDanCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("待送达(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderDaiSongDaCount
                                    ? _vm.taskStat.HelpOrderDaiSongDaCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已完成(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderYiWanChengCount
                                    ? _vm.taskStat.HelpOrderYiWanChengCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("已取消(万能任务)"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStat.HelpOrderYiQuXiaoCount
                                    ? _vm.taskStat.HelpOrderYiQuXiaoCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("笔 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "支付统计(总)", name: "zhifu" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(服务任务)零钱支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.serviceLingQianMoney
                                    ? _vm.taskStatZf.serviceLingQianMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(服务任务)微信支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.serviceWXMoney
                                    ? _vm.taskStatZf.serviceWXMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(服务任务)支付宝支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.serviceZFBMoney
                                    ? _vm.taskStatZf.serviceZFBMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(万能任务)零钱支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.HelpOrderLingQianMoney
                                    ? _vm.taskStatZf.HelpOrderLingQianMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(万能任务)微信支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.HelpOrderWxMoney
                                    ? _vm.taskStatZf.HelpOrderWxMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("(万能任务)支付宝支付"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatZf.HelpOrderZFBMoney
                                    ? _vm.taskStatZf.HelpOrderZFBMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "用户分析", name: "user" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("总用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.allUserCount
                                    ? _vm.taskStatUser.allUserCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("会员用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.vipCount
                                    ? _vm.taskStatUser.vipCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("普通用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.generalCount
                                    ? _vm.taskStatUser.generalCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("商家用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.shopUserCount
                                    ? _vm.taskStatUser.shopUserCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("师傅用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.workUserCount
                                    ? _vm.taskStatUser.workUserCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("员工用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.staffUserCount
                                    ? _vm.taskStatUser.staffUserCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("公众号用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.officialCount
                                    ? _vm.taskStatUser.officialCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("小程序用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.courseCount
                                    ? _vm.taskStatUser.courseCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("H5用户人数"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatUser.h5Count
                                    ? _vm.taskStatUser.h5Count
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "平台佣金统计", name: "yongjin" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("佣金总金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatYj.allMoney
                                    ? _vm.taskStatYj.allMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("推广员金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatYj.popularizeMoney
                                    ? _vm.taskStatYj.popularizeMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("平台金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatYj.pingMoney
                                    ? _vm.taskStatYj.pingMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("商家金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatYj.shopMoney
                                    ? _vm.taskStatYj.shopMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("师傅金额"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskStatYj.workerMoney
                                    ? _vm.taskStatYj.workerMoney
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("元 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "待处理事项", name: "daichuli" } },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("投诉待处理"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatesD1()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.chatNumTs ? _vm.chatNumTs : 0)
                                ),
                              ]
                            ),
                            _vm._v("件 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("个人认证待审核"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatesD2()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.chatNumSf ? _vm.chatNumSf : 0)
                                ),
                              ]
                            ),
                            _vm._v("件 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("企业认证待审核"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatesD3()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.chatNumQy ? _vm.chatNumQy : 0)
                                ),
                              ]
                            ),
                            _vm._v("件 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("评价待审核"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatesD4()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.chatNumPj ? _vm.chatNumPj : 0)
                                ),
                              ]
                            ),
                            _vm._v("件 "),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "box" }, [
                        _c("div", { staticClass: "box_num" }, [
                          _c("div", { staticClass: "box_color" }, [
                            _vm._v("服务待审核"),
                          ]),
                          _c("div", { staticClass: "text_color" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updatesD5()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.chatNumFw ? _vm.chatNumFw : 0)
                                ),
                              ]
                            ),
                            _vm._v("件 "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }