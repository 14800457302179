<template>
  <div>
    <el-descriptions :column="6" direction="vertical" border class="service_descriptions">
      <template #title>
        <h2 class="details_title"><span></span>基本信息</h2>
      </template>
      <el-descriptions-item label="预约单号">{{ basicInfo.preOrdersNo }}</el-descriptions-item>
      <el-descriptions-item label="提交时间">{{ basicInfo.createTime }}</el-descriptions-item>
      <el-descriptions-item label="下单人">
        <span>{{ basicInfo.createUserName }}</span>
        <el-button type="text" style="margin-left: 10px" @click="goToUserInfo">查看</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="订单来源">{{ basicInfo.customerName }}</el-descriptions-item>
      <el-descriptions-item label="订单类型">{{ basicInfo.typeDesc }}</el-descriptions-item>
      <el-descriptions-item label="服务顾问">
        <span :class="{ text_red: !basicInfo.counselorName }">{{ basicInfo.counselorName }}</span>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    // 跳转到用户信息
    goToUserInfo() {
      this.$router.push({
        path: '/userDetail',
        query: {
          userId: this.basicInfo.createUserId
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.text_red {
  color: red;
}
</style>