var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detailtabel" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "inline-block",
          "font-size": "18px",
          "margin-bottom": "15px",
        },
      },
      [
        _c(
          "a",
          {
            staticStyle: { "text-decoration": "none", "font-size": "14px" },
            attrs: { href: "#" },
            on: { click: _vm.prev },
          },
          [
            _c("icon-svg", {
              staticStyle: {
                width: "1.2em",
                height: "1.2em",
                position: "relative",
                top: "0.3em",
              },
              attrs: { name: "jiantou" },
            }),
            _vm._v(" 返回 "),
          ],
          1
        ),
        _c(
          "span",
          {
            staticStyle: {
              display: "inline-block",
              margin: "0 15px",
              color: "#D9D9D9",
            },
          },
          [_vm._v("|")]
        ),
        _c("span", [_vm._v("派单详情")]),
      ]
    ),
    _c("div", { staticClass: "table_main" }, [
      _c("table", [
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("用户名称")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.nickName))]),
            _c("th", [_vm._v("标题")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.title))]),
            _c("th", { staticStyle: { width: "180px" } }, [_vm._v("服务要求")]),
            _c("td", { staticClass: "border-rt" }, [
              _vm._v(_vm._s(_vm.tableData.content)),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("验证信息")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.verifyContent))]),
            _c("th", [_vm._v("打开内容")]),
            _c("td", { staticStyle: { width: "180px" } }, [
              _vm._v(_vm._s(_vm.tableData.openContent)),
            ]),
            _c("th", [_vm._v("编号")]),
            _c("td", { staticClass: "border-rt" }, [
              _vm._v(_vm._s(_vm.tableData.id)),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("数量")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.taskNum))]),
            _c("th", [_vm._v("接单数量")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.endNum))]),
            _c("th", [_vm._v("发布时间")]),
            _c("td", { staticClass: "border-rt" }, [
              _vm._v(_vm._s(_vm.tableData.createTime)),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("截至时间")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.endTime) + " ")]),
            _c("th", [_vm._v("审核时间（按分）")]),
            _c("td", [_vm._v(_vm._s(_vm.tableData.auditTime))]),
            _c("th", [_vm._v("服务限时（按分）")]),
            _c("td", { staticClass: "border-rt" }, [
              _vm._v(_vm._s(_vm.tableData.restrictTime)),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("状态")]),
            _vm.tableData.state == 0 ? _c("td", [_vm._v("待审核")]) : _vm._e(),
            _vm.tableData.state == 1 ? _c("td", [_vm._v("进行中")]) : _vm._e(),
            _vm.tableData.state == 2 ? _c("td", [_vm._v("已结束")]) : _vm._e(),
            _vm.tableData.state == 3 ? _c("td", [_vm._v("已拒绝")]) : _vm._e(),
            _vm.tableData.state == 4 ? _c("td", [_vm._v("已结算")]) : _vm._e(),
            _c("th", [_vm._v("打开方式")]),
            _c("td", [
              _vm.tableData.openType == 1
                ? _c("span", [_vm._v("链接打开")])
                : _vm._e(),
              _vm.tableData.openType == 2
                ? _c("span", [_vm._v("口令打开")])
                : _vm._e(),
              _vm.tableData.openType == 3
                ? _c("span", [_vm._v("扫码打开")])
                : _vm._e(),
            ]),
            _c("th", [_vm._v("打开app分类")]),
            _c("td", { staticClass: "border-rt" }, [
              _vm._v(_vm._s(_vm.tableData.openApp)),
            ]),
          ]),
          _c("tr", [
            _c("th", { staticClass: "border-bt" }, [_vm._v("验证信息")]),
            _c("td", { staticClass: "border-bt" }, [
              _vm._v(_vm._s(_vm.tableData.verifyContent)),
            ]),
            _c("th", { staticClass: "border-bt" }, [_vm._v("价格(原价)")]),
            _c("td", { staticClass: "border-bt" }, [
              _vm._v(_vm._s(_vm.tableData.taskOriginalPrice)),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticStyle: { "margin-top": "25px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableDataLoading,
                expression: "tableDataLoading",
              },
            ],
            attrs: { data: _vm.taskData.list },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "", prop: "id", label: "接单id" },
            }),
            _c("el-table-column", {
              attrs: { prop: "nickName", label: "接单人名称", width: "150" },
            }),
            _c("el-table-column", {
              attrs: { prop: "content", label: "提交内容", width: "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.content))])]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "money",
                label: "价格(接单时收取的价格)",
                width: "180",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "zhiMoney", label: "直属收益" },
            }),
            _c("el-table-column", {
              attrs: { prop: "feiMoney", label: "非直属收益", width: "150" },
            }),
            _c("el-table-column", {
              attrs: { prop: "pingMoney", label: "平台收益" },
            }),
            _c("el-table-column", {
              attrs: { prop: "auditContent", label: "拒绝原因", width: "160" },
            }),
            _c("el-table-column", {
              attrs: { prop: "category", label: "拒绝类型", width: "160" },
            }),
            _c("el-table-column", {
              attrs: { prop: "createTime", label: "创建时间", width: "160" },
            }),
            _c("el-table-column", {
              attrs: { prop: "auditTime", label: "审核时间", width: "160" },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                prop: "state",
                label: "状态",
                width: "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.state === 0
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("接单成功"),
                          ])
                        : _vm._e(),
                      scope.row.state === 1
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("提交待审核"),
                          ])
                        : _vm._e(),
                      scope.row.state === 2
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("审核成功"),
                          ])
                        : _vm._e(),
                      scope.row.state === 3
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("已拒绝"),
                          ])
                        : _vm._e(),
                      scope.row.state === 4
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("已维权"),
                          ])
                        : _vm._e(),
                      scope.row.state === 5
                        ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                            _vm._v("已放弃"),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { fixed: "right", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.check(scope.$index, scope.row)
                            },
                          },
                        },
                        [_vm._v("查看 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "center", "margin-top": "10px" } },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [5, 10, 15, 20],
                "page-size": _vm.limit,
                "current-page": _vm.page,
                layout: "total,sizes, prev, pager, next,jumper",
                total: _vm.taskData.totalCount,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "查看",
              visible: _vm.dialogFormVisible,
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _vm._l(_vm.helpTaskData, function (item, index) {
              return _c(
                "div",
                { key: index, staticStyle: { "text-align": "center" } },
                [
                  _c("span", [_vm._v("步骤 " + _vm._s(index + 1))]),
                  _c("p", { staticClass: "red" }, [_vm._v("验证图")]),
                  _c("img", {
                    attrs: {
                      src: item.picture,
                      alt: "",
                      width: "260",
                      height: "460",
                    },
                  }),
                ]
              )
            }),
            _vm.helpTaskData == ""
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("暂无内容"),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }