var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-upload",
        {
          ref: _vm.multiple ? "fileUpload" : null,
          attrs: {
            action: _vm.uploadUrl,
            multiple: _vm.multiple,
            limit: _vm.limit,
            accept: _vm.accept,
            "http-request": _vm.customRequest,
            "file-list": _vm.fileList,
            "on-remove": _vm.deleteFile,
            "before-upload": _vm.handlerUploadFile,
            "list-type": "picture-card",
            "on-preview": _vm.handlePictureCardPreview,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(_vm._s(_vm.tip))]
          ),
          _c("el-button", {
            attrs: { slot: "trigger", type: "text", icon: "el-icon-plus" },
            slot: "trigger",
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { attrs: { src: _vm.dialogImageUrl, alt: "预览" } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }