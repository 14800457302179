<template>
  <el-dialog title="操作驳回" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="驳回信息" prop="auditRemark">
        <el-input
          type="textarea"
          v-model="ruleForm.auditRemark"
          :rows="4"
          resize="none"
          show-word-limit
          placeholder="请输入驳回信息"
          maxlength="300"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { checkChangeScheme } from '@/api/maintenance/index'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {
        auditRemark: [{ required: true, message: '请输入驳回信息', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleOpen(params) {
      this.ruleForm = {
        ...this.ruleForm,
        ...params
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            await checkChangeScheme(this.ruleForm)
            this.$message.success('操作成功!')
            this.handleClose()
            this.$emit('refresh')
          } catch (error) {
            console.log(error);
            this.$message.error(error.msg)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style>
</style>