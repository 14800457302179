var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "上传文件",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        close: _vm.closeHandle,
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-upload",
        {
          staticStyle: { "text-align": "center" },
          attrs: {
            drag: "",
            action: _vm.url,
            "before-upload": _vm.beforeUploadHandle,
            "on-success": _vm.successHandle,
            multiple: "",
            "file-list": _vm.fileList,
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("只支持jpg、png、gif格式的图片！")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }