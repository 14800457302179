var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "list_title" }, [_vm._v("数据筛选")]),
      _c("FormRender", {
        ref: "reportForm",
        staticClass: "list_form",
        attrs: {
          "form-config": _vm.formConfig,
          "form-data": _vm.formData,
          "form-type": "submit",
          "label-width": "100px",
          formType: "search",
        },
        on: {
          "update:formData": function ($event) {
            _vm.formData = $event
          },
          "update:form-data": function ($event) {
            _vm.formData = $event
          },
          onSearch: _vm.getList,
          onReset: _vm.onReset,
        },
      }),
      _c("div", { staticClass: "list_title" }, [_vm._v("售后跟进列表")]),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          "operate-width": "200",
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
        },
        scopedSlots: _vm._u([
          {
            key: "preOrdersNo",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToOrder(scopeRow)
                      },
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.preOrdersNo))]
                ),
              ]
            },
          },
          {
            key: "preOrdersTypeValue",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "span",
                  { class: { text_red: scopeRow.preOrdersType === 1 } },
                  [_vm._v(_vm._s(scopeRow.preOrdersTypeValue))]
                ),
              ]
            },
          },
          {
            key: "stateDesc",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.afterStateOptions.find(
                        (item) => item.value === scopeRow.state
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.stateDesc))]
                ),
              ]
            },
          },
          {
            key: "fileInfoList",
            fn: function ({ scopeRow }) {
              return [
                scopeRow.fileInfoList?.length
                  ? _c("el-image", {
                      attrs: {
                        src: scopeRow.fileInfoList[0].url,
                        "preview-src-list": scopeRow.fileInfoList.map(
                          (item) => item.url
                        ),
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail(scopeRow)
                      },
                    },
                  },
                  [_vm._v("查看订单")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.handleOpenAfterSaleDetails(scopeRow)
                      },
                    },
                  },
                  [_vm._v("售后记录")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("AfterSaleDetailsDialog", { ref: "afterSaleDetailsDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }