<template>
  <div>
    <div class="list_title">数据筛选</div>
    <FormRender
      class="list_form"
      ref="reportForm"
      :form-config="formConfig"
      :form-data.sync="formData"
      form-type="submit"
      label-width="100px"
      formType="search"
      @onSearch="getList"
      @onReset="onReset"
    >
    </FormRender>
    <div class="list_title">售后跟进列表</div>
    <TableCustom ref="tableCustomRef" operate-width="200" :columns="tableColumns" :get-page-list="getPageList">
      <!-- 预约单号 -->
      <template #preOrdersNo="{ scopeRow }">
        <el-button type="text" @click="goToOrder(scopeRow)">{{ scopeRow.preOrdersNo }}</el-button>
      </template>
      <!-- 订单类型 -->
      <template #preOrdersTypeValue="{ scopeRow }">
        <span :class="{ text_red: scopeRow.preOrdersType === 1 }">{{ scopeRow.preOrdersTypeValue }}</span>
      </template>
      <!-- 售后状态 -->
      <template #stateDesc="{ scopeRow }">
        <el-tag :type="afterStateOptions.find((item) => item.value === scopeRow.state)?.type">{{
          scopeRow.stateDesc
        }}</el-tag>
      </template>
      <!-- 售后图片 -->
      <template #fileInfoList="{ scopeRow }">
        <el-image
          v-if="scopeRow.fileInfoList?.length"
          :src="scopeRow.fileInfoList[0].url"
          :preview-src-list="scopeRow.fileInfoList.map((item) => item.url)"
        ></el-image>
      </template>
      <template #operate="{ scopeRow }">
        <el-button type="text" @click="goToDetail(scopeRow)">查看订单</el-button>
        <el-button type="text" @click="handleOpenAfterSaleDetails(scopeRow)">售后记录</el-button>
      </template>
    </TableCustom>
    <!-- 售后处理记录 -->
    <AfterSaleDetailsDialog ref="afterSaleDetailsDialogRef"></AfterSaleDetailsDialog>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import { getAfterSalePageList } from '@/api/maintenance/index'
import { afterStateOptions } from '@/const/dispatchOrderDict'
import AfterSaleDetailsDialog from "./components/afterSaleDetailsDialog.vue";
export default {
  components: {
    TableCustom,
    FormRender,
    AfterSaleDetailsDialog
  },
  data() {
    return {
      // form配置
      formConfig: [
        {
          label: '预约单号',
          prop: 'preOrdersNo',
          type: 'input',
          clearable: true,
        },
        {
          label: '服务单号',
          prop: 'serveOrderNo',
          type: 'input',
          clearable: true,
        },
        {
          label: '售后状态',
          prop: 'state',
          type: 'select',
          dict: afterStateOptions,
          clearable: true,
        },
        {
          label: '申请时间',
          prop: 'createTime',
          type: 'daterange',
          clearable: true,
        },
      ],
      // 搜索值
      formData: {},
      tableColumns: [
        {
          label: '关联预约单号',
          prop: 'preOrdersNo',
          minWidth: '240',
        },
        {
          label: '服务单号',
          prop: 'serveOrderNo',
          minWidth: '180',
        },
        {
          label: '订单类型',
          prop: 'preOrdersTypeValue',
          minWidth: '180',
        },
        {
          label: '售后状态',
          prop: 'stateDesc',
          minWidth: '180',
        },
        {
          label: '服务顾问',
          prop: 'counselorName',
          minWidth: '180',
        },
        {
          label: '售后内容',
          prop: 'contents',
          minWidth: '180',
        },
        {
          label: '售后图片',
          prop: 'fileInfoList',
          minWidth: '180',
        },
        {
          label: '预估上门时间',
          prop: 'estimateDoorTime',
          minWidth: '180',
        },
        {
          label: '预估工期',
          prop: 'estimateDuration',
          minWidth: '180',
        },
        {
          label: '指派的服务商家',
          prop: 'merchantName',
          minWidth: '180',
        },
        {
          label: '申请售后时间',
          prop: 'createTime',
          minWidth: '180',
        },
        {
          label: '售后完成时间',
          prop: 'completeTime',
          minWidth: '180',
        },
      ],
      // 售后状态选项
      afterStateOptions,
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 列表接口
    async getPageList(params) {
      if (this.formData.createTime?.length) {
        this.formData = {
          ...this.formData,
          createStartTime: this.formData.createTime[0],
          createEndTime: this.formData.createTime[1],
        }
      }
      try {
        const res = await getAfterSalePageList({ ...params, data: { ...this.formData } })
        return res
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.getList()
    },
    // 跳转到预约单详情
    goToOrder(row) {
      this.$router.push({
        path: '/dispatchOrderDetails',
        query: {
          id: row.preOrdersId
        }
      })
    },
    // 查看服务详情
    goToDetail(row) {
      this.$router.push({
        path: '/serviceCentreDetails',
        query: {
          id: row.serveOrderId
        }
      })
    },
    // 打开售后详情弹窗
    handleOpenAfterSaleDetails(row) {
      this.$refs.afterSaleDetailsDialogRef?.handleOpen(row)
    }
  }
}
</script>

<style lang='less' scoped>
.text_red {
  color: red;
}
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>