import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import qs from 'qs';
import merge from 'lodash/merge';
import { clearLoginInfo } from '@/utils';
import { BASEURL, SOCKETURL } from './env';
export const entryName = '渝高智维平台';
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers['token'] = Vue.cookie.get('token'); // 请求头带上token
    config.headers['x-platform-type'] = 4;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code === 401) {
      // 401, token失效
      clearLoginInfo();
      router.push({ name: 'login' });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
  // return 'http://192.168.0.132:8181/sqx_fast/' + actionName
  // return 'https://jiazhengadminv5.xianmaxiong.com/sqx_fast/' + actionName
  return BASEURL + actionName;
};

http.adornWss = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
  // return 'wss://jiazhengadminv5.xianmaxiong.com/wss/' + actionName
  return SOCKETURL + actionName;
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = false) => {
  var defaults = {
    t: new Date().getTime(),
  };
  return openDefultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = false, contentType = 'json') => {
  var defaults = {
    t: new Date().getTime(),
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};
// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const post = (url, data = {}, params = {}) => {
  return http({
    method: 'post',
    url: http.adornUrl(url),
    data,
    params,
  });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 * @param {object} responseType
 */
export const responseTypePost = (url, data = {}, params = {}, responseType = 'json') => {
  return http({
    method: 'post',
    url: http.adornUrl(url),
    data,
    params,
    responseType,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const get = (url, params = {}) => {
  return http({
    method: 'get',
    url: http.adornUrl(url),
    params,
  });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const put = (url, data = {}, params = {}) => {
  return http({
    method: 'put',
    url: http.adornUrl(url),
    params,
    data,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const _delete = (url, params = {}) => {
  return http({
    method: 'delete',
    url: http.adornUrl(url),
    params,
  });
};

export default http;
