import { Message } from "element-ui";
import { download } from "../api/common/index.js";
// 下载文件流
export function downloadFile(file, fileName) {
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(file);
  downloadElement.href = href;
  downloadElement.download = fileName;
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
}

// 下载附件
export default async function downloadApi(id, fileName) {
  try {
    const res = await download(id)
    downloadFile(res, fileName)
  } catch (error) {
    console.log(error);
    Message.error(error.msg)
  }
}