var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mod-menu" },
    [
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.dataForm } },
        [
          _c(
            "el-form-item",
            [
              _vm.isAuth("sys:menu:save")
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addOrUpdateHandle()
                        },
                      },
                    },
                    [_vm._v("新增")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataListLoading,
              expression: "dataListLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, "row-key": "menuId", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              "header-align": "center",
              "min-width": "150",
              label: "名称",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "parentName",
              "header-align": "center",
              align: "center",
              width: "120",
              label: "上级菜单",
            },
          }),
          _c("el-table-column", {
            attrs: { "header-align": "center", align: "center", label: "图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("icon-svg", { attrs: { name: scope.row.icon || "" } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              "header-align": "center",
              align: "center",
              label: "类型",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 0
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _vm._v("目录"),
                        ])
                      : scope.row.type === 1
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "success" } },
                          [_vm._v("菜单")]
                        )
                      : scope.row.type === 2
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "info" } },
                          [_vm._v("按钮")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNum",
              "header-align": "center",
              align: "center",
              label: "排序号",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "url",
              "header-align": "center",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "菜单URL",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "perms",
              "header-align": "center",
              align: "center",
              width: "150",
              "show-overflow-tooltip": true,
              label: "授权标识",
            },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.isAuth("sys:menu:update"),
                          type: "text",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addOrUpdateHandle(scope.row.menuId)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.isAuth("sys:menu:delete"),
                          type: "text",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteHandle(scope.row.menuId)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.addOrUpdateVisible
        ? _c("add-or-update", {
            ref: "addOrUpdate",
            on: { refreshDataList: _vm.getDataList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }