var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("FormRender", {
        ref: "reportForm",
        attrs: {
          "form-config": _vm.formConfig,
          "form-data": _vm.formData,
          "form-type": "submit",
          "label-width": "100px",
          inline: "",
          formType: "search",
        },
        on: {
          "update:formData": function ($event) {
            _vm.formData = $event
          },
          "update:form-data": function ($event) {
            _vm.formData = $event
          },
          onSearch: _vm.getList,
          onReset: _vm.onReset,
        },
      }),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          operate: false,
          border: "",
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }