var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h2", { staticClass: "dispatch_title" }, [
              _c("span"),
              _vm._v("施工方案状态：" + _vm._s(_vm.basicInfo.stateDesc)),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.hanldeSave(0)
                    },
                  },
                },
                [_vm._v("暂存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.hanldeSave(1)
                    },
                  },
                },
                [_vm._v("提交客户确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h2", { staticClass: "details_title" }, [
        _c("span"),
        _vm._v(_vm._s(_vm.basicInfo.serveOrderName)),
      ]),
      _c(
        "div",
        [
          _c(
            "el-row",
            {
              staticClass: "scheme_item",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "details_title_span" }, [
                  _vm._v("方案清单"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.handleAddPlan },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                data: _vm.tableData,
                align: "center",
                border: "",
                "keep-source": "",
                "edit-config": {
                  trigger: "click",
                  mode: "cell",
                  autoClear: false,
                },
                "max-height": "600px",
                "min-height": "200px",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "name",
                  "min-width": "180",
                  title: "科目",
                  "edit-render": {},
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function ({ row, rowIndex }) {
                      return [
                        _c("el-autocomplete", {
                          staticClass: "inline-input",
                          attrs: {
                            "fetch-suggestions": _vm.querySearch,
                            placeholder: "请输入内容",
                            "trigger-on-focus": false,
                            "value-key": "name",
                          },
                          on: { select: (e) => _vm.handleSelect(e, rowIndex) },
                          model: {
                            value: row.name,
                            callback: function ($$v) {
                              _vm.$set(row, "name", $$v)
                            },
                            expression: "row.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "content",
                  "min-width": "500",
                  title: "具体内容描述",
                  "edit-render": {
                    name: "input",
                    attrs: { type: "text" },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "unit",
                  "min-width": "80",
                  title: "单位",
                  "edit-render": {
                    name: "input",
                    attrs: { type: "text" },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "number",
                  "min-width": "80",
                  title: "数量",
                  "edit-render": {},
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function ({ row }) {
                      return [
                        _c("vxe-input", {
                          attrs: { type: "float", step: "1" },
                          on: { change: _vm.changeNumber },
                          model: {
                            value: row.number,
                            callback: function ($$v) {
                              _vm.$set(row, "number", $$v)
                            },
                            expression: "row.number",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "unitPrice",
                  "min-width": "120",
                  title: "单价(元)",
                  "edit-render": {},
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function ({ row, rowIndex }) {
                      return [
                        _c("vxe-input", {
                          attrs: { type: "float", step: "1" },
                          on: {
                            change: (e) => _vm.changeUnitPrice(e, rowIndex),
                          },
                          model: {
                            value: row.unitPrice,
                            callback: function ($$v) {
                              _vm.$set(row, "unitPrice", $$v)
                            },
                            expression: "row.unitPrice",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "negotiatePrice",
                  "min-width": "120",
                  title: "协商定价(元)",
                  "edit-render": {},
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function ({ row }) {
                      return [
                        _c("vxe-input", {
                          attrs: { type: "float", step: "1" },
                          on: { change: _vm.changeNegotiatePrice },
                          model: {
                            value: row.negotiatePrice,
                            callback: function ($$v) {
                              _vm.$set(row, "negotiatePrice", $$v)
                            },
                            expression: "row.negotiatePrice",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "notes",
                  "min-width": "120",
                  title: "备注",
                  "edit-render": {
                    name: "input",
                    attrs: { type: "text" },
                  },
                },
              }),
              _c("vxe-table-column", {
                attrs: { "min-width": "80", title: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ rowIndex }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelScheme(rowIndex)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "scheme_details_footer",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _c("el-col", { attrs: { span: 20 } }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "span",
                    { staticClass: "quality_time" },
                    [
                      _vm._v("预估工期："),
                      _c("el-input-number", {
                        staticStyle: { width: "50%", margin: "0 8px" },
                        attrs: {
                          "controls-position": "right",
                          precision: 1,
                          min: 0,
                        },
                        model: {
                          value: _vm.basicInfo.estimateDuration,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "estimateDuration", $$v)
                          },
                          expression: "basicInfo.estimateDuration",
                        },
                      }),
                      _vm._v("天"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "quality_time" },
                    [
                      _vm._v("承诺质保时限："),
                      _c("el-input-number", {
                        staticStyle: { width: "50%", margin: "0 8px" },
                        attrs: {
                          "controls-position": "right",
                          disabled: "",
                          min: 0,
                        },
                        model: {
                          value: _vm.basicInfo.warrantyPeriodDays,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "warrantyPeriodDays", $$v)
                          },
                          expression: "basicInfo.warrantyPeriodDays",
                        },
                      }),
                      _vm._v("天"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "quality_time" },
                    [
                      _vm._v("预估上门时间："),
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.basicInfo.estimateDoorTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "estimateDoorTime", $$v)
                          },
                          expression: "basicInfo.estimateDoorTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 4, align: "end" } }, [
                _c("span", { staticClass: "quality_total" }, [
                  _vm._v("方案总金额："),
                  _c("i", [
                    _vm._v("￥" + _vm._s(_vm.basicInfo.schemeTotal || 0)),
                  ]),
                ]),
                _c("div", { staticClass: "quality_total" }, [
                  _vm._v(" 已优惠："),
                  _c("i", [
                    _vm._v("￥" + _vm._s(_vm.basicInfo.discountTotal || 0)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "40px" } },
        [
          _c(
            "el-row",
            {
              staticClass: "scheme_item",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "details_title_span" }, [
                  _vm._v("方案附件"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus" },
                      on: { click: _vm.handleAddFile },
                    },
                    [_vm._v("添加附件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vxe-table",
            {
              ref: "xTableFile",
              attrs: {
                data: _vm.tableDataFile,
                border: "",
                "max-height": "600px",
                "min-height": "0",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "name", "min-width": "180", title: "附件名称" },
              }),
              _c("vxe-table-column", {
                attrs: { width: "180", title: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row, rowIndex }) {
                      return [
                        row.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleViews(row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        row.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownload(row)
                                  },
                                },
                              },
                              [_vm._v("下载")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#f56c6c" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelFile(rowIndex)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("AddFileDialog", {
        ref: "addFileDialogRef",
        on: { handleOk: _vm.addFileTable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }