var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "18px",
            "margin-bottom": "15px",
          },
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("订单详情")]),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c(
                "el-row",
                { staticClass: "item_left" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [_vm._v("订单信息:")]),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c("span", [_vm._v("订单号:")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.tableData.orderNum)),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("span", [_vm._v("订单备注:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.tableData.descrition
                                    ? _vm.tableData.descrition
                                    : "未填写"
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c("span", [_vm._v("订单状态:")]),
                            _vm.tableData.status == 1
                              ? _c("span", [_vm._v("待付款")])
                              : _vm._e(),
                            _vm.tableData.status == 2
                              ? _c("span", [_vm._v("待发货")])
                              : _vm._e(),
                            _vm.tableData.status == 3
                              ? _c("span", [_vm._v("已发货")])
                              : _vm._e(),
                            _vm.tableData.status == 4
                              ? _c("span", [_vm._v("已收货")])
                              : _vm._e(),
                            _vm.tableData.status == 5
                              ? _c("span", [_vm._v("已取消")])
                              : _vm._e(),
                            _vm.tableData.status == 6
                              ? _c("span", [_vm._v("退款中")])
                              : _vm._e(),
                            _vm.tableData.status == 7
                              ? _c("span", [
                                  _vm._v("已退款 ("),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        "*原因：" + _vm._s(_vm.tableData.refund)
                                      ),
                                    ]
                                  ),
                                  _vm._v(")"),
                                ])
                              : _vm._e(),
                            _vm.tableData.status == 8
                              ? _c("span", [
                                  _vm._v("已驳回 ("),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        "*原因：" + _vm._s(_vm.tableData.refund)
                                      ),
                                    ]
                                  ),
                                  _vm._v(")"),
                                ])
                              : _vm._e(),
                            _vm.tableData.status == 9
                              ? _c("span", [_vm._v("已拼团")])
                              : _vm._e(),
                            _vm.tableData.status == 10
                              ? _c("span", [_vm._v("已评价")])
                              : _vm._e(),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("span", [_vm._v("支付方式:")]),
                            _vm.tableData.payWay === 1
                              ? _c("span", [_vm._v("微信")])
                              : _vm._e(),
                            _vm.tableData.payWay === 2
                              ? _c("span", [_vm._v("微信公众号")])
                              : _vm._e(),
                            _vm.tableData.payWay === 3
                              ? _c("span", [_vm._v("微信小程序")])
                              : _vm._e(),
                            _vm.tableData.payWay === 4
                              ? _c("span", [_vm._v("app支付宝")])
                              : _vm._e(),
                            _vm.tableData.payWay === 5
                              ? _c("span", [_vm._v("H5支付宝")])
                              : _vm._e(),
                            _vm.tableData.payWay === 6
                              ? _c("span", [_vm._v("零钱")])
                              : _vm._e(),
                            _vm.tableData.payWay === 7
                              ? _c("span", [_vm._v("积分")])
                              : _vm._e(),
                            _vm.tableData.payWay === 8
                              ? _c("span", [_vm._v("支付宝")])
                              : _vm._e(),
                            _vm.tableData.payWay === null ||
                            _vm.tableData.payWay === ""
                              ? _c("span", [_vm._v("未支付")])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 16 } }, [
                            _c("span", [_vm._v("创建时间:")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.tableData.createAt)),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("span", [_vm._v("付款时间:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.tableData.payTime
                                    ? _vm.tableData.payTime
                                    : "未付款"
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "item_left" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [_vm._v("商品信息:")]),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c("span", [_vm._v("商品图片")]),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c("span", [_vm._v("商品标题")]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("span", [_vm._v("商品价格")]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("span", [_vm._v("支付金额")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "order_item border-top" },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c("img", {
                              attrs: {
                                src: _vm.tableData.img,
                                alt: "",
                                width: "40px",
                                height: "40px",
                              },
                            }),
                          ]),
                          _c("el-col", { attrs: { span: 8 } }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "line-height": "21px",
                                  "margin-right": "15px",
                                },
                              },
                              [_vm._v(_vm._s(_vm.tableData.title))]
                            ),
                            _c("div", [
                              _vm._v(
                                "规格：" +
                                  _vm._s(
                                    _vm.tableData.detailJson
                                      ? _vm.tableData.detailJson
                                      : "-"
                                  )
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("span", [
                              _vm._v(
                                "￥" +
                                  _vm._s(_vm.tableData.price) +
                                  " x " +
                                  _vm._s(_vm.tableData.number)
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("span", [
                              _vm._v("￥" + _vm._s(_vm.tableData.payMoney)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { float: "right" }, attrs: { span: 8 } },
            [
              _c(
                "el-row",
                { staticClass: "item_right" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [_vm._v("收货信息:")]),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", [_vm._v("收货人:")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.tableData.consignee)),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", [_vm._v("手机号:")]),
                            _c("span", [_vm._v(_vm._s(_vm.tableData.mobile))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "order_item" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", [_vm._v("收货地址:")]),
                            _c("span", [_vm._v(_vm._s(_vm.tableData.detail))]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "item_right" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [_vm._v("物流信息:")]),
                      _vm.tableData.status == 3 || _vm.tableData.status == 4
                        ? _c(
                            "el-row",
                            { staticClass: "order_item" },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("span", [_vm._v("物流公司:")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.tableData.expressName)),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("span", [_vm._v("运单号:")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.tableData.expressNumber)),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.status !== 3 && _vm.tableData.status !== 4
                        ? _c("el-row", { staticClass: "order_item" }, [
                            _vm._v(" 暂无信息 "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "item_right" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("h3", [_vm._v("评价信息:")]),
                      _vm.discussData
                        ? _c(
                            "el-row",
                            { staticClass: "order_item" },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [_vm._v("评价用户:")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.discussData.userName)),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [_vm._v("用户图像:")]),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.discussData.img,
                                      alt: "",
                                      width: "40",
                                      height: "40",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c("span", [_vm._v("评价内容:")]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.discussData.content)),
                                  ]),
                                ]
                              ),
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("span", [_vm._v("评价时间:")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.discussData.createTime)),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _c("el-row", { staticClass: "order_item" }, [
                            _vm._v(" 暂无评价 "),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }