var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    {
      staticClass: "site-content",
      class: { "site-content--tabs": _vm.$route.meta.isTab },
    },
    [
      _vm.$route.meta.isTab
        ? _c(
            "el-tabs",
            {
              attrs: { closable: true },
              on: {
                "tab-click": _vm.selectedTabHandle,
                "tab-remove": _vm.removeTabHandle,
              },
              model: {
                value: _vm.mainTabsActiveName,
                callback: function ($$v) {
                  _vm.mainTabsActiveName = $$v
                },
                expression: "mainTabsActiveName",
              },
            },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "site-tabs__tools",
                  attrs: { "show-timeout": 0 },
                },
                [
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.tabsCloseCurrentHandle.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("关闭当前标签页")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.tabsCloseOtherHandle.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("关闭其它标签页")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.tabsCloseAllHandle.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("关闭全部标签页")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.refresh()
                            },
                          },
                        },
                        [_vm._v("刷新当前标签页")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.mainTabs, function (item) {
                return _c(
                  "el-tab-pane",
                  {
                    key: item.name,
                    attrs: { label: item.title, name: item.name },
                  },
                  [
                    _c(
                      "el-card",
                      { attrs: { "body-style": _vm.siteContentViewHeight } },
                      [
                        item.type === "iframe"
                          ? _c("iframe", {
                              attrs: {
                                src: item.iframeUrl,
                                width: "100%",
                                height: "100%",
                                frameborder: "0",
                                scrolling: "yes",
                              },
                            })
                          : _c(
                              "keep-alive",
                              [
                                item.name === _vm.mainTabsActiveName
                                  ? _c("router-view")
                                  : _vm._e(),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c(
            "el-card",
            { attrs: { "body-style": _vm.siteContentViewHeight } },
            [
              !_vm.$route.meta.isNotKeep
                ? _c("keep-alive", [_c("router-view")], 1)
                : _c("router-view"),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }