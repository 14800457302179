import { httpDelete, httpGet, httpPut, httpPost, httpPostPage } from '@/utils/request';
import { config } from './config';
import { Message } from 'element-ui';

// 获取服务分类启用列表
export const selectClassifyList = async (parentId) => {
  const res = await httpGet(config.selectClassifyList, parentId);
  return res;
};

// 获取服务分类树
export const selectClassifyTree = async (parentId = '') => {
  try {
    const res = await selectClassifyList(parentId);
    return res.map((el) => {
      return {
        value: el.id + '',
        label: el.gameName,
        children: el.children.map((el) => {
          return {
            value: el.id + '',
            label: el.gameName,
          };
        }),
      };
    });
  } catch (error) {}
};

// 获取服务类型列表
export const getServiceTypeList = async () => {
  try {
    const res = await await httpGet(config.serviceTypes);
    return res.map((el) => {
      return {
        value: el.id,
        label: el.name,
      };
    });
  } catch (error) {}
};

// 获取服务列表
export const getServiceList = async (pageInfo, params) => {
  try {
    const res = await httpPostPage(config.serviceItemList, pageInfo, params);
    return res;
  } catch (error) {}
};

// 新增服务
export const addService = async (params) => {
  try {
    const res = await httpPost(config.serviceItemAdd, params);
    return res;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
    });
    return false;
  }
};

//   编辑服务
export const editService = async (params) => {
  try {
    const res = await httpPost(config.serviceItemEdit, params);
    return res;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
    });
    return false;
  }
};

// 通过id获取服务详情
export const getServiceDetail = async (id) => {
  try {
    const res = await httpGet(config.serviceItemGet, id);
    return res;
  } catch (error) {}
};

// 删除服务
export const deleteService = async (id) => {
  try {
    const res = await httpDelete(config.serviceItemDelete + '/' + id, '');
    return res;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
    });
    return false;
  }
};

// 上下架
export const serviceItemDisable = async (id, isEnable) => {
  try {
    const res = await httpPost(config.serviceItemDisable, { id, isEnable });
    return true;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
    });
    return false;
  }
};
