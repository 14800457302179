<template>
  <div>
    <div class="list_title">数据筛选</div>
    <FormRender
      class="list_form"
      ref="reportForm"
      :form-config="formConfig"
      :form-data.sync="formData"
      form-type="submit"
      label-width="100px"
      formType="search"
      @onSearch="onSearch"
      @onReset="onReset"
    >
    </FormRender>
    <el-row>
      <el-col :span="12" class="list_title">服务跟进列表</el-col>
      <el-col :span="12" align="end">
        <el-button type="primary" plain icon="el-icon-thumb" @click="handleOpenMerchant" :disabled="!selectRow?.length"
          >指派服务商家</el-button
        >
      </el-col>
    </el-row>
    <TableCustom
      ref="tableCustomRef"
      operate-width="200"
      :columns="tableColumns"
      :get-page-list="getPageList"
      selection
      @onSelection="selectionTable"
      :selectable="selectable"
    >
      <!-- 关联预约单号 -->
      <template #preOrdersNo="{ scopeRow }">
        <el-button type="text" @click="goToOrder(scopeRow)">{{ scopeRow.preOrdersNo }}</el-button>
      </template>
      <!-- 订单类型 -->
      <template #ordersTypeValue="{ scopeRow }">
        <span :class="{ text_red: scopeRow.preOrdersType === 1 }">{{ scopeRow.ordersTypeValue }}</span>
      </template>
      <!-- 服务状态 -->
      <template #orderStateValue="{ scopeRow }">
        <el-tag :type="serviceStateOptions.find((item) => item.value === scopeRow.orderState)?.type">{{
          scopeRow.orderStateValue
        }}</el-tag>
      </template>
      <!-- 方案状态 -->
      <template #schemeStateValue="{ scopeRow }">
        <el-tag
          v-if="scopeRow.schemeStateValue"
          :type="schemeStateOptions.find((item) => item.value === scopeRow.schemeState)?.type"
          >{{ scopeRow.schemeStateValue }}</el-tag
        >
      </template>
      <!-- 验收状态 -->
      <template #checkAcceptValue="{ scopeRow }">
        <el-tag :type="serviceStateOptions.find((item) => item.value === scopeRow.checkAccept)?.type">{{
          scopeRow.checkAcceptValue
        }}</el-tag>
      </template>
      <template #imgUrl="{ scopeRow }">
        <el-image :src="scopeRow.imgUrl"></el-image>
      </template>
      <template #doorInfo="{ scopeRow }">
        <div>联系人：{{ scopeRow.contactsName }}</div>
        <div>联系手机：{{ scopeRow.contactsPhone }}</div>
        <div>联系地址：{{ scopeRow.contactsAddress }}</div>
        <div>上门时间：{{ scopeRow.expectDoorTime }}</div>
      </template>
      <template #constructionPlan="{ scopeRow }">
        <el-button
          v-if="!scopeRow.schemeState || scopeRow.schemeState === 1 || scopeRow.schemeState === 3"
          type="primary"
          @click="eidtScheme(scopeRow, 1)"
          >编辑施工方案</el-button
        >
        <el-button v-else type="success" @click="eidtScheme(scopeRow, 2)">查看施工方案</el-button>
      </template>
      <template #orderAmount="{ scopeRow }">
        <el-tag :type="serviceStateOptions.find((item) => item.value === scopeRow.ordersState)?.type">{{
          scopeRow.ordersStateDesc
        }}</el-tag>
      </template>
      <template #createUserNam1="{ scopeRow }">
        <el-tag :type="serviceStateOptions.find((item) => item.value === scopeRow.ordersState)?.type">{{
          scopeRow.ordersStateDesc
        }}</el-tag>
      </template>
      <template #operate="{ scopeRow }">
        <el-button type="text" @click="goToDetail(scopeRow)">查看订单</el-button>
      </template>
    </TableCustom>
    <AssignmentMerchantDialog ref="assignmentMerchantDialogRef" @refresh="getList"></AssignmentMerchantDialog>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import { queryServeOrder } from '@/api/maintenance/index'
import { schemeStateOptions, serviceStateOptions, checkAcceptOptions } from '@/const/dispatchOrderDict'
import AssignmentMerchantDialog from './components/assignmentMerchantDialog.vue'
export default {
  components: {
    TableCustom,
    FormRender,
    AssignmentMerchantDialog
  },
  data() {
    return {
      // form配置
      formConfig: [
        {
          label: '预约单号',
          prop: 'preOrdersNo',
          type: 'input',
          clearable: true,
        },
        {
          label: '服务单号',
          prop: 'orderNo',
          type: 'input',
          clearable: true,
        },
        {
          label: '服务状态',
          prop: 'orderState',
          type: 'select',
          dict: serviceStateOptions,
          clearable: true,
        },
        {
          label: '派单时间',
          prop: 'assignTime',
          type: 'daterange',
          clearable: true,
        },
      ],
      // 搜索值
      formData: {},
      tableColumns: [
        {
          label: '关联预约单号',
          prop: 'preOrdersNo',
          minWidth: '240',
        },
        {
          label: '服务单号',
          prop: 'orderNo',
          minWidth: '180',
        },
        {
          label: '订单类型',
          prop: 'ordersTypeValue',
          minWidth: '180',
        },
        {
          label: '服务状态',
          prop: 'orderStateValue',
          minWidth: '180',
        },
        {
          label: '服务顾问',
          prop: 'counselorName',
          minWidth: '180',
        },
        {
          label: '服务图片',
          prop: 'imgUrl',
          minWidth: '180',
        },
        {
          label: '服务分类',
          prop: 'serverCategory',
          minWidth: '180',
        },
        {
          label: '服务名称',
          prop: 'serveName',
          minWidth: '180',
        },
        {
          label: '服务类型',
          prop: 'serveType',
          minWidth: '180',
        },
        {
          label: '预约金额',
          prop: 'appointmentAmount',
          minWidth: '180',
        },
        {
          label: '上门信息',
          prop: 'doorInfo',
          minWidth: '180',
        },
        {
          label: '施工方案',
          prop: 'constructionPlan',
          minWidth: '180',
        },
        {
          label: '方案金额',
          prop: 'schemeAmount',
          minWidth: '180',
        },
        {
          label: '方案状态',
          prop: 'schemeStateValue',
          minWidth: '180',
        },
        {
          label: '指派的服务商家',
          prop: 'merchantName',
          minWidth: '180',
        },
        {
          label: '验收状态',
          prop: 'checkAcceptValue',
          minWidth: '180',
        },
        {
          label: '质保时限',
          prop: 'warrantyPeriodDays',
          minWidth: '180',
        },
        {
          label: '创建时间',
          prop: 'createTime',
          minWidth: '180',
        },
        {
          label: '派单时间',
          prop: 'assignTime',
          minWidth: '180',
        },
        {
          label: '接单时间',
          prop: 'takeTime',
          minWidth: '180',
        },
        {
          label: '开始服务时间',
          prop: 'serveStartTime',
          minWidth: '180',
        },
        {
          label: '服务完成时间',
          prop: 'serveCompleteTime',
          minWidth: '180',
        },
        {
          label: '开始验收时间',
          prop: 'checkStartTime',
          minWidth: '180',
        },
        {
          label: '验收完成时间',
          prop: 'checkCompleteTime',
          minWidth: '180',
        },
        {
          label: '结算完成时间',
          prop: 'settleCompleteTime',
          minWidth: '180',
        },
        {
          label: '开始质保时间',
          prop: 'periodStartTime',
          minWidth: '180',
        },
        {
          label: '结束质保时间',
          prop: 'periodFinishTime',
          minWidth: '180',
        },
        {
          label: '申请售后时间',
          prop: 'applyAfterSaleTime',
          minWidth: '180',
        },
        {
          label: '售后完成时间',
          prop: 'completeAfterSaleTime',
          minWidth: '180',
        },
        {
          label: '订单完成时间',
          prop: 'ordersCompleteTime',
          minWidth: '180',
        },
      ],
      // 服务状态选项
      serviceStateOptions,
      // 方案状态选项
      schemeStateOptions,
      // 验收状态选项
      checkAcceptOptions,
      // 选中的行
      selectRow: []
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 列表接口
    async getPageList(params) {
      if (this.formData.assignTime?.length) {
        this.formData = {
          ...this.formData,
          startAssignTime: this.formData.assignTime[0],
          endAssignTime: this.formData.assignTime[1],
        }
      }
      const res = await queryServeOrder({ ...params, data: { ...this.formData } })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return []
      }
      return res.data.data
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.$refs?.tableCustomRef?.currentPage(1)
    },
    onSearch() {
      this.$refs?.tableCustomRef?.currentPage(1)
    },
    // 复选框是否禁用
    selectable(row) {
      // 1.抢修单时，没指派商家可以直接指派
      // 2.预约单时，必须要方案已确认并且未指派商家才行
      // 3.所有订单状态不能为已取消
      return (!row.merchantId && row.ordersType === 1) || (!row.merchantId && row.ordersType === 2 && row.schemeState === 4) && row.orderState !== 10
    },
    // 选中的复选框
    selectionTable(row) {
      this.selectRow = row
    },
    // 跳转到预约单详情
    goToOrder(row) {
      this.$router.push({
        path: '/dispatchOrderDetails',
        query: {
          id: row.preOrdersId
        }
      })
    },
    // 编辑方案详情
    eidtScheme(row, type) {
      if (type === 1) {
        this.$router.push({
          path: '/dispatchOrderScheme',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push({
          path: '/dispatchOrderSchemeDetails',
          query: {
            id: row.id
          }
        })
      }
    },
    // 查看服务详情
    goToDetail(row) {
      this.$router.push({
        path: '/serviceCentreDetails',
        query: {
          id: row.id
        }
      })
    },
    // 打开指派服务商家弹窗
    handleOpenMerchant() {
      this.$refs.assignmentMerchantDialogRef.handleOpen(this.selectRow)
    }
  }
}
</script>

<style lang='less' scoped>
.text_red {
  color: red;
}
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>