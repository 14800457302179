<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h2 class="dispatch_title"><span></span>当前订单状态：{{ basicInfo.orderStateValue }}</h2>
      </el-col>
      <el-col :span="12" align="end">
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button @click="handleOpenRemark">备注订单</el-button>
        <el-button v-if="basicInfo.isShowCheckBtn" type="danger" @click="handleOpenCheck(0)">驳回验收</el-button>
        <el-button v-if="basicInfo.isShowCheckBtn" type="primary" @click="handleOpenCheck(1)">确认通过验收</el-button>
      </el-col>
    </el-row>
    <el-steps class="header_status" :active="active" finish-status="success" process-status="finish">
      <el-step title="确认接单" :description="timeObj.takeTime"></el-step>
      <el-step title="开始服务" :description="timeObj.serveStartTime"></el-step>
      <el-step title="开始验收" :description="timeObj.checkStartTime"></el-step>
      <el-step title="开始结算" :description="timeObj.settleStartTime"></el-step>
      <el-step title="已完成" :description="timeObj.settleCompleteTime"></el-step>
    </el-steps>
    <!-- 备注订单弹窗 -->
    <OrderRemarkDialog ref="orderRemarkDialogRef" @refresh='refresh'></OrderRemarkDialog>
    <!-- 审核验收 -->
    <AuditDialog ref="auditDialogRef" @refresh="refresh"></AuditDialog>
  </div>
</template>

<script>
import OrderRemarkDialog from '../components/orderRemarkDialog.vue'
import AuditDialog from '../components/auditDialog.vue'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    OrderRemarkDialog,
    AuditDialog
  },
  data() {
    return {
      // 头部时间对象
      timeObj: {}
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.timeObj = val?.basicInfo || {}
      },
      deep: true
    }
  },
  computed: {
    active() {
      const arr = [this.timeObj.takeTime, this.timeObj.serveStartTime, this.timeObj.checkStartTime, this.timeObj.settleStartTime, this.timeObj.settleCompleteTime].filter(item => item)
      console.log(this.timeObj);
      return arr.length || 0
    }
  },
  methods: {
    // 打开备注订单弹窗
    handleOpenRemark() {
      this.$refs.orderRemarkDialogRef?.handleOpen({
        ...this.basicInfo,
        id: this.$route.query.id
      })
    },
    // 打开查看验收申请弹窗
    handleOpenCheck(type) {
      this.$refs.auditDialogRef?.handleOpen(this.$route.query.id, type)
    },
    // 
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='less' scoped>
.header_status {
  margin-bottom: 20px;
  :deep(.el-step__icon) {
    width: 32px;
    height: 32px;
  }
}
.dispatch_title {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #95a3fc;
  margin: 0 0 40px;
  span {
    display: inline-block;
    width: 6px;
    height: 24px;
    background-color: #3181ff;
    margin-right: 4px;
  }
}
</style>