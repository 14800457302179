export const config = {
  // 预约单-分页获取列表接口
  getBookingPageList: '/admin/preOrders/getPageList',
  // 预约单-关闭订单
  doCloseOrders: '/admin/preOrders/doCloseOrders',
  // 预约单-指派服务顾问
  assignPreOrders: '/admin/preOrders/assignPreOrders',
  // 预约单-查询数据详情
  getPreOrdersDetail: '/admin/preOrders/getPreOrdersDetail',
  // 预约单-服务顾问手动确认订单
  manualComplete: '/admin/preOrders/manualComplete',
  // 预约单-更新数据
  savePreOrdersInfo: '/admin/preOrders/savePreOrdersInfo',
  // 预约单-备注记录-更新数据
  saveNotesRecordInfo: '/admin/notesRecord/saveNotesRecordInfo',
  // 预约单-备注记录 查询列表数据【非分页】
  getNotesRecordList: '/admin/notesRecord/getNotesRecordList',
  // 预约单-订单业务操作记录 查询列表数据【非分页】
  getOrdersOperateLogList: '/admin/ordersOperateLog/getOrdersOperateLogList',

  // 服务单-服务单列表分页
  queryServeOrder: '/serveOrder/queryServeOrder',
  // 服务单-服务单详情
  queryServeOrderDetail: '/serveOrder/queryServeOrderDetail',
  // 服务单-保存服务
  saveServe: '/serveOrder/saveServe',
  // 服务单-平台审核验收服务单
  plantCheckAcceptance: '/serveOrder/plantCheckAcceptance',
  // 服务单-预约单详情-服务单列表
  serviceQueryList: '/serviceItem/queryList',
  // 服务单-获取服务分类
  getSelectList: '/game/getSelectList',
  // 服务单-平台取消服务单
  pcCancelServeOrder: '/serveOrder/pcCancelServeOrder',
  // 服务单-平台删除服务单
  logicDelServeOrder: 'serveOrder/logicDelServeOrder',
  // 服务单-售后记录操作表
  getAfterSaleRecordList: '/admin/serveOrderAfterSale/getAfterSaleRecordList',
  // 服务单-施工方案-查询服务方施工方案
  getSchemeDetailInfo: '/admin/scheme/getSchemeDetailInfo',
  // 服务单-施工方案-查询列表数据【非分页】
  getSchemeList: '/admin/scheme/getSchemeList',
  // 服务单-施工方案-暂存or提交
  saveSchemeInfo: '/admin/scheme/saveSchemeInfo',
  // 服务单-施工方案-科目下拉
  schemeDictList: '/schemeItemSys/select/list',
  // 服务单-施工方案-平台审核服务商申请调整方案
  checkChangeScheme: '/serveOrder/checkChangeScheme',
  // 服务单-施工方案-方案撤销提交
  cancelCommitScheme: '/serveOrder/cancelCommitScheme',
  // 服务单-施工方案-导出pdf
  getSchemeDetailInfoPdf: '/admin/scheme/getSchemeDetailInfoPdf/',
  // 服务单-获取指派商家列表
  getAssignCompanyList: '/company/getAssignCompanyList',
  // 服务单-顾问手动派单
  counselorAssign: '/serveOrder/counselorAssign',

  // 售后单列表
  getAfterSalePageList: '/admin/serveOrderAfterSale/getAfterSalePageList',

  // 公共
  // 获取服务顾问列表
  getCounselorList: '/admin/preOrders/getCounselorList',

  // 投诉
  // PC端处理投诉
  dealComplaint: '/admin/complaint/dealComplaint',
  // PC端投诉详情
  pcComplaintInfo: '/admin/complaint/pcComplaintInfo',
}