var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "site-sidebar",
      class: "site-sidebar--" + _vm.sidebarLayoutSkin,
    },
    [
      _c(
        "div",
        { staticClass: "site-sidebar__inner" },
        [
          _c(
            "el-menu",
            {
              staticClass: "site-sidebar__menu",
              attrs: {
                "default-active": _vm.menuActiveName || "home",
                collapse: _vm.sidebarFold,
                collapseTransition: false,
              },
            },
            _vm._l(_vm.menuList, function (menu) {
              return _c("sub-menu", {
                key: menu.menuId,
                attrs: { menu: menu, dynamicMenuRoutes: _vm.dynamicMenuRoutes },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }