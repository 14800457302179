var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        {
          staticClass: "service_descriptions",
          attrs: { column: 6, direction: "vertical", border: "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("基本信息"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-descriptions-item", { attrs: { label: "预约单号" } }, [
            _vm._v(_vm._s(_vm.basicInfo.preOrdersNo)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "提交时间" } }, [
            _vm._v(_vm._s(_vm.basicInfo.createTime)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "下单人" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.basicInfo.createUserName))]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "text" },
                  on: { click: _vm.goToUserInfo },
                },
                [_vm._v("查看")]
              ),
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "订单来源" } }, [
            _vm._v(_vm._s(_vm.basicInfo.customerName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "订单类型" } }, [
            _vm._v(_vm._s(_vm.basicInfo.typeDesc)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "服务顾问" } }, [
            _c("span", { class: { text_red: !_vm.basicInfo.counselorName } }, [
              _vm._v(_vm._s(_vm.basicInfo.counselorName)),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }