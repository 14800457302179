var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        {
          staticClass: "service_descriptions",
          attrs: { column: 5, direction: "vertical", border: "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("服务单信息"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-descriptions-item", { attrs: { label: "服务单号" } }, [
            _vm._v(_vm._s(_vm.formData.serveOrderNo)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "关联预约单号" } },
            [
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.goToOrder } },
                [_vm._v(_vm._s(_vm.formData.preOrdersNo))]
              ),
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "订单来源" } }, [
            _vm._v(_vm._s(_vm.formData.source)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "客户" } }, [
            _vm._v(_vm._s(_vm.formData.customerName)),
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "客户对接人" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.formData.contactUserName))]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "text" },
                  on: { click: _vm.goToUserInfo },
                },
                [_vm._v("查看")]
              ),
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "订单类型" } }, [
            _c(
              "span",
              { class: { text_red: _vm.formData.preOrdersType === 1 } },
              [_vm._v(_vm._s(_vm.formData.preOrdersTypeValue))]
            ),
          ]),
          _c("el-descriptions-item", { attrs: { label: "生成时间" } }, [
            _vm._v(_vm._s(_vm.formData.createTime)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "预约金额" } }, [
            _vm._v(_vm._s(_vm.formData.appointmentAmount)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "方案金额" } }, [
            _vm._v(_vm._s(_vm.formData.schemeAmount)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "质保时间" } }, [
            _vm._v(_vm._s(_vm.formData.periodFinishTime)),
          ]),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          staticClass: "service_descriptions",
          attrs: { column: 5, direction: "vertical", border: "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("服务信息"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-descriptions-item", { attrs: { label: "服务名称" } }, [
            _vm._v(_vm._s(_vm.serveInfo.serveName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "服务分类" } }, [
            _vm._v(_vm._s(_vm.serveInfo.serverCategory)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "服务类型" } }, [
            _vm._v(_vm._s(_vm.serveInfo.serveType)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "服务地址" } }, [
            _vm._v(_vm._s(_vm.serveInfo.address)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "期望上门时间" } }, [
            _vm._v(_vm._s(_vm.serveInfo.expectDoorTime)),
          ]),
        ],
        1
      ),
      _c("div", [
        _c("p", { staticClass: "second_title" }, [_vm._v("服务说明")]),
        _c("span", [_vm._v(_vm._s(_vm.serveInfo.eventExplain || "暂无说明"))]),
        !!_vm.serveInfo?.imgUrlList?.length
          ? _c(
              "ul",
              { staticClass: "maintain_content" },
              _vm._l(_vm.serveInfo?.imgUrlList, function (item, i) {
                return _c(
                  "li",
                  { key: i },
                  [_c("el-image", { attrs: { src: item } })],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }