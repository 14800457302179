var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "50vw",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "margin-auto flex flex-column justify-center",
          staticStyle: { padding: "32px", "min-height": "250px" },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              staticStyle: { "max-height": "60vh", "overflow-y": "auto" },
              attrs: {
                border: "",
                labelStyle: {
                  width: "150px",
                  "justify-content": "end",
                  "padding-right": "16px",
                },
                size: "medium",
                title: "",
                column: 1,
              },
            },
            [
              _c("el-descriptions-item", { attrs: { label: "企业状态" } }, [
                _vm._v(_vm._s(_vm.companyInfo.auditStatusDescription)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "企业全称" } }, [
                _vm._v(_vm._s(_vm.companyInfo.fullName)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "统一信用代码" } }, [
                _vm._v(_vm._s(_vm.companyInfo.socialCode)),
              ]),
              _vm.isMerchant
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "维修专业分类" } },
                    [
                      _c("el-tree", {
                        ref: "treeRef",
                        staticStyle: {
                          height: "150px",
                          width: "350px",
                          overflow: "auto",
                          border: "1px solid #eee",
                        },
                        attrs: {
                          data: _vm.classifyTree,
                          "show-checkbox": "",
                          "node-key": "value",
                          props: _vm.treeDefaultProps,
                          "default-checked-keys": _vm.serveIds,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-descriptions-item",
                { attrs: { label: "上传相关认证资料" } },
                [
                  _vm.companyInfo.fileInfoDtoList &&
                  _vm.companyInfo.fileInfoDtoList.length > 0
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.companyInfo.fileInfoDtoList,
                          function (item) {
                            return _c("el-image", {
                              key: item.url,
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                                "margin-right": "6px",
                              },
                              attrs: {
                                src: item.url,
                                "preview-src-list": [item.url],
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _c("span", [_vm._v("暂无图片")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "div",
            { staticClass: "margin-auto mt-40", staticStyle: { width: "60%" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-full",
                          attrs: { type: "danger" },
                          on: { click: _vm.handleReject },
                        },
                        [_vm._v("驳 回")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-popconfirm",
                        {
                          attrs: { title: "是否确认通过该企业审核？" },
                          on: { confirm: _vm.handlePass },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "w-full",
                              attrs: { slot: "reference", type: "primary" },
                              slot: "reference",
                            },
                            [_vm._v("通 过")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("RejectDialog", {
        ref: "rejectDialogRef",
        on: { submit: _vm.onReject },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }