var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "margin-auto flex flex-column justify-center",
          staticStyle: { width: "80%", "min-height": "250px" },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: {
                labelStyle: "width: 100px; justify-content: flex-end;",
                size: "medium",
                title: "",
                column: 1,
              },
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "已打款保证金缴款凭证" } },
                [
                  _vm.companyInfo.payCertificateFileInfoList &&
                  _vm.companyInfo.payCertificateFileInfoList.length > 0
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.companyInfo.payCertificateFileInfoList,
                          function (item) {
                            return _c("img", {
                              key: item.url,
                              attrs: {
                                src: item.url,
                                width: "80",
                                height: "40",
                              },
                            })
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v("暂无图片")]),
                ]
              ),
              _c("el-descriptions-item", { attrs: { label: "已缴保证金" } }, [
                _vm._v(_vm._s(_vm.companyInfo.depositAmount) + "元"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注信息:" } },
                [
                  _c("el-input", {
                    staticClass: "w-full",
                    attrs: { type: "textarea", rows: "5" },
                    model: {
                      value: _vm.auditRemark,
                      callback: function ($$v) {
                        _vm.auditRemark = $$v
                      },
                      expression: "auditRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "div",
            { staticClass: "margin-auto mt-40", staticStyle: { width: "60%" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-full",
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-full",
                          attrs: { type: "primary" },
                          on: { click: _vm.handlePass },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }