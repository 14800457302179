var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "4px" } },
    [
      _c(
        "el-collapse-item",
        {
          attrs: { name: "9" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("服务协议"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", {
            staticClass: "agreement_container",
            domProps: { innerHTML: _vm._s(_vm.commonInfo.value) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }