var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-descriptions",
        {
          staticClass: "service_descriptions",
          attrs: { column: 6, direction: "vertical", border: "" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("上门信息"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-descriptions-item", { attrs: { label: "联系人" } }, [
            _vm._v(_vm._s(_vm.basicInfo.orderUserName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "手机号码" } }, [
            _vm._v(_vm._s(_vm.basicInfo.orderUserPhone)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "项目名称" } }, [
            _vm._v(_vm._s(_vm.basicInfo.projectName)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "项目地址" } }, [
            _vm._v(_vm._s(_vm.basicInfo.address)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "预约上门时间" } }, [
            _vm._v(_vm._s(_vm.basicInfo.expectDoorTime)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "服务事项说明" } }, [
            _vm._v(_vm._s(_vm.basicInfo.eventExplain)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }