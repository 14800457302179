<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="5">
      <template #title>
        <h2 class="details_title"><span></span>验收记录</h2>
      </template>
      <el-timeline :reverse="false" v-if="checkInfos?.length">
        <el-timeline-item v-for="item in checkInfos" :key="item.id" :timestamp="item.createTime" placement="top">
          <div class="check_operate">{{ item.nodeName }}</div>
          <el-row>
            <el-col :span="4" class="check_user">
              <span>{{ item.userTypeValue }}</span>
              <span>{{ item.userName }}</span>
            </el-col>
            <el-col :span="20">
              <div class="check_content">{{ item.contents }}</div>
              <ul class="maintain_content" v-if="item?.imgList?.length">
                <li v-for="url in item?.imgList" :key="url">
                  <el-image :src="url" />
                </li>
              </ul>
            </el-col>
          </el-row>
        </el-timeline-item>
      </el-timeline>
      <el-empty v-else :image-size="160"></el-empty>
    </el-collapse-item>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 验收记录数据
      checkInfos: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.checkInfos = val?.checkInfos || []
      },
      deep: true
    }
  },
}
</script>

<style lang='less' scoped>
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
.check_operate {
  font-size: 16px;
  font-weight: 700;
}
.check_content {
  margin-bottom: 20px;
  font-weight: 700;
}
.check_user {
  span {
    margin-right: 20px;
  }
}
</style>