<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
    <div class="margin-auto flex flex-column justify-center" style="width: 80%; min-height: 250px; ">
      <el-descriptions labelStyle="width: 100px; justify-content: flex-end;" size="medium" class="margin-top" title="" :column="1">
        <el-descriptions-item label="已打款保证金缴款凭证">
          <div v-if="companyInfo.payCertificateFileInfoList && companyInfo.payCertificateFileInfoList.length>0">
            <img v-for="item in companyInfo.payCertificateFileInfoList" :key="item.url" :src="item.url" width="80" height="40" />
          </div>
          <span v-else>暂无图片</span>
        </el-descriptions-item>
        <el-descriptions-item label="已缴保证金">{{ companyInfo.depositAmount }}元</el-descriptions-item>
      </el-descriptions>
      <el-form ref="form" label-width="110px">
        <el-form-item label="备注信息:">
          <el-input type="textarea" rows="5" v-model="auditRemark" class="w-full"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="margin-auto mt-40" style="width: 60%;" v-if="!isDetail">
      <el-row :gutter="60">
        <el-col :span="12">
          <el-button class="w-full" @click="handleClose">取 消</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" class="w-full" @click="handlePass">确 定</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { CompanyAudit, GetCompanyInfo } from '@/api/auth';
// 审核操作类型：1:通过 0:拒绝
const AuditAction = {
  Pass: 1,
  Reject: 0,
};
export default {
  name: 'RemitAuditDialog',
  props: {
    title: {
      type: String,
      default: '企业信息审核',
    },
  },
  data() {
    return {
      dialogVisible: false,
      // 是否是详情
      isDetail: false,
      // 审核备注
      auditRemark: '',
      // 公司信息
      companyInfo: {
        fileInfoDtoList: [],
        // 上传凭证
        payCertificateFileInfoList: [],
        companyAccount: '',
        companyName: '',
        contactPhone: '',
        doorInfo: '',
        // 保证金
        depositAmount: 0,
        socialCode: '',
        auditRemark: '',
        auditStatus: '',
        auditStatusDescription: '',
        createTime: '',
        id: '',
      },
    }
  },
  methods: {
    // 获取公司详情
    getCompanyInfo() {
      GetCompanyInfo(this.companyInfo.id).then((res) => {
        if (res) {
          this.companyInfo = res;
        }
      });
    },
    handleOpen(row) {
      this.dialogVisible = true;
      this.companyInfo = row;
      this.getCompanyInfo();
    },
    // 显示详情
    showDetail(row) {
      this.isDetail = true;
      this.handleOpen(row);
    },
    // 显示审核
    showAudit(row) {
      this.isDetail = false;
      this.handleOpen(row);
    },
    // 审核操作类型：1:通过 0:拒绝
    async hanleRequest(auditAction) {
      const res = await CompanyAudit(this.companyInfo.id, auditAction, {auditRemark: this.auditRemark});
      if(res) {
        this.$message({
          message: '提交成功',
          type: 'success',
          plain: true,
        })
        this.dialogVisible = false;
        this.$emit('refresh');
      }
    },
    // 拒绝
    handleReject() {
      this.hanleRequest(AuditAction.Reject);
    },
    // 通过
    handlePass() {
      this.hanleRequest(AuditAction.Pass);
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style>
</style>