import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import qs from 'qs';
import merge from 'lodash/merge';
import { clearLoginInfo } from '@/utils';
import { BASEURL, SOCKETURL } from './env';
import { Message } from 'element-ui';
export const entryName = '渝高智维';
const http = axios.create({
  baseURL: BASEURL,
  timeout: 1000 * 60,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers['token'] = Vue.cookie.get('token'); // 请求头带上token
    config.headers['x-platform-type'] = 4;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 后置拦截器（获取到响应时的拦截）
http.interceptors.response.use(
  async (res) => {
    /**
     * 根据你的项目实际情况来对 response 和 error 做处理
     * 这里对 response 和 error 不做任何处理，直接返回
     */
    const { data } = res;
    if (res.config.responseType === 'blob') {
      const text = await data.text();
      // 处理后端抛错
      if (text.indexOf('"code":') !== -1) {
        throw JSON.parse(text);
      } else {
        return data.data ? data.data : data;
      }
    }
    if (res.status === 200) {
      if (data.code === 401) {
        Message({
          type: 'error',
          message: '用户未登录',
        });
        setTimeout(() => {
          // 401, token失效
          clearLoginInfo();
          router.push({ name: 'login' });
        }, 1000);
      } else if (data.code === 0) {
        return data.data !== undefined ? data.data : data.list ? data.list : data;
      } else {
        throw res.data ? res.data : res;
      }
    }
  },
  (error) => {
    const { response } = error;
    if (response && response.data) {
      return Promise.reject(error);
    }
    const { message } = error;
    return Promise.reject(error);
  },
);

http.adornWss = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
  // return 'wss://jiazhengadminv5.xianmaxiong.com/wss/' + actionName
  return SOCKETURL + actionName;
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = false) => {
  var defaults = {
    t: new Date().getTime(),
  };
  return openDefultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = false, contentType = 'json') => {
  var defaults = {
    t: new Date().getTime(),
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};
// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const httpPost = (url, data = {}, params = {}) => {
  return http({
    method: 'post',
    url,
    data,
    params,
  });
};
// post 分页请求
export const httpPostPage = (url, pageInfo = { page: 1, limit: 20 }, data = {}, params = {}) => {
  console.log(pageInfo);
  return http({
    method: 'post',
    url,
    data: {
      data,
      page: pageInfo.page,
      limit: pageInfo.limit,
    },
    params,
  })
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return { list: [], totalCount: 0, ...res };
    });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 * @param {object} responseType
 */
export const responseTypePost = (url, data = {}, params = {}, responseType = 'json') => {
  return http({
    method: 'post',
    url,
    data,
    params,
    responseType,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const httpGet = (url, params = {}, responseType) => {
  return http({
    method: 'get',
    url,
    params,
    responseType,
  });
};

// get 分页请求
export const httpGetPage = (url, pageInfo = { page: 1, limit: 20 }, params = {}) => {
  return http({
    method: 'get',
    url,
    params: {
      ...params,
      page: pageInfo.page,
      limit: pageInfo.limit,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return { list: [], totalCount: 0, ...res };
    });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const httpPut = (url, data = {}, params = {}) => {
  return http({
    method: 'put',
    url,
    params,
    data,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const httpDelete = (url, params = {}) => {
  return http({
    method: 'delete',
    url,
    params,
  });
};

export default http;
