var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加附件",
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: _vm.$http.adornUrl("oss/upload"),
            "show-file-list": false,
            multiple: "",
            "on-success": _vm.handleAvatarSuccess,
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { slot: "trigger", size: "small", type: "primary" },
              slot: "trigger",
            },
            [_vm._v("选取文件")]
          ),
        ],
        1
      ),
      _vm._l(_vm.fileList, function (item, i) {
        return _c(
          "el-row",
          { key: item.url, attrs: { type: "flex", align: "middle" } },
          [
            _c("span", [_vm._v(_vm._s(item.name))]),
            _c("i", {
              staticClass: "el-icon-delete file_del",
              on: {
                click: function ($event) {
                  return _vm.handleDelete(i)
                },
              },
            }),
          ]
        )
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }