var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "list_title" }, [_vm._v("数据筛选")]),
      _c("FormRender", {
        ref: "reportForm",
        staticClass: "list_form",
        attrs: {
          "form-config": _vm.formConfig,
          "form-data": _vm.formData,
          "form-type": "submit",
          "label-width": "100px",
          formType: "search",
        },
        on: {
          "update:formData": function ($event) {
            _vm.formData = $event
          },
          "update:form-data": function ($event) {
            _vm.formData = $event
          },
          onSearch: _vm.onSearch,
          onReset: _vm.onReset,
        },
      }),
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "list_title", attrs: { span: 12 } }, [
            _vm._v("服务跟进列表"),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-thumb",
                    disabled: !_vm.selectRow?.length,
                  },
                  on: { click: _vm.handleOpenMerchant },
                },
                [_vm._v("指派服务商家")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          "operate-width": "200",
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
          selection: "",
          selectable: _vm.selectable,
        },
        on: { onSelection: _vm.selectionTable },
        scopedSlots: _vm._u([
          {
            key: "preOrdersNo",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToOrder(scopeRow)
                      },
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.preOrdersNo))]
                ),
              ]
            },
          },
          {
            key: "ordersTypeValue",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "span",
                  { class: { text_red: scopeRow.preOrdersType === 1 } },
                  [_vm._v(_vm._s(scopeRow.ordersTypeValue))]
                ),
              ]
            },
          },
          {
            key: "orderStateValue",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.serviceStateOptions.find(
                        (item) => item.value === scopeRow.orderState
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.orderStateValue))]
                ),
              ]
            },
          },
          {
            key: "schemeStateValue",
            fn: function ({ scopeRow }) {
              return [
                scopeRow.schemeStateValue
                  ? _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.schemeStateOptions.find(
                            (item) => item.value === scopeRow.schemeState
                          )?.type,
                        },
                      },
                      [_vm._v(_vm._s(scopeRow.schemeStateValue))]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "checkAcceptValue",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.serviceStateOptions.find(
                        (item) => item.value === scopeRow.checkAccept
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.checkAcceptValue))]
                ),
              ]
            },
          },
          {
            key: "imgUrl",
            fn: function ({ scopeRow }) {
              return [_c("el-image", { attrs: { src: scopeRow.imgUrl } })]
            },
          },
          {
            key: "doorInfo",
            fn: function ({ scopeRow }) {
              return [
                _c("div", [_vm._v("联系人：" + _vm._s(scopeRow.contactsName))]),
                _c("div", [
                  _vm._v("联系手机：" + _vm._s(scopeRow.contactsPhone)),
                ]),
                _c("div", [
                  _vm._v("联系地址：" + _vm._s(scopeRow.contactsAddress)),
                ]),
                _c("div", [
                  _vm._v("上门时间：" + _vm._s(scopeRow.expectDoorTime)),
                ]),
              ]
            },
          },
          {
            key: "constructionPlan",
            fn: function ({ scopeRow }) {
              return [
                !scopeRow.schemeState ||
                scopeRow.schemeState === 1 ||
                scopeRow.schemeState === 3
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.eidtScheme(scopeRow, 1)
                          },
                        },
                      },
                      [_vm._v("编辑施工方案")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.eidtScheme(scopeRow, 2)
                          },
                        },
                      },
                      [_vm._v("查看施工方案")]
                    ),
              ]
            },
          },
          {
            key: "orderAmount",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.serviceStateOptions.find(
                        (item) => item.value === scopeRow.ordersState
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.ordersStateDesc))]
                ),
              ]
            },
          },
          {
            key: "createUserNam1",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.serviceStateOptions.find(
                        (item) => item.value === scopeRow.ordersState
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.ordersStateDesc))]
                ),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail(scopeRow)
                      },
                    },
                  },
                  [_vm._v("查看订单")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("AssignmentMerchantDialog", {
        ref: "assignmentMerchantDialogRef",
        on: { refresh: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }