<template>
  <Pagination
    :current-page="currentPage"
    :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
    :page-size="pageSize"
    :layout="paginationLayout"
    :total="total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  >
  </Pagination>
</template>
<script>
import { Pagination } from 'element-ui';

export default {
  components: {
    Pagination,
  },
  props: {
    total: {
      type: Number,
      require: true,
      default: 0,
    },
    pageSize: {
      type: Number,
      require: true,
      default: 10,
    },
    currentPage: {
      type: Number,
      require: true,
      default: 1,
    },
    paginationLayout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$emit('onSizeChange', val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$emit('onCurrentChange', val);
    },
  },
};
</script>
