<template>
  <el-collapse-item name="1">
    <template #title>
      <h2 class="details_title"><span></span>工人安排</h2>
    </template>
    <vxe-table ref="xTable" :data="tableData" align="center" border max-height="600px" min-height="0">
      <vxe-table-column field="name" width="100" title="姓名"> </vxe-table-column>
      <vxe-table-column field="phone" min-width="180" title="联系方式"></vxe-table-column>
    </vxe-table>
  </el-collapse-item>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 工人列表
      tableData: [],
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.tableData = val?.serveInfo?.workerList || []
      },
      deep: true
    }
  }
}
</script>

<style>
</style>