export const config = {
  aa: 'aa',
  upload: 'oss/upload',
  // 获取数据字典
  getDict: 'sys/dict/getlist/',
  // 附件下载
  download: 'oss/download/',
  // 修改密码
  updatePassword: 'sys/user/password',
  // 退出登录
  logout: 'sys/logout',
  // 第三方token获取token
  thirdSsolog: 'sys/sso',
};
