var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.getClassName,
      attrs: { width: _vm.width, height: _vm.height, "aria-hidden": "true" },
    },
    [_c("use", { attrs: { "xlink:href": _vm.getName } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }