<template>
  <el-dialog title="指派服务商家" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
    <el-form :model="ruleForm" ref="ruleForm" inline label-width="100px" class="demo-ruleForm">
      <el-form-item label="">
        <el-cascader placeholder="请选择服务分类" v-model="ruleForm.serveIds" :options="serviceTypeList"></el-cascader>
      </el-form-item>
      <el-form-item label="">
        <el-input type="text" v-model="ruleForm.name" placeholder="输入关键字查询"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getAssignCompanyList">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>
    <vxe-table
      border
      ref="xTable"
      max-height="400"
      :data="tableData"
      :radio-config="{ highlight: true }"
      @radio-change="radioChangeEvent"
    >
      <vxe-column type="radio" width="60"> </vxe-column>
      <vxe-column field="serveDescription" title="服务分类"></vxe-column>
      <vxe-column field="fullName" title="商家名称"></vxe-column>
    </vxe-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { counselorAssign, getAssignCompanyList } from '@/api/maintenance/index'
import { selectClassifyTree } from '@/api/serviced/index.js'
export default {
  data() {
    return {
      // 服务单id
      ids: [],
      dialogVisible: false,
      ruleForm: {},
      // 服务分类下拉
      serviceTypeList: [],
      tableData: [],
      // 选中的数据
      selectRow: {}
    }
  },
  methods: {
    handleOpen(params) {
      console.log(params);
      this.ids = params?.map(item => item.id)
      this.dialogVisible = true
      this.getAssignCompanyList()
      this.getServiceClassify();
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    // 初始化服务分类下拉框
    getServiceClassify() {
      selectClassifyTree().then(res => {
        this.serviceTypeList = res;
      })
    },
    // 获取服务商列表
    async getAssignCompanyList() {
      try {
        let params = { ...this.ruleForm }
        if (this.ruleForm.serveIds?.length) {
          params.serveIds = [this.ruleForm.serveIds[this.ruleForm.serveIds.length - 1]]
        }
        const res = await getAssignCompanyList({
          data: params,
          page: 1,
          limit: 1000
        })
        this.tableData = res.list
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
    // 重置
    onReset() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.getAssignCompanyList()
    },
    // 选中表格单行
    radioChangeEvent({ row }) {
      this.selectRow = row
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            await counselorAssign({
              ids: this.ids,
              companyId: this.selectRow.id
            })
            this.$message.success('操作成功!')
            this.handleClose()
            this.$emit('refresh')
          } catch (error) {
            console.log(error);
            this.$message.error(error.msg)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>
</style>