var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "whole-wrapper" }, [
      _c("div", { staticClass: "left_whole_wrapper" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c("el-input", {
              attrs: {
                placeholder: "搜索联系人",
                "suffix-icon": "el-icon-search",
                clearable: "",
              },
              on: { change: _vm.changeSearch, input: _vm.searchChange },
              model: {
                value: _vm.userName,
                callback: function ($$v) {
                  _vm.userName = $$v
                },
                expression: "userName",
              },
            }),
          ],
          1
        ),
        _vm.chatList
          ? _c(
              "div",
              {
                staticClass: "left_wrapper",
                staticStyle: { "overflow-y": "auto" },
              },
              _vm._l(_vm.chatList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.userId,
                    staticClass: "left-item",
                    class: { blue: item.userId == _vm.activeUserId },
                    staticStyle: { position: "relative", cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.clickItem(item, item.userId)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "left-item-left" }, [
                      item.userHead == null || item.userHead == ""
                        ? _c("div", { staticClass: "left-item-head" }, [
                            _c("img", {
                              attrs: { src: require("@/assets/img/head.png") },
                            }),
                          ])
                        : _c("div", { staticClass: "left-item-head" }, [
                            _c("img", { attrs: { src: item.userHead } }),
                          ]),
                      _c("div", { staticClass: "left-item-info" }, [
                        _c("div", { staticClass: "left-item-info_title" }, [
                          _vm._v(_vm._s(item.userName)),
                        ]),
                        _c("div", { staticClass: "left-item-info_content" }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "left-item-time" }, [
                      item.contentTime == null
                        ? _c("div", { staticClass: "left-item-time-time" })
                        : _c("div", { staticClass: "left-item-time-time" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.contentTime.substring(11, 16)) +
                                " "
                            ),
                          ]),
                      item.storeCount
                        ? _c(
                            "div",
                            {
                              staticClass: "left-item-time-number",
                              staticStyle: {
                                position: "absolute",
                                left: "59px",
                                top: "8px",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.storeCount) + " ")]
                          )
                        : _vm._e(),
                      _vm.isAuth("vueMchat:delete")
                        ? _c(
                            "div",
                            {
                              staticStyle: { position: "relative", top: "6px" },
                              on: {
                                click: function ($event) {
                                  return _vm.deletes(item)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/delete.png"),
                                  alt: "",
                                  width: "25",
                                  height: "25",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "center_whole_wrapper" }, [
        _c("div", { staticClass: "center_wrapper" }, [
          _c(
            "div",
            { staticClass: "center_wrapper_top" },
            [
              _c("div", { staticClass: "center_wrapper_top_name" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
              _vm.name
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        top: "30px",
                        right: "20px",
                        "border-radius": "4px",
                        position: "absolute",
                      },
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.updates(_vm.userId)
                        },
                      },
                    },
                    [_vm._v("用户详情 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "dialogue_box",
              staticClass: "center_wrapper_center",
              staticStyle: { "overflow-y": "auto" },
              attrs: { id: "ele" },
            },
            _vm._l(_vm.dialogueList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    item.sendType == "1"
                      ? _vm.center_wrapper_center_item
                      : _vm.center_wrapper_center_item_reserve,
                },
                [
                  _c("div", { staticClass: "left-item-head" }, [
                    item.sendType == "1" && _vm.userHead !== null
                      ? _c("img", { attrs: { src: _vm.userHead } })
                      : item.sendType == "1" && _vm.userHead == null
                      ? _c("img", {
                          attrs: { src: require("@/assets/img/head.png") },
                        })
                      : _c("img", {
                          attrs: { src: require("@/assets/img/avatar.png") },
                        }),
                  ]),
                  item.type == 1
                    ? _c("div", { staticClass: "center-item-info_wrapper" }, [
                        _c("div", { staticClass: "center-item-tip" }, [
                          _c("div", { staticClass: "center-item-info" }, [
                            _vm._v(" " + _vm._s(item.content) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "center-item-time" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                      ])
                    : _vm._e(),
                  item.type == 2
                    ? _c("div", { staticClass: "center-item-info_wrapper" }, [
                        _c("div", { staticClass: "center-item-tip" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    title: "",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      slot: "reference",
                                      src: item.content,
                                      alt: "",
                                    },
                                    slot: "reference",
                                  }),
                                  _c("img", {
                                    staticStyle: {
                                      width: "300px",
                                      height: "300px",
                                    },
                                    attrs: { src: item.content, alt: "" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "center-item-time" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                      ])
                    : _vm._e(),
                  item.type == 6
                    ? _c("div", { staticClass: "center-item-info_wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "center-item-tip",
                            staticStyle: {
                              "background-color": "#fff",
                              cursor: "pointer",
                              padding: "10px",
                              "border-radius": "4px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.orderDetails(
                                      item.content.ordersNo
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: item.content.homepageImg,
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "145px",
                                          "font-size": "13px",
                                          "line-height": "20px",
                                          "margin-left": "5px",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                          display: "-webkit-box",
                                          "-webkit-line-clamp": "2",
                                          "-webkit-box-orient": "vertical",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.content.myLevel) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "hoverp",
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#999999",
                                    },
                                  },
                                  _vm._l(
                                    item.content.gameName,
                                    function (item1, index1) {
                                      return _c("span", { key: index1 }, [
                                        _vm._v(_vm._s(item1)),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#999999",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 创建时间:" +
                                        _vm._s(item.content.updateTime) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "center-item-time" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                      ])
                    : _vm._e(),
                  item.type == 7
                    ? _c("div", { staticClass: "center-item-info_wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "center-item-tip",
                            staticStyle: {
                              "background-color": "#fff",
                              padding: "10px",
                              "border-radius": "4px",
                            },
                          },
                          [
                            _c("div", {}, [
                              _c("img", {
                                attrs: {
                                  src: item.content.homepageImg,
                                  alt: "",
                                  width: "160",
                                  height: "120",
                                },
                              }),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "160px",
                                    "line-height": "20px",
                                    "margin-bottom": "0",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.content.myLevel) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    color: "#f56c6c",
                                    "margin-bottom": "0",
                                  },
                                },
                                [
                                  _vm._v(
                                    " ¥" + _vm._s(item.content.money) + " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "center-item-time" }, [
                          _vm._v(_vm._s(item.createTime)),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "center_wrapper_right" },
            [
              _c("div", { staticClass: "icon-list" }, [
                _c(
                  "div",
                  { staticClass: "icon-item" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: _vm.$http.adornUrl("oss/upload"),
                          "show-file-list": false,
                          "on-success": _vm.handleAvatarSuccess,
                          "before-upload": _vm.beforeAvatarUpload,
                        },
                        model: {
                          value: _vm.coverImg,
                          callback: function ($$v) {
                            _vm.coverImg = $$v
                          },
                          expression: "coverImg",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "icon-item",
                            attrs: { title: "发送文件" },
                          },
                          [
                            _c("img", {
                              staticClass: "icon-item-img",
                              attrs: {
                                src: require("@/assets/img/wenjian.png"),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  id: "textarea",
                  rows: 2,
                  placeholder: "请输入内容",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.sendTextarea(1)
                  },
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
              _vm.content
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.sendTextarea(1)
                        },
                      },
                    },
                    [_vm._v("发送 ")]
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { type: "primary", disabled: "" },
                      on: {
                        click: function ($event) {
                          return _vm.sendTextarea(1)
                        },
                      },
                    },
                    [_vm._v("发送 ")]
                  ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }