var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.renwuData },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "type",
              label: "编号",
              align: "center",
              width: "80",
            },
          }),
          _c("el-table-column", { attrs: { prop: "min", label: "类型" } }),
          _c("el-table-column", {
            attrs: { prop: "value", label: "内容", width: "500" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createAt", label: "创建时间" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", prop: "id", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: !_vm.isAuth("allocationList:update"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.renwuAmend(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "修改", visible: _vm.dialogFormVisible1, center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible1 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form1 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "配置类型：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "65%" },
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.form1.min,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "min", $$v)
                      },
                      expression: "form1.min",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "内容：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "65%" },
                    model: {
                      value: _vm.form1.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "value", $$v)
                      },
                      expression: "form1.value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.renwuNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }