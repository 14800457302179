<template>
  <div>
    <div style="margin: 2% 0; display: inline-block">
      <span>投诉类型:</span>
      <el-select v-model="searchFormType" placeholder="请选择">
        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <span style="margin-left: 20px">处理结果:</span>
      <el-select v-model="searchFormDealResult" placeholder="请选择">
        <el-option v-for="item in dealResultOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div style="display: inline-block">
      <el-button style="margin-left: 15px" size="mini" type="primary" icon="document" @click="select">查询</el-button>
      <el-button style="margin-left: 15px" size="mini" type="primary" icon="document" @click="cleans">重置</el-button>
    </div>
    <el-table v-loading="tableDataLoading" :data="tableData.list">
      <el-table-column fixed prop="id" label="编号" width="80"> </el-table-column>
      <el-table-column prop="complaintUserName" label="投诉用户" width="150">
        <template slot-scope="scope">
          <span style="color: #4f9dec; cursor: pointer" @click="updates(scope.row)">{{
            scope.row.complaintUserName ? scope.row.complaintUserName : '未绑定'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="beComplaintUserName" label="被投诉用户"> </el-table-column>
      <el-table-column prop="typeValue" label="投诉类型"> </el-table-column>
      <el-table-column prop="content" label="投诉内容"> </el-table-column>
      <el-table-column prop="serveOrderId" label="关联订单ID" min-width="180">
        <template v-slot="scope">
          <el-button type="text" @click="goToService(scope.row)">{{ scope.row.serveOrderNo }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="imgUrlList" label="图片" min-width="100px">
        <template slot-scope="scope">
          <div v-if="scope.row.imgUrlList == null || scope.row.imgUrlList == ''">暂无图片</div>
          <div v-else style="display: flex; flex-wrap: wrap">
            <div v-for="item in scope.row.imgUrlList" :key="item" style="margin: 2px">
              <el-popover placement="top-start" title="" trigger="hover">
                <img style="width: 50px; height: 50px" :src="item" alt="" slot="reference" />
                <img style="width: 200px; height: 200px" :src="item" alt="" />
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="dealResultValue" label="处理结果"> </el-table-column>
      <el-table-column prop="dealContent" label="审核/处理内容"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="160"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button v-if="scope.row.dealResult === 1" size="mini" type="primary" @click="complainDetails(scope.row)"
            >处理</el-button
          >
          <el-button v-else size="mini" type="primary" @click="complainDetails(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="limit"
        :current-page="page"
        layout="total,sizes, prev, pager, next,jumper"
        :total="tableData.totalCount"
      >
      </el-pagination>
    </div>
    <HandleComplaint ref="handleComplaintRef" @onReset="dataSelect"></HandleComplaint>
  </div>
</template>
<script>
import HandleComplaint from './components/handleComplaint.vue'
export default {
  components: {
    HandleComplaint
  },
  data() {
    return {
      limit: 10,
      page: 1,
      userId: '',
      // 投诉类型
      searchFormType: '',
      // 状态
      searchFormDealResult: '',
      typeOptions: [
        { label: '服务投诉', value: 1 },
        { label: '任务投诉', value: 2 },
      ],
      dealResultOptions: [
        { label: '待处理', value: 1 },
        { label: '已处理', value: 4 },
      ],
      form: {
        id: '',
        content: '',
        picture: '',
        state: ''
      },
      imgs: [],
      formLabelWidth: '200px',
      activeName: 'first',
      tableDataLoading: false,
      tableData: [],
    }
  },
  mounted() {
    this.dataSelect()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val;
      this.dataSelect()
    },
    handleCurrentChange(val) {
      this.page = val;
      this.dataSelect()
    },
    // 用户详情跳转
    updates(row) {
      this.$router.push({ path: '/userDetail', query: { userId: row.complaintUserId } })
    },
    //删除服务投诉
    deleteStair(row) {
      let delid = row.id
      this.$confirm(`确定删除此条信息?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`helpClassify/deleteClassifyById/?id=${delid}`),
          method: 'post',
          data: this.$http.adornData({
          })
        }).then(({ data }) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.name = '';
              this.classifySelect()
            }
          })
        })
      }).catch(() => { })
    },
    // 查询
    select() {
      this.dataSelect()
    },
    // 重置
    cleans() {
      this.searchFormType = ''
      this.searchFormDealResult = ''
      this.dataSelect()
    },
    complainDetails(row) {
      // 1待处理 4已处理
      if (row.dealResult === 1) {
        this.$refs.handleComplaintRef.handleOpen(row, 1)
      } else {
        this.$refs.handleComplaintRef.handleOpen(row, 2)
      }
    },
    // 获取服务投诉列表
    dataSelect() {
      this.tableDataLoading = true
      this.$http({
        url: this.$http.adornUrl('admin/complaint/pcComplaintList'),
        method: 'post',
        data: {
          'page': this.page,
          'limit': this.limit,
          data: {
            type: this.searchFormType,
            dealResult: this.searchFormDealResult,
          }
        }
      }).then(({ data }) => {
        this.tableDataLoading = false
        this.tableData = data.data || { list: [] }
      })
    },
    // 跳转服务详情
    goToService(row) {
      this.$router.push({
        path: '/serviceCentreDetails',
        query: {
          id: row.serveOrderId
        }
      })
    },
  },
};
</script>

<style>
</style>
