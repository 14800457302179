<template>
	<el-tabs v-model="activeName" @tab-click="handleClick">
		<el-tab-pane label="优惠券列表" name="seventh">
			<div style="position: relative;display: inline-block;margin: 10px 0px;">
				<span>状态：</span>
				<el-select v-model="state" style="width:150px;margin-left: 10px;" @change="animeDat(state)">
					<el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>&nbsp;&nbsp;&nbsp;
			</div>
			<div style="position: relative;display: inline-block;margin: 10px 0px;">
				<span>手机号：</span>
				<el-input style="width: 200px;" @keydown.enter.native="animeDat" placeholder="请输入手机号" v-model="phone">
				</el-input>&nbsp;&nbsp
			</div>
			<div style="position: relative;display: inline-block;margin: 10px 0px;">
				<span>用户名：</span>
				<el-input style="width: 200px;" @keydown.enter.native="animeDat" placeholder="请输入用户名" v-model="userName">
				</el-input>&nbsp;&nbsp
			</div>
			<div style="position: relative;display: inline-block;margin: 10px 0px;">
				<span>优惠券名称：</span>
				<el-input style="width: 200px;" @keydown.enter.native="animeDat" placeholder="请输入优惠券名称" v-model="couponName">
				</el-input>&nbsp;&nbsp
			</div>
			<div style="position: relative;display: inline-block;margin-right:2%;">
				<el-button style="margin-left:15px;" size="mini" type="primary" icon="document" @click="animeDat">查询
				</el-button>
				<el-button style="margin-left:15px;" size="mini" type="primary" icon="document" @click="cleans">重置
				</el-button>
			</div>
			<el-table v-loading="tableDataLoading" :data="tableData.records">
				<el-table-column fixed prop="id" label="编号" width="80">
				</el-table-column>
				<el-table-column fixed prop="nickName" label="昵称" width="150">
					<template slot-scope="scope">
						<span style="color: #f56c6c;cursor: pointer;"
							@click="updataDetails(scope.row)">{{scope.row.userName ? scope.row.userName : '未绑定'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="图像">
					<template slot-scope="scope">
						<img v-if="scope.row.avatar==null" src="~@/assets/img/avatar.png" alt="" width="40" height="40">
						<img v-else :src="scope.row.avatar" alt="" width="40" height="40">
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号">
				</el-table-column>
				<el-table-column prop="couponName" label="优惠券名称">
				</el-table-column>
				<el-table-column prop="couponPicture" label="图片">
					<template slot-scope="scope">
						<img :src="scope.row.couponPicture" alt="" width="40" height="40">
					</template>
				</el-table-column>
				<el-table-column prop="money" label="优惠券金额">
				</el-table-column>
				<el-table-column prop="minMoney" label="最低消费">
				</el-table-column>
				<!-- <el-table-column prop="needIntegral" label="兑换积分">
        </el-table-column> -->
				<el-table-column prop="createTime" label="领取时间">
				</el-table-column>
				<el-table-column prop="expirationTime" label="过期时间">
				</el-table-column>
				<el-table-column prop="expirationTime" label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.status==0">待使用</span>
						<span v-else-if="scope.row.status==1">已使用</span>
						<span v-else-if="scope.row.status==2">已失效</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 10px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30,50,100]" :page-size="limit" :current-page="page"
					layout="total,sizes, prev, pager, next" :total="tableData.total">
				</el-pagination>
			</div>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	export default {
		data() {
			return {
				limit: 10,
				page: 1,
				state: '',
				url: '',
				title: '',
				type: '',
				id: '',
				types: [{
						value: 'word',
						label: '文字'
					},
					{
						value: 'url',
						label: '链接'
					}
				],
				phone: '',
				content: '',
				activeName: 'seventh',
				tableDataLoading: false,
				dialogFormVisible1: false,
				dialogFormVisible2: false,
				dialogFormVisible: false,
				tableData: [],
				typeList: [{
						value: '',
						label: '全部'
					},
					{
						value: 0,
						label: '待使用'
					},
					{
						value: 1,
						label: '已使用'
					},
					{
						value: 2,
						label: '已失效'
					}
				],
				phone: '',
				couponName:'',
				userName:'',
			}
		},
		methods: {
			// 详情跳转
			updataDetails(row) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: row.userId
					}
				});
			},
			handleSizeChange(val) {
				this.limit = val;
				this.dataSelect()
			},
			handleCurrentChange(val) {
				this.page = val;
				this.dataSelect()
			},
			handleClick(tab, event) {

				if (tab._props.label == '优惠券列表') {
					this.page = 1
					this.limit = 10
					this.state = 8
					this.dataSelect()
				}
			},
			// 获取数据列表
			dataSelect() {
				this.tableDataLoading = true
				this.$http({
					url: this.$http.adornUrl('admin/couponUser/getMyCouponList'),
					method: 'get',
					params: this.$http.adornParams({
						'page': this.page,
						'limit': this.limit,
						'userId': '',
						'status': this.state,
						'phone': this.phone,
						'couponName':this.couponName,
						'userName':this.userName
					})
				}).then(({
					data
				}) => {
					this.tableDataLoading = false
					let returnData = data.data;
					this.tableData = returnData
				})
			},
			animeDat() {
				this.page = 1
				this.dataSelect()
			},
			cleans() {
				this.page = 1
				this.phone = ''
				this.state = ''
				this.couponName = ''
				this.userName = ''
				this.dataSelect()
			}
		},
		mounted() {
			this.dataSelect()
		}
	};
</script>

<style>

</style>
