// 预约单状态
export const ordersStateOptions = [
  { label: '待沟通', value: 1, type: 'primary' },
  { label: '进行中', value: 2, type: 'success' },
  { label: '已完成', value: 3, type: 'info' },
  { label: '已取消', value: 4, type: 'info' },
]
// 服务单状态
export const serviceStateOptions = [
  { label: '方案沟通中', value: 1, type: 'primary' },
  { label: '待接单', value: 2, type: 'success' },
  { label: '系统自动派单中', value: 3, type: 'info' },
  { label: '平台派单中', value: 4, type: 'info' },
  { label: '服务中', value: 5, type: 'info' },
  { label: '验收中', value: 6, type: 'info' },
  { label: '验收完成', value: 7, type: 'info' },
  { label: '结算中', value: 8, type: 'info' },
  { label: '已完成', value: 9, type: 'info' },
  { label: '已取消', value: 10, type: 'info' },
]
// 方案状态
export const schemeStateOptions = [
  { label: '待提交', value: 1, type: 'primary' },
  { label: '待客户确认方案', value: 2, type: 'success' },
  { label: '客户驳回方案', value: 3, type: 'danger' },
  { label: '方案已确认', value: 4, type: 'success' },
  { label: '服务方申请调整方案', value: 5, type: 'danger' },
  { label: '方案调整中', value: 6, type: 'primary' },
  { label: '方案调整完成', value: 7, type: 'success' },
]
// 售后状态
export const afterStateOptions = [
  { label: '待服务方确认', value: 1, type: 'danger' },
  { label: '售后服务中', value: 2, type: 'success' },
  { label: '待客户确认', value: 3, type: 'danger' },
  { label: '已完成', value: 4, type: 'success' },
  { label: '已取消', value: 5, type: 'danger' },
]
// 验收状态
export const checkAcceptOptions = [
  { label: '未开始', value: 1, type: 'info' },
  { label: '待平台验收', value: 2, type: 'primary' },
  { label: '平台验收不通过', value: 3, type: 'danger' },
  { label: '待客户验收', value: 4, type: 'primary' },
  { label: '客户验收不通过', value: 5, type: 'danger' },
  { label: '验收完成', value: 6, type: 'info' },
]