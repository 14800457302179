var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "list_title" }, [_vm._v("数据筛选")]),
      _c("FormRender", {
        ref: "reportForm",
        attrs: {
          "form-config": _vm.formConfig,
          "form-data": _vm.formData,
          "form-type": "submit",
          "label-width": "100px",
          formType: "search",
        },
        on: {
          "update:formData": function ($event) {
            _vm.formData = $event
          },
          "update:form-data": function ($event) {
            _vm.formData = $event
          },
          onSearch: _vm.onSearch,
          onReset: _vm.onReset,
        },
      }),
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "list_title", attrs: { span: 12 } }, [
            _vm._v("全部订单列表"),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-thumb",
                    disabled: !_vm.selectRow?.length,
                  },
                  on: { click: _vm.handleOpenConsultant },
                },
                [_vm._v("指派服务顾问")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          "operate-width": "200",
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
          selection: "",
          selectable: _vm.selectable,
        },
        on: { onSelection: _vm.selectionTable },
        scopedSlots: _vm._u([
          {
            key: "servicePathName",
            fn: function ({ scopeRow }) {
              return [
                _c("div", { class: { text_red: !scopeRow.servicePathName } }, [
                  _vm._v(_vm._s(scopeRow.servicePathName || "未指定")),
                ]),
              ]
            },
          },
          {
            key: "counselorName",
            fn: function ({ scopeRow }) {
              return [
                _c("div", { class: { text_red: !scopeRow.counselorId } }, [
                  _vm._v(_vm._s(scopeRow.counselorName)),
                ]),
              ]
            },
          },
          {
            key: "doorInfo",
            fn: function ({ scopeRow }) {
              return [
                _c("div", [
                  _vm._v("联系人：" + _vm._s(scopeRow.createUserName)),
                ]),
                _c("div", [
                  _vm._v("联系手机：" + _vm._s(scopeRow.createUserPhone)),
                ]),
                _c("div", [_vm._v("联系地址：" + _vm._s(scopeRow.address))]),
                _c("div", [
                  _vm._v("上门时间：" + _vm._s(scopeRow.expectDoorTime)),
                ]),
              ]
            },
          },
          {
            key: "processState",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "div",
                  {
                    class: {
                      text_red: scopeRow?.preOrdersSerInfo?.processState,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(scopeRow?.preOrdersSerInfo?.processState || "-")
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "ordersStateDesc",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.ordersStateOptions.find(
                        (item) => item.value === scopeRow.ordersState
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.ordersStateDesc))]
                ),
              ]
            },
          },
          {
            key: "createUserName",
            fn: function ({ scopeRow }) {
              return [
                _c("span", [_vm._v(_vm._s(scopeRow.createUserName))]),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToUserInfo(scopeRow)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.goToDetail(scopeRow)
                      },
                    },
                  },
                  [_vm._v("查看订单")]
                ),
                scopeRow.ordersState === 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleOpenConfirm(scopeRow)
                          },
                        },
                      },
                      [_vm._v("确认完成")]
                    )
                  : _vm._e(),
                scopeRow.ordersState === 1
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCloseOrder(scopeRow)
                          },
                        },
                      },
                      [_vm._v("关闭订单")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("ServiceConsultantDialog", {
        ref: "serviceConsultantDialogRef",
        on: { handleOk: _vm.getList },
      }),
      _c("ConfirmCompletionDialog", {
        ref: "confirmCompletionDialogRef",
        on: { handleOk: _vm.getList },
      }),
      _c("CloseOrderdDialog", {
        ref: "closeOrderdDialogRef",
        on: { handleOk: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }