var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.renwuTitles,
        top: "5vh",
        "close-on-click-modal": false,
        customClass: "customWidth",
        visible: _vm.dialogVisible,
        center: "",
      },
      on: {
        close: _vm.closeDialog,
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "max-height": "70vh", "overflow-y": "auto" } },
        [
          _c(
            "el-form",
            {
              ref: "ruleFormRef",
              staticClass: "margin-auto",
              staticStyle: { width: "72%" },
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务名称", prop: "itemName" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.itemName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemName", $$v)
                              },
                              expression: "ruleForm.itemName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: " 服务简称", prop: "itemShortName" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.itemShortName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemShortName", $$v)
                              },
                              expression: "ruleForm.itemShortName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务logo", prop: "itemLogoUrl" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.$http.adornUrl("oss/upload"),
                                "show-file-list": false,
                                "on-progress": _vm.onprogress,
                                "on-success": _vm.handleLogoSuccess,
                              },
                              model: {
                                value: _vm.ruleForm.itemLogoUrl,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "itemLogoUrl", $$v)
                                },
                                expression: "ruleForm.itemLogoUrl",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "支持 JPG、JPEG、PNG、BMP 格式，图片小于10M"
                                  ),
                                ]
                              ),
                              _vm.ruleForm.itemLogoUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    staticStyle: {
                                      "border-radius": "6px",
                                      width: "148px",
                                      height: "148px",
                                    },
                                    attrs: { src: _vm.ruleForm.itemLogoUrl },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon iconss",
                                  }),
                              _vm.percentage > 0 && _vm.percentage < 100
                                ? _c("el-progress", {
                                    attrs: {
                                      type: "circle",
                                      percentage: _vm.percentage,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务分类", prop: "itemCategory" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: { options: _vm.serviceTypeList },
                            model: {
                              value: _vm.ruleForm.itemCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemCategory", $$v)
                              },
                              expression: "ruleForm.itemCategory",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务类型", prop: "itemType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择服务类型" },
                              model: {
                                value: _vm.ruleForm.itemType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "itemType", $$v)
                                },
                                expression: "ruleForm.itemType",
                              },
                            },
                            _vm._l(_vm.serviceTypes, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value + "",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预约单价", prop: "itemUnitPrice" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入预约单价" },
                            model: {
                              value: _vm.ruleForm.itemUnitPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemUnitPrice", $$v)
                              },
                              expression: "ruleForm.itemUnitPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上架状态", prop: "isEnable" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.isEnable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "isEnable", $$v)
                                },
                                expression: "ruleForm.isEnable",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("上架"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("下架"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序权重", prop: "sort" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.ruleForm.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sort", $$v)
                              },
                              expression: "ruleForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.isEdit
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "服务编号", prop: "itemCode" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.itemCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "itemCode", $$v)
                                  },
                                  expression: "ruleForm.itemCode",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "价格说明", prop: "itemPriceDesc" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: "6" },
                            model: {
                              value: _vm.ruleForm.itemPriceDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemPriceDesc", $$v)
                              },
                              expression: "ruleForm.itemPriceDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务封面", prop: "itemUrls" } },
                        [
                          _c("CustomImageUpload", {
                            ref: "itemUrls",
                            attrs: {
                              tip: "支持 JPG、JPEG、PNG、BMP 格式，图片小于10M",
                              prop: "itemUrls",
                              accept: ".png,.jpg,.jepg,.bmp",
                              fileSize: 10,
                            },
                            model: {
                              value: _vm.ruleForm.itemUrls,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "itemUrls", $$v)
                              },
                              expression: "ruleForm.itemUrls",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", display: "flex" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务详情：")]
              ),
              _c("quill-editor", {
                ref: "myTextEditor",
                staticStyle: {
                  "padding-bottom": "10px",
                  height: "300px",
                  width: "72%",
                  display: "inline-table",
                  "margin-bottom": "60px",
                },
                attrs: { options: _vm.quillOption },
                model: {
                  value: _vm.ruleForm.itemDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "itemDesc", $$v)
                  },
                  expression: "ruleForm.itemDesc",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "10px", "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmit("ruleFormRef")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }