var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "服务管理", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", float: "right" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled: !_vm.isAuth("missionsye:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addTasks()
                    },
                  },
                },
                [_vm._v("发布服务")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled:
                      !_vm.isAuth("missionsye:delete") ||
                      _vm.checkBoxData.length <= 0,
                    size: "mini",
                    type: "danger",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.delectTasks()
                    },
                  },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: { prop: "classifyName", label: "分类" },
              }),
              _c("el-table-column", {
                attrs: { prop: "titlePicture", label: "服务图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.titlePicture,
                            alt: "",
                            width: "60",
                            height: "60",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "服务标题", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "服务内容", width: "300" },
                domProps: { innerHTML: _vm._s(_vm.content) },
              }),
              _c("el-table-column", {
                attrs: { prop: "luckyValue", label: "幸运值" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": _vm.openValue,
                            "inactive-value": _vm.closeValue,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.change(scope.row.state, scope.row.id)
                            },
                          },
                          model: {
                            value: scope.row.state,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "state", $$v)
                            },
                            expression: "scope.row.state",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("missionsye:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.redactTask(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("missionsye:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.delectTask(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 15, 20],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.totalnum,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "分类管理", name: "third" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", float: "right" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled: !_vm.isAuth("missionsye:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.classifyStair()
                    },
                  },
                },
                [_vm._v("添加分类")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled:
                      !_vm.isAuth("missionsye:delete") ||
                      _vm.checkBoxData.length <= 0,
                    size: "mini",
                    type: "danger",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteStairs()
                    },
                  },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.classifyData },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  type: "index",
                  label: "编号",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.page - 1) * _vm.limit + scope.$index + 1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "classifyName", label: "分类名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("missionsye:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.compile(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("missionsye:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteStair(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加分类",
                visible: _vm.dialogFormVisible,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("分类名称：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.classifyName,
                      callback: function ($$v) {
                        _vm.classifyName = $$v
                      },
                      expression: "classifyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.StairNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑分类",
                visible: _vm.dialogFormVisible2,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible2 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.form.classifyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "classifyName", $$v)
                          },
                          expression: "form.classifyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible2 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.CompileNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "服务审核", name: "thirdly" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", float: "right" } },
            [
              _vm.isAuth("missionsye:pass")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.passClick()
                        },
                      },
                    },
                    [_vm._v("通过")]
                  )
                : _vm._e(),
              _vm.isAuth("missionsye:refuse")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.refuseClick()
                        },
                      },
                    },
                    [_vm._v("拒绝")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.auditData },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "titlePicture",
                  label: "服务图片",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.titlePicture,
                            alt: "",
                            width: "60",
                            height: "60",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "服务标题", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "Tcontent", label: "用户内容" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.userId))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "服务内容", width: "300" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state === 0
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待提交"),
                            ])
                          : _vm._e(),
                        scope.row.state === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                        scope.row.state === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已通过"),
                            ])
                          : _vm._e(),
                        scope.row.state === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已拒绝"),
                            ])
                          : _vm._e(),
                        scope.row.state === 4
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已放弃"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.lookTask(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 15, 20],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.totalnum,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { title: "查看服务", visible: _vm.dialogFormVisible3 },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible3 = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v(_vm._s(_vm.Tcontent ? _vm.Tcontent : "暂无内容")),
              ]),
              _vm._l((_vm.picture || "").split(","), function (item, index) {
                return _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("img", {
                    staticStyle: { width: "50%", height: "100%" },
                    attrs: { src: item, alt: "" },
                  }),
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: !_vm.isAuth("missionsye:pass"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.passClickrow(_vm.id)
                        },
                      },
                    },
                    [_vm._v("通过")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "判决理由",
                visible: _vm.dialogFormVisible5,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible5 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("拒绝理由：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入拒绝理由" },
                    model: {
                      value: _vm.auditContent,
                      callback: function ($$v) {
                        _vm.auditContent = $$v
                      },
                      expression: "auditContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible5 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refuseto()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "服务进行中", name: "sixth" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.proceedData },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "titlePicture",
                  label: "服务图片",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.titlePicture,
                            alt: "",
                            width: "60",
                            height: "60",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "服务标题", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "Tcontent", label: "用户内容" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.userId))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "服务内容", width: "300" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state === 0
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待提交"),
                            ])
                          : _vm._e(),
                        scope.row.state === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                        scope.row.state === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已通过"),
                            ])
                          : _vm._e(),
                        scope.row.state === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已拒绝"),
                            ])
                          : _vm._e(),
                        scope.row.state === 4
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已放弃"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("missionsye:warn"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.remind(scope.row)
                              },
                            },
                          },
                          [_vm._v("提醒 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("missionsye:waive"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.abandon(scope.row)
                              },
                            },
                          },
                          [_vm._v("放弃 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 15, 20],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.totalnum,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "红包榜分类", name: "fourth" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", float: "right" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled: !_vm.isAuth("missionsye:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addClick()
                    },
                  },
                },
                [_vm._v("添加队列")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.queueData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "队列名称", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "luckyValue",
                  label: "幸运值额度",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "队列等级", width: "80" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "recommendMoney",
                  label: "推荐奖",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.recommendMoney.toFixed(2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "handOut", label: "派送红包", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.handOut.toFixed(2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "numberOfPeople",
                  label: "所需人数",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startPerson",
                  label: "队列开始所需人数",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "intervalPerson",
                  label: "队列间隔人数",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("missionsye:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.compileQueue(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("missionsye:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteQueue(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加队列类型",
                visible: _vm.dialogFormVisible6,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible6 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("队列名称：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入队列名称" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("幸运值额度：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "number", placeholder: "请输入幸运值额度" },
                    model: {
                      value: _vm.luckyValue,
                      callback: function ($$v) {
                        _vm.luckyValue = $$v
                      },
                      expression: "luckyValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("队列等级：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "number", placeholder: "请输入队列等级" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("推荐奖：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "number", placeholder: "请输入推荐奖" },
                    model: {
                      value: _vm.recommendMoney,
                      callback: function ($$v) {
                        _vm.recommendMoney = $$v
                      },
                      expression: "recommendMoney",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("派送红包：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "number", placeholder: "请输入派送红包" },
                    model: {
                      value: _vm.handOut,
                      callback: function ($$v) {
                        _vm.handOut = $$v
                      },
                      expression: "handOut",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("所需人数：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "number", placeholder: "请输入所需人数" },
                    model: {
                      value: _vm.numberOfPeople,
                      callback: function ($$v) {
                        _vm.numberOfPeople = $$v
                      },
                      expression: "numberOfPeople",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("队列开始所需人数：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "number",
                      placeholder: "请输入队列开始所需人数",
                    },
                    model: {
                      value: _vm.startPerson,
                      callback: function ($$v) {
                        _vm.startPerson = $$v
                      },
                      expression: "startPerson",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("队列间隔人数：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "number",
                      placeholder: "请输入队列间隔人数",
                    },
                    model: {
                      value: _vm.intervalPerson,
                      callback: function ($$v) {
                        _vm.intervalPerson = $$v
                      },
                      expression: "intervalPerson",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible6 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.queueNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "编辑队列类型",
                visible: _vm.dialogFormVisible7,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible7 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form2 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "队列名称：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.form2.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "name", $$v)
                          },
                          expression: "form2.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "幸运值额度：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.luckyValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "luckyValue", $$v)
                          },
                          expression: "form2.luckyValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "队列等级：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { readonly: "", type: "number" },
                        model: {
                          value: _vm.form2.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "sort", $$v)
                          },
                          expression: "form2.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "推荐奖：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.recommendMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "recommendMoney", $$v)
                          },
                          expression: "form2.recommendMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "派送红包：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.handOut,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "handOut", $$v)
                          },
                          expression: "form2.handOut",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所需人数：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.numberOfPeople,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "numberOfPeople", $$v)
                          },
                          expression: "form2.numberOfPeople",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "队列开始所需人数：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.startPerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "startPerson", $$v)
                          },
                          expression: "form2.startPerson",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "队列间隔人数：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.intervalPerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "intervalPerson", $$v)
                          },
                          expression: "form2.intervalPerson",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible7 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.CompileQueueTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "红包榜审核", name: "fifth" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", float: "right" } },
            [
              _vm.isAuth("missionsye:pass")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.queueClick()
                        },
                      },
                    },
                    [_vm._v("通过")]
                  )
                : _vm._e(),
              _vm.isAuth("missionsye:refuse")
                ? _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.queueRefuse()
                        },
                      },
                    },
                    [_vm._v("拒绝")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.checkData },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", { attrs: { type: "selection" } }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "队列名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "imageUrl", label: "用户图像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.imageUrl,
                            alt: "",
                            width: "60",
                            height: "60",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "auditContent", label: "审核内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "用户名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "state", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state === 0
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待审核"),
                            ])
                          : _vm._e(),
                        scope.row.state === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("通过"),
                            ])
                          : _vm._e(),
                        scope.row.state === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("拒绝"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 15, 20],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.totalnum,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "判决理由",
                visible: _vm.dialogFormVisible8,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible8 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("拒绝理由：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入拒绝理由" },
                    model: {
                      value: _vm.auditContent,
                      callback: function ($$v) {
                        _vm.auditContent = $$v
                      },
                      expression: "auditContent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible8 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.queueRefuseto()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }