<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="9">
      <template #title>
        <h2 class="details_title"><span></span>服务协议</h2>
      </template>
      <div class="agreement_container" v-html="commonInfo.value"></div>
    </el-collapse-item>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      commonInfo: {}
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.commonInfo = val?.commonInfo || {}
      },
      deep: true
    }
  }
}
</script>

<style lang='less' scoped>
.agreement_container {
  border: 1px solid #f2f2f2;
  padding: 20px;
  min-height: 400px;
}
</style>