<template>
  <div>
    <div style="font-size:18px;margin-bottom: 15px;">
      <a href="#" @click="prev" style="text-decoration:none;font-size: 14px;">
        <icon-svg name="jiantou" style="width: 1.2em;height: 1.2em;position: relative;top: 0.3em;"></icon-svg>
        返回
      </a>
      <span style="display: inline-block;margin: 0 15px;color: #D9D9D9;">|</span>
      <span>保证金明细</span>
    </div>
    <el-table v-loading="tableDataLoading" :data="tableData.list">
      <el-table-column fixed prop="id" label="编号" width="80">
      </el-table-column>
      <!-- <el-table-column prop = "nickName" label = "用户昵称" width = "120">
      	<template slot-scope="scope">
      		<div style="color: #4f9dec;cursor: pointer;" @click="updates(scope.row)">{{scope.row.nickName?scope.row.nickName:'未绑定'}}</div>
      	</template>
      </el-table-column> -->
      <el-table-column prop="money" label="保证金" width="100">
        <template slot-scope="scope">
          <span style="color: #f56c6c;" v-if="scope.row.type==2">- {{ scope.row.money }}</span>
          <span style="color: #008200;" v-if="scope.row.type==1">+ {{ scope.row.money }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="phone" label="手机号" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.phone ? scope.row.phone : '暂无'}}</span>
        </template>
      </el-table-column> -->

      <el-table-column prop="title" label="标题">
      </el-table-column>
      <el-table-column prop="content" label="内容">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="150">
      </el-table-column>
      <el-table-column prop="type" label="状态" width="100" fixed="right">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">已交保证金</span>
          <span v-if="scope.row.type == 2">已退保证金</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 10px;">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]" :page-size="limit" :current-page="page" layout="total,sizes, prev, pager, next"
        :total="tableData.totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        limit: 10,
        page: 1,
        activeName: 'first',
        tableDataLoading: false,
        dialogFormVisible: false,
        tableData: {},
        shopId:'',
      }
    },
    methods: {
    

      handleSizeChange(val) {
        this.limit = val;
        this.dataSelect()
      },
      handleCurrentChange(val) {
        this.page = val;
        this.dataSelect()
      },
			handleSizeChangeBzj(val) {
				this.limit = val
				this.baozhengjinSelect()
			},
			handleCurrentChangeBzj(val) {
				this.page = val
				this.baozhengjinSelect()
			},
      // 获取派单数据列表
      dataSelect() {
        this.tableDataLoading = true
        this.$http({
          url: this.$http.adornUrl('admin/shop/selectShopCashDepositList'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.page,
            'limit': this.limit,
            'shopId': this.shopId
          })
        }).then(({
          data
        }) => {
          this.tableDataLoading = false
          let returnData = data.data;
          this.tableData = returnData
        })
      },
      // 返回上一级
      prev() {
        this.$router.back()
      },
    },
    mounted() {
      this.shopId = this.$route.query.shopId
      this.dataSelect()
    }
  };
</script>

<style>

</style>
