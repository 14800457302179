var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h2", { staticClass: "dispatch_title" }, [
              _c("span"),
              _vm._v("当前订单状态：" + _vm._s(_vm.basicInfo.orderStateValue)),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
              _c("el-button", { on: { click: _vm.handleOpenRemark } }, [
                _vm._v("备注订单"),
              ]),
              _vm.basicInfo.isShowCheckBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOpenCheck(0)
                        },
                      },
                    },
                    [_vm._v("驳回验收")]
                  )
                : _vm._e(),
              _vm.basicInfo.isShowCheckBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleOpenCheck(1)
                        },
                      },
                    },
                    [_vm._v("确认通过验收")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-steps",
        {
          staticClass: "header_status",
          attrs: {
            active: _vm.active,
            "finish-status": "success",
            "process-status": "finish",
          },
        },
        [
          _c("el-step", {
            attrs: { title: "确认接单", description: _vm.timeObj.takeTime },
          }),
          _c("el-step", {
            attrs: {
              title: "开始服务",
              description: _vm.timeObj.serveStartTime,
            },
          }),
          _c("el-step", {
            attrs: {
              title: "开始验收",
              description: _vm.timeObj.checkStartTime,
            },
          }),
          _c("el-step", {
            attrs: {
              title: "开始结算",
              description: _vm.timeObj.settleStartTime,
            },
          }),
          _c("el-step", {
            attrs: {
              title: "已完成",
              description: _vm.timeObj.settleCompleteTime,
            },
          }),
        ],
        1
      ),
      _c("OrderRemarkDialog", {
        ref: "orderRemarkDialogRef",
        on: { refresh: _vm.refresh },
      }),
      _c("AuditDialog", {
        ref: "auditDialogRef",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }