<template>
  <div>
    <el-collapse style="border: 0" v-model="activeNames">
      <!-- 详情页头部 -->
      <DetailsHeaderModule @refresh="queryServeOrderDetail" :basicInfo="basicInfo"></DetailsHeaderModule>
      <!-- 服务单信息、服务信息、服务说明 -->
      <ServiceInfoModule :basicInfo="basicInfo"></ServiceInfoModule>
      <!-- 工人管理 -->
      <WorkerArrangementModule :basicInfo="basicInfo"></WorkerArrangementModule>
      <!-- 服务方案 -->
      <SchemeModule :basicInfo="basicInfo"></SchemeModule>
      <!-- 服务进度详情 -->
      <ServiceProgressModule :basicInfo="basicInfo"></ServiceProgressModule>
      <!-- 验收记录 -->
      <CheckRemarkModule :basicInfo="basicInfo"></CheckRemarkModule>
      <!-- 售后记录 -->
      <AfterSaleModule :basicInfo="basicInfo"></AfterSaleModule>
      <!-- 服务评价 -->
      <ServiceRateModule :basicInfo="basicInfo"></ServiceRateModule>
      <!-- 结算信息 -->
      <SettlementModule :basicInfo="basicInfo"></SettlementModule>
      <!-- 服务协议 -->
      <ServiceAgreementModule :basicInfo="basicInfo"></ServiceAgreementModule>
      <!-- 备注信息 -->
      <RemarkModule :basicInfo="basicInfo"></RemarkModule>
      <!-- 操作信息 -->
      <OperateInfoModule :basicInfo="basicInfo"></OperateInfoModule>
    </el-collapse>
  </div>
</template>

<script>
import DetailsHeaderModule from './module/detailsHeaderModule.vue'
import ServiceInfoModule from './module/serviceInfoModule.vue'
import WorkerArrangementModule from './module/workerArrangementModule.vue'
import SchemeModule from './module/schemeModule.vue'
import ServiceProgressModule from './module/serviceProgressModule.vue'
import CheckRemarkModule from './module/checkRemarkModule.vue'
import ServiceRateModule from './module/serviceRateModule.vue'
import AfterSaleModule from './module/afterSaleModule.vue'
import SettlementModule from './module/settlementModule.vue'
import ServiceAgreementModule from './module/serviceAgreementModule.vue'
import RemarkModule from './module/remarkModule.vue'
import OperateInfoModule from './module/operateInfoModule.vue'
import { queryServeOrderDetail } from '@/api/maintenance/index'
export default {
  components: {
    DetailsHeaderModule,
    ServiceInfoModule,
    WorkerArrangementModule,
    SchemeModule,
    SettlementModule,
    ServiceProgressModule,
    CheckRemarkModule,
    AfterSaleModule,
    ServiceRateModule,
    ServiceAgreementModule,
    RemarkModule,
    OperateInfoModule,
  },
  data() {
    return {
      id: '',
      // 折叠面板
      activeNames: [],
      // 详情信息
      basicInfo: {},
    }
  },
  created() {
    this.id = this.$route.query.id
    this.queryServeOrderDetail()
  },
  methods: {
    // 获取详情
    async queryServeOrderDetail() {
      const res = await queryServeOrderDetail({
        id: this.id
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.basicInfo = res.data.data
    },
  }
}
</script>

<style lang='less' scoped>
</style>