var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { margin: "2% 0", display: "inline-block" } },
        [
          _c("span", [_vm._v("用户昵称:")]),
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "请输入用户昵称" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.select.apply(null, arguments)
              },
            },
            model: {
              value: _vm.nickName,
              callback: function ($$v) {
                _vm.nickName = $$v
              },
              expression: "nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.select },
            },
            [_vm._v("查询 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.cleans },
            },
            [_vm._v("重置 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.tableData.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "chatConversationId",
              label: "id",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "用户昵称", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row.userId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.userName ? scope.row.userName : "未绑定"
                            )
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row.byUserId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.byUserName
                                ? scope.row.byUserName
                                : "未绑定"
                            )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "picture", label: "用户头像", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.avatar == null || scope.row.avatar == ""
                      ? _c("div", [_vm._v(" 暂无图片 ")])
                      : _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.avatar,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: { src: scope.row.avatar, alt: "" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                    scope.row.byAvatar == null || scope.row.byAvatar == ""
                      ? _c("div", [_vm._v(" 暂无图片 ")])
                      : _c(
                          "div",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.byAvatar,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: { src: scope.row.byAvatar, alt: "" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "最新聊天内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.messageType == 1
                      ? _c("div", [_vm._v(_vm._s(scope.row.content))])
                      : _vm._e(),
                    scope.row.messageType == 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.content,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: { src: scope.row.content, alt: "" },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    scope.row.messageType == 3
                      ? _c("div", [
                          _c("audio", {
                            attrs: {
                              src: scope.row.content,
                              type: "audio/wav",
                              controls: "controls",
                            },
                          }),
                        ])
                      : _vm._e(),
                    scope.row.messageType == 4
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src:
                                      "https://taskshop.xianmxkj.com/emoji/" +
                                      scope.row.content,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: {
                                    src:
                                      "https://taskshop.xianmxkj.com/emoji/" +
                                      scope.row.content,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    scope.row.messageType == 5
                      ? _c("div", [
                          _c("span", [
                            _c("i", {
                              staticClass: "el-icon-location",
                              staticStyle: {
                                width: "30px",
                                cursor: "pointer",
                                color: "#4f9dec",
                              },
                            }),
                            _vm._v(_vm._s(scope.row.content.address) + " "),
                          ]),
                        ])
                      : _vm._e(),
                    scope.row.messageType == 6
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "订单咨询：" +
                                _vm._s(scope.row.content.ordersNo) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "30px",
                                  cursor: "pointer",
                                  color: "#4f9dec",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderBtn(
                                      scope.row.content.ordersNo
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]),
                          _c("div", { staticStyle: { "margin-top": "5px" } }, [
                            _c(
                              "div",
                              { staticStyle: { float: "left", margin: "5px" } },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.content.homepageImg,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: {
                                        src: scope.row.content.homepageImg,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "60px" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#000",
                                      "font-weight": "600",
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.content.myLevel))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "line-height": "12px",
                                    },
                                  },
                                  _vm._l(
                                    scope.row.content.gameName,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        _vm._v(_vm._s(item) + " "),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c("div", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "¥" + _vm._s(scope.row.content.payMoney)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "updateTime", label: "创建时间", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.complainDetails(scope.row)
                          },
                        },
                      },
                      [_vm._v("聊天内容 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next",
              total: _vm.tableData.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "聊天内容",
            visible: _vm.dialogFormVisible,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "2% 0", display: "inline-block" } },
            [
              _c("span", [_vm._v("内容:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入聊天内容" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select1.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select1 },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans1 },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.userData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "chatContentId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户昵称", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "用户头像", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar == null || scope.row.avatar == ""
                          ? _c("div", [_vm._v(" 暂无图片 ")])
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.avatar,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: { src: scope.row.avatar, alt: "" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "信息内容" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.messageType == 1
                          ? _c("div", [_vm._v(_vm._s(scope.row.content))])
                          : _vm._e(),
                        scope.row.messageType == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.content,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: {
                                        src: scope.row.content,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.messageType == 3
                          ? _c("div", [
                              _c("audio", {
                                attrs: {
                                  src: scope.row.content,
                                  type: "audio/wav",
                                  controls: "controls",
                                },
                              }),
                            ])
                          : _vm._e(),
                        scope.row.messageType == 4
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src:
                                          "https://taskshop.xianmxkj.com/emoji/" +
                                          scope.row.content,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: {
                                        src:
                                          "https://taskshop.xianmxkj.com/emoji/" +
                                          scope.row.content,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.messageType == 5
                          ? _c("div", [
                              _c("span", [
                                _c("i", {
                                  staticClass: "el-icon-location",
                                  staticStyle: {
                                    width: "30px",
                                    cursor: "pointer",
                                    color: "#4f9dec",
                                  },
                                }),
                                _vm._v(_vm._s(scope.row.content.address) + " "),
                              ]),
                            ])
                          : _vm._e(),
                        scope.row.messageType == 6
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  "订单咨询：" +
                                    _vm._s(scope.row.content.ordersNo) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      cursor: "pointer",
                                      color: "#4f9dec",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderBtn(
                                          scope.row.content.ordersNo
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "5px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        margin: "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: scope.row.content
                                                .homepageImg,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "200px",
                                              height: "200px",
                                            },
                                            attrs: {
                                              src: scope.row.content
                                                .homepageImg,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-left": "60px" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.content.myLevel)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "line-height": "12px",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.content.gameName,
                                          function (item, index) {
                                            return _c("span", { key: index }, [
                                              _vm._v(_vm._s(item) + " "),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            "¥" +
                                              _vm._s(scope.row.content.payMoney)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "150" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("chatRecord:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteuser(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.userData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "处理服务",
            visible: _vm.dialogFormVisible1,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务状态：")]
              ),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("下架")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("不下架")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.complainTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }