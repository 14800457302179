var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("DetailsHeaderModule", {
        attrs: { basicInfo: _vm.basicInfo },
        on: {
          refresh: _vm.getPreOrdersDetail,
          refreshRemark: _vm.getNotesRecordList,
        },
      }),
      _c("BasciModule", { attrs: { basicInfo: _vm.basicInfo } }),
      _c("WorkersModule", { attrs: { basicInfo: _vm.basicInfo } }),
      _c("MaintainImgModule", { attrs: { basicInfo: _vm.basicInfo } }),
      _c("ServiceInfoModule", {
        attrs: { basicInfo: _vm.basicInfo },
        on: { refresh: _vm.getPreOrdersDetail },
      }),
      _c("RemarkModule", { attrs: { basicInfo: _vm.remarkInfo } }),
      _c("OperateModule", { attrs: { basicInfo: _vm.operateInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }