var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { margin: "2% 0", display: "inline-block" } },
        [
          _c("span", [_vm._v("投诉类型:")]),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.searchFormType,
                callback: function ($$v) {
                  _vm.searchFormType = $$v
                },
                expression: "searchFormType",
              },
            },
            _vm._l(_vm.typeOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c("span", { staticStyle: { "margin-left": "20px" } }, [
            _vm._v("处理结果:"),
          ]),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.searchFormDealResult,
                callback: function ($$v) {
                  _vm.searchFormDealResult = $$v
                },
                expression: "searchFormDealResult",
              },
            },
            _vm._l(_vm.dealResultOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.select },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.cleans },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.tableData.list },
        },
        [
          _c("el-table-column", {
            attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "complaintUserName",
              label: "投诉用户",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.complaintUserName
                              ? scope.row.complaintUserName
                              : "未绑定"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "beComplaintUserName", label: "被投诉用户" },
          }),
          _c("el-table-column", {
            attrs: { prop: "typeValue", label: "投诉类型" },
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "投诉内容" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serveOrderId",
              label: "关联订单ID",
              "min-width": "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.goToService(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.serveOrderNo))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "imgUrlList", label: "图片", "min-width": "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.imgUrlList == null || scope.row.imgUrlList == ""
                      ? _c("div", [_vm._v("暂无图片")])
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(scope.row.imgUrlList, function (item) {
                            return _c(
                              "div",
                              { key: item, staticStyle: { margin: "2px" } },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: item,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: { src: item, alt: "" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "dealResultValue", label: "处理结果" },
          }),
          _c("el-table-column", {
            attrs: { prop: "dealContent", label: "审核/处理内容" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { fixed: "right", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.dealResult === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.complainDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("处理")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.complainDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next,jumper",
              total: _vm.tableData.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("HandleComplaint", {
        ref: "handleComplaintRef",
        on: { onReset: _vm.dataSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }