var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "18px",
            "margin-bottom": "15px",
          },
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("员工调度")]),
        ]
      ),
      _c("div", [
        _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "10px",
              display: "inline-block",
              width: "100%",
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "15px" },
                attrs: { size: "mini", type: "primary", icon: "document" },
                on: { click: _vm.handleChange55 },
              },
              [_vm._v(" 刷新")]
            ),
          ],
          1
        ),
      ]),
      _c("div", [
        _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoadingDt,
              expression: "tableDataLoadingDt",
            },
          ],
          staticStyle: {
            width: "95%",
            height: "80vh",
            "margin-left": "2.5%",
            position: "relative",
          },
          attrs: { id: "container" },
        }),
      ]),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "1000" },
          attrs: {
            title: "待服务订单",
            visible: _vm.dialogFormVisible2,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "服务订单", name: "first" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "document",
                          },
                          on: { click: _vm.animeDat },
                        },
                        [_vm._v("刷新 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "document",
                          },
                          on: { click: _vm.zhipaiDet },
                        },
                        [_vm._v(" 指派订单 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoading,
                          expression: "tableDataLoading",
                        },
                      ],
                      attrs: { data: _vm.tableData.records },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "ordersId", label: "编号", width: "80" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "ordersNo",
                          label: "订单编号",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "myLevel", label: "标题", width: "150" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "detailsAddress",
                          label: "服务信息",
                          width: "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "联系人姓名：" + _vm._s(scope.row.name)
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "联系人联系：" + _vm._s(scope.row.phone)
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "服务地址：" +
                                      _vm._s(scope.row.province) +
                                      _vm._s(scope.row.city) +
                                      _vm._s(scope.row.detailsAddress) +
                                      " " +
                                      _vm._s(scope.row.district) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTime",
                          label: "上门时间",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "remarks", label: "备注", width: "200" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "oldMoney",
                          label: "价格",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "payMoney", label: "支付金额" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f56c6c" } },
                                  [_vm._v(_vm._s(scope.row.payMoney))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderNumber",
                          label: "时长",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "unit", label: "单位", width: "100" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "code", label: "确认码" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "state",
                          label: "订单状态",
                          fixed: "right",
                          width: "80",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.state == 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#f56c6c" } },
                                      [_vm._v("待支付")]
                                    )
                                  : _vm._e(),
                                scope.row.state == 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#E87D0D" } },
                                      [_vm._v("进行中")]
                                    )
                                  : _vm._e(),
                                scope.row.state == 2
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#009900" } },
                                      [_vm._v("已完成")]
                                    )
                                  : _vm._e(),
                                scope.row.state == 3
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("已退款")]
                                    )
                                  : _vm._e(),
                                scope.row.state == 4
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("待服务")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          width: "160",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.limit,
                          "current-page": _vm.page,
                          layout: "total,sizes, prev, pager, next",
                          total: _vm.tableData.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "任务订单", name: "daili" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "document",
                          },
                          on: { click: _vm.animeDatRw },
                        },
                        [_vm._v("刷新 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "document",
                          },
                          on: { click: _vm.zhipaiDetRw },
                        },
                        [_vm._v(" 指派订单 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoading,
                          expression: "tableDataLoading",
                        },
                      ],
                      attrs: { data: _vm.tableData.records },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "helpOrderId",
                          label: "编号",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "userName",
                          label: "发单用户",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updates(scope.row.userId)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.userName
                                            ? scope.row.userName
                                            : "未绑定"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "taskType", label: "服务类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.taskType == 1
                                  ? _c("span", [_vm._v("悬赏任务")])
                                  : _vm._e(),
                                scope.row.taskType == 2
                                  ? _c("span", [_vm._v("报价任务")])
                                  : _vm._e(),
                                scope.row.taskType == 3
                                  ? _c("span", [_vm._v("一口价任务")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "下单人姓名",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "phone",
                          label: "下单人手机号",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "province",
                          label: "下单人地址",
                          width: "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row.province) +
                                      _vm._s(scope.row.city) +
                                      _vm._s(scope.row.district) +
                                      _vm._s(scope.row.detailsAddress)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "content", label: "提交内容" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "money",
                          label: "接单价格",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "startTime", label: "服务开始时间" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "endTime", label: "服务结束时间" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTaskAddress",
                          label: "服务开始地址",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endTaskAddress",
                          label: "服务结束地址",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "startTime", label: "服务开始时间" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "endTime", label: "服务结束时间" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTaskImg",
                          label: "服务开始前图片",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.startTaskImg
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-wrap": "wrap",
                                        },
                                      },
                                      _vm._l(
                                        scope.row.startTaskImg.split(","),
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            { key: index },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    title: "",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "50px",
                                                      height: "50px",
                                                      margin: "3px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: item,
                                                      alt: "",
                                                    },
                                                    slot: "reference",
                                                  }),
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "300px",
                                                      height: "auto",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endTaskImg",
                          label: "服务结束后图片",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.endTaskImg
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-wrap": "wrap",
                                        },
                                      },
                                      _vm._l(
                                        scope.row.endTaskImg.split(","),
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            { key: index },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    title: "",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "50px",
                                                      height: "50px",
                                                      margin: "3px",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                      src: item,
                                                      alt: "",
                                                    },
                                                    slot: "reference",
                                                  }),
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "300px",
                                                      height: "auto",
                                                    },
                                                    attrs: {
                                                      src: item,
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startAddress",
                          label: "服务开始地址",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endAddress",
                          label: "服务结束地址",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTaskLongitude",
                          label: "服务开始经度",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "startTaskLatitude",
                          label: "服务开始纬度",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endTaskLongitude",
                          label: "服务结束经度",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "endTaskLatitude",
                          label: "服务结束纬度",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "code", label: "确认码" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "serviceTime",
                          label: "预约时间",
                          fixed: "right",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remarks",
                          label: "订单备注",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          prop: "status",
                          label: "状态",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status === 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#4f9dec" } },
                                      [_vm._v("待服务")]
                                    )
                                  : _vm._e(),
                                scope.row.status === 2
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#4f9dec" } },
                                      [_vm._v("服务中")]
                                    )
                                  : _vm._e(),
                                scope.row.status === 3
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#4f9dec" } },
                                      [_vm._v("已完成")]
                                    )
                                  : _vm._e(),
                                scope.row.status === 4
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("已取消")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.limit,
                          "current-page": _vm.page,
                          layout: "total,sizes, prev, pager, next",
                          total: _vm.tableData.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "服务订单列表",
            visible: _vm.dialogFormVisible,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("订单类型：")]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "10px" },
                      on: {
                        change: function ($event) {
                          return _vm.selectFw()
                        },
                      },
                      model: {
                        value: _vm.typeFw,
                        callback: function ($$v) {
                          _vm.typeFw = $$v
                        },
                        expression: "typeFw",
                      },
                    },
                    _vm._l(_vm.typeNum, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v("    "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("下单人用户名：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入下单人用户名" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.selectFw.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.userNameFw,
                      callback: function ($$v) {
                        _vm.userNameFw = $$v
                      },
                      expression: "userNameFw",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "3px",
                  },
                },
                [
                  _c("span", [_vm._v("订单编号：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入订单编号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.selectFw.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.ordersNoFw,
                      callback: function ($$v) {
                        _vm.ordersNoFw = $$v
                      },
                      expression: "ordersNoFw",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "5px", display: "inline-block" } },
                [
                  _c("span", [_vm._v("开始时间：")]),
                  _c("el-date-picker", {
                    staticStyle: { width: "160px", "margin-left": "10px" },
                    attrs: {
                      align: "right",
                      type: "datetime",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择开始时间",
                    },
                    model: {
                      value: _vm.startTimeFw,
                      callback: function ($$v) {
                        _vm.startTimeFw = $$v
                      },
                      expression: "startTimeFw",
                    },
                  }),
                  _vm._v("    "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "5px", display: "inline-block" } },
                [
                  _c("span", [_vm._v("截止时间：")]),
                  _c("el-date-picker", {
                    staticStyle: { width: "160px", "margin-left": "10px" },
                    attrs: {
                      align: "right",
                      type: "datetime",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择截止时间",
                    },
                    model: {
                      value: _vm.endTimeFw,
                      callback: function ($$v) {
                        _vm.endTimeFw = $$v
                      },
                      expression: "endTimeFw",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.selectFw },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleansFw },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingU,
                  expression: "tableDataLoadingU",
                },
              ],
              attrs: { data: _vm.userData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "ordersId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "riderName", label: "接单员工" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("span", [_vm._v(" - ")])
                          : _c("span", { staticStyle: { color: "#009900" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.riderName
                                    ? scope.row.riderName
                                    : "未绑定"
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "下单用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ordersType == 1
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#008000",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row.userId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ordersUserName))]
                            )
                          : _vm._e(),
                        scope.row.ordersType == 2
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#008000",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row.userId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.ordersUserName))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "companyName", label: "商家名称", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ordersNo", label: "订单编号", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ordersType", label: "订单类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.ordersType == 1
                          ? _c("span", [_vm._v("服务订单")])
                          : _vm._e(),
                        scope.row.ordersType == 2
                          ? _c("span", [_vm._v("会员订单")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "myLevel", label: "标题", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "oldMoney", label: "发布价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "普通用户价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "memberMoney", label: "会员价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "rate", label: "商家佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.rate ? scope.row.rate : "0")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "couponMoney", label: "优惠金额", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.couponMoney)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payMoney", label: "支付金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(scope.row.payMoney)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNumber", label: "时长", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "unit", label: "单位", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "detailsAddress",
                  label: "服务信息",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v("联系人姓名：" + _vm._s(scope.row.name)),
                        ]),
                        _c("div", [
                          _vm._v("联系人联系：" + _vm._s(scope.row.phone)),
                        ]),
                        _c("div", [
                          _vm._v(
                            "服务地址：" +
                              _vm._s(scope.row.province) +
                              _vm._s(scope.row.city) +
                              _vm._s(scope.row.detailsAddress) +
                              " " +
                              _vm._s(scope.row.district) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "上门时间", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "备注", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "确认码" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "state",
                  label: "订单状态",
                  fixed: "right",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("待支付"),
                            ])
                          : _vm._e(),
                        scope.row.state == 1
                          ? _c("span", { staticStyle: { color: "#E87D0D" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        scope.row.state == 2
                          ? _c("span", { staticStyle: { color: "#009900" } }, [
                              _vm._v("已完成"),
                            ])
                          : _vm._e(),
                        scope.row.state == 3
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已退款"),
                            ])
                          : _vm._e(),
                        scope.row.state == 4
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  fixed: "right",
                  label: "操作",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state === 4
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("shopsList:paidan"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.querenBtns(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 派单")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit1,
                  "current-page": _vm.page1,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.userData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChangeU,
                  "current-change": _vm.handleCurrentChangeU,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务订单列表",
            visible: _vm.dialogFormVisibleDel,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleDel = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "div",
                { staticStyle: { display: "inline-block", margin: "3px" } },
                [
                  _c("span", [_vm._v("服务类型：")]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px", "margin-left": "10px" },
                      on: {
                        change: function ($event) {
                          return _vm.phoneSelect()
                        },
                      },
                      model: {
                        value: _vm.taskTypeT,
                        callback: function ($$v) {
                          _vm.taskTypeT = $$v
                        },
                        expression: "taskTypeT",
                      },
                    },
                    _vm._l(_vm.parentIdList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "10px 0px",
                  },
                },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.phoneSelect.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phoneR,
                      callback: function ($$v) {
                        _vm.phoneR = $$v
                      },
                      expression: "phoneR",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.phoneSelect },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleansRw },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingU,
                  expression: "tableDataLoadingU",
                },
              ],
              attrs: { data: _vm.tableData2.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "helpOrderId",
                  label: "编号",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "userName",
                  label: "发单用户",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "taskType", label: "服务类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.taskType == 1
                          ? _c("span", [_vm._v("悬赏任务")])
                          : _vm._e(),
                        scope.row.taskType == 2
                          ? _c("span", [_vm._v("报价任务")])
                          : _vm._e(),
                        scope.row.taskType == 3
                          ? _c("span", [_vm._v("一口价任务")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "下单人姓名", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "下单人手机号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "province", label: "下单人地址", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.province) +
                              _vm._s(scope.row.city) +
                              _vm._s(scope.row.district) +
                              _vm._s(scope.row.detailsAddress)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "提交内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "接单价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serviceTime",
                  label: "预约时间",
                  fixed: "right",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "订单备注", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  prop: "status",
                  label: "状态",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                        scope.row.status === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("服务中"),
                            ])
                          : _vm._e(),
                        scope.row.status === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已完成"),
                            ])
                          : _vm._e(),
                        scope.row.status === 4
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已取消"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("shopsList:paidan"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.querenBtnsRw(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 派单")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit1,
                  "current-page": _vm.page1,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.tableData2.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChangeRw,
                  "current-change": _vm.handleCurrentChangeRw,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }