var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "font-size": "18px", "margin-bottom": "15px" } },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("商品列表")]),
        ]
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "商品列表", name: "first" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "10px 0px",
                  },
                },
                [
                  _c("span", [_vm._v("商铺名称：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入商铺名称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.shopSelect.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.shopName,
                      callback: function ($$v) {
                        _vm.shopName = $$v
                      },
                      expression: "shopName",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("商品名称：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "请输入商品名称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("商品类型：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "margin15",
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择商品类型" },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange()
                        },
                      },
                      model: {
                        value: _vm.classifyId,
                        callback: function ($$v) {
                          _vm.classifyId = $$v
                        },
                        expression: "classifyId",
                      },
                    },
                    _vm._l(_vm.classDatas, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.classifyName,
                          value: item.classifyId,
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "10px 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("商品状态：")]),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请选择商品状态" },
                      on: {
                        change: function ($event) {
                          return _vm.select(_vm.status)
                        },
                      },
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    },
                    _vm._l(_vm.statusmain, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        disabled: !_vm.isAuth("shopAdmin:add"),
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.addShop1 },
                    },
                    [_vm._v("新增商品")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.shanxiajia()
                        },
                      },
                    },
                    [_vm._v("批量上下架 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "danger",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deletesPl()
                        },
                      },
                    },
                    [_vm._v("批量删除 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "0 0 20px 20px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: _vm.checkBoxData.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.kiucun()
                        },
                      },
                    },
                    [_vm._v("批量改库存 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                  on: { "selection-change": _vm.changeFun },
                },
                [
                  _c("el-table-column", { attrs: { type: "selection" } }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "goodsId",
                      label: "编号",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "goodsCover",
                      label: "商品封面图",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              attrs: {
                                src: scope.row.goodsCover,
                                alt: "",
                                width: "60",
                                height: "60",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsCover",
                      label: "商品图",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.goodsPicture,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    display: "inline-block",
                                    margin: "3px",
                                  },
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          src: item,
                                          alt: "",
                                        },
                                        slot: "reference",
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "300px",
                                          height: "auto",
                                        },
                                        attrs: { src: item, alt: "" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsName", label: "商品类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.classifyName == null
                              ? _c("span", [_vm._v("暂无分类")])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.classifyName)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "goodsName",
                      label: "商品标题",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsDescribe",
                      label: "商品描述",
                      width: "300",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "originalMoney", label: "商品原价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numFilter")(scope.row.originalMoney)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "inventory", label: "库存", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.inventory))]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "relative",
                                  display: "inline-block",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "160px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入修改库存",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.kcselect(
                                        scope.row,
                                        scope.row.kucun
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.kucun,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "kucun", $$v)
                                    },
                                    expression: "scope.row.kucun",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "18px",
                                      top: "8px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.kcselect(
                                          scope.row,
                                          scope.row.kucun
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("icon-svg", {
                                      staticClass: "site-sidebar__menu-icon",
                                      attrs: { name: "bianji" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsMoney", label: "商品售价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numFilter")(scope.row.goodsMoney)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "packMoney", label: "打包费" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("numFilter")(scope.row.packMoney))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsLabel", label: "标签" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "salesVolume", label: "商品销量" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              attrs: { size: "small", min: 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.paixu(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "gsrStatus",
                      label: "库存状态",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.gsrStatus === 2
                              ? _c("span", [_vm._v("已售完")])
                              : _c("span", [
                                  _vm._v(
                                    "剩余：" + _vm._s(scope.row.inventory)
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "商品状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.goodsId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("shopAdmin:update"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.shopUpdates(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("修改 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("shopAdmin:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.shopdeletes(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.size,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "商品分类", name: "second" } },
            [
              _c(
                "div",
                { staticStyle: { float: "right", "margin-right": "2%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        disabled: !_vm.isAuth("shopsList:add"),
                        icon: "document",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.addNotice },
                    },
                    [_vm._v("添加分类")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.typeDatas.records, "row-key": "id" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "编号", prop: "classifyId" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "名称", prop: "classifyName" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              attrs: { size: "small", min: 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.paixuS(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.isAuth("shopsList:update"),
                                  size: "mini",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.isAuth("shopsList:delete"),
                                  size: "mini",
                                  type: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletes(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.size,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.typeDatas.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange2,
                      "current-change": _vm.handleCurrentChange2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "商品规格", name: "third" } },
            [
              _c(
                "div",
                { staticStyle: { float: "right", "margin-right": "2%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        disabled: !_vm.isAuth("shopsList:add"),
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.addNotice1 },
                    },
                    [_vm._v("添加规格")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.typeDatas1.records, "row-key": "id" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "编号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ruleName", label: "规格名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "规格值" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.ruleValue,
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(item.value) + ":"),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.detail))]),
                                ]),
                              ])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "创建时间" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("shopsList:update"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates1(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 修改 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("shopsList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletes1(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.size,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.typeDatas1.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange3,
                      "current-change": _vm.handleCurrentChange3,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogFormVisible,
                center: "",
                title: "商品列表",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("商品标题：")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入商品标题" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.title1,
                      callback: function ($$v) {
                        _vm.title1 = $$v
                      },
                      expression: "title1",
                    },
                  }),
                  _vm._v("     "),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select1 },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans1 },
                    },
                    [_vm._v("重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        disabled: !_vm.isAuth("shopAdmin:add"),
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.add(1)
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading1,
                      expression: "tableDataLoading1",
                    },
                  ],
                  attrs: { data: _vm.tableData1.list },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "goodsId",
                      label: "编号",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "goodsName",
                      label: "商品标题",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsCover", label: "商品封面图" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              attrs: {
                                src: scope.row.goodsCover,
                                alt: "",
                                width: "60",
                                height: "60",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsCover",
                      label: "商品图",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.goodsPicture,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    display: "inline-block",
                                    margin: "3px",
                                  },
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          src: item,
                                          alt: "",
                                        },
                                        slot: "reference",
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "300px",
                                          height: "auto",
                                        },
                                        attrs: { src: item, alt: "" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsName", label: "商品类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.classifyName == null
                              ? _c("span", [_vm._v("暂无分类")])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row.classifyName)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodsDescribe",
                      label: "商品描述",
                      width: "300",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "originalMoney", label: "商品原价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numFilter")(scope.row.originalMoney)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsMoney", label: "商品售价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numFilter")(scope.row.goodsMoney)
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "packMoney", label: "打包费" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("numFilter")(scope.row.packMoney))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsLabel", label: "标签" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "salesVolume", label: "商品销量" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "商品状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 0
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("上架")]
                                )
                              : _vm._e(),
                            scope.row.status === 1
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("下架")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("shopAdmin:add"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.add(scope.row)
                                  },
                                },
                              },
                              [_vm._v("添加 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 50, 100],
                      "page-size": _vm.size1,
                      "current-page": _vm.page1,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData1.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange1,
                      "current-change": _vm.handleCurrentChange1,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogFormVisible1,
                center: "",
                title: _vm.titles,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible1 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("分类名称：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入分类名称" },
                    model: {
                      value: _vm.classifyName,
                      callback: function ($$v) {
                        _vm.classifyName = $$v
                      },
                      expression: "classifyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("分类排序：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入分类排序" },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.releasNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改规格",
                customClass: "customWidth",
                visible: _vm.dialogFormVisible2,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible2 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格标题：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入分类名称" },
                        model: {
                          value: _vm.form.ruleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ruleName", $$v)
                          },
                          expression: "form.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "73px" } },
                      _vm._l(_vm.form.ruleValue, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "ruleitem" },
                          [
                            _c("div", [
                              _vm._v(_vm._s(item.value) + " "),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleterule1(index)
                                    },
                                  },
                                },
                                [
                                  _c("icon-svg", {
                                    staticClass: "ruleicon",
                                    attrs: { name: "schu" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _vm._l(
                                  item.detail.split(","),
                                  function (tag, j) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: tag,
                                        attrs: {
                                          closable: "",
                                          "disable-transitions": false,
                                        },
                                        on: {
                                          close: function ($event) {
                                            return _vm.handleClose1(
                                              tag,
                                              j,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(tag) + " ")]
                                    )
                                  }
                                ),
                                _c("el-input", {
                                  ref: "saveTagInput",
                                  refInFor: true,
                                  staticClass: "input-new-tag",
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    size: "small",
                                    placeholder: "请输入属性名",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.handleInputConfirm1(
                                        index,
                                        item
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.inputValues[index],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputValues, index, $$v)
                                    },
                                    expression: "inputValues[index]",
                                  },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "button-new-tag",
                                    attrs: { size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleInputConfirm(
                                          index,
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("el-form-item", [
                    _c("div", { staticStyle: { "margin-left": "83px" } }, [
                      _vm.isshow
                        ? _c(
                            "div",
                            { staticClass: "btn_specif" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.btnToclick1()
                                    },
                                  },
                                },
                                [_vm._v("添加新规格")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      !_vm.isshow
                        ? _c(
                            "div",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: { visible: _vm.dialogFormVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogFormVisible = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        display: "inline-block",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v("规格：")]
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "50%" },
                                    attrs: { placeholder: "请输入规格" },
                                    model: {
                                      value: _vm.releobject.value,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.releobject, "value", $$v)
                                      },
                                      expression: "releobject.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        display: "inline-block",
                                        "text-align": "right",
                                      },
                                    },
                                    [_vm._v("规格值：")]
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "50%" },
                                    attrs: { placeholder: "请输入规格值" },
                                    model: {
                                      value: _vm.releobject.detail,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.releobject, "detail", $$v)
                                      },
                                      expression: "releobject.detail",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.speciTo1()
                                        },
                                      },
                                    },
                                    [_vm._v("确 定")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.speciTotwo1()
                                        },
                                      },
                                    },
                                    [_vm._v("取 消")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.amendNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加规格",
                customClass: "customWidth",
                visible: _vm.dialogFormVisible3,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible3 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "80px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("规格标题：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入标题名称 例:衣服" },
                    model: {
                      value: _vm.ruleName,
                      callback: function ($$v) {
                        _vm.ruleName = $$v
                      },
                      expression: "ruleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { margin: "40px 0 0 73px" } },
                _vm._l(this.ruleValue, function (item, index) {
                  return _c("div", { key: index, staticClass: "ruleitem" }, [
                    _c("div", [
                      _vm._v(_vm._s(item.value) + " "),
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleterule(index)
                            },
                          },
                        },
                        [
                          _c("icon-svg", {
                            staticClass: "ruleicon",
                            attrs: { name: "schu" },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _vm._l(item.detail.split(","), function (tag, j) {
                          return _c(
                            "el-tag",
                            {
                              key: tag,
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.handleClose(tag, j, item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag) + " ")]
                          )
                        }),
                        _c("el-input", {
                          ref: "saveTagInput",
                          refInFor: true,
                          staticClass: "input-new-tag",
                          staticStyle: { width: "120px" },
                          attrs: { size: "small", placeholder: "请输入属性名" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleInputConfirm(index, item)
                            },
                          },
                          model: {
                            value: _vm.inputValues[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.inputValues, index, $$v)
                            },
                            expression: "inputValues[index]",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticClass: "button-new-tag",
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleInputConfirm(index, item)
                              },
                            },
                          },
                          [_vm._v("添加")]
                        ),
                      ],
                      2
                    ),
                  ])
                }),
                0
              ),
              _c("div", { staticStyle: { margin: "30px 0 0 83px" } }, [
                _vm.isshow
                  ? _c(
                      "div",
                      { staticClass: "btn_specif" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.btnToclick()
                              },
                            },
                          },
                          [_vm._v("添加新规格")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticStyle: { margin: "10px 0 0 0px" } }, [
                !_vm.isshow
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "20px" },
                        attrs: { visible: _vm.dialogFormVisible },
                        on: {
                          "update:visible": function ($event) {
                            _vm.dialogFormVisible = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "80px",
                                  display: "inline-block",
                                  "text-align": "right",
                                },
                              },
                              [_vm._v("规格：")]
                            ),
                            _c("el-input", {
                              staticStyle: { width: "50%" },
                              attrs: { placeholder: "例:颜色" },
                              model: {
                                value: _vm.releobject.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.releobject, "value", $$v)
                                },
                                expression: "releobject.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "80px",
                                  display: "inline-block",
                                  "text-align": "right",
                                },
                              },
                              [_vm._v("规格值：")]
                            ),
                            _c("el-input", {
                              staticStyle: { width: "50%" },
                              attrs: { placeholder: "例:黑色" },
                              model: {
                                value: _vm.releobject.detail,
                                callback: function ($$v) {
                                  _vm.$set(_vm.releobject, "detail", $$v)
                                },
                                expression: "releobject.detail",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.speciTo()
                                  },
                                },
                              },
                              [_vm._v("确 定")]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.speciTotwo()
                                  },
                                },
                              },
                              [_vm._v("取 消")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible3 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.specificationTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量上下架",
                customClass: "customWidth",
                center: "",
                visible: _vm.dialogFormVisibleSxj,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisibleSxj = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("状态：")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.guigeType,
                        callback: function ($$v) {
                          _vm.guigeType = $$v
                        },
                        expression: "guigeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("上架")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("下架")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisibleSxj = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.transferClcik()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "批量修改库存",
                customClass: "customWidth",
                center: "",
                visible: _vm.dialogFormVisibleKc,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisibleKc = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("库存：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入库存" },
                    model: {
                      value: _vm.num,
                      callback: function ($$v) {
                        _vm.num = $$v
                      },
                      expression: "num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisibleKc = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.transferClcikKc()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }