import { post } from '@/utils/httpRequest';
import { httpPost, httpGet } from '@/utils/request'
import { config } from './config'

// 预约单-分页获取列表接口
export const getBookingPageList = async (data) => {
  const res = await post(config.getBookingPageList, data);
  return res;
};
// 预约单-关闭订单
export const doCloseOrders = async (data) => {
  const res = await post(config.doCloseOrders, data);
  return res;
};
// 预约单-指派服务顾问
export const assignPreOrders = async (data) => {
  const res = await post(config.assignPreOrders, data);
  return res;
};
// 预约单-服务顾问手动确认订单
export const manualComplete = async (data) => {
  const res = await post(config.manualComplete, data);
  return res;
};
// 预约单-查询数据详情
export const getPreOrdersDetail = async (data) => {
  const res = await post(config.getPreOrdersDetail, data);
  return res;
};
// 预约单-修改预约信息
export const savePreOrdersInfo = async (data) => {
  const res = await post(config.savePreOrdersInfo, data);
  return res;
};
// 预约单-备注记录-更新数据
export const saveNotesRecordInfo = async (data) => {
  const res = await post(config.saveNotesRecordInfo, data);
  return res;
};
// 预约单-备注记录 查询列表数据【非分页】
export const getNotesRecordList = async (data) => {
  const res = await post(config.getNotesRecordList, data);
  return res;
};
// 预约单-订单业务操作记录 查询列表数据【非分页】
export const getOrdersOperateLogList = async (data) => {
  const res = await post(config.getOrdersOperateLogList, data);
  return res;
};

// 服务单-服务单列表
export const queryServeOrder = async (data) => {
  const res = await post(config.queryServeOrder, data);
  return res;
};
// 服务单-服务单详情
export const queryServeOrderDetail = async (data) => {
  const res = await post(config.queryServeOrderDetail, data);
  return res;
};
// 服务单-保存服务
export const saveServe = async (data) => {
  const res = await post(config.saveServe, data);
  return res;
};
// 服务单-平台审核验收服务单
export const plantCheckAcceptance = async (data) => {
  const res = await post(config.plantCheckAcceptance, data);
  return res;
};
// 服务单-预约单详情-服务单列表
export const serviceQueryList = async (data) => {
  const res = await httpPost(config.serviceQueryList, data);
  return res;
};
// 服务单-获取服务分类列表
export const getSelectList = async (data) => {
  const res = await httpGet(config.getSelectList, data);
  return res;
};
// 服务单-平台取消服务单
export const pcCancelServeOrder = async (data) => {
  const res = await httpPost(config.pcCancelServeOrder, data);
  return res;
};
// 服务单-平台删除服务单
export const logicDelServeOrder = async (data) => {
  const res = await httpPost(config.logicDelServeOrder, data);
  return res;
};
// 服务单-售后记录操作表
export const getAfterSaleRecordList = async (data) => {
  const res = await httpPost(config.getAfterSaleRecordList, data);
  return res;
};
// 服务单-施工方案-查询服务方施工方案
export const getSchemeDetailInfo = async (data) => {
  const res = await httpPost(config.getSchemeDetailInfo, data);
  return res;
};
// 服务单-施工方案-查询列表数据【非分页】
export const getSchemeList = async (data) => {
  const res = await httpPost(config.getSchemeList, data);
  return res;
};
// 服务单-施工方案-暂存or提交
export const saveSchemeInfo = async (data) => {
  const res = await httpPost(config.saveSchemeInfo, data);
  return res;
};
// 服务单-施工方案-科目下拉
export const schemeDictList = async (data) => {
  const res = await httpGet(config.schemeDictList, data);
  return res;
};
// 服务单-施工方案-平台审核服务商申请调整方案
export const checkChangeScheme = async (data) => {
  const res = await httpPost(config.checkChangeScheme, data);
  return res;
};
// 服务单-施工方案-方案撤销提交
export const cancelCommitScheme = async (data) => {
  const res = await httpPost(config.cancelCommitScheme, data);
  return res;
};
// 服务单-施工方案-获取指派商家列表
export const getSchemeDetailInfoPdf = async (id) => {
  const res = await httpGet(config.getSchemeDetailInfoPdf + id, '', 'blob');
  return res;
};
// 服务单-获取指派商家列表
export const getAssignCompanyList = async (data) => {
  const res = await httpPost(config.getAssignCompanyList, data);
  return res;
};
// 服务单-顾问手动派单
export const counselorAssign = async (data) => {
  const res = await httpPost(config.counselorAssign, data);
  return res;
};

// 售后单列表
export const getAfterSalePageList = async (data) => {
  const res = await httpPost(config.getAfterSalePageList, data);
  return res;
};

// 公共
// 获取服务顾问列表
export const getCounselorList = async (data) => {
  const res = await httpPost(config.getCounselorList, data);
  return res;
};

// 投诉
// PC端处理投诉
export const dealComplaint = async (data) => {
  const res = await httpPost(config.dealComplaint, data);
  return res;
};
// PC端投诉详情
export const pcComplaintInfo = async (data) => {
  const res = await httpPost(config.pcComplaintInfo, data);
  return res;
};