<template>
  <el-dialog
    :title="auditAction === 0 ? '操作驳回' : '操作通过'"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="备注信息" :prop="auditAction === 0 ? 'auditRemark' : ''">
        <el-input
          type="textarea"
          v-model="ruleForm.auditRemark"
          :rows="4"
          resize="none"
          show-word-limit
          placeholder="请输入备注信息"
          maxlength="300"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { plantCheckAcceptance } from '@/api/maintenance/index'
export default {
  data() {
    return {
      auditAction: 0,
      dialogVisible: false,
      ruleForm: {},
      rules: {
        auditRemark: [{ required: true, message: '请输入备注信息', trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleOpen(id, auditAction) {
      this.ruleForm = {
        id,
        auditAction,
      }
      this.auditAction = auditAction
      this.dialogVisible = true
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await plantCheckAcceptance(this.ruleForm)
          if (res.data.code === 500) {
            this.$message.error(res.data.msg)
            return
          }
          this.$message.success('操作成功!')
          this.handleClose()
          this.$emit('refresh')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style>
</style>