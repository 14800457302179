<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="10">
      <template #title>
        <h2 class="details_title"><span></span>备注信息</h2>
      </template>
      <TableCustom ref="tableCustomRef" border :operate="false" :columns="tableColumns" :get-page-list="getPageList"
        :pagination="false">
      </TableCustom>
    </el-collapse-item>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TableCustom,
  },
  data() {
    return {
      tableColumns: [
        {
          label: '操作者',
          prop: 'createUserName',
          minWidth: '180',
        },
        {
          label: '备注信息',
          prop: 'content',
          minWidth: '180',
        },
        {
          label: '备注时间',
          prop: 'createTime',
          minWidth: '180',
        },
      ],
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    // 列表接口
    async getPageList() {
      return { list: this.basicInfo?.notesRecords || [] }
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
  }
}
</script>

<style></style>
