<template>
  <el-dialog title="修改保证金金额" :close-on-click-modal="false" center append-to-body :visible.sync="dialogVisible" @close="close" width="700px">
    <el-form :model="formModel" :rules="rules" ref="formRef" label-width="150px" class="demo-ruleForm">
      <el-form-item label="修改类型" prop="type">
        <el-radio-group v-model="formModel.type">
          <el-radio :label="1">添加</el-radio>
          <el-radio :label="0">减少</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="金额" prop="depositAmount">
        <el-input-number :precision="2" :controls="false"  placeholder="请输入金额" v-model="formModel.depositAmount"></el-input-number>
      </el-form-item>
      <el-form-item label="上传凭证" prop="fileInfoDtoList">
        <CustomImageUpload 
          tip="支持 JPG、JPEG、PNG、BMP 格式，图片小于10M" 
          prop="fileInfoDtoList" 
          accept=".png,.jpg,.jepg,.bmp" 
          :fileSize="10"
          ref="fileInfoDtoList"
          v-model="formModel.fileInfoDtoList" />
      </el-form-item>
      <el-form-item label="备注原因" prop="depositRemark">
        <el-input type="textarea" show-word-limit maxlength="300" rows="5" v-model="formModel.depositRemark"></el-input>
      </el-form-item>
      <div class="margin-auto mt-40" style="width: 80%;">
        <el-row :gutter="60">
          <el-col :span="12">
            <el-button class="w-full" @click="close">取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button class="w-full" type="primary" @click="submitForm('formRef')">确定</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import CustomImageUpload from '@/components/customImageUpload';
export default {
  name: 'EditDepositDialog',
  components: {
    CustomImageUpload,
  },
  data: () => {
    return {
      dialogVisible: false,
      companyId: '',
      formModel: {
        depositRemark: '',
        type: 1,
        depositAmount: '',
        fileInfoDtoList: [],

      },
      rules: {
        depositRemark: [
          { required: true, message: '请输入备注信息', trigger: 'blur' },
          { max: 300, message: '长度小于300个字符', trigger: 'blur' }
        ],
         fileInfoDtoList: [
          { required: true, message: '上传凭证不能为空', trigger: 'change' },
        ],
        type: [
          { required: true, message: '请选择修改类型', trigger: 'blur' },
        ],
        depositAmount: [
          { required: true, message: '请输入金额', trigger: 'change' },
        ],
      }
    };
  },
  methods: {
    show(companyId) {
      this.dialogVisible = true;
      this.companyId = companyId
    },
    close() {
      this.$refs.formRef.resetFields();
      this.dialogVisible = false;
      this.companyId = '';
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit('submit', { ...this.formModel, companyId: this.companyId });
          }else {
          }
      });
    }
  }
}
</script>

<style>

</style>