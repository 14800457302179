var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          border: "",
          operate: false,
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
          pagination: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "details_title" }, [
      _c("span"),
      _vm._v("备注信息"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }