var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-collapse-item",
        {
          attrs: { name: "2" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("服务方案"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "second_title" }, [
            _vm._v(_vm._s(_vm.serveInfo.serverCategory) + "-方案清单:"),
          ]),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                data: _vm.tableData,
                align: "center",
                border: "",
                "max-height": "600px",
                "min-height": "0",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "name", "min-width": "180", title: "科目" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "content",
                  "min-width": "500",
                  title: "具体内容描述",
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "unit", "min-width": "80", title: "单位" },
              }),
              _c("vxe-table-column", {
                attrs: { field: "number", "min-width": "80", title: "数量" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "unitPrice",
                  "min-width": "120",
                  title: "单价(元)",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "negotiatePrice",
                  "min-width": "120",
                  title: "协商定价(元)",
                },
              }),
              _c("vxe-table-column", {
                attrs: { field: "notes", "min-width": "180", title: "备注" },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "scheme_details_footer",
              attrs: { type: "flex", align: "middle" },
            },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "quality_time" }, [
                  _vm._v(
                    "预估工期：" +
                      _vm._s(_vm.schemeItem.estimateDuration || 0) +
                      "天"
                  ),
                ]),
                _c("span", { staticClass: "quality_time" }, [
                  _vm._v(
                    "承诺质保时限：" +
                      _vm._s(_vm.schemeItem.warrantyPeriodDays || 0) +
                      "天"
                  ),
                ]),
                _c("span", { staticClass: "quality_time" }, [
                  _vm._v(
                    "预估上门时间：" +
                      _vm._s(_vm.schemeItem.estimateDoorTime || "-")
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 12, align: "end" } }, [
                _c("span", { staticClass: "quality_total" }, [
                  _vm._v("方案总金额："),
                  _c("i", [
                    _vm._v(
                      "￥" + _vm._s(_vm.schemeItem.schemeTotalAmount || 0)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "quality_total" }, [
                  _vm._v(" 已优惠："),
                  _c("i", [
                    _vm._v("￥" + _vm._s(_vm.schemeItem.discountAmount || 0)),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-collapse-item",
        {
          attrs: { name: "3" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("方案附件"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "vxe-table",
            {
              ref: "xTableFile",
              attrs: {
                data: _vm.schemeFileList,
                border: "",
                "max-height": "600px",
                "min-height": "0",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { field: "name", "min-width": "180", title: "附件名称" },
              }),
              _c("vxe-table-column", {
                attrs: { width: "180", title: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViews(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDownload(row)
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }