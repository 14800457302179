<template>
  <!-- 投诉处理 -->
  <el-dialog
    title="投诉处理"
    :visible.sync="dialogFormVisible"
    width="30%"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
      label-position="top"
      :disabled="isDisabled"
      :hide-required-asterisk="isDisabled"
      :show-message="!isDisabled"
    >
      <div>投诉编号：{{ businessData.id }}</div>
      <el-form-item label="处理详情：" :prop="isDisabled ? '' : 'dealContent'">
        <el-input v-model="ruleForm.dealContent" type="textarea" :rows="3" resize="none"></el-input>
      </el-form-item>
      <el-form-item label="上传处理照片：" prop="fileInfoList">
        <el-upload
          v-if="!isDisabled"
          class="upload-demo"
          :action="$http.adornUrl('oss/upload')"
          :show-file-list="false"
          multiple
          :on-success="handleAvatarSuccess"
          accept=".png,.jpg,.jepg,.bmp"
        >
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        <el-row type="flex" align="middle" class="file_content">
          <el-col :span="8" class="file_content_item" v-for="(item, i) in fileInfoList" :key="item.url">
            <el-image :src="item.url" :preview-src-list="[item.url]" style="width: 60px; height: 60px"></el-image>
            <i v-if="!isDisabled" class="el-icon-delete file_del" @click="handleDelete(i)"></i>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button v-if="!isDisabled" type="primary" @click="dealComplaint">提交处理</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dealComplaint, pcComplaintInfo } from '@/api/maintenance/index'
export default {
  data() {
    return {
      ruleForm: {},
      // 附件列表
      fileInfoList: [],
      rules: {
        dealContent: [
          { required: true, message: '请输入处理意见', trigger: 'blur' },
        ],
      },
      // 是否禁用
      isDisabled: true,
      // 列表的业务数据
      businessData: {},
      dialogFormVisible: false
    }
  },
  methods: {
    // 打开弹窗
    handleOpen(row, type) {
      this.businessData = row
      this.dialogFormVisible = true
      this.isDisabled = type !== 1
      if (type !== 1) {
        this.pcComplaintInfo(row.id)
      }
    },
    // 关闭弹窗
    handleClose() {
      this.ruleForm = {}
      this.fileInfoList = []
      this.dialogFormVisible = false
      this.$refs.ruleForm.resetFields();
    },
    // 投诉详情
    async pcComplaintInfo(id) {
      const res = await pcComplaintInfo({ id })
      this.ruleForm = {
        ...this.ruleForm,
        dealContent: res.dealContent,
      }
      this.fileInfoList = res.dealImgUrlList.map(item => ({ url: item, name: item }))
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.fileInfoList = [
        ...this.fileInfoList,
        {
          name: file.name,
          url: res.data
        }
      ]
    },
    // 删除
    handleDelete(i) {
      this.fileInfoList.splice(i, 1)
    },
    // 处理
    async dealComplaint() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          await dealComplaint({
            dealContent: this.ruleForm.dealContent,
            fileInfoList: this.fileInfoList,
            id: this.businessData.id
          })
          this.$message.success('操作成功!')
          this.handleClose()
          this.$emit('onReset')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang='less' scoped>
.file_content {
  margin-top: 20px;
}
.file_content_item {
  position: relative;
  padding: 10px;
  border: 1px solid #bbb;
}
.file_del {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  cursor: pointer;
}
</style>