<template>
	<nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
		<div class="site-navbar__header">
			<h1 class="site-navbar__brand">
				<a class="site-navbar__brand-lg" href="javascript:;">{{entryName}}</a>
				<a class="site-navbar__brand-mini" href="javascript:;">
					<img src="~@/assets/img/avatar.png" style="width: 30px;height: 30px; border-radius: 50%;">
				</a>
			</h1>
		</div>
		<div class="site-navbar__body clearfix">
			<el-menu class="site-navbar__menu" mode="horizontal">
				<el-menu-item index="1" style="position: relative;">
					<template slot="title">
						<audio id="audio" autoplay="autoplay"
							src="https://pw.xianmxkj.com/file/uploadPath/2022/01/19/0753211f78d718d44ee6372e33eae9ee.mp3"
							ref='audio' controls hidden></audio>
					</template>
				</el-menu-item>
				<el-menu-item class="site-navbar__switch" index="0" @click="sidebarFold = !sidebarFold">
					<IconSvg name="zhedie"></IconSvg>
				</el-menu-item>
			</el-menu>
			<el-menu class="site-navbar__menu site-navbar__menu--right" mode="horizontal">
				<el-menu-item index="1" style="position: relative;" @click="$router.push({ name: 'vueMchat' })">
					<template slot="title">
						<span class="left-number" v-if="chatNum">{{chatNum}}</span>
						<IconSvg name="tixing" class="el-icon-setting" style="font-size: 28px;"></IconSvg>
						<!-- <audio autoplay="autoplay" src="" ref='audio' controls hidden></audio> -->
						<audio id="audio" autoplay="autoplay"
							src="https://pw.xianmxkj.com/file/uploadPath/2022/01/19/0753211f78d718d44ee6372e33eae9ee.mp3"
							ref='audio' controls hidden></audio>
					</template>
				</el-menu-item>
				<el-menu-item class="site-navbar__avatar" index="3">
					<el-dropdown :show-timeout="0" placement="bottom">
						<span class="el-dropdown-link">
							<span style="margin-right: 10px;">{{ username ? username : '' }} <span
									v-if="username">,欢迎您登录</span></span>
							<img src="~@/assets/img/avatar.png" :alt="userName">
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
							<el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-menu-item>
			</el-menu>
		</div>

		<!-- 弹窗, 修改密码 -->
		<!-- <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password> -->
	</nav>
</template>

<script>
	// import UpdatePassword from './main-navbar-update-password'
	import {
		clearLoginInfo
	} from '@/utils'
	import { entryName } from '@/utils/httpRequest'
	import { logout } from '@/api/common/index'
	import IconSvg from '@/components/icon-svg'
	export default {
		data() {
			return {
				// updatePassowrdVisible: false,
				username: '',
				intervalId: 0,
				storeId: 0,
				isshow: false,
				chatNum: '',
				errCount: 0,
				chatNumJl: 0,
				chatNumQy: 0,
				chatNum1: 0,
				errCount1:0,
				errCount2: 0,
				chatNum2:0,
				entryName:'',
			}
		},
		components: {
			// UpdatePassword
			IconSvg
		},
		computed: {
			navbarLayoutType: {
				get() {
					return this.$store.state.common.navbarLayoutType
				}
			},
			sidebarFold: {
				get() {
					return this.$store.state.common.sidebarFold
				},
				set(val) {
					this.$store.commit('common/updateSidebarFold', val)
				}
			},
			mainTabs: {
				get() {
					return this.$store.state.common.mainTabs
				},
				set(val) {
					this.$store.commit('common/updateMainTabs', val)
				}
			},
			userName: {
				get() {
					return this.$store.state.user.name
				}
			},
		},
		methods: {
			// 修改密码
			updatePasswordHandle() {
				// this.updatePassowrdVisible = true
				// this.$nextTick(() => {
				// 	this.$refs.updatePassowrd.init()
				// })
				this.$router.push({
					path:'/updatePwd'
				})
			},
			// 退出
			logoutHandle() {
				this.$confirm(`确定进行[退出]操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						await logout()
						this.$cookie.set('token', '')
						this.$cookie.set('userId', '')
						this.$cookie.set('numberDts', '')
						this.$cookie.set('numberDrz', '')
						sessionStorage.clear()
						this.$router.options.isAddDynamicMenuRoutes = false;
						this.$router.push({
							name: 'login'
						})
						this.$message.success('退出成功')
					} catch (error) {
						console.log(error);
						this.$message.error(error.msg)
					}
				}).catch(() => {})
			},

			// 获取用户信息
			userSelect() {
				let userId = this.$cookie.get('userId')
				this.$http({
					url: this.$http.adornUrl(`sys/user/info`),
					method: 'get',
					params: this.$http.adornParams({})
				}).then(({
					data
				}) => {
					let returnData = data.user;
					// if (returnData == null) {
					//   this.$cookie.set('token', '')
					//   this.$cookie.set('userId', '')
					//   sessionStorage.clear()
					//   this.$notify({
					//     title: '提示',
					//     duration: 1800,
					//     message: '用户信息失效，退出登录',
					//     type: 'warning'
					//   });
					//   this.$router.push({
					//     path: '/login'
					//   });
					// } else {
					this.username = returnData.username
					// }
				})
			},
			// 获取聊天消息
			chatSelect() {
				this.$http({
					url: this.$http.adornUrl('chats/count'),
					method: 'get',
					params: this.$http.adornParams({
						'storeId': this.storeId
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.chatNum = returnData
					if (this.chatNum == 0) {
						this.isshow = false
						this.errCount = 0
					} else {
						if (this.errCount != data.data) {
							this.errCount = data.data
							this.aplayAudio()
							// this.chatAudio();
							// let audio = new Audio()
							// audio.src = require('../assets/img/notify.mp3')
							// audio.play();
							//如果不是当前页面，标题栏闪动+消息提示
							if (document.hidden) {
								var options = {
									body: '您有新的未读消息，请及时处理',
									silent: true
								}
								var notification = new Notification('消息通知', options);
								notification.onclick = function() {
									window.open(window.location.href, '_blank');
								}
								//标题栏闪动
								var defaultTitle = document.title;
								if (that.isReceive) {
									return;
								} else {
									that.isReceive = true;
								}
								that.timer = setInterval(function() {
									var title = document.title;
									if (document.hidden && that.isReceive) {
										if (/你有新消息/.test(title) == false) {
											document.title = '【你有新消息】';
										} else {
											document.title = defaultTitle;
										}
									} else {
										that.isReceive = false;
										document.title = defaultTitle;
									}
								}, 500);
							}
							// this.isshow = true

						} else {
							this.errCount = data.data
						}
					}
				})
			},
			//申请浏览器通知权限，具体参见上面文档链接
			notification() {
				let that = this;
				if (!("Notification" in window)) {
					console.log("浏览器不支持消息通知");
					return;
				}
				Notification.requestPermission(function(permission) {});
			},
			// 获取聊天消息
			chatSelect2() {
				this.$http({
					url: this.$http.adornUrl('chats/count'),
					method: 'get',
					params: this.$http.adornParams({
						'storeId': this.storeId
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					if (data.data > 0) {
						this.$notify.info({
							title: '消息',
							dangerouslyUseHTMLString: true,
							message: '您有' + data.data + '条未读消息。',
							offset: 80,
							duration: 2500,
							type: "warning"
						});
					}
				})
			},
			aplayAudio() {
				const audio = document.getElementById('audio')
				audio.play()
				console.log('语音提示')
			},
			// 获取企业实名认证待审核数据
			dataSelect() {
				this.$http({
					url: this.$http.adornUrl('userCertification/queryCertification'),
					method: 'get',
					params: this.$http.adornParams({
						'classify': 2,
						'page': 1,
						'limit': 10,
						'status': 0,
						'name': ''
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.$cookie.set('numberDrz', data.data.totalCount)
					}

				})
			},
			// 获取个人实名认证待审核数据
			dataSelectPy() {
				this.$http({
					url: this.$http.adornUrl('userCertification/queryCertification'),
					method: 'get',
					params: this.$http.adornParams({
						'classify': 1,
						'page': 1,
						'limit': 10,
						'status': 0,
						'name': ''
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.$cookie.set('numberDts', data.data.totalCount)
					}
				})
			},
			// 获取评价认证待审核数据
			dataSelectPj() {
				this.$http({
					url: this.$http.adornUrl('admin/evaluate/getUserEvaluate'),
					method: 'get',
					params: this.$http.adornParams({
						'riderUserName': '',
						'page': 1,
						'limit': 10,
						'status': 0,
						'userName': '',
						'evaluateMessage':'',
						'indentNumber':'',
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.$cookie.set('numberDsh', data.data.total)
					}
				})
			},
			// 服务待审核数据
			InformationSelect() {
				this.$http({
					url: this.$http.adornUrl(`orderTaking/queryAllOrderTaking`),
					method: 'get',
					params: this.$http.adornParams({
						'page': 1,
						'limit': 10,
						'userName': '',
						'gameId': '',
						'status': 1,
						'city': ''
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.$cookie.set('numberDfw', data.data.totalCount)
					}
			
				})
			},
			// 投诉待审核数据
			dataSelectTs() {
				this.$http({
					url: this.$http.adornUrl('message/selectMessageByType'),
					method: 'get',
					params: this.$http.adornParams({
						'page': 1,
						'limit': 10,
						'state': 3,
						'platform': '',
						'status': 0,
					})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						this.$cookie.set('numberTs', data.data.totalCount)
					}
			
				})
			},
			//  订单消息
			messageSelect5() {
				var _this = this
				_this.$http({
					url: _this.$http.adornUrl('message/page/10/1/10'),
					method: 'get',
					params: _this.$http.adornParams({
					})
				}).then(({
					data
				}) => {
					if (data.data.totalCount > 0) {
						
						if (_this.chatNum1 != data.data.totalCount) {
							_this.aplayAudio()
							_this.$notify.info({
								title: '消息',
								dangerouslyUseHTMLString: true,
								message: '您有' + data.data.totalCount + '条服务订单消息提醒。',
								offset: 80,
								duration: 0,
								type: "warning",
								onClick() {
									_this.defineCallBack1()
								},
								onClose() {
								}
							});
						}
						_this.chatNum1 = data.data.totalCount
						_this.errCount1 = data.data.totalCount
						// }
					}else{
						_this.chatNum1 = 0
						_this.errCount1 = 0
					}
				})
			},
			// defineCallBack1() {
			// 	this.$router.replace({
			// 		name: 'orderCenter',
			// 		query: {}
			// 	})
			// },
			//  任务消息
			messageSelect2() {
				var _this = this
				_this.$http({
					url: _this.$http.adornUrl('message/page/11/1/10'),
					method: 'get',
					params: _this.$http.adornParams({
					})
				}).then(({
					data
				}) => {
					if (data.data.totalCount > 0) {
						
						if (_this.chatNum2 != data.data.totalCount) {
							_this.aplayAudio()
							_this.$notify.info({
								title: '消息',
								dangerouslyUseHTMLString: true,
								message: '您有' + data.data.totalCount + '条任务订单消息提醒。',
								offset: 80,
								duration: 0,
								type: "warning",
								onClick() {
									_this.defineCallBack2()
								},
								onClose() {
								}
							});
						}
						_this.chatNum2 = data.data.totalCount
						_this.errCount2 = data.data.totalCount
						// }
					}else{
						_this.chatNum2 = 0
						_this.errCount2 = 0
					}
				})
			},
			defineCallBack1() {
				this.$http({
					url: this.$http.adornUrl('message/updateMessageIsSee'),
					method: 'post',
					params: this.$http.adornParams({
					// data: this.$http.adornData({
						'state': 10,
					})
				}).then(({
					data
				}) => {
				
				})
				this.$router.replace({
					name: 'orderCenter',
					query: {}
				})
			},
			defineCallBack2() {
				this.$http({
					url: this.$http.adornUrl('message/updateMessageIsSee'),
					method: 'post',
					params: this.$http.adornParams({
					// data: this.$http.adornData({
						'state': 11,
					})
				}).then(({
					data
				}) => {
				
				})
				this.$router.replace({
					name: 'universalMission',
					query: {}
				})
			},
		},
		mounted() {
			this.entryName = entryName
			this.userSelect()
			this.chatSelect()
			this.chatSelect2()
			this.notification();

			this.dataSelect()
			this.dataSelectPy()
			this.dataSelectPj()
			this.InformationSelect()
			this.dataSelectTs()

		},
		created() {
			const stepTime = 1000 * 600;
			this.chatNum = setInterval(() => {
				this.chatSelect()
			}, stepTime)
			this.chatNumJl = setInterval(() => {
				this.dataSelect()
			}, stepTime)
			this.chatNumQy = setInterval(() => {
				this.dataSelectPy()
			}, stepTime)
			setInterval(() => {
				this.dataSelectPj()
			}, stepTime)
			setInterval(() => {
				this.InformationSelect()
			}, stepTime)
			setInterval(() => {
				this.dataSelectTs()
			}, stepTime)
			
			setInterval(() => {
				this.messageSelect5()
			}, stepTime)
			setInterval(() => {
				this.messageSelect2()
			}, stepTime)
			
		},
		beforeDestroy() {
			clearInterval(this.chatNum)
			clearInterval(this.chatNum1)
			clearInterval(this.chatNum2)
			
		}
	}
</script>
<style scoped="scoped">
	.site-navbar__avatar .el-dropdown-link>img {
		height: 36px;
	}

	.left-number {
		position: absolute;
		top: 0;
		right: 20px;
		width: 18px;
		height: 18px;
		line-height: 18px;
		border-radius: 50%;
		background: #ff4949;
		color: #ffffff;
		text-align: center;
		font-size: 10px;
		font-family: PingFang SC, PingFang SC-Regular;
		font-weight: 400;
		margin-top: 5px;
	}
</style>