<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="7">
      <template #title>
        <el-row type="flex" align="middle" class="service_rate">
          <el-col :span="12">
            <h2 class="details_title"><span></span>服务评价</h2>
          </el-col>
          <el-col :span="12">
            <el-rate v-model="takingCommnts.score" disabled show-score text-color="#ff9900" score-template="{value}">
            </el-rate>
          </el-col>
        </el-row>
      </template>
      <div v-if="takingCommnts.content">
        <span>{{ takingCommnts.content }}</span>
        <ul class="maintain_content" v-if="fileList.length">
          <li v-for="item in fileList" :key="item">
            <el-image :src="item" />
          </li>
        </ul>
      </div>
      <el-empty v-else :image-size="160" description='暂无评价'></el-empty>
    </el-collapse-item>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 服务评价对象
      takingCommnts: {},
      // 评价图片
      fileList: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.takingCommnts = val?.takingCommnts?.[0] || {}
        this.fileList = this.takingCommnts?.image?.split(',') || []
      },
      deep: true
    }
  },
}
</script>

<style lang='less' scoped>
.service_rate {
  width: 400px;
}
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
</style>