<template>
  <el-dialog title="分配服务顾问" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="服务顾问" prop="counselorId">
        <el-select v-model="ruleForm.counselorId" placeholder="请选择服务顾问">
          <el-option
            v-for="item in counselorIdOptions"
            :label="item.username"
            :key="item.userId"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { assignPreOrders, getCounselorList } from '@/api/maintenance/index'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {
        counselorId: [{ required: true, message: '请选择服务顾问', trigger: 'change' },]
      },
      // 服务顾问列表
      counselorIdOptions: [],
      // 选中的id集合
      ids: []
    }
  },
  methods: {
    handleOpen(row) {
      this.dialogVisible = true
      this.ids = row.map(item => item.id)
      this.getCounselorList()
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    // 获取服务顾列表
    async getCounselorList() {
      const res = await getCounselorList()
      this.counselorIdOptions = res
    },
    // 确定
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await assignPreOrders({
            ids: this.ids,
            ...this.ruleForm
          })
          if (res.data.code === 500) {
            this.$message.error(res.data.msg)
            return
          }
          this.$message.success('操作成功!')
          this.handleClose()
          this.$emit('handleOk')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style>
</style>