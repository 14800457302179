var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-collapse-item",
    {
      staticStyle: { "margin-top": "4px" },
      attrs: { name: "8" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("h2", { staticClass: "details_title" }, [
                _c("span"),
                _vm._v("结算信息"),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          border: "",
          operate: false,
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "settleAccount",
            fn: function ({ scopeRow }) {
              return [
                _c("div", [
                  _vm._v("进度款：￥" + _vm._s(scopeRow.progressFee || 0)),
                ]),
                _c("div", [
                  _vm._v(
                    "质保金：￥" + _vm._s(scopeRow.periodSettleAccount || 0)
                  ),
                ]),
                _c("div", [
                  _vm._v("管理费：￥" + _vm._s(scopeRow.manageFee || 0)),
                ]),
              ]
            },
          },
          {
            key: "fileList",
            fn: function ({ scopeRow }) {
              return _vm._l(scopeRow.fileList, function (item) {
                return _c(
                  "el-row",
                  { key: item.id, attrs: { type: "flex", align: "middle" } },
                  [
                    _c("el-col", { attrs: { span: 16 } }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 8, align: "end" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDownload(scopeRow)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDownload(scopeRow)
                              },
                            },
                          },
                          [_vm._v("下载")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }