<template>
  <div>
    <div v-if="!hideSearch">
      <FormRender
        ref="reportForm"
        :form-config="formConfig"
        :form-data.sync="formData"
        form-type="submit"
        label-width="100px"
        inline
        formType="search"
        @onSearch="getList"
        @onReset="onReset"
      >
      </FormRender>
    </div>
    <div class="pb-16">
      <span class="bold">保证金总金额:</span>
      <span class="ml-12 fs-16 bold warning">{{ totalAmount }}</span>元
    </div>
    <TableCustom
      ref="tableCustomRef"
      operate-width="200"
      :operate="false"
      :columns="tableColumns"
      :get-page-list="getPageList"
    >
      <template #fileInfoDtoList="{ scopeRow }">
        <div v-if="scopeRow.fileInfoDtoList && scopeRow.fileInfoDtoList.length>0">
          <el-image v-for="item in scopeRow.fileInfoDtoList" :preview-src-list="[item.url]" :key="item.url" :src="item.url" style="width:40px;" width="40" height="40" />
        </div>
        <span v-else>暂无图片</span>
      </template>
      
      <template #depositAmount="{ scopeRow }">
        <span v-if="scopeRow.depositAmount>0" style="color: #67C23A">+{{ scopeRow.depositAmount }}</span>
        <span style="color: #F56C6C" v-else>{{ scopeRow.depositAmount }}</span>
      </template>
    </TableCustom>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import { DepositGetListByPage, GetTotalAmount } from '@/api/auth/index';
export default {
  name: 'DepositList',
  components: {
    TableCustom,
    FormRender,
  },
  props: {
    companyId: {
      type: String,
      default: '',
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // 保证金总金额
      totalAmount: 0,
      // form配置
      formConfig: [
        {
          label: '商家名称',
          prop: 'companyName',
          type: 'input',
        },
      ],
      // 搜索值
      formData: {
        companyName: this.$route.query.name,
      },
      tableColumns: [
        {
          label: '编号',
          prop: 'id',
          width: '80',
        },
        {
          label: '商家名称',
          prop: 'companyName',
          width: '200',
        },
        {
          label: '缴款凭证',
          prop: 'fileInfoDtoList',
        },
        {
          label: '备注',
          prop: 'depositRemark',
          width: '200',
        },
        {
          label: '操作人',
          prop: 'createUserName',
          width: '150',
        },
        {
          label: '金额(元)',
          prop: 'depositAmount',
          width: '150',
        },
        {
          label: '创建时间',
          prop: 'createTime',
          width: '150',
        },
      ],
    }
  },
  mounted() {
    this.formData.companyName = this.$route.query.name;
    this.getList();
  },
  methods: {

    // 列表接口
    async getPageList(pageInfo) {
      this.totalAmount = await GetTotalAmount(this.companyId, this.formData.companyName);
      const res = await DepositGetListByPage(pageInfo, { companyId: this.companyId, ...this.formData});
      return res;
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.getList()
    },
  }
}
</script>

<style lang='less' scoped>
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>