var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "site-wrapper",
      class: { "site-sidebar--fold": _vm.sidebarFold },
      attrs: { "element-loading-text": "拼命加载中" },
    },
    [
      !_vm.loading
        ? [
            _c("main-navbar"),
            _c("main-sidebar"),
            _c(
              "div",
              {
                staticClass: "site-content__wrapper",
                style: { "min-height": _vm.documentClientHeight + "px" },
              },
              [
                !_vm.$store.state.common.contentIsNeedRefresh
                  ? _c("main-content")
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }