var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "师傅列表", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "10px 0px",
                  },
                },
                [
                  _c("span", [_vm._v("姓名：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入姓名" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.shopSelect.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.shopName1,
                      callback: function ($$v) {
                        _vm.shopName1 = $$v
                      },
                      expression: "shopName1",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    margin: "10px 0px",
                  },
                },
                [
                  _c("span", [_vm._v("电话：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入电话" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.shopSelect.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.region,
                      callback: function ($$v) {
                        _vm.region = $$v
                      },
                      expression: "region",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.shopSelect },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans2 },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.typeDatas.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "姓名", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.name ? scope.row.name : "未绑定")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isRecommend", label: "推荐", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": _vm.openValue1,
                            disabled: !_vm.isAuth("shopsList:update"),
                            "inactive-value": _vm.closeValue1,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.change2(
                                scope.row.isRecommend,
                                scope.row
                              )
                            },
                          },
                          model: {
                            value: scope.row.isRecommend,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isRecommend", $$v)
                            },
                            expression: "scope.row.isRecommend",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "头像", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.avatar,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: { src: scope.row.avatar, alt: "" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [_vm._v(" 暂无图片 ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "电话", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "idNumber", label: "身份证号码", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cardFront", label: "身份证正面", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.front == null || scope.row.front == ""
                          ? _c("div", [_vm._v(" 暂无图片 ")])
                          : _vm._e(),
                        scope.row.front
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.front,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: { src: scope.row.front, alt: "" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "cardBack", label: "身份证反面", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.back == null || scope.row.back == ""
                          ? _c("div", [_vm._v(" 暂无图片 ")])
                          : _vm._e(),
                        scope.row.back
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.back,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: { src: scope.row.back, alt: "" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "标签", prop: "label" },
              }),
              _c("el-table-column", { attrs: { label: "年龄", prop: "age" } }),
              _c("el-table-column", {
                attrs: { label: "个人介绍", prop: "details", width: "300" },
              }),
              _c("el-table-column", {
                attrs: { label: "服务次数", prop: "serviceNum" },
              }),
              _c("el-table-column", {
                attrs: { label: "评分", prop: "score" },
              }),
              _c("el-table-column", {
                attrs: { label: "地址", prop: "nowAddress", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "onLine", fixed: "right", label: "接单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.onLine == 1
                          ? _c("span", { staticStyle: { color: "#13ce66" } }, [
                              _vm._v("上线"),
                            ])
                          : _c("span", { staticStyle: { color: "#ff4949" } }, [
                              _vm._v("下线"),
                            ]),
                        _c("el-switch", {
                          attrs: {
                            "active-value": _vm.openValue,
                            disabled: !_vm.isAuth("userList:delete"),
                            "inactive-value": _vm.closeValue,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeS(scope.row.onLine, scope.row.id)
                            },
                          },
                          model: {
                            value: scope.row.onLine,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "onLine", $$v)
                            },
                            expression: "scope.row.onLine",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "230", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.shopsListFw(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 服务列表 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.shopsListRw(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 任务订单 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: {
                              disabled: !_vm.isAuth("shopsList:update"),
                              size: "mini",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updatesShop(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("修改信息 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.shopsListSr(scope.row)
                              },
                            },
                          },
                          [_vm._v("收益统计 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.shopsListQb(scope.row)
                              },
                            },
                          },
                          [_vm._v("钱包信息 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.shopsListYg(scope.row)
                              },
                            },
                          },
                          [_vm._v("评价明细 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50, 100],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.typeDatas.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            center: "",
            title: _vm.titles,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("姓名：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("手机号：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入手机号" },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("身份证号：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入身份证号" },
                model: {
                  value: _vm.idNumber,
                  callback: function ($$v) {
                    _vm.idNumber = $$v
                  },
                  expression: "idNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                margin: "2% 0",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("头像：")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "148px",
                    height: "148px",
                    "background-color": "#fbfdff",
                    border: "1px dashed #c0ccda",
                    "border-radius": "6px",
                    "text-align": "center",
                    "line-height": "148px",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess3,
                      },
                      model: {
                        value: _vm.avatar,
                        callback: function ($$v) {
                          _vm.avatar = $$v
                        },
                        expression: "avatar",
                      },
                    },
                    [
                      _vm.avatar != "" && _vm.avatar != null
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { width: "148px", height: "148px" },
                            attrs: { src: _vm.avatar },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              "font-size": "28px",
                              color: "#8c939d",
                            },
                          }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                margin: "2% 0",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("身份证正面照：")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "148px",
                    height: "148px",
                    "background-color": "#fbfdff",
                    border: "1px dashed #c0ccda",
                    "border-radius": "6px",
                    "text-align": "center",
                    "line-height": "148px",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess1,
                      },
                      model: {
                        value: _vm.cardFront,
                        callback: function ($$v) {
                          _vm.cardFront = $$v
                        },
                        expression: "cardFront",
                      },
                    },
                    [
                      _vm.cardFront != "" && _vm.cardFront != null
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { width: "148px", height: "148px" },
                            attrs: { src: _vm.cardFront },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              "font-size": "28px",
                              color: "#8c939d",
                            },
                          }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                margin: "2% 0",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("身份证背面照：")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "148px",
                    height: "148px",
                    "background-color": "#fbfdff",
                    border: "1px dashed #c0ccda",
                    "border-radius": "6px",
                    "text-align": "center",
                    "line-height": "148px",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess2,
                      },
                      model: {
                        value: _vm.cardBack,
                        callback: function ($$v) {
                          _vm.cardBack = $$v
                        },
                        expression: "cardBack",
                      },
                    },
                    [
                      _vm.cardBack != "" && _vm.cardBack != null
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: { width: "148px", height: "148px" },
                            attrs: { src: _vm.cardBack },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                            staticStyle: {
                              "font-size": "28px",
                              color: "#8c939d",
                            },
                          }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("标签：")]
              ),
              _vm._l(_vm.label, function (item, index) {
                return _c("span", { key: index, staticClass: "bqList" }, [
                  _vm._v(_vm._s(item) + " "),
                  _c("span", { staticClass: "delss" }, [
                    _c("i", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.dels1(index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  placeholder: "请输入标签",
                  onkeyup: "this.value=this.value.replace(/[, ]/g,'')",
                },
                model: {
                  value: _vm.bq,
                  callback: function ($$v) {
                    _vm.bq = $$v
                  },
                  expression: "bq",
                },
              }),
              _c("el-button", {
                staticClass: "tj",
                attrs: {
                  type: "primary",
                  size: "mini",
                  icon: "el-icon-edit",
                  circle: "",
                },
                on: { click: _vm.btnTj },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("年龄：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入年龄" },
                model: {
                  value: _vm.age,
                  callback: function ($$v) {
                    _vm.age = $$v
                  },
                  expression: "age",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务次数：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入服务次数" },
                model: {
                  value: _vm.serviceNum,
                  callback: function ($$v) {
                    _vm.serviceNum = $$v
                  },
                  expression: "serviceNum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("评分：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入评分" },
                model: {
                  value: _vm.score,
                  callback: function ($$v) {
                    _vm.score = $$v
                  },
                  expression: "score",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("个人介绍：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: "请输入个人介绍",
                },
                model: {
                  value: _vm.details,
                  callback: function ($$v) {
                    _vm.details = $$v
                  },
                  expression: "details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("地址：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入地址", type: "text" },
                model: {
                  value: _vm.nowAddress,
                  callback: function ($$v) {
                    _vm.nowAddress = $$v
                  },
                  expression: "nowAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.releasNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible5,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible5 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("新密码：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "password",
                  controls: false,
                  placeholder: "请输入新密码",
                },
                model: {
                  value: _vm.newPassword,
                  callback: function ($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("确认密码：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "password",
                  controls: false,
                  placeholder: "请输入确认密码",
                },
                model: {
                  value: _vm.querenPassword,
                  callback: function ($$v) {
                    _vm.querenPassword = $$v
                  },
                  expression: "querenPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible5 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePassword()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.dialogFormVisible11,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible11 = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "请输入手机号" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.select11.apply(null, arguments)
              },
            },
            model: {
              value: _vm.phoneU,
              callback: function ($$v) {
                _vm.phoneU = $$v
              },
              expression: "phoneU",
            },
          }),
          _vm._v("     "),
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "请输入昵称" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.select11.apply(null, arguments)
              },
            },
            model: {
              value: _vm.nickName,
              callback: function ($$v) {
                _vm.nickName = $$v
              },
              expression: "nickName",
            },
          }),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select11 },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans11 },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading11,
                  expression: "tableDataLoading11",
                },
              ],
              attrs: {
                data: _vm.userData.list,
                "row-key": (row) => {
                  return row.id
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userId", label: "编号" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "userName", label: "昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(
                            _vm._s(
                              scope.row.userName ? scope.row.userName : "未设置"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar == null
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/avatar.png"),
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: scope.row.avatar,
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "platform", label: "来源" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "15px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "document",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.closes2(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 确定")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.size11,
                  "current-page": _vm.page11,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.userData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange11,
                  "current-change": _vm.handleCurrentChange11,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "评价列表",
            visible: _vm.dialogFormVisibleHd,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleHd = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.huodongData.records },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "evaluateId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "评论用户", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.userName) },
                          on: {
                            click: function ($event) {
                              return _vm.updatesvideo(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "evaluateMessage", label: "评价内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "evaluateImg", label: "评论图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.evaluateImg
                          ? _c(
                              "div",
                              _vm._l(
                                scope.row.evaluateImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "200px",
                                              height: "200px",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "satisfactionFlag",
                  label: "评分",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.satisfactionFlag == 1
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("非常差")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 2
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("差")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 3
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("一般")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 4
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("满意")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 5
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("非常满意")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "评价时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("shopsList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteuser(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 15, 20, 25],
                  "page-size": _vm.limitH,
                  "current-page": _vm.pageH,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.huodongData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预约时间列表",
            visible: _vm.dialogFormVisibleTime,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleTime = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: !_vm.isAuth("shopsList:add"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updataTime()
                    },
                  },
                },
                [_vm._v("添加 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingTime,
                  expression: "tableDataLoadingTime",
                },
              ],
              attrs: { data: _vm.yuyueTimeDime },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "serviceTimeId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "serviceTime", label: "接单时间" },
              }),
              _c("el-table-column", { attrs: { prop: "num", label: "数量" } }),
              _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("shopsList:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updataTime(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("shopsList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteTime(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titlesTime,
            visible: _vm.dialogFormVisibleTimeAU,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleTimeAU = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("开始时间：")]
              ),
              _c("el-time-select", {
                attrs: {
                  placeholder: "起始时间",
                  "picker-options": {
                    start: "08:30",
                    step: "00:15",
                    end: "18:30",
                  },
                },
                model: {
                  value: _vm.startTime,
                  callback: function ($$v) {
                    _vm.startTime = $$v
                  },
                  expression: "startTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("结束时间：")]
              ),
              _c("el-time-select", {
                attrs: {
                  placeholder: "结束时间",
                  "picker-options": {
                    start: "08:30",
                    step: "00:15",
                    end: "18:30",
                    minTime: _vm.startTime,
                  },
                },
                model: {
                  value: _vm.endTime,
                  callback: function ($$v) {
                    _vm.endTime = $$v
                  },
                  expression: "endTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("数量：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "number",
                  min: 0,
                  max: 100,
                  controls: false,
                  placeholder: "请输入数量",
                },
                model: {
                  value: _vm.num,
                  callback: function ($$v) {
                    _vm.num = $$v
                  },
                  expression: "num",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("排序：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "number",
                  min: 0,
                  controls: false,
                  placeholder: "请输入排序",
                },
                model: {
                  value: _vm.sort,
                  callback: function ($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisibleTimeAU = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.StairNoticeToTime()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "服务列表",
            visible: _vm.dialogFormVisibleFwddd,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleFwddd = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.listSelect },
            },
            [_vm._v("刷新 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.addSelect },
            },
            [_vm._v("添加 ")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingHd,
                  expression: "tableDataLoadingHd",
                },
              ],
              attrs: { data: _vm.rwData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "masterServiceId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedClassify.classifyName",
                  label: "分类名称",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "img", label: "分类图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fixedClassify &&
                        scope.row.fixedClassify.img != ""
                          ? _c("img", {
                              attrs: {
                                src: scope.row.fixedClassify.img,
                                width: "40",
                                height: "40",
                              },
                            })
                          : _c("span", [_vm._v("暂无图片")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "fixedClassify.money", label: "一口价价格" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedClassify.minOfferMoney",
                  label: "悬赏最低价",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedClassify.minQuoteMoney",
                  label: "报价最低价",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedClassify.minMasterMoney",
                  label: "师傅任务最低价",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fixedClassify.updateTime",
                  label: "创建时间",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  prop: "id",
                  width: "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "warning",
                              disabled: !_vm.isAuth("classification:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.classDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务订单",
            visible: _vm.dialogFormVisibleRwddd,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleRwddd = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("服务类型：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.selectR()
                    },
                  },
                  model: {
                    value: _vm.taskTypeT,
                    callback: function ($$v) {
                      _vm.taskTypeT = $$v
                    },
                    expression: "taskTypeT",
                  },
                },
                _vm._l(_vm.parentIdList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.selectR(_vm.status1)
                    },
                  },
                  model: {
                    value: _vm.status1,
                    callback: function ($$v) {
                      _vm.status1 = $$v
                    },
                    expression: "status1",
                  },
                },
                _vm._l(_vm.statesnum, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("服务分类：")]),
              _c("el-cascader", {
                staticStyle: { width: "150px" },
                attrs: {
                  options: _vm.homeData1,
                  placeholder: "请选择服务分类",
                  "show-all-levels": false,
                  props: {
                    expandTrigger: "hover",
                    value: "classifyId",
                    label: "classifyName",
                  },
                  filterable: "",
                },
                on: { change: _vm.handleChange2 },
                model: {
                  value: _vm.gameIdsVal,
                  callback: function ($$v) {
                    _vm.gameIdsVal = $$v
                  },
                  expression: "gameIdsVal",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("手机号：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.selectR.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phoneR,
                      callback: function ($$v) {
                        _vm.phoneR = $$v
                      },
                      expression: "phoneR",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("内容：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入内容" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.selectR.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.content1,
                      callback: function ($$v) {
                        _vm.content1 = $$v
                      },
                      expression: "content1",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.selectR },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleansR },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingHd,
                  expression: "tableDataLoadingHd",
                },
              ],
              attrs: { data: _vm.fuwuData.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "helpOrderId",
                  label: "编号",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "userName",
                  label: "发单用户",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "task_type", label: "服务类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.task_type == 1
                          ? _c("span", [_vm._v("悬赏任务")])
                          : _vm._e(),
                        scope.row.task_type == 2
                          ? _c("span", [_vm._v("报价任务")])
                          : _vm._e(),
                        scope.row.task_type == 3
                          ? _c("span", [_vm._v("一口价任务")])
                          : _vm._e(),
                        scope.row.task_type == 4
                          ? _c("span", [_vm._v("师傅任务")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "下单人姓名", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "下单人手机号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "province", label: "下单人地址", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.province) +
                              _vm._s(scope.row.city) +
                              _vm._s(scope.row.district) +
                              _vm._s(scope.row.detailsAddress)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "提交内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "接单价格", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "服务开始时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "服务结束时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTaskAddress", label: "服务开始地址" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTaskAddress", label: "服务结束地址" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "服务开始时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "服务结束时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startTaskImg",
                  label: "服务开始前图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.startTaskImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.startTaskImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endTaskImg",
                  label: "服务结束后图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.endTaskImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.endTaskImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startAddress",
                  label: "服务开始地址",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endAddress",
                  label: "服务结束地址",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTaskLongitude", label: "服务开始经度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTaskLatitude", label: "服务开始纬度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTaskLongitude", label: "服务结束经度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTaskLatitude", label: "服务结束纬度" },
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "确认码" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serviceTime",
                  label: "预约时间",
                  fixed: "right",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "600" },
                          },
                          [_vm._v(_vm._s(scope.row.deliveryTime))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "订单备注", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.remarks ? scope.row.remarks : "未填写"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  prop: "status",
                  label: "状态",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                        scope.row.status === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("服务中"),
                            ])
                          : _vm._e(),
                        scope.row.status === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已完成"),
                            ])
                          : _vm._e(),
                        scope.row.status === 4
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已取消"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 15, 20, 25],
                  "page-size": _vm.limitH,
                  "current-page": _vm.pageH,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.fuwuData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChangeRw,
                  "current-change": _vm.handleCurrentChangeRw,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务分类",
            visible: _vm.dialogFormVisibleRwfl,
            center: "",
            width: "65%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleRwfl = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: {
                data: _vm.tableDataFl,
                "row-key": "classifyId",
                "default-expand-all": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "classifyId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "classifyName", label: "分类名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "img", label: "分类图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.img && scope.row.img != ""
                          ? _c("img", {
                              attrs: {
                                src: scope.row.img,
                                width: "40",
                                height: "40",
                              },
                            })
                          : _c("span", [_vm._v("暂无图片")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "一口价价格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "minOfferMoney", label: "悬赏最低价" },
              }),
              _c("el-table-column", {
                attrs: { prop: "minQuoteMoney", label: "报价最低价" },
              }),
              _c("el-table-column", {
                attrs: { prop: "minMasterMoney", label: "师傅任务最低价" },
              }),
              _c("el-table-column", {
                attrs: { prop: "isEnable", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isEnable == 1
                          ? _c("span", [_vm._v("上架")])
                          : _c("span", [_vm._v("下架")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "updateTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  prop: "id",
                  width: "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.parentId != 0
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth(
                                    "classification:update"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.classAdd(scope.row)
                                  },
                                },
                              },
                              [_vm._v("添加 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }