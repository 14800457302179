<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h2 class="dispatch_title"><span></span>施工方案状态：{{ basicInfo.stateDesc }}</h2>
      </el-col>
      <el-col :span="12" align="end">
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button v-if="basicInfo.state === 5" type="danger" @click="handleAudit(0)">驳回申请</el-button>
        <el-button v-if="basicInfo.state === 5" type="primary" @click="handleAudit(1)">确认调整</el-button>
        <el-button
          v-if="basicInfo.state === 1 || basicInfo.state === 3 || basicInfo.state === 6"
          type="primary"
          @click="goToEdit"
          >重新编辑方案</el-button
        >
        <el-button v-if="basicInfo.state === 2" type="primary" @click="handleCancel">撤销提交</el-button>
      </el-col>
    </el-row>
    <h2 class="details_title"><span></span>{{ basicInfo.serveOrderName }}</h2>
    <!-- 方案详情 -->
    <div>
      <el-row type="flex" align="middle" class="scheme_item">
        <el-col :span="12">
          <span class="details_title_span">方案清单</span>
        </el-col>
        <el-col :span="12" align="end">
          <el-button icon="el-icon-plus" @click="exportPDF">导出PDF</el-button>
        </el-col>
      </el-row>
      <vxe-table ref="xTable" :data="tableData" align="center" border keep-source max-height="600px" min-height="0">
        <vxe-table-column field="name" min-width="180" title="科目" :edit-render="{}">
          <template #edit="{ row }">
            <el-autocomplete
              class="inline-input"
              v-model="row.name"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="content"
          min-width="500"
          title="具体内容描述"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        ></vxe-table-column>
        <vxe-table-column
          field="unit"
          min-width="80"
          title="单位"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        >
        </vxe-table-column>
        <vxe-table-column field="number" min-width="80" title="数量" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input type="number" v-model="row.number" min="1"></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column field="unitPrice" min-width="120" title="单价(元)" :edit-render="{}">
          <template #edit="{ row, rowIndex }">
            <vxe-input
              type="float"
              @change="(e) => changeUnitPrice(e, rowIndex)"
              v-model="row.unitPrice"
              min="0"
              step="1"
            ></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column field="negotiatePrice" min-width="120" title="协商定价(元)" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input
              type="float"
              @change="changeNegotiatePrice"
              v-model="row.negotiatePrice"
              min="0"
              step="1"
            ></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="notes"
          min-width="120"
          title="备注"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        >
        </vxe-table-column>
        <!-- <vxe-table-column min-width="120" title="操作"> </vxe-table-column> -->
      </vxe-table>
      <el-row type="flex" align="middle" class="scheme_details_footer">
        <el-col :span="20">
          <div style="display: flex">
            <span class="quality_time"
              >预估工期：<el-input
                v-model.number="basicInfo.estimateDuration"
                style="width: 50%; margin: 0 8px"
                disabled
              ></el-input
              >天</span
            >
            <span class="quality_time"
              >承诺质保时限：<el-input
                v-model.number="basicInfo.warrantyPeriodDays"
                style="width: 50%; margin: 0 8px"
                disabled
              ></el-input
              >天</span
            >
            <span class="quality_time"
              >预估上门时间：<el-date-picker
                v-model="basicInfo.estimateDoorTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                disabled
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker
            ></span>
          </div>
        </el-col>
        <el-col :span="4" align="end">
          <span class="quality_total"
            >方案总金额：<i>￥{{ basicInfo.schemeTotal || 0 }}</i></span
          >
          <div class="quality_total">
            已优惠：<i>￥{{ basicInfo.discountTotal || 0 }}</i>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 方案附件 -->
    <div style="margin-top: 40px">
      <el-row type="flex" align="middle" class="scheme_item">
        <el-col :span="12">
          <span class="details_title_span">方案附件</span>
        </el-col>
      </el-row>
      <vxe-table ref="xTableFile" :data="tableDataFile" border max-height="600px" min-height="0">
        <vxe-table-column field="name" min-width="180" title="附件名称"></vxe-table-column>
        <vxe-table-column width="180" title="操作">
          <template #default="{ row }">
            <el-button type="text" @click="handleViews(row)">查看</el-button>
            <el-button type="text" @click="handleDownload(row)">下载</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <RejectDialog ref="rejectDialogRef" @refresh="getSchemeDetailInfo"></RejectDialog>
  </div>
</template>

<script>
import { getSchemeDetailInfo, checkChangeScheme, cancelCommitScheme, getSchemeDetailInfoPdf } from '@/api/maintenance/index.js'
import download, { downloadFile } from '@/utils/download.js';
import { isImage, isPdf } from '@/utils/file.js';
import RejectDialog from './components/rejectDialog.vue'
export default {
  components: {
    RejectDialog
  },
  data() {
    return {
      // 详情数据
      basicInfo: {},
      // 服务id
      serveOrderId: '',
      tableData: [],
      // 附件列表
      tableDataFile: []
    }
  },
  mounted() {
    this.serveOrderId = this.$route.query.id
    this.getSchemeDetailInfo()
  },
  methods: {
    // 详情接口
    async getSchemeDetailInfo() {
      const res = await getSchemeDetailInfo({
        serveOrderId: this.serveOrderId
      })
      this.basicInfo = res
      this.tableDataFile = res.fileInfoList
      this.tableData = res.schemeItemList
    },
    // 导出PDF
    async exportPDF() {
      const res = await getSchemeDetailInfoPdf(this.serveOrderId)
      downloadFile(res, '方案清单.pdf');
    },
    // 下载附件
    handleDownload(file) {
      download(file.id, file.name);
    },
    // 查看附件
    handleViews(file) {
      window.open(file.url)
      if (isImage(file.url) || isPdf(file.url)) {
      } else {
        this.$message.error('暂不支持该格式的预览')
      }
    },
    // 通过/驳回
    handleAudit(type) {
      if (type === 0) {
        this.$refs.rejectDialogRef.handleOpen({
          id: this.basicInfo.id,
          auditAction: type
        })
      } else {
        this.$confirm(`是否${type === 0 ? '驳回' : '通过'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await checkChangeScheme({
              auditAction: type,
              id: this.basicInfo.id
            })
            this.$message.success('操作成功!')
            this.getSchemeDetailInfo()
          } catch (error) {
            console.log(error);
            this.$message.error(error.msg)
          }
        }).catch(() => {
        });
      }
    },
    // 去编辑
    goToEdit() {
      this.$router.push({
        path: '/dispatchOrderScheme',
        query: {
          id: this.serveOrderId
        }
      })
    },
    // 撤销提交
    handleCancel() {
      this.$confirm('是否撤销提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await cancelCommitScheme({
            id: this.basicInfo.id
          })
          this.$message.success('操作成功!')
          this.getSchemeDetailInfo()
        } catch (error) {
          console.log(error);
          this.$message.error(error.msg)
        }
      }).catch(() => {
      });
    },
  }
}
</script>

<style lang='less' scoped>
.dispatch_title {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #95a3fc;
  margin: 0 0 40px;
  span {
    display: inline-block;
    width: 6px;
    height: 30px;
    background-color: #3181ff;
    margin-right: 4px;
  }
}
.scheme_item {
  margin-bottom: 20px;
}
.details_title_span {
  font-size: 16px;
  font-weight: 700;
}
.quality_time {
  display: flex;
  align-items: center;
}
.scheme_details_footer {
  margin-top: 20px;
}
.quality_total {
  font-size: 18px;
  i {
    font-style: normal;
    color: #557efc;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>