<template>
  <div>
    <div class="list_title">数据筛选</div>
    <FormRender
      ref="reportForm"
      :form-config="formConfig"
      :form-data.sync="formData"
      form-type="submit"
      label-width="100px"
      formType="search"
      @onSearch="onSearch"
      @onReset="onReset"
    >
    </FormRender>
    <el-row>
      <el-col :span="12" class="list_title">全部订单列表</el-col>
      <el-col :span="12" align="end">
        <el-button
          type="primary"
          plain
          icon="el-icon-thumb"
          @click="handleOpenConsultant"
          :disabled="!selectRow?.length"
          >指派服务顾问</el-button
        >
      </el-col>
    </el-row>
    <TableCustom
      ref="tableCustomRef"
      operate-width="200"
      :columns="tableColumns"
      :get-page-list="getPageList"
      selection
      @onSelection="selectionTable"
      :selectable="selectable"
    >
      <!-- 服务分类 -->
      <template #servicePathName="{ scopeRow }">
        <div :class="{ text_red: !scopeRow.servicePathName }">{{ scopeRow.servicePathName || '未指定' }}</div>
      </template>
      <!-- 服务顾问 -->
      <template #counselorName="{ scopeRow }">
        <div :class="{ text_red: !scopeRow.counselorId }">{{ scopeRow.counselorName }}</div>
      </template>
      <!-- 上门信息 -->
      <template #doorInfo="{ scopeRow }">
        <div>联系人：{{ scopeRow.createUserName }}</div>
        <div>联系手机：{{ scopeRow.createUserPhone }}</div>
        <div>联系地址：{{ scopeRow.address }}</div>
        <div>上门时间：{{ scopeRow.expectDoorTime }}</div>
      </template>
      <!-- 待办服务情况 -->
      <template #processState="{ scopeRow }">
        <div :class="{ text_red: scopeRow?.preOrdersSerInfo?.processState }">{{ scopeRow?.preOrdersSerInfo?.processState || '-' }}</div>
      </template>
      <!-- 订单状态 -->
      <template #ordersStateDesc="{ scopeRow }">
        <el-tag :type="ordersStateOptions.find((item) => item.value === scopeRow.ordersState)?.type">{{
          scopeRow.ordersStateDesc
        }}</el-tag>
      </template>
      <!-- 下单人 -->
      <template #createUserName="{ scopeRow }">
        <span>{{ scopeRow.createUserName }}</span>
        <el-button type="text" style="margin-left: 10px" @click="goToUserInfo(scopeRow)">查看</el-button>
      </template>
      <template #operate="{ scopeRow }">
        <el-button type="text" @click="goToDetail(scopeRow)">查看订单</el-button>
        <el-button v-if="scopeRow.ordersState === 2" type="text" @click="handleOpenConfirm(scopeRow)"
          >确认完成</el-button
        >
        <el-button v-if="scopeRow.ordersState === 1" type="text" @click="handleCloseOrder(scopeRow)"
          >关闭订单</el-button
        >
      </template>
    </TableCustom>
    <!-- 服务顾问弹窗 -->
    <ServiceConsultantDialog ref="serviceConsultantDialogRef" @handleOk="getList"></ServiceConsultantDialog>
    <!-- 确认完成弹窗 -->
    <ConfirmCompletionDialog ref="confirmCompletionDialogRef" @handleOk="getList"></ConfirmCompletionDialog>
    <!-- 关闭订单弹窗 -->
    <CloseOrderdDialog ref="closeOrderdDialogRef" @handleOk="getList"></CloseOrderdDialog>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import ServiceConsultantDialog from './components/serviceConsultantDialog.vue'
import ConfirmCompletionDialog from './components/confirmCompletionDialog.vue'
import CloseOrderdDialog from './components/closeOrderdDialog.vue'
import { getBookingPageList } from '@/api/maintenance/index'
import { ordersStateOptions } from '@/const/dispatchOrderDict'
export default {
  components: {
    TableCustom,
    FormRender,
    ServiceConsultantDialog,
    ConfirmCompletionDialog,
    CloseOrderdDialog
  },
  data() {
    return {
      // form配置
      formConfig: [
        {
          label: '预约单号',
          prop: 'preOrdersNo',
          type: 'input',
          clearable: true,
        },
        {
          label: '订单类型',
          prop: 'type',
          type: 'select',
          clearable: true,
          dict: [
            {
              label: '抢修',
              value: 1,
            },
            {
              label: '预约',
              value: 2,
            },
          ],
        },
        {
          label: '订单状态',
          prop: 'ordersState',
          type: 'select',
          clearable: true,
          dict: ordersStateOptions,
        },
        {
          label: '提交时间',
          prop: 'createTime1',
          type: 'daterange',
          clearable: true,
        },
        {
          label: '上门时间',
          prop: 'expectDoorTime1',
          type: 'daterange',
          clearable: true,
        },
        {
          label: '服务分类',
          prop: 'serviceId',
          type: 'select',
          dictUrl: '/game/getSelectList',
          apiType: 'get',
          clearable: true,
          dictProps: {
            label: 'namePath',
            value: 'id'
          }
        },
        {
          label: '服务顾问',
          prop: 'counselorId',
          type: 'select',
          dictUrl: '/admin/preOrders/getCounselorList',
          apiType: 'post',
          clearable: true,
          dictProps: {
            label: 'username',
            value: 'userId'
          }
        },
        {
          label: '所属企业',
          prop: 'customerName',
          type: 'input',
        },
        {
          label: '下单人',
          prop: 'createUserName',
          type: 'input',
        },
      ],
      // 搜索值
      formData: {},
      tableColumns: [
        {
          label: '预约单号',
          prop: 'preOrdersNo',
          minWidth: '180',
        },
        {
          label: '服务分类',
          prop: 'servicePathName',
          minWidth: '180',
        },
        {
          label: '服务顾问',
          prop: 'counselorName',
          minWidth: '180',
        },
        {
          label: '上门信息',
          prop: 'doorInfo',
          minWidth: '180',
        },
        {
          label: '服务事项说明',
          prop: 'eventExplain',
          minWidth: '180',
        },
        {
          label: '待办服务情况',
          prop: 'processState',
          minWidth: '180',
        },
        {
          label: '订单状态',
          prop: 'ordersStateDesc',
          minWidth: '180',
        },
        {
          label: '订单类型',
          prop: 'typeDesc',
          minWidth: '180',
        },
        {
          label: '提交时间',
          prop: 'createTime',
          minWidth: '180',
        },
        {
          label: '服务开始时间',
          prop: 'startTime',
          minWidth: '180',
        },
        {
          label: '服务完成时间',
          prop: 'completeTime',
          minWidth: '180',
        },
        {
          label: '订单金额',
          prop: 'orderAmount',
          minWidth: '180',
        },
        {
          label: '所属企业',
          prop: 'customerName',
          minWidth: '180',
        },
        {
          label: '下单人',
          prop: 'createUserName',
          minWidth: '180',
        },
      ],
      // 订单状态选项
      ordersStateOptions,
      // 选中的行
      selectRow: []
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 列表接口
    async getPageList(params) {
      let paramsData = { ...this.formData }
      if (paramsData.expectDoorTime1?.length) {
        paramsData.expectDoorStartTime = paramsData.expectDoorTime1[0]
        paramsData.expectDoorEndTime = paramsData.expectDoorTime1[1]
      }
      if (paramsData.createTime1?.length) {
        paramsData.createStartTime = paramsData.createTime1[0]
        paramsData.createEndTime = paramsData.createTime1[1]
      }
      const res = await getBookingPageList({ ...params, data: paramsData })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return []
      }
      return res.data.data
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.$refs?.tableCustomRef?.currentPage(1)
    },
    onSearch() {
      this.$refs?.tableCustomRef?.currentPage(1)
    },
    // 复选框是否禁用
    selectable(row) {
      return row.ordersState === 1
    },
    // 选中的复选框
    selectionTable(row) {
      this.selectRow = row
    },
    // 分配服务顾问
    handleOpenConsultant() {
      this.$refs.serviceConsultantDialogRef?.handleOpen(this.selectRow)
    },
    // 确认完成
    handleOpenConfirm(row) {
      this.$refs.confirmCompletionDialogRef?.handleOpen(row)
    },
    // 关闭订单
    handleCloseOrder(row) {
      this.$refs.closeOrderdDialogRef?.handleOpen(row)
    },
    // 跳转到用户信息
    goToUserInfo(row) {
      this.$router.push({
        path: '/userDetail',
        query: {
          userId: row.createUserId
        }
      })
    },
    // 查看订单详情
    goToDetail(row) {
      this.$router.push({
        path: '/dispatchOrderDetails',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.text_red {
  color: red;
}
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>