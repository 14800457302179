import { render, staticRenderFns } from "./serviceAgreementModule.vue?vue&type=template&id=da0b2dca&scoped=true"
import script from "./serviceAgreementModule.vue?vue&type=script&lang=js"
export * from "./serviceAgreementModule.vue?vue&type=script&lang=js"
import style0 from "./serviceAgreementModule.vue?vue&type=style&index=0&id=da0b2dca&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da0b2dca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prodctyun-yugao-maintenance-manage-web/code_dir-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da0b2dca')) {
      api.createRecord('da0b2dca', component.options)
    } else {
      api.reload('da0b2dca', component.options)
    }
    module.hot.accept("./serviceAgreementModule.vue?vue&type=template&id=da0b2dca&scoped=true", function () {
      api.rerender('da0b2dca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/serviceCentre/module/serviceAgreementModule.vue"
export default component.exports