var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "分配服务顾问",
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务顾问", prop: "counselorId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择服务顾问" },
                  model: {
                    value: _vm.ruleForm.counselorId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "counselorId", $$v)
                    },
                    expression: "ruleForm.counselorId",
                  },
                },
                _vm._l(_vm.counselorIdOptions, function (item) {
                  return _c("el-option", {
                    key: item.userId,
                    attrs: { label: item.username, value: item.userId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }