var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "1200px",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
    },
    [
      _vm.visible
        ? _c("DepositList", {
            attrs: { companyId: _vm.companyId, hideSearch: true },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }