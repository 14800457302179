var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.id ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "字典名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "参数名" },
                model: {
                  value: _vm.dataForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "name", $$v)
                  },
                  expression: "dataForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字典类型", prop: "type" } },
            [
              _c("el-input", {
                attrs: { placeholder: "参数值" },
                model: {
                  value: _vm.dataForm.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "type", $$v)
                  },
                  expression: "dataForm.type",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字典码", prop: "code" } },
            [
              _c("el-input", {
                attrs: { placeholder: "参数值" },
                model: {
                  value: _vm.dataForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "code", $$v)
                  },
                  expression: "dataForm.code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字典值", prop: "value" } },
            [
              _c("el-input", {
                attrs: { placeholder: "参数值" },
                model: {
                  value: _vm.dataForm.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "value", $$v)
                  },
                  expression: "dataForm.value",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  label: "排序号",
                },
                model: {
                  value: _vm.dataForm.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "orderNum", $$v)
                  },
                  expression: "dataForm.orderNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "备注" },
                model: {
                  value: _vm.dataForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "remark", $$v)
                  },
                  expression: "dataForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }