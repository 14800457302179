<template>
  <!-- 添加信息弹框 -->
  <el-dialog :title="renwuTitles" @close="closeDialog" top="5vh" :close-on-click-modal="false" customClass="customWidth" :visible.sync="dialogVisible" center>
    <div style="max-height: 70vh;overflow-y: auto;">
      <el-form style="width: 72%" class="margin-auto" :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="服务名称" prop="itemName">
              <el-input v-model="ruleForm.itemName"></el-input>
            </el-form-item>
            <el-form-item label=" 服务简称" prop="itemShortName">
              <el-input v-model="ruleForm.itemShortName"></el-input>
            </el-form-item>
             <el-form-item label="服务logo" prop="itemLogoUrl">
              <el-upload class="avatar-uploader" v-model="ruleForm.itemLogoUrl" :action="$http.adornUrl('oss/upload')"
                :show-file-list="false" :on-progress="onprogress" :on-success="handleLogoSuccess">
                <!-- <el-progress type="circle" :percentage="100" status="success"></el-progress> -->
                <div slot="tip" class="el-upload__tip">支持 JPG、JPEG、PNG、BMP 格式，图片小于10M</div>
                <img v-if="ruleForm.itemLogoUrl" :src="ruleForm.itemLogoUrl" class="avatar"
                  style="border-radius: 6px;width: 148px;height: 148px;" />
                <i v-else class="el-icon-plus avatar-uploader-icon iconss"></i>
                <el-progress v-if="percentage>0&&percentage<100" type="circle" :percentage="percentage">
                </el-progress>
              </el-upload>
            </el-form-item>
            <el-form-item label="服务分类" prop="itemCategory">
              <el-cascader
                style="width: 100%;"
                v-model="ruleForm.itemCategory"
                :options="serviceTypeList"></el-cascader>
              <!-- <el-select v-model="ruleForm.itemCategory" placeholder="请选择服务分类">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="服务类型" prop="itemType">
              <el-select style="width: 100%;" v-model="ruleForm.itemType" placeholder="请选择服务类型">
                <el-option v-for="item in serviceTypes" :key="item.value" :label="item.label" :value="item.value+''"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预约单价" prop="itemUnitPrice">
              <el-input v-model="ruleForm.itemUnitPrice" placeholder="请输入预约单价"></el-input>
            </el-form-item>
            <el-form-item label="上架状态" prop="isEnable">
              <el-radio-group v-model="ruleForm.isEnable">
                <el-radio label="0">上架</el-radio>
                <el-radio label="2">下架</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序权重" prop="sort">
              <el-input v-model="ruleForm.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="isEdit" label="服务编号" prop="itemCode">
              <el-input disabled v-model="ruleForm.itemCode"></el-input>
            </el-form-item>
            <el-form-item label="价格说明" prop="itemPriceDesc">
              <el-input type="textarea" rows="6" v-model="ruleForm.itemPriceDesc"></el-input>
            </el-form-item>
            <el-form-item label="服务封面" prop="itemUrls">
              <CustomImageUpload 
                tip="支持 JPG、JPEG、PNG、BMP 格式，图片小于10M" 
                prop="itemUrls" 
                accept=".png,.jpg,.jepg,.bmp" 
                :fileSize="10"
                ref="itemUrls"
                v-model="ruleForm.itemUrls" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-bottom: 10px;display:flex;">
        <span style="width: 200px;display: inline-block;text-align: right;">服务详情：</span>
        <!-- 富文本 -->
        <quill-editor ref="myTextEditor" v-model="ruleForm.itemDesc" :options="quillOption"
          style="padding-bottom: 10px;height: 300px;width: 72%;display: inline-table;margin-bottom: 60px;">
        </quill-editor>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 10px;text-align: center;">
      <el-button @click="dialogVisible=false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit('ruleFormRef')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
    quillEditor
  } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import quillConfig from './../../quill-config.js';
import {addService, editService, getServiceTypeList, selectClassifyTree  } from '@/api/serviced/index.js'
import CustomImageUpload from '@/components/customImageUpload/index.vue';

const initialData = {
          itemName: '',
          itemShortName: '',
          itemCategory: '',
          itemType: '',
          itemUnitPrice: undefined,
          isEnable: '0',
          sort: undefined,
          itemCode: '',
          itemPriceDesc: '',
          itemUrls: [],          
          itemLogoUrl: '',
        };
export default {
  name: 'ModifyService',
  components: {
    quillEditor,
    CustomImageUpload
  },
  data() {
    return {
      // 服务分类下拉
      serviceTypeList: [],
      // 服务类型
      serviceTypes: [],
      dialogVisible: false,
      quillOption: quillConfig,
      percentage: 0,
      ruleForm: initialData,
      type: 1, // 1 新增 2 编辑
      rules: {
        itemName: [
          { required: true, message: '请输入服务名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        itemShortName: [
          { required: true, message: '请输入服务名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        itemLogoUrl: [
          { required: true, message: '请上传服务logo', trigger: 'change' }
        ],
        itemUrls: [
          { required: true, message: '请上传服务封面图', trigger: 'change' }
        ],
        itemCategory: [
          { required: true, message: '请选择服务分类', trigger: 'change' }
        ],
        itemType: [
          { required: true, message: '请选择服务类型', trigger: 'change' }
        ],
        itemUnitPrice: [
          { required: true, message: '请输入预约单价', trigger: 'blur' }
        ],
        isEnable: [
          { required: true, message: '请选择上架状态', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    renwuTitles() {
      return this.type === 1 ? '新增服务' : '修改服务';
    },
    isEdit() {
      return this.type !== 1;
    }
  },
  methods: {
    // 显示添加服务
    showAdd() {
      this.type = 1;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getServiceClassify();
        this.getServiceTypes();
      });
    },
    // 显示编辑
    showEdit(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {

        this.type = 2;
        this.getServiceClassify();
        this.getServiceTypes();
        
        this.ruleForm = {
          ...row,
          isEnable: row.isEnable + '',
          itemUrls: row.itemUrls.map(el => ({ url: el})),
          // serviceType: row.serviceType.split(','),
          // serviceClassify: row.serviceClassify.split(',')
        };
      });
    },
    resetData() {
      this.$refs.ruleFormRef.resetFields();
      this.percentage = 0;
      this.ruleForm = initialData;
    },
    onprogress(event, file, fileList) {
      this.percentage = parseInt(event.percent)
    },
    // 初始化服务分类下拉框
    getServiceClassify() {
      selectClassifyTree().then(res => {
        this.serviceTypeList = res;
        if (this.ruleForm.id) {
          const parentItem = res.find(item => item.children.findIndex(child => child.value == this.ruleForm.itemCategory) !== -1);
          if (parentItem) {
            // this.ruleForm.itemCategory = [parentItem.value, this.ruleForm.itemCategory];
            this.$set(this.ruleForm, 'itemCategory', [parentItem.value, this.ruleForm.itemCategory + '']);
          }
        }
      })
    },

    // 获取服务类型列表
    getServiceTypes() {
      getServiceTypeList().then(res => {
        this.serviceTypes = res
      })
    },

    // 封面图片上传
    handleAvatarSuccess(file) {
      this.ruleForm.itemUrl = file.data;
    },
    // lgo图片上传
    handleLogoSuccess(file) {
      this.ruleForm.itemLogoUrl = file.data;
    },
    // 关闭弹窗
    closeDialog() {
      this.resetData();
      this.dialogVisible = false;
    },
    // 提交请求成功
    handleSuccess() {
      this.$emit('saveSuccess');
      this.closeDialog();
    },
    // 将数组图片对象转为 只是数组里面存的url地址
    imagesArrayToUrls(images) {
      if(images && Array.isArray(images)) {
        return images.map(el => (el.url));
      }
      return [];
    },
    // 将form表单数据转换成提交到服务器的数据
    formDataToServerData(formData) {
      return {
        ...formData,
        itemUrls: this.imagesArrayToUrls(formData.itemUrls),
        itemCategory: formData.itemCategory[formData.itemCategory.length - 1]
      };
    },
    // 提交
    handleSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.type == 2) {
            const rs = await editService(this.formDataToServerData(this.ruleForm));
            if (rs) {
              this.handleSuccess();
            }
          } else {
            const rs = await addService(this.formDataToServerData(this.ruleForm));
            if (rs) {
              this.handleSuccess();
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep .avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
  }
} 
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

</style>