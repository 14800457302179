export const config = {
  // 企业/商家-认证列表
  certificationList: '/company/certification/list',
  // 企业/商家-认证审核
  companyAudit: '/company/audit',
  // 企业/商家-详情
  companyInfo: '/company/companyInfo',
  // 企业-员工管理列表
  getCompanyUserList: '/company/getCompanyUserList',
  // 查询公司保证金列表
  depositGetListByPage: '/company/deposit/getListByPage',
  // 商家-保存保证金记录
  saveCompanyDeposit: '/company/deposit/saveCompanyDeposit',
  // 统计公司保证金总额
  getTotalAmount: '/company/deposit/getTotalAmount',
  // 企业-重置账号密码
  resetUserPassword: '/company/resetUserPassword',
};
