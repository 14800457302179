var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.id ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "roleName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "角色名称" },
                model: {
                  value: _vm.dataForm.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "roleName", $$v)
                  },
                  expression: "dataForm.roleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "备注" },
                model: {
                  value: _vm.dataForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "remark", $$v)
                  },
                  expression: "dataForm.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { size: "mini", label: "授权" } },
            [
              _c("el-tree", {
                ref: "menuListTree",
                attrs: {
                  data: _vm.menuList,
                  props: _vm.menuListTreeProps,
                  "node-key": "menuId",
                  "default-expand-all": true,
                  "show-checkbox": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }