var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "4px" } },
    [
      _c(
        "el-collapse-item",
        {
          attrs: { name: "6" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("售后记录"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "assurance_box" }, [
            _c("span", { staticClass: "assurance_day" }, [
              _vm._v(
                "质保期剩余" +
                  _vm._s(_vm.serveOrderAfterSaleInfoVO.periodDays || 0) +
                  "天"
              ),
            ]),
            _vm.serveOrderAfterSaleInfoVO.periodFinishTime
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.serveOrderAfterSaleInfoVO.periodFinishTime) +
                      "到期"
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "vxe-table",
            {
              ref: "xTableFile",
              attrs: {
                data: _vm.afterSales,
                border: "",
                "max-height": "600px",
                "min-height": "0",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: {
                  field: "createTime",
                  "min-width": "180",
                  title: "售后申请日期",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "contents",
                  "min-width": "180",
                  title: "售后内容",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "imgList",
                  "min-width": "180",
                  title: "相关图片",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.imgList?.length
                          ? _c("el-image", {
                              attrs: {
                                src: row.imgList[0],
                                "preview-src-list": row.imgList,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "expectDoorTime",
                  "min-width": "180",
                  title: "预估上门时间",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  field: "estimateDuration",
                  "min-width": "180",
                  title: "预估工期",
                },
              }),
              _c("vxe-table-column", {
                attrs: { width: "180", title: "售后状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenAfterSaleDetails(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.stateValue))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("AfterSaleDetailsDialog", { ref: "afterSaleDetailsDialogRef" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }