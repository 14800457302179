<template>
  <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="50vw" :before-close="handleClose">
    <div class="margin-auto flex flex-column justify-center" style="padding: 32px; min-height: 250px; ">
      <el-descriptions border style="max-height: 60vh; overflow-y: auto;" :labelStyle="{width: '150px', 'justify-content': 'end', 'padding-right': '16px'}" size="medium" class="margin-top" title="" :column="1">
        <el-descriptions-item label="企业状态">{{ companyInfo.auditStatusDescription }}</el-descriptions-item>
        <el-descriptions-item label="企业全称">{{ companyInfo.fullName }}</el-descriptions-item>
        <el-descriptions-item label="统一信用代码">{{ companyInfo.socialCode }}</el-descriptions-item>
        <el-descriptions-item v-if="isMerchant" label="维修专业分类">
          <el-tree
            :data="classifyTree"
            show-checkbox
            ref="treeRef"
            style="height: 150px;width: 350px; overflow: auto; border: 1px solid #eee;"
            node-key="value"
            :props="treeDefaultProps"
            :default-checked-keys="serveIds">
        </el-tree>
        </el-descriptions-item>
        <el-descriptions-item label="上传相关认证资料">
          <div v-if="companyInfo.fileInfoDtoList && companyInfo.fileInfoDtoList.length>0">
            <el-image v-for="item in companyInfo.fileInfoDtoList" :key="item.url" :src="item.url" :preview-src-list="[item.url]" style="width: 100px; height: 100px;margin-right:6px;" />
          </div>
          <span v-else>暂无图片</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="margin-auto mt-40" style="width: 60%;" v-if="!isDetail">
      <el-row :gutter="60">
        <el-col :span="12">
          <el-button type="danger" class="w-full" @click="handleReject">驳 回</el-button>
        </el-col>
        <el-col :span="12">
          <el-popconfirm
            @confirm="handlePass"
            title="是否确认通过该企业审核？"
          >
            <el-button type="primary" slot="reference" class="w-full">通 过</el-button>
          </el-popconfirm>
          
        </el-col>
      </el-row>
    </div>
    <RejectDialog @submit="onReject" ref="rejectDialogRef" />
  </el-dialog>
</template>

<script>
import { CompanyAudit, GetCompanyInfo } from '@/api/auth';
import { selectClassifyTree } from '@/api/serviced';
import RejectDialog from './rejectDialog.vue';
// 审核操作类型：1:通过 0:拒绝
const AuditAction = {
  Pass: 1,
  Reject: 0,
};
export default {
  name: 'EditDialog',
  components: {
    RejectDialog
  },
  props: {
    title: {
      type: String,
      default: '企业信息',
    },
    isMerchant: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 维修分类
      classifyTree: [],
      treeDefaultProps: {
        children: 'children',
        label: 'label',
        value: 'value',
        disabled: () => true
      },
      dialogVisible: false,
      // 是否是详情
      isDetail: false,
      serveIds: [],
      // 公司信息
      companyInfo: {
        fileInfoDtoList: [],
        companyAccount: '',
        companyName: '',
        contactPhone: '',
        doorInfo: '',
        socialCode: '',
        auditRemark: '',
        auditStatus: '',
        auditStatusDescription: '',
        createTime: '',
        id: '',
      },
    }
  },
  methods: {
    // 获取公司详情
    getCompanyInfo() {
      GetCompanyInfo(this.companyInfo.id).then((res) => {
        if (res) {
          this.companyInfo = res;
          this.serveIds = res.serveIds.split(',');
        }
      });
    },
    handleOpen(row) {
      this.dialogVisible = true;
      this.companyInfo = row;
      if (this.isMerchant) {
        this.getClassifyTree();
      }
      this.getCompanyInfo();
    },
    // 显示详情
    showDetail(row) {
      this.isDetail = true;
      this.handleOpen(row);
    },
    // 显示审核
    showAudit(row) {
      this.isDetail = false;
      this.handleOpen(row);
    },
    // 获取维修分类树
    async getClassifyTree() {
      const res = await selectClassifyTree();
      this.classifyTree = res;
    },
    // 审核操作类型：1:通过 0:拒绝
    async hanleRequest(auditAction,  auditRemark) {
      const res = await CompanyAudit(this.companyInfo.id, auditAction, { auditRemark });
      if(res) {
        this.$message({
          message: '提交成功成功',
          type: 'success',
          plain: true,
        });
        if(auditAction === AuditAction.Reject) {
          this.$refs.rejectDialogRef.close();
        }
        this.dialogVisible = false;
        this.$emit('refresh');
      }
    },
    // 拒绝
    handleReject() {
      this.$refs.rejectDialogRef.show();
      // this.hanleRequest(AuditAction.Reject);
    },
    onReject(auditRemark) {
      this.hanleRequest(AuditAction.Reject, auditRemark);
    },
    // 通过
    handlePass() {
      this.hanleRequest(AuditAction.Pass, this.auditRemark);
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #3E8EF7;
  border-color: #3E8EF7;
}
</style>