<template>
  <div>
    <!-- <slot name="table"> -->
    <Table
      ref="tableRef"
      :key="tableKey"
      v-loading="loading"
      :data="dataList"
      :border="border"
      :row-key="rowKey"
      v-bind="{
        ...(!!height ? { height: height } : { 'max-height': 570 }),
      }"
      show-header
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <TableColumn v-if="selection" type="selection" fixed="left" width="55" :selectable="selectable"></TableColumn>
      <TableColumn v-if="radioTable" width="55">
        <template #default="scope">
          <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
          <Radio v-model="radioCheck" class="radio" :label="scope.row" @input="handleRadioChange">&nbsp;</Radio>
        </template>
      </TableColumn>
      <TableColumn
        v-for="column in columnsIn"
        :key="column.prop"
        :width="column.width || 'auto'"
        :min-width="column.minWidth"
        :type="column.type"
        :prop="column.prop"
        :label="column.label"
        :align="column.align"
        :fixed="column.fixed"
        :formatter="column.formatter"
        :show-overflow-tooltip="column.showOverflowTooltip"
      >
        <template v-if="column.type !== 'index'" #default="scope">
          <!-- 提供插槽供页面显示自定义文本, 插槽名以字段名来命名 -->
          <span v-if="column.formatter">
            {{ column.formatter(scope.row, scope.row[column.prop], scope) }}
          </span>
          <slot v-else :name="column.prop" :scope-row="scope.row">
            <template v-if="column.dict">
              {{ getDictLabel(column.dict, scope.row[column.prop]) }}
            </template>
            <template v-else>
              {{ scope.row[column.prop] || scope.row[column.prop] === 0 ? scope.row[column.prop] : '-' }}
            </template>
          </slot>
        </template>
        <template #header>
          <span v-if="!column.customHeader">{{ column.label }}</span>
          <slot v-else :name="column.prop + 'Header'" :scope-row="column"></slot>
        </template>
      </TableColumn>
      <TableColumn v-if="operate" label="操作" align="center" fixed="right" :width="operateWidth">
        <template #default="scope">
          <slot name="operate" :scope-row="scope.row"></slot>
        </template>
      </TableColumn>
      <!-- <slot name="operate"></slot> -->
    </Table>
    <PaginationCustom
      v-if="pagination"
      class="pagination-box"
      :page-size="pageInfo.limit"
      :current-page="pageInfo.page"
      :total="pageTotal"
      :pagination-layout="paginationLayout"
      @onSizeChange="sizeChange"
      @onCurrentChange="currentPage"
    ></PaginationCustom>
    <!-- </slot> -->
    <slot></slot>
  </div>
</template>
<script>
import Vue from 'vue';
import { Table, TableColumn, Loading, Radio } from 'element-ui';
import PaginationCustom from '@/components/pagination/index.vue';
import { getSomeData } from '@/api/common/index';
import { getDictLabel } from '@/utils/common';

Vue.use(Loading);
export default {
  components: {
    PaginationCustom,
    Table,
    TableColumn,
    Radio,
  },
  props: {
    // 列表表头
    columns: {
      type: Array,
      require: true,
      default: () => [],
    },
    // 列表高度
    height: {
      type: Number,
      default: 0,
    },
    // 列表高度
    rowKey: {},
    // 列表多选框
    selection: {
      type: Boolean,
      default: false,
    },
    // 列表单选框
    radioTable: {
      type: Boolean,
      default: false,
    },
    // 获取数据的请求方法
    getPageList: {
      type: Function,
      require: true,
      default: () => { },
    },
    // 操作栏宽度
    operateWidth: {
      default: '150',
      type: String,
    },
    // 是否显示边框
    border: {
      type: Boolean,
      default: false,
    },
    // 是否需要分页
    pagination: {
      type: Boolean,
      default: true,
    },
    // 分页格式
    paginationLayout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    // 是否需要操作栏， $slots.operate不知道为啥不生效，暂时新增一个参数来控制
    operate: {
      type: Boolean,
      default: true,
    },
    // 每页展示多少条
    tablePageSize: {
      default: 20,
      type: Number,
    },
    // 是否手动触发第一次请求 默认为true，需要手动去调用请求，
    manualRequest: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Function,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      getDictLabel,
      columnsIn: this.columns,
      loading: false, // 列表加载
      radioCheck: null,
      dataList: [], // 列表数据
      pageInfo: {
        // 分页信息
        limit: this.tablePageSize,
        page: 1,
      },
      pageTotal: null, // 列表总数
      tableHeight: null, // 列表高度
      dictDefaultProps: {
        label: 'label',
        value: 'value',
      },
      tableKey: '',
    };
  },
  watch: {
    columns: {
      handler(val) {
        const initConfig = JSON.parse(JSON.stringify(val));
        this.columnsIn = initConfig;
        this.initDict();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.initDict();
    if (!this.manualRequest) {
      this.fetchList();
    }
  },
  methods: {
    // 初始化字典项
    initDict() {
      if (this.columnsIn.some((i) => i.hasOwnProperty('dictUrl'))) {
        this.columnsIn.forEach(async (item, index) => {
          if (item?.dictUrl) {
            const dict = await this.getDictData(item);
            this.$set(item, 'dict', dict);
          }
          this.$set(this.columnsIn, index, item);
          this.tableKey = Math.random();
        });
      }
    },
    doLayout() {
      this.$nextTick(() => {
        // myTable是表格的ref属性值
        if (this.$refs.tableRef && this.$refs.tableRef.doLayout) {
          this.$refs.tableRef.doLayout();
        }
      });

      // this.$ref.tableRef.doLayout();
    },
    // 多选行
    handleSelectionChange(val) {
      this.$emit('onSelection', val);
    },
    // 单选行
    handleRadioChange(val) {
      this.$emit('onRadio', val);
    },
    // 获取列表数据 增加传入搜索参数
    fetchList(searchData, callback) {
      if (!this.getPageList) return;
      this.loading = true;
      let params = {};
      if (this.pagination) params = { ...params, ...this.pageInfo };
      this.getPageList(params, searchData || {})
        .then((res) => {
          this.loading = false;
          this.dataList = res?.list || [];
          if (this.pagination) {
            // this.pageInfo = {
            //   limit: res.pageSize,
            //   page: res.currPage,
            // };
            this.pageTotal = res.totalCount;
          }
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 选择当前显示多少条
    sizeChange(val) {
      console.log('sizeChange', val);
      this.pageInfo = {
        ...this.pageInfo,
        limit: val,
      };
      this.fetchList();
    },
    // 翻页
    currentPage(val) {
      console.log('currentPage', val);
      this.pageInfo = {
        ...this.pageInfo,
        page: val,
      };
      this.fetchList();
    },
    // 获取字典
    /**
     * formConfig中请求字典需要的参数
     * dictUrl：请求地址
     * dictParams：请求参数
     * dictProps：字典对应的label value字段
     */
    async getDictData(item) {
      let dict = [];
      const props = item.dictProps || this.dictDefaultProps;
      await getSomeData(item.dictUrl, item.dictParams || {}).then((res) => {
        dict = res.data?.map((i) => {
          return {
            label: i[props.label],
            value: i[props.value],
          };
        });
      });
      return dict;
    },
    // 选中
    toggleRowSelection(rows) {
      rows.forEach((item) => {
        this.$refs.tableRef.toggleRowSelection(item, true);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pagination-box {
  margin-top: 12px;
  text-align: right;
}
</style>
