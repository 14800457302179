<template>
  <div v-loading="loading">
    <el-upload
      :ref="multiple ? 'fileUpload' : null"
      :action="uploadUrl"
      :multiple="multiple"
      :limit="limit"
      :accept="accept"
      :http-request="customRequest"
      :file-list="fileList"
      :on-remove="deleteFile"
      :before-upload="handlerUploadFile"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
    >
      <div slot="tip" class="el-upload__tip">{{ tip }}</div>
      <el-button type="text" icon="el-icon-plus"  slot="trigger"></el-button>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img :src="dialogImageUrl" alt="预览" />
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from '@/api/common/index';
import { Message } from 'element-ui';

export default {
  components: {
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 12,
    },
    accept: {
      type: String,
      default: '.',
    },
    disabled: {
      type: Boolean,
    },
    fileSize: {
      type: Number,
      default: 30,
    },
    extraObj: {
      type: Object,
      default: () => ({}),
    },
    tip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileList: this.value || [],
      loading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      uploadUrl: '#',
    };
  },
  computed: {
    isShowBtn() {
      let flag = true;
      if (this.multiple) {
        if (this.fileList.length >= this.limit) {
          flag = false;
        }
      } else if (this.fileList.length >= 1) {
        flag = false;
      }
      return !(flag || this.disabled);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (Array.isArray(newValue)) {
          this.fileList = newValue.map(({name, url}) => ({ name, url }));
          // newValue.forEach(({name, url}, index) => {
          //   this.$set(this.fileList, index, {name, url});
          // });
        } else {
          console.log('gggxxx');
          this.fileList = [];
        }
      },
    },
  },
  methods: {
    async handlerUploadFile(file) {
      if (file.size > this.fileSize * 1024 * 1024) {
        Message.error('上传文件过大,请确认后上传');
        return false;
      }
      if(this.accept && this.accept !== '.') {
        let index = file.name.lastIndexOf(".");
        let typeName = '.'+file.name.substr(index + 1, file.name.length);
        if (this.accept.split(',').indexOf(typeName.toLowerCase()) === -1) {
          Message.error( `请上传后缀名为${accept}的附件`);
          this.loading = false;
          return false;
        }
      }
      return true;
    },
    async customRequest({ file, onSuccess, onError}) {
      this.loading = true;
      const name = file.name;
      const formData = new FormData();
      formData.append('file', file);
      try {
        const res = await uploadFile(formData, this.extraObj);
        const fileData = {
          name,
          url: res,
          path: res,
        };
        this.fileList.push(fileData);
        this.handleFileChange(fileData, this.fileList);
        this.loading = false;
        onSuccess();
      } catch (error) {
        this.loading = false;
        onError();
      }
    },
    handleFileChange(file, fileList) {
      const data = fileList.map(({url, name}) => ({url, name}));
      this.$emit('input', data);
      this.$emit('blur', file, data);
      
       // 手动触发校验
       this.$nextTick(() => {
        if(this.$parent.validate) {
          this.$parent.validate('change');
        }
      });
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true;
    },
    deleteFile(file) {
      if (file) {
        this.fileList = this.fileList.filter(el => el.url !== file.url);
        this.handleFileChange(file, this.fileList);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.import-tip {
  margin-left: 10px;
  color: #999;
}

.file-list {
  display: flex;
  // justify-content: space-between;
  width: 100%;

  .file-name {
    flex: 1;
    color: #409eff;
    cursor: pointer;
    word-wrap: break-word;
    word-break: break-all;
  }

  .file-operation {
    width: 60px;
  }

  .file-icon {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  &.noBtn {
    margin-top: -10px;
  }
}
</style>
