<template>
  <el-dialog
    title="售后处理记录"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-timeline :reverse="false" v-if="afterSalesInfo?.length">
      <el-timeline-item v-for="item in afterSalesInfo" :key="item.id" :timestamp="item.createTime" placement="top">
        <div class="check_operate">{{ item.nodeName }}</div>
        <el-row>
          <el-col :span="24" class="check_user">
            <span>{{ item.createUserTypeDesc }}</span>
            <span style="margin-left: 10px;">{{ item.createUserName }}</span>
          </el-col>
          <el-col :span="24">
            <div class="check_content">{{ item.content }}</div>
            <ul class="maintain_content" v-if="item?.fileInfoList?.length">
              <li v-for="file in item?.fileInfoList" :key="file.id">
                <el-image :src="file.url" />
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-timeline-item>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAfterSaleRecordList } from '@/api/maintenance/index'
export default {
  data() {
    return {
      // 售后id
      id: '',
      dialogVisible: false,
      // 售后处理记录列表
      afterSalesInfo: []
    }
  },
  methods: {
    handleOpen(params) {
      this.id = params.id
      this.dialogVisible = true
      this.getAfterSaleRecordList()
    },
    handleClose() {
      this.dialogVisible = false
    },
    async getAfterSaleRecordList() {
      try {
        const res = await getAfterSaleRecordList({ afterSaleId: this.id })
        this.afterSalesInfo = res
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    }
  }
}
</script>

<style lang='less' scoped>
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
</style>