<template>
  <div>
    <el-descriptions :column="5" direction="vertical" border class="service_descriptions">
      <template #title>
        <h2 class="details_title"><span></span>服务单信息</h2>
      </template>
      <el-descriptions-item label="服务单号">{{ formData.serveOrderNo }}</el-descriptions-item>
      <el-descriptions-item label="关联预约单号">
        <el-button type="text" @click="goToOrder">{{ formData.preOrdersNo }}</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="订单来源">{{ formData.source }}</el-descriptions-item>
      <el-descriptions-item label="客户">{{ formData.customerName }}</el-descriptions-item>
      <el-descriptions-item label="客户对接人">
        <span>{{ formData.contactUserName }}</span>
        <el-button type="text" style="margin-left: 10px" @click="goToUserInfo">查看</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="订单类型">
        <span :class="{ text_red: formData.preOrdersType === 1 }">{{ formData.preOrdersTypeValue }}</span>
      </el-descriptions-item>
      <el-descriptions-item label="生成时间">{{ formData.createTime }}</el-descriptions-item>
      <el-descriptions-item label="预约金额">{{ formData.appointmentAmount }}</el-descriptions-item>
      <el-descriptions-item label="方案金额">{{ formData.schemeAmount }}</el-descriptions-item>
      <el-descriptions-item label="质保时间">{{ formData.periodFinishTime }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="5" direction="vertical" border class="service_descriptions">
      <template #title>
        <h2 class="details_title"><span></span>服务信息</h2>
      </template>
      <el-descriptions-item label="服务名称">{{ serveInfo.serveName }}</el-descriptions-item>
      <el-descriptions-item label="服务分类">{{ serveInfo.serverCategory }}</el-descriptions-item>
      <el-descriptions-item label="服务类型">{{ serveInfo.serveType }}</el-descriptions-item>
      <el-descriptions-item label="服务地址">{{ serveInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="期望上门时间">{{ serveInfo.expectDoorTime }}</el-descriptions-item>
    </el-descriptions>
    <div>
      <p class="second_title">服务说明</p>
      <span>{{ serveInfo.eventExplain || '暂无说明' }}</span>
      <ul class="maintain_content" v-if="!!serveInfo?.imgUrlList?.length">
        <li v-for="(item, i) in serveInfo?.imgUrlList" :key="i">
          <el-image :src="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 服务单信息数据
      formData: {},
      // 服务信息数据
      serveInfo: {}
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.formData = val?.basicInfo || {}
        this.serveInfo = val?.serveInfo || {}
      },
      deep: true
    }
  },
  methods: {
    // 跳转到预约单详情
    goToOrder() {
      this.$router.push({
        path: '/dispatchOrderDetails',
        query: {
          id: this.formData.preOrdersId
        }
      })
    },
    // 跳转到用户信息
    goToUserInfo() {
      this.$router.push({
        path: '/userDetail',
        query: {
          userId: this.formData.contactUserId
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.text_red {
  color: red;
}
.service_descriptions {
  margin-bottom: 20px;
}
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
.second_title {
  font-size: 16px;
  font-weight: 700;
}
</style>