import { post } from '@/utils/httpRequest';
import { httpGet, httpPost } from '@/utils/request';
import { config } from './config';

// 公共请求
export const getSomeData = async (apiType, url, data) => {
  if (apiType === 'get') {
    const res = await httpGet(url, data);
    return res;
  } else {
    const res = await httpPost(url, data);
    return res;
  }
};
// 数据字典
export const getDict = async (code, params) => {
  const res = await httpGet(config.getDict + code, params);
  return res;
};
// 附件下载
export const download = async (id) => {
  const res = await httpGet(config.download + id, '', 'blob');
  return res;
};
// 修改密码
export const updatePassword = async (data) => {
  const res = await httpPost(config.updatePassword, data);
  return res;
};
// 附件上传
export const uploadFile = async (data) => {
  const res = await httpPost(config.upload, data);
  return res;
};
// 退出登录
export const logout = async (data) => {
  const res = await httpPost(config.logout, data);
  return res;
};
// 第三方token获取token
export const thirdSsolog = async (data) => {
  const res = await httpPost(config.thirdSsolog, data);
  return res;
};
