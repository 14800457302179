var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { float: "right", "margin-right": "2%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled: !_vm.isAuth("app:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: { click: _vm.addUpgrade },
                },
                [_vm._v("添加升级")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.upgradeData1.records },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "wgtUrl", label: "统一地址" },
              }),
              _c("el-table-column", {
                attrs: { prop: "androidWgtUrl", label: "安卓" },
              }),
              _c("el-table-column", {
                attrs: { prop: "iosWgtUrl", label: "苹果" },
              }),
              _c("el-table-column", {
                attrs: { prop: "version", label: "安卓版本" },
              }),
              _c("el-table-column", {
                attrs: { prop: "iosVersion", label: "ios版本" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "升级方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.method == "true"
                                ? "强制升级"
                                : "普通升级"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("app:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.upgradebj(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("app:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.upgradelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.upgradeData1.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加升级",
                visible: _vm.dialogFormVisible3,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible3 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("统一地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入统一地址" },
                    model: {
                      value: _vm.wgtUrl,
                      callback: function ($$v) {
                        _vm.wgtUrl = $$v
                      },
                      expression: "wgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("安卓地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入安卓地址" },
                    model: {
                      value: _vm.androidWgtUrl,
                      callback: function ($$v) {
                        _vm.androidWgtUrl = $$v
                      },
                      expression: "androidWgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("苹果地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入苹果地址" },
                    model: {
                      value: _vm.iosWgtUrl,
                      callback: function ($$v) {
                        _vm.iosWgtUrl = $$v
                      },
                      expression: "iosWgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("安卓版本号："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入安卓版本号" },
                    model: {
                      value: _vm.version,
                      callback: function ($$v) {
                        _vm.version = $$v
                      },
                      expression: "version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("ios版本号："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入ios版本号" },
                    model: {
                      value: _vm.iosVersion,
                      callback: function ($$v) {
                        _vm.iosVersion = $$v
                      },
                      expression: "iosVersion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("升级方式："),
                    ]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { placeholder: "请选择升级方式" },
                      model: {
                        value: _vm.method,
                        callback: function ($$v) {
                          _vm.method = $$v
                        },
                        expression: "method",
                      },
                    },
                    _vm._l(_vm.methods, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("升级内容："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入升级内容" },
                    model: {
                      value: _vm.des,
                      callback: function ($$v) {
                        _vm.des = $$v
                      },
                      expression: "des",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible3 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.upgradaddTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改",
                visible: _vm.dialogFormVisible4,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible4 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.formupgrad } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "统一地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.wgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "wgtUrl", $$v)
                          },
                          expression: "formupgrad.wgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安卓地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.androidWgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "androidWgtUrl", $$v)
                          },
                          expression: "formupgrad.androidWgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "苹果地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.iosWgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "iosWgtUrl", $$v)
                          },
                          expression: "formupgrad.iosWgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安卓版本号：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.version,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "version", $$v)
                          },
                          expression: "formupgrad.version",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "ios版本号：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.iosVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "iosVersion", $$v)
                          },
                          expression: "formupgrad.iosVersion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "升级方式：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "65%" },
                          attrs: { placeholder: "请选择升级方式" },
                          model: {
                            value: _vm.formupgrad.method,
                            callback: function ($$v) {
                              _vm.$set(_vm.formupgrad, "method", $$v)
                            },
                            expression: "formupgrad.method",
                          },
                        },
                        _vm._l(_vm.methods, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "升级内容：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.des,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "des", $$v)
                          },
                          expression: "formupgrad.des",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible4 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.upgradbjTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }