var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: !_vm.dataForm.id ? "新增" : "修改",
        "close-on-click-modal": false,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.dataForm,
            rules: _vm.dataRule,
            "label-width": "80px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.dataFormSubmit()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "type", $$v)
                    },
                    expression: "dataForm.type",
                  },
                },
                _vm._l(_vm.dataForm.typeList, function (type, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: index } },
                    [_vm._v(_vm._s(type))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.dataForm.typeList[_vm.dataForm.type] + "名称",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    _vm.dataForm.typeList[_vm.dataForm.type] + "名称",
                },
                model: {
                  value: _vm.dataForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "name", $$v)
                  },
                  expression: "dataForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上级菜单", prop: "parentName" } },
            [
              _c(
                "el-popover",
                {
                  ref: "menuListPopover",
                  attrs: { placement: "bottom-start", trigger: "click" },
                },
                [
                  _c("el-tree", {
                    ref: "menuListTree",
                    staticStyle: { height: "500px", overflow: "auto" },
                    attrs: {
                      data: _vm.menuList,
                      props: _vm.menuListTreeProps,
                      "node-key": "menuId",
                      "default-expand-all": true,
                      "highlight-current": true,
                      "expand-on-click-node": false,
                    },
                    on: {
                      "current-change": _vm.menuListTreeCurrentChangeHandle,
                    },
                  }),
                ],
                1
              ),
              _c("el-input", {
                directives: [
                  {
                    name: "popover",
                    rawName: "v-popover:menuListPopover",
                    arg: "menuListPopover",
                  },
                ],
                staticClass: "menu-list__input",
                attrs: { readonly: true, placeholder: "点击选择上级菜单" },
                model: {
                  value: _vm.dataForm.parentName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "parentName", $$v)
                  },
                  expression: "dataForm.parentName",
                },
              }),
            ],
            1
          ),
          _vm.dataForm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单路由", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "菜单路由" },
                    model: {
                      value: _vm.dataForm.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "url", $$v)
                      },
                      expression: "dataForm.url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type !== 0
            ? _c(
                "el-form-item",
                { attrs: { label: "授权标识", prop: "perms" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "多个用逗号分隔, 如: user:list,user:create",
                    },
                    model: {
                      value: _vm.dataForm.perms,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "perms", $$v)
                      },
                      expression: "dataForm.perms",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "排序号", prop: "orderNum" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      label: "排序号",
                    },
                    model: {
                      value: _vm.dataForm.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "orderNum", $$v)
                      },
                      expression: "dataForm.orderNum",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dataForm.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单图标", prop: "icon" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-popover",
                            {
                              ref: "iconListPopover",
                              attrs: {
                                placement: "bottom-start",
                                trigger: "click",
                                "popper-class": "mod-menu__icon-popover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mod-menu__icon-inner" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mod-menu__icon-list" },
                                    _vm._l(
                                      _vm.iconList,
                                      function (item, index) {
                                        return _c(
                                          "el-button",
                                          {
                                            key: index,
                                            class: {
                                              "is-active":
                                                item === _vm.dataForm.icon,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.iconActiveHandle(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("icon-svg", {
                                              attrs: { name: item },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("el-input", {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover:iconListPopover",
                                arg: "iconListPopover",
                              },
                            ],
                            staticClass: "icon-list__input",
                            attrs: {
                              readonly: true,
                              placeholder: "菜单图标名称",
                            },
                            model: {
                              value: _vm.dataForm.icon,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "icon", $$v)
                              },
                              expression: "dataForm.icon",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "icon-list__tips", attrs: { span: 2 } },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "top", effect: "light" } },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v("全站推荐使用SVG Sprite, 详细请参考:"),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "//github.com/daxiongYang/sz-fast-vue/blob/master/src/icons/index.js",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("icons/index.js")]
                                  ),
                                  _vm._v("描述"),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-warning" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.dataFormSubmit()
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }