<template>
  <el-dialog title="修改预约信息" :visible.sync="dialogVisible" width="20%" :before-close="handleClose">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="联系人：" prop="orderUserName">
        <el-input type="text" v-model="ruleForm.orderUserName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号码：" prop="orderUserPhone">
        <el-input type="text" v-model="ruleForm.orderUserPhone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上门时间：" prop="expectDoorTime">
        <el-date-picker
          style="width: 100%"
          v-model="ruleForm.expectDoorTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { savePreOrdersInfo } from '@/api/maintenance/index'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {
        orderUserName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        orderUserPhone: [{ required: true, message: '请输入联系人手机', trigger: 'blur' }],
        expectDoorTime: [{ required: true, message: '请选择上门时间', trigger: 'change' }],
      }
    }
  },
  methods: {
    handleOpen(params) {
      this.ruleForm = {
        id: params.id,
        orderUserName: params.orderUserName,
        orderUserPhone: params.orderUserPhone,
        expectDoorTime: params.expectDoorTime,
        // 修改预约信息为1，其它不用传
        visitType: 1
      }
      this.dialogVisible = true
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const res = await savePreOrdersInfo(this.ruleForm)
          if (res.data.code === 500) {
            this.$message.error(res.data.msg)
            return
          }
          this.$message.success('操作成功!')
          this.handleClose()
          this.$emit('refresh')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style>
</style>