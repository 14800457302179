<template>
  <div>
    <el-descriptions :column="6" direction="vertical" border class="service_descriptions">
      <template #title>
        <h2 class="details_title"><span></span>上门信息</h2>
      </template>
      <el-descriptions-item label="联系人">{{ basicInfo.orderUserName }}</el-descriptions-item>
      <el-descriptions-item label="手机号码">{{ basicInfo.orderUserPhone }}</el-descriptions-item>
      <el-descriptions-item label="项目名称">{{ basicInfo.projectName }}</el-descriptions-item>
      <el-descriptions-item label="项目地址">{{ basicInfo.address }}</el-descriptions-item>
      <el-descriptions-item label="预约上门时间">{{ basicInfo.expectDoorTime }}</el-descriptions-item>
      <el-descriptions-item label="服务事项说明">{{ basicInfo.eventExplain }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>
</style>