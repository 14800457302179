var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _c("span", [_vm._v("开始时间：")]),
          _c("el-date-picker", {
            staticStyle: { width: "160px", "margin-left": "10px" },
            attrs: {
              align: "right",
              type: "datetime",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              placeholder: "选择开始时间",
            },
            model: {
              value: _vm.startTime,
              callback: function ($$v) {
                _vm.startTime = $$v
              },
              expression: "startTime",
            },
          }),
          _vm._v("    "),
          _c("span", [_vm._v("截止时间：")]),
          _c("el-date-picker", {
            staticStyle: { width: "160px", "margin-left": "10px" },
            attrs: {
              align: "right",
              type: "datetime",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              placeholder: "选择截止时间",
            },
            model: {
              value: _vm.endTime,
              callback: function ($$v) {
                _vm.endTime = $$v
              },
              expression: "endTime",
            },
          }),
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "请输入电话" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.timeDate2.apply(null, arguments)
              },
            },
            model: {
              value: _vm.phone,
              callback: function ($$v) {
                _vm.phone = $$v
              },
              expression: "phone",
            },
          }),
          _vm._v("     "),
          _c("el-input", {
            staticStyle: { width: "150px" },
            attrs: { clearable: "", placeholder: "请输入昵称" },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.timeDate2.apply(null, arguments)
              },
            },
            model: {
              value: _vm.nickName,
              callback: function ($$v) {
                _vm.nickName = $$v
              },
              expression: "nickName",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.timeDate2 },
            },
            [_vm._v("查询 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.refresh1 },
            },
            [_vm._v("刷新 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.rechargeData.content },
        },
        [
          _c("el-table-column", {
            attrs: { fixed: "", prop: "userId", label: "编号", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { prop: "nickName", label: "员工昵称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.nickName ? scope.row.nickName : "null"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.phone ? scope.row.phone : "未绑定")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "ordersCount", label: "订单数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.ordersCount ? scope.row.ordersCount : "0"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "ordersPayMoney", label: "订单金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.ordersPayMoney
                            ? scope.row.ordersPayMoney
                            : "0"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "ordersPayMoney", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "document",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.paidanBtn(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看派单")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "document",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.zhipaiBtn(scope.row)
                          },
                        },
                      },
                      [_vm._v("指派订单")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 15, 20],
              "page-size": _vm.size,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next",
              total: _vm.totalnum2,
            },
            on: {
              "size-change": _vm.handleSizeChange1,
              "current-change": _vm.handleCurrentChange1,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "派单列表",
            visible: _vm.dialogFormVisibleP,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleP = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("订单状态：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择订单状态" },
                  on: {
                    change: function ($event) {
                      return _vm.select()
                    },
                  },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                },
                _vm._l(_vm.orderTypes, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("订单号：")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入订单号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.orderNum,
                  callback: function ($$v) {
                    _vm.orderNum = $$v
                  },
                  expression: "orderNum",
                },
              }),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("收货人手机号：")]),
              _c("el-input", {
                staticStyle: { width: "180px" },
                attrs: { clearable: "", placeholder: "请输入收货人手机号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("下单开始时间：")]),
              _c("el-date-picker", {
                staticStyle: { width: "160px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "datetime",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择开始时间",
                },
                model: {
                  value: _vm.startTime1,
                  callback: function ($$v) {
                    _vm.startTime1 = $$v
                  },
                  expression: "startTime1",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("下单截止时间：")]),
              _c("el-date-picker", {
                staticStyle: { width: "160px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "datetime",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择截止时间",
                },
                model: {
                  value: _vm.endTime1,
                  callback: function ($$v) {
                    _vm.endTime1 = $$v
                  },
                  expression: "endTime1",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("预约开始时间：")]),
              _c("el-date-picker", {
                staticStyle: { width: "160px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "datetime",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择开始时间",
                },
                model: {
                  value: _vm.startTimeYy,
                  callback: function ($$v) {
                    _vm.startTimeYy = $$v
                  },
                  expression: "startTimeYy",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("预约截止时间：")]),
              _c("el-date-picker", {
                staticStyle: { width: "160px", "margin-left": "10px" },
                attrs: {
                  align: "right",
                  type: "datetime",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择截止时间",
                },
                model: {
                  value: _vm.endTimeYy,
                  callback: function ($$v) {
                    _vm.endTimeYy = $$v
                  },
                  expression: "endTimeYy",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans },
                },
                [_vm._v("重置 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select },
                },
                [_vm._v("刷新 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingP,
                  expression: "tableDataLoadingP",
                },
              ],
              attrs: { data: _vm.tableData.content },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", width: "60", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: { prop: "img", label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.img,
                            alt: "",
                            width: "60",
                            height: "60",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  fixed: "",
                  label: "商品标题",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "oneGoodsType.name", label: "一级商品分类" },
              }),
              _c("el-table-column", {
                attrs: { prop: "twoGoodsType.name", label: "二级商品分类" },
              }),
              _c("el-table-column", {
                attrs: { prop: "detailJson", label: "商品规格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.detailJson ? scope.row.detailJson : "无"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "number", label: "商品个数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "descrition", label: "订单备注", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.descrition
                                ? scope.row.descrition
                                : "未填写"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNum", label: "订单号", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orderNum))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "团长用户", fixed: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.teamId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.teamUserName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "consignee", label: "收货人", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "收货人手机号", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "detail", label: "详细地址", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.provinces) +
                              _vm._s(scope.row.detail)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "price", label: "商品价格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(_vm._f("numFilter")(scope.row.price))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payMoney", label: "支付金额", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f56c6c",
                              cursor: "pointer",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("numFilter")(scope.row.payMoney))
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "startImg", label: "服务前图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.startImg == null || scope.row.startImg == ""
                          ? _c("div", [_vm._v(" 暂无图片 ")])
                          : _vm._e(),
                        scope.row.startImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.startImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "200px",
                                              height: "200px",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "endImg", label: "服务后图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.endImg == null || scope.row.endImg == ""
                          ? _c("div", [_vm._v(" 暂无图片 ")])
                          : _vm._e(),
                        scope.row.endImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.endImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "200px",
                                              height: "200px",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "确认码", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "refund", label: "退款原因", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.refund ? scope.row.refund : "未退款"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "refusedRefund",
                  label: "驳回原因",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.refusedRefund
                                ? scope.row.refusedRefund
                                : "未拒绝"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payWay", label: "支付方式", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payWay === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("微信"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("微信公众号"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("微信小程序"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 4
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("app支付宝"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 5
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("H5支付宝"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 6
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("零钱"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === 7
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("积分"),
                            ])
                          : _vm._e(),
                        scope.row.payWay === null
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("未支付"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "serviceTime",
                  label: "预约时间",
                  fixed: "right",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "600" },
                          },
                          [_vm._v(_vm._s(scope.row.serviceTime))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "payTime", label: "付款时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.payTime ? scope.row.payTime : "未付款"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  label: "服务开始时间",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "服务结束时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "finishTime", label: "收货时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.finishTime
                                ? scope.row.finishTime
                                : "未收货"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isOver", label: "是否超时", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isOver == 1
                          ? _c("span", [_vm._v("是")])
                          : _c("span", [_vm._v("否")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "staffUserId",
                  label: "是否派单",
                  width: "50",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.staffUserId ? "是" : "否")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  prop: "status",
                  label: "订单状态",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待付款"),
                            ])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                        scope.row.status == 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("服务中"),
                            ])
                          : _vm._e(),
                        scope.row.status == 7
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已完结"),
                            ])
                          : _vm._e(),
                        scope.row.status == 8
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("申请退款"),
                            ])
                          : _vm._e(),
                        scope.row.status == 9
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已退款"),
                            ])
                          : _vm._e(),
                        scope.row.status == 10
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("拒绝退款"),
                            ])
                          : _vm._e(),
                        scope.row.status == 11
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已评价"),
                            ])
                          : _vm._e(),
                        scope.row.status == 12
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("已取消"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50, 100],
                  "page-size": _vm.size1,
                  "current-page": _vm.page1,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.tableData.totalElements,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }