<template>
  <el-dialog title="关闭订单" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="备注信息" prop="remark">
        <el-input
          type="textarea"
          v-model="ruleForm.remark"
          :rows="4"
          resize="none"
          show-word-limit
          placeholder="请输入备注信息"
          maxlength="300"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { doCloseOrders } from '@/api/maintenance/index'
export default {
  data() {
    return {
      id: '',
      dialogVisible: false,
      ruleForm: {},
      rules: {}
    }
  },
  methods: {
    handleOpen(row) {
      this.dialogVisible = true
      this.id = row.id
    },
    handleClose() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    // 关闭订单
    async handleOk() {
      const res = await doCloseOrders({
        id: this.id,
        ...this.ruleForm
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('操作成功!')
      this.handleClose()
      this.$emit('handleOk')
    }
  }
}
</script>

<style>
</style>