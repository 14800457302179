export function getFileSuffix(str) {
  try {
    console.log(str);
    const urlSplit = str.split('?');
    const flieArr = urlSplit[0].split('.');
    const suffix = flieArr[flieArr.length - 1];
    return suffix.toLocaleLowerCase();
  } catch (error) {
    return '';
  }
}

// 根据文件名判断文件类型
export function isFileByType(url, types) {
  const suffix = getFileSuffix(url);
  return types.includes(suffix.toLocaleLowerCase());
}
const ImgTypes = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'];
// 根据文件名判断文件是否是图片
export function isImage(str) {
  return isFileByType(str, ImgTypes);
}
// 根据附件接口返回的附件类型转换成vant需要的格式
export function docFormatToAccept(str) {
  const formatArr = str.split('/');
  let accept = '';
  formatArr.forEach((item) => {
    if (ImgTypes.includes(item)) {
      item = ` image/${item}`;
    } else {
      item = ` .${item}`;
    }
    accept += `${item},`;
  });
  accept = accept.substring(0, accept.length - 1);
  return accept;
}

// 根据文件名或者url判断文件是否是 excel
export function isExcel(url) {
  const types = ['xls', 'xlsx'];
  if (url.file) {
    return isFileByType(url.file.name, types);
  }
  return isFileByType(url.url, types);
}

// 根据文件名或者url判断文件是否是 word
export function isDoc(url) {
  const types = ['doc', 'docx'];
  if (url.file) {
    return isFileByType(url.file.name, types);
  }
  return isFileByType(url.url, types);
}

// 根据文件名或者url判断文件是否是 pdf
export function isPdf(fileName) {
  const types = ['pdf'];
  return isFileByType(fileName, types);
}

// 根据文件名或者url判断文件是否是 ppt
export function isPpt(url) {
  const types = ['ppt', 'pptx'];
  if (url.file) {
    return isFileByType(url.file.name, types);
  }
  return isFileByType(url.url, types);
}

// 根据文件名或者url判断文件是否是 video
export function isVideo(url) {
  const types = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
  if (url.file) {
    return isFileByType(url.file.name, types);
  }
  return isFileByType(url.url, types);
}

// 根据文件名或者url判断文件是否是 audio
export function isAudio(url) {
  const types = ['mp3', 'wav', 'wmv'];
  if (url.file) {
    return isFileByType(url.file.name, types);
  }
  return isFileByType(url.url, types);
}

// 解析上传附件类型 doc/docx/pdf/xlsx/xls/jpg/png

// 下载文件
export function DownloadByUrl(url, name) {
  const elink = document.createElement('a');
  elink.download = name || Math.random().toFixed(32).substring(2, 5);
  elink.style.display = 'none';
  elink.href = url;
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href); // 释放URL 对象
  document.body.removeChild(elink);
}

export const base64ByBlob = (base64, callback) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  callback(new Blob([u8arr], { type: mime }));
};
