// 节流函数
export const throttle = (fn, delay) => {
  let timer = null;
  return (...args) => {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

// 获取字典文本
export const getDictLabel = (dicts, value) => {
  const dict = dicts.find((item) => item.value === value);
  return dict ? dict.label : value;
};

// 生成随机id
export const uniqueId = () => {
  return Number(Math.random().toString().substr(2, 0) + Date.now()).toString(36);
};

// 生成两个整数之间的连续整数
export const getIntRange = (start, end) => {
  let startInt = start;
  const arr = [];
  for (let i = 0; i <= end - start; i++) {
    arr[i] = startInt;
    startInt++;
  }
  // arr = arr.map((item) => {
  //   if (item < 10) item = `0${item}`;
  //   return item;
  // });
  return arr;
};
