<template>
	<el-submenu v-if="menu.list && menu.list.length >= 1" :index="menu.menuId + ''"
		:popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'">
		<template slot="title">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</template>
		<sub-menu v-for="item in menu.list" :key="item.menuId" :menu="item" :dynamicMenuRoutes="dynamicMenuRoutes">
		</sub-menu>
	</el-submenu>
	<el-menu-item v-else :index="menu.menuId + ''" @click="gotoRouteHandle(menu)">
		<el-badge class="item" v-if="menu.menuId==142&&chatNumDrz!=0" :value="chatNumDrz">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</el-badge>
		<el-badge class="item" v-else-if="menu.menuId==143&&chatNumDts!=0" :value="chatNumDts">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</el-badge>
		<el-badge class="item" v-else-if="menu.menuId==132&&chatNumDsh!=0" :value="chatNumDsh">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</el-badge>
		<el-badge class="item" v-else-if="menu.menuId==72&&chatNumDFw!=0" :value="chatNumDFw">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</el-badge>
		<el-badge class="item" v-else-if="menu.menuId==41&&chatNumTs!=0" :value="chatNumTs">
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>
			<span>{{ menu.name }}</span>
		</el-badge>
		<div class="el-badge item" v-else>
			<icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon aaaaaaa"></icon-svg>
			<span>{{ menu.name }}</span>
		</div>
	</el-menu-item>
</template>

<script>
	import SubMenu from './main-sidebar-sub-menu'
	export default {
		name: 'sub-menu',
		data(){
			return {
				chatNumDrz:0,//实名认证
				chatNumDts:0,
				chatNumDsh:0,
				chatNumDFw:0,
				chatNumTs:0,
			}
		},
		props: {
			menu: {
				type: Object,
				required: true
			},
			dynamicMenuRoutes: {
				type: Array,
				required: true
			}
		},
		components: {
			SubMenu
		},
		computed: {
			sidebarLayoutSkin: {
				get() {
					return this.$store.state.common.sidebarLayoutSkin
				}
			}
		},
		methods: {
			// 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
			gotoRouteHandle(menu) {
				var route = this.dynamicMenuRoutes.filter(item => item.meta.menuId === menu.menuId)
				if (route.length >= 1) {
					this.$router.push({
						name: route[0].name
					})
				}
			}
		},
		mounted() {
			setInterval(() => {
				if(this.$cookie.get('numberDrz')){
					this.chatNumDrz = this.$cookie.get('numberDrz')
				}
				if(this.$cookie.get('numberDts')){
					this.chatNumDts = this.$cookie.get('numberDts')
				}
				if(this.$cookie.get('numberDsh')){
					this.chatNumDsh = this.$cookie.get('numberDsh')
				}
				if(this.$cookie.get('numberDfw')){
					this.chatNumDFw = this.$cookie.get('numberDfw')
				}
				if(this.$cookie.get('numberTs')){
					this.chatNumTs = this.$cookie.get('numberTs')
				}
				
			}, 1000)
		},
	}
</script>
<style>
	.item {
		/* margin-top: 10px;
		margin-right: 40px; */

	}

	.el-badge__content.is-fixed.is-dot {
		right: -5px;
		top: 20px;
	}
	.el-badge__content.is-fixed{
		right: 0px;
		top: 20px;
	}
</style>
