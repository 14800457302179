<template>
  <el-dialog
  :title="title"
  :visible.sync="visible"
  width="1200px"
  top="5vh"
  @close="close"
>
  <DepositList :companyId="companyId" :hideSearch="true" v-if="visible" />
</el-dialog>
</template>

<script>
import DepositList from './../../finance/deposit/index.vue'
export default {
  name: 'DepositRecordDialog',
  components:{
    DepositList
  },
  data() {
    return {
      visible: false,
      title: '保证金记录',
      companyId: '',
    }
  },
  methods: {
    show(companyId) {
      this.companyId = companyId;
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }

}
</script>

<style>

</style>