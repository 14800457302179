var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "关闭订单",
        visible: _vm.dialogVisible,
        width: "30%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "备注信息", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  resize: "none",
                  "show-word-limit": "",
                  placeholder: "请输入备注信息",
                  maxlength: "300",
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }