// 1:企业客户 2:服务商家
export const ECompanyType = {
  Enterprise: 1,
  ServiceProvider: 2,
};

export const CompanyTypeList = [
  {
    value: ECompanyType.Enterprise,
    label: '企业客户',
  },
  {
    value: ECompanyType.ServiceProvider,
    label: '服务商家',
  },
];
