var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { "text-align": "right", "margin-bottom": "10px" } },
        [
          _vm.basicInfo.isShowAddServeBtn === 1
            ? _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.handleAddService },
                },
                [_vm._v("添加服务")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("TableCustom", {
        ref: "tableCustomRef",
        attrs: {
          border: "",
          columns: _vm.tableColumns,
          "get-page-list": _vm.getPageList,
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "itemLogoUrl",
            fn: function ({ scopeRow }) {
              return [_c("el-image", { attrs: { src: scopeRow.itemLogoUrl } })]
            },
          },
          {
            key: "constructionPlan",
            fn: function ({ scopeRow }) {
              return [
                !scopeRow.schemeState ||
                scopeRow.schemeState === 1 ||
                scopeRow.schemeState === 3
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.eidtScheme(scopeRow, 1)
                          },
                        },
                      },
                      [_vm._v("编辑施工方案")]
                    )
                  : _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.eidtScheme(scopeRow, 2)
                          },
                        },
                      },
                      [_vm._v("查看施工方案")]
                    ),
              ]
            },
          },
          {
            key: "schemeStateDesc",
            fn: function ({ scopeRow }) {
              return [
                scopeRow.schemeStateDesc
                  ? _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.schemeStateOptions.find(
                            (item) => item.value === scopeRow.schemeState
                          )?.type,
                        },
                      },
                      [_vm._v(_vm._s(scopeRow.schemeStateDesc))]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "orderStateDesc",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.serviceStateOptions.find(
                        (item) => item.value === scopeRow.orderState
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.orderStateDesc))]
                ),
              ]
            },
          },
          {
            key: "checkAcceptDesc",
            fn: function ({ scopeRow }) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.checkAcceptOptions.find(
                        (item) => item.value === scopeRow.checkAccept
                      )?.type,
                    },
                  },
                  [_vm._v(_vm._s(scopeRow.checkAcceptDesc))]
                ),
              ]
            },
          },
          {
            key: "operate",
            fn: function ({ scopeRow }) {
              return [
                scopeRow.generateType !== 1 &&
                (scopeRow.schemeState === 1 || scopeRow.schemeState === 3)
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { color: "#f56c6c" },
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelService(scopeRow)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    )
                  : _vm._e(),
                scopeRow.orderState === 2
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.pcCancelServeOrder(scopeRow)
                          },
                        },
                      },
                      [_vm._v("取消订单")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "service_total" }, [
        _c("span", [
          _vm._v("预估总额："),
          _c("i", [
            _vm._v("￥" + _vm._s(_vm.preOrdersSerInfo?.predictAmount || 0)),
          ]),
        ]),
      ]),
      _c("AddServiceDialog", {
        ref: "addServiceDialogRef",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "details_title" }, [
      _c("span"),
      _vm._v("服务信息"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }