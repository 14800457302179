<template>
  <div>
    <!-- 头部操作项 -->
    <DetailsHeaderModule
      :basicInfo="basicInfo"
      @refresh="getPreOrdersDetail"
      @refreshRemark="getNotesRecordList"
    ></DetailsHeaderModule>
    <!-- 基本信息 -->
    <BasciModule :basicInfo="basicInfo"></BasciModule>
    <!-- 上门信息 -->
    <WorkersModule :basicInfo="basicInfo"></WorkersModule>
    <!-- 维修部位照片 -->
    <MaintainImgModule :basicInfo="basicInfo"></MaintainImgModule>
    <!-- 服务信息 -->
    <ServiceInfoModule @refresh="getPreOrdersDetail" :basicInfo="basicInfo"></ServiceInfoModule>
    <!-- 备注信息 -->
    <RemarkModule :basicInfo="remarkInfo"></RemarkModule>
    <!-- 操作信息 -->
    <OperateModule :basicInfo="operateInfo"></OperateModule>
  </div>
</template>

<script>
import DetailsHeaderModule from './module/detailsHeaderModule.vue'
import BasciModule from './module/basciModule.vue'
import WorkersModule from './module/workersModule.vue'
import MaintainImgModule from './module/maintainImgModule.vue'
import ServiceInfoModule from './module/serviceInfoModule.vue'
import RemarkModule from './module/remarkModule.vue'
import OperateModule from './module/operateModule.vue'
import { getPreOrdersDetail, getNotesRecordList, getOrdersOperateLogList } from '@/api/maintenance/index'
export default {
  components: {
    DetailsHeaderModule,
    BasciModule,
    WorkersModule,
    MaintainImgModule,
    ServiceInfoModule,
    RemarkModule,
    OperateModule
  },
  data() {
    return {
      id: '',
      // 详情信息
      basicInfo: {},
      // 备注列表
      remarkInfo: {},
      // 操作列表
      operateInfo: {}
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getPreOrdersDetail()
    this.getNotesRecordList()
    this.getOrdersOperateLogList()
  },
  methods: {
    // 获取详情
    async getPreOrdersDetail() {
      const res = await getPreOrdersDetail({
        id: this.id
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.basicInfo = res.data.data
    },
    // 获取备注列表
    async getNotesRecordList() {
      const res = await getNotesRecordList({
        bizId: this.id,
        bizType: 2
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.remarkInfo = { list: res.data.data }
    },
    // 获取操作列表
    async getOrdersOperateLogList() {
      const res = await getOrdersOperateLogList({
        bizId: this.id,
        bizType: 2
      })
      if (res.data.code === 500) {
        this.$message.error(res.data.msg)
        return
      }
      this.operateInfo = { list: res.data.data }
    },
  }
}
</script>

<style lang='less' scoped>
</style>