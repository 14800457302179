var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "投诉列表", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { "margin-right": "2%" } },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.animeDat(_vm.complaintState)
                    },
                  },
                  model: {
                    value: _vm.complaintState,
                    callback: function ($$v) {
                      _vm.complaintState = $$v
                    },
                    expression: "complaintState",
                  },
                },
                _vm._l(_vm.statesnum, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
              _c("span", [_vm._v("投诉类型：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.animeDat1(_vm.illegalId)
                    },
                  },
                  model: {
                    value: _vm.illegalId,
                    callback: function ($$v) {
                      _vm.illegalId = $$v
                    },
                    expression: "illegalId",
                  },
                },
                _vm._l(_vm.illegalList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.illegal, value: item.id },
                  })
                }),
                1
              ),
              _vm._v("     "),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("订单编号：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入订单编号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.indentNumber,
                      callback: function ($$v) {
                        _vm.indentNumber = $$v
                      },
                      expression: "indentNumber",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "complaintId",
                  label: "编号",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "indentNumber",
                  label: "订单号",
                  width: "200",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nickName",
                  label: "被投诉的骑手",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.nickName
                                    ? scope.row.nickName
                                    : "未绑定"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "投诉来源", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 1
                          ? _c("div", [_vm._v("用户投诉")])
                          : _vm._e(),
                        scope.row.type == 2
                          ? _c("div", [_vm._v("商户投诉")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "illegal", label: "投诉类型", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 1
                          ? _c("div", [_vm._v(_vm._s(scope.row.illegal))])
                          : _vm._e(),
                        scope.row.type == 2
                          ? _c("div", [_vm._v(_vm._s(scope.row.shopIllegal))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "wrongExplain",
                  label: "违规说明",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "resultHanding",
                  label: "处理结果",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.complaintState == "5"
                          ? _c("span", [_vm._v("待审核")])
                          : scope.row.complaintState == "6"
                          ? _c("span", [_vm._v("投诉未通过")])
                          : _c("span", [_vm._v("投诉通过")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "complaintTime",
                  label: "投诉时间",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appealMessage",
                  label: "申诉理由",
                  width: "200",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "appealTime", label: "申诉时间", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "auditMessage",
                  label: "申诉结果",
                  width: "200",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "complaintState", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.complaintState == "1"
                          ? _c("span", [_vm._v("可申诉")])
                          : _vm._e(),
                        scope.row.complaintState == "2"
                          ? _c("span", [_vm._v("申诉中")])
                          : _vm._e(),
                        scope.row.complaintState == "3"
                          ? _c("span", [_vm._v("申诉未通过")])
                          : _vm._e(),
                        scope.row.complaintState == "4"
                          ? _c("span", [_vm._v("申诉通过")])
                          : _vm._e(),
                        scope.row.complaintState == "5"
                          ? _c("span", [_vm._v("待审核")])
                          : _vm._e(),
                        scope.row.complaintState == "6"
                          ? _c("span", [_vm._v("投诉未通过")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "240",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.complaintState == "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("business:jujue"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.refuseClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v("拒绝投诉 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled:
                                    !_vm.isAuth("business:jujue") ||
                                    scope.row.complaintState != "2",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.refuseClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v("拒绝申诉 ")]
                            ),
                        scope.row.complaintState == "5"
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled:
                                    !_vm.isAuth("business:tongguo") ||
                                    scope.row.complaintState != "5",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.passClicks1(scope.row)
                                  },
                                },
                              },
                              [_vm._v("通过投诉 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled:
                                    !_vm.isAuth("business:tongguo") ||
                                    scope.row.complaintState != "2",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.passClicks(scope.row)
                                  },
                                },
                              },
                              [_vm._v("通过申诉 ")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "margin-top": "10px",
                float: "right",
              },
            },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "服务拒绝",
                visible: _vm.dialogFormVisible,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                        position: "relative",
                        top: "-70px",
                      },
                    },
                    [_vm._v("拒绝理由：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入拒绝理由",
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refuseto()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "投诉分类", name: "second" } },
        [
          _c(
            "div",
            { staticStyle: { float: "right", "margin-right": "2%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled: !_vm.isAuth("business:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: { click: _vm.advertNotice },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.guangaoData.list },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "illegal", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("business:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.advertUpdates(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("business:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.advertdeletes(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.guangaoData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加",
                visible: _vm.dialogFormVisible2,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible2 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("标题：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入标题", type: "text" },
                    model: {
                      value: _vm.illegal,
                      callback: function ($$v) {
                        _vm.illegal = $$v
                      },
                      expression: "illegal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible2 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.advertNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改",
                visible: _vm.dialogFormVisible1,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible1 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: { placeholder: "请输入标题", type: "text" },
                        model: {
                          value: _vm.form.illegal,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "illegal", $$v)
                          },
                          expression: "form.illegal",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.amendadvertTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }