var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          staticStyle: { border: "0" },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c("DetailsHeaderModule", {
            attrs: { basicInfo: _vm.basicInfo },
            on: { refresh: _vm.queryServeOrderDetail },
          }),
          _c("ServiceInfoModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("WorkerArrangementModule", {
            attrs: { basicInfo: _vm.basicInfo },
          }),
          _c("SchemeModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("ServiceProgressModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("CheckRemarkModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("AfterSaleModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("ServiceRateModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("SettlementModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("ServiceAgreementModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("RemarkModule", { attrs: { basicInfo: _vm.basicInfo } }),
          _c("OperateInfoModule", { attrs: { basicInfo: _vm.basicInfo } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }