var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-wrapper site-page--not-found" }, [
    _c("div", { staticClass: "site-content__wrapper" }, [
      _c(
        "div",
        { staticClass: "site-content" },
        [
          _c("h2", { staticClass: "not-found-title" }, [_vm._v("400")]),
          _vm._m(0),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回上一页")]
          ),
          _c(
            "el-button",
            {
              staticClass: "not-found-btn-gohome",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ name: "home" })
                },
              },
            },
            [_vm._v("进入首页")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "not-found-desc" }, [
      _vm._v("抱歉！您访问的页面"),
      _c("em", [_vm._v("失联")]),
      _vm._v("啦 ..."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }