var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-collapse-item",
    {
      attrs: { name: "1" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("h2", { staticClass: "details_title" }, [
                _c("span"),
                _vm._v("工人安排"),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            data: _vm.tableData,
            align: "center",
            border: "",
            "max-height": "600px",
            "min-height": "0",
          },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "name", width: "100", title: "姓名" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "phone", "min-width": "180", title: "联系方式" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }