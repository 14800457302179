<template>
  <div>
    <FormRender
      ref="reportForm"
      :form-config="formConfig"
      :form-data.sync="formData"
      form-type="submit"
      label-width="100px"
      inline
      formType="search"
      @onSearch="getList"
      @onReset="onReset"
    >
    </FormRender>
    <TableCustom
      ref="tableCustomRef"
      operate-width="200"
      :columns="tableColumns"
      :get-page-list="getPageList"
    >
      <template #fileInfo="{ scopeRow }">
        <div v-if="scopeRow.fileInfoDtoList && scopeRow.fileInfoDtoList.length>0">
          <img v-for="item in scopeRow.fileInfoDtoList" :key="item.url" :src="item.url" width="40" height="40" />
        </div>
        <span v-else>暂无图片</span>
      </template>
      <template #operate="{ scopeRow }">
        <el-button type="text" class="mr-5" @click="goToDetail(scopeRow)" v-if="scopeRow.isShowDetail">查看</el-button>
        <el-button type="text" class="mr-5" @click="handleAudit(scopeRow)" v-if="scopeRow.isShowAudit">审核</el-button>
        <el-button type="text" class="mr-5" @click="handleRemitAudit(scopeRow)" v-if="scopeRow.isRemitAudit">审核保证金</el-button>
        <el-button type="text" class="mr-5" @click="handleDeposit(scopeRow)" v-if="scopeRow.isSecurityDeposit">查看保证金记录</el-button>
        <el-popconfirm @confirm="handleResetPassword(scopeRow)" v-if="scopeRow.showResetPassword" title="确认重置该账号密码?">
          <template #reference>
            <el-button type="text" class="mr-5">重置密码</el-button>
          </template>
        </el-popconfirm>
        <!-- <el-button type="text" @click="handleResetPassword(scopeRow)" v-if="scopeRow.showResetPassword">重置密码</el-button> -->
        <el-button type="text" @click="handleEditDeposit(scopeRow)" v-if="scopeRow.isShowEditDeposit">修改保证金</el-button>
      </template>
    </TableCustom>
    <EditDialog :isMerchant="true" ref="editDialog" @refresh="handleModifySuccess" title="企业信息" />
    <RemitAuditDialog ref="remitAuditDialog" @refresh="handleModifySuccess" title="企业信息审核" />
    <EditDepositDialog ref="editDepositDialog" @submit="handleSubmitDeposit" />
    <DepositRecordDialog ref="depositRecordDialog" />
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
import FormRender from '@/components/formRender/index.vue'
import { AuditStatus, AuditStatusDic } from '@/const/auditStatus';
import { GetPageMerchantCertification, ResetUserPassword, SaveCompanyDeposit } from '@/api/auth/index';
import EditDialog from '../components/editDialog.vue';
import RemitAuditDialog from '../components/remitAuditDialog.vue';
import EditDepositDialog from '../components/editDepositDialog.vue';
import DepositRecordDialog from '../components/depositRecordDialog.vue';
import { Message } from 'element-ui';
export default {
  components: {
    TableCustom,
    FormRender,
    EditDialog,
    RemitAuditDialog,
    EditDepositDialog,
    DepositRecordDialog
  },
  data() {
    return {
      // form配置
      formConfig: [
        {
          label: '企业状态',
          prop: 'auditStatus',
          type: 'select',
          dict: [{value: '', label: '全部'}, ...AuditStatusDic],
        },
        {
          label: '企业名称',
          prop: 'name',
          type: 'input',
        },
        {
          label: '联系电话',
          prop: 'contactPhone',
          type: 'input',
        },
      ],
      // 搜索值
      formData: {},
      tableColumns: [
        {
          label: '企业名称',
          prop: 'fullName',
          minWidth: '180',
        },
        {
          label: '统一信用代码',
          prop: 'socialCode',
          minWidth: '180',
        },
        {
          label: '营业执照和相关专业资质证书',
          prop: 'fileInfo',
          minWidth: '260',
        },
        {
          label: '营业执照有效期',
          prop: 'doorInfo',
          minWidth: '120',
        },
        {
          label: '已缴纳保证金（元）',
          prop: 'depositAmount',
          minWidth: '120',
        },
        {
          label: '注册账号',
          prop: 'companyAccount',
          minWidth: '120',
        },
        {
          label: '联系手机',
          prop: 'contactPhone',
          minWidth: '120',
        },
        {
          label: '创建时间',
          prop: 'createTime',
          minWidth: '120',
        },
        {
          label: '审核备注',
          prop: 'auditRemark',
          minWidth: '100',
        },
        {
          label: '审核状态',
          prop: 'auditStatusDescription',
          minWidth: '120',
        },
      ],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 查看按钮显示
    isShowDetail(row) {
      return row.auditStatus !== AuditStatus.AuditingEnterpriseInfo;
    },
    // 显示审核按钮
    isShowAudit(row) {
      return row.auditStatus === AuditStatus.AuditingEnterpriseInfo;
    },
    // 显示重置密码
    showResetPassword(row) {
      return row.auditStatus === AuditStatus.Certified;
    },

    // 是否显示打款审核
    isRemitAudit(row) {
      return row.auditStatus === AuditStatus.RemittancePendingAudit;
    },

    // 是否显示查看保证金记录
    isSecurityDeposit(row) {
      return row.auditStatus === AuditStatus.Certified;
    },
    // 是否显示编辑保证金
    isShowEditDeposit(row) {
      return row.auditStatus === AuditStatus.Certified;
    },
    // 重置密码
    async handleResetPassword(row) {
      const res = await ResetUserPassword(row.userId);
      if(res) {
        Message({
          type: 'success',
          message: '重置密码成功',
        });
      }
    },

    // 审核
    handleAudit(row) {
      // do something 
      this.$refs.editDialog.showAudit(row);
    },

    // 审核
    handleRemitAudit(row) {
      // do something 
      this.$refs.remitAuditDialog.showAudit(row);
    },

    // 查看保证金记录
    handleDeposit(row) {
      console.log(row);
      this.$refs.depositRecordDialog.show(row.id);
      // this.$router.push({
      //   name: 'depositRecord',
      //   query: {
      //     name: row.fullName
      //   }
      // })
    },

    // 修改保证金
    handleEditDeposit(row) {
      this.$refs.editDepositDialog.show(row.id);
    },

    // 调用修改保证金接口
    async handleSubmitDeposit(data) {
      const res = await SaveCompanyDeposit(data);
      if (res) {
        Message({
          message: '修改成功',
          type: 'success',
          plain: true,
        });
        this.getList();
        this.$refs.editDepositDialog.close();
      }
    },
    // 查看详情
    goToDetail(row) {
      // do something 
      this.$refs.editDialog.showDetail(row);
    },

    // 列表接口
    async getPageList(pageInfo) {
      const res = await GetPageMerchantCertification(pageInfo, this.formData)
      return {...res, list: res.list.map(el => ({
        ...el,
        isShowAudit: this.isShowAudit(el),
        isShowDetail: this.isShowDetail(el),
        showResetPassword: this.showResetPassword(el),
        isRemitAudit: this.isRemitAudit(el),
        isSecurityDeposit: this.isSecurityDeposit(el),
        isShowEditDeposit: this.isShowEditDeposit(el),
      }))}
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
    // 重置
    onReset() {
      this.formData = {}
      this.getList()
    },
    handleModifySuccess() {
      this.getList();
    }
  }
}
</script>

<style lang='less' scoped>
.list_form {
  margin-bottom: 20px;
}
.list_title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}
</style>