<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="10">
      <template #title>
        <h2 class="details_title"><span></span>操作信息</h2>
      </template>
      <TableCustom
        ref="tableCustomRef"
        border
        :operate="false"
        :columns="tableColumns"
        :get-page-list="getPageList"
        :pagination="false"
      >
      </TableCustom>
    </el-collapse-item>
  </div>
</template>

<script>
import TableCustom from '@/components/table/module/table.vue'
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    TableCustom,
  },
  data() {
    return {
      tableColumns: [
        {
          label: '操作者',
          prop: 'createUserName',
          minWidth: '180',
        },
        {
          label: '操作时间',
          prop: 'createTime',
          minWidth: '180',
        },
        {
          label: '服务状态',
          prop: 'dataStateValue',
          minWidth: '180',
        },
        {
          label: '操作内容',
          prop: 'content',
          minWidth: '180',
        },
      ],
      // 操作信息
      ordersOperateLogs: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.ordersOperateLogs = val?.ordersOperateLogs || []
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    // 列表接口
    async getPageList() {
      return { list: this.ordersOperateLogs }
    },
    // 调用列表接口
    getList() {
      this.$nextTick(() => {
        this.$refs?.tableCustomRef?.fetchList();
      })
    },
  }
}
</script>

<style>
</style>