var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { float: "right", "margin-right": "2%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px 0" },
              attrs: {
                disabled: !_vm.isAuth("bannerList:add"),
                size: "mini",
                type: "primary",
                icon: "document",
              },
              on: { click: _vm.addNotice },
            },
            [_vm._v("添加会员特权")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.tableData.list },
        },
        [
          _c("el-table-column", {
            attrs: { fixed: "", prop: "memberId", label: "编号", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { prop: "memberImg", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: {
                        src: scope.row.memberImg,
                        width: "60",
                        height: "60",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "memberName", label: "特权名称" },
          }),
          _c("el-table-column", { attrs: { prop: "sort", label: "排序" } }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: !_vm.isAuth("bannerList:update"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.amendBanner(scope.$index, scope.row)
                          },
                        },
                      },
                      [_vm._v("修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          disabled: !_vm.isAuth("bannerList:delete"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deletes(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next,jumper",
              total: _vm.tableData.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", display: "flex" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("图片：")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "148px",
                    height: "148px",
                    border: "1px dashed #c0ccda",
                    "border-radius": "6px",
                    "text-align": "center",
                    "line-height": "148px",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                      },
                      model: {
                        value: _vm.memberImg,
                        callback: function ($$v) {
                          _vm.memberImg = $$v
                        },
                        expression: "memberImg",
                      },
                    },
                    [
                      _vm.memberImg
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: {
                              "border-radius": "6px",
                              width: "148px",
                              height: "148px",
                            },
                            attrs: { src: _vm.memberImg },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("名称：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.memberName,
                  callback: function ($$v) {
                    _vm.memberName = $$v
                  },
                  expression: "memberName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("排序：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  placeholder: "请输入排序",
                  onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                },
                model: {
                  value: _vm.sort,
                  callback: function ($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }