import { ECompanyType } from '@/const/companyType';
import { httpGet, httpPost, httpPostPage } from '@/utils/request';
import { Message } from 'element-ui';
import { config } from './config';

/**
 * 分页获取认证列表
 * @param {{limit: number, page: number}} pageInfo 分页信息
 * @param  认证列表
 * @returns {{data: Array, total: number}}
 */
export const GetPageCertification = async (pageInfo, data) => {
  try {
    const res = await httpPostPage(config.certificationList, pageInfo, data);
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
  }
};

/**
 * 分页获取公司认证列表
 * @param {{limit: number, page: number}} pageInfo 分页信息
 * @param  认证列表
 * @returns {{data: Array, total: number}}
 */
export const GetPageCompanyCertification = async (pageInfo, data) => {
  try {
    const res = await GetPageCertification(pageInfo, { ...data, type: ECompanyType.Enterprise });
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
  }
};

/**
 * 分页获取商家认证列表
 * @param {{limit: number, page: number}} pageInfo 分页信息
 * @param  认证列表
 * @returns {{data: Array, total: number}}
 */
export const GetPageMerchantCertification = async (pageInfo, data) => {
  try {
    const res = await GetPageCertification(pageInfo, { ...data, type: ECompanyType.ServiceProvider });
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
  }
};

/**
 * 企业/商家-认证审核
 * @param {*} id
 * @param {*} auditAction 审核操作类型：1:通过 0:拒绝
 * @returns
 */
export const CompanyAudit = async (id, auditAction, extra = {}) => {
  try {
    const res = await httpPost(config.companyAudit, { id, auditAction, ...extra });
    return true;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
      plain: true,
    });
    return false;
  }
};

/**
 * 获取公司信息
 * @param {*} companyId 公司id
 * @returns
 */
export const GetCompanyInfo = async (companyId) => {
  try {
    const res = await httpGet(config.companyInfo, { companyId });
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
    return null;
  }
};

/**
 * 获取公司用户列表
 * @param {*} pageInfo
 * @param {*} data
 * @returns
 */
export const getCompanyUserList = async (pageInfo, data) => {
  try {
    const res = await httpPostPage(config.getCompanyUserList, pageInfo, data);
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
  }
};

/**
 * 查询公司保证金列表
 * @param {*} pageInfo
 * @param {*} data：{companyId: , companyName: string }
 * @returns
 */
export const DepositGetListByPage = async (pageInfo, data) => {
  try {
    const res = await httpPostPage(config.depositGetListByPage, pageInfo, data);
    return res;
  } catch (error) {
    Message({
      type: 'error',
      message: error.msg,
    });
  }
};
/**
 * 商家-保存保证金记录
 * @param {
 * companyId
 * depositAmount
 * depositRemark
 * fileInfoDtoList: [{name, url}]
 * type
 * } data
 * @returns boolean
 */
export const SaveCompanyDeposit = async (data) => {
  try {
    const res = await httpPost(config.saveCompanyDeposit, data);
    return true;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
      plain: true,
    });
    return false;
  }
};

/**
 * 统计公司保证金总额
 * @param {*} companyId
 * @returns
 */
export const GetTotalAmount = async (companyId = '', companyName='') => {
  try {
    const res = await httpGet(config.getTotalAmount, { companyId, companyName });
    return res;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
      plain: true,
    });
    return 0;
  }
};

/**
 * 企业-重置公司账号密码
 * @param {*} userId
 * @returns
 */
export const ResetUserPassword = async (userId = '') => {
  try {
    const res = await httpPost(config.resetUserPassword, { userId });
    return true;
  } catch (error) {
    Message({
      message: error.msg,
      type: 'error',
      plain: true,
    });
    return false;
  }
};