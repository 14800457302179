<template>
  <div>
    <!-- 服务方案 -->
    <el-collapse-item name="2">
      <template #title>
        <h2 class="details_title"><span></span>服务方案</h2>
      </template>
      <div class="second_title">{{ serveInfo.serverCategory }}-方案清单:</div>
      <vxe-table ref="xTable" :data="tableData" align="center" border max-height="600px" min-height="0">
        <vxe-table-column field="name" min-width="180" title="科目"> </vxe-table-column>
        <vxe-table-column field="content" min-width="500" title="具体内容描述"></vxe-table-column>
        <vxe-table-column field="unit" min-width="80" title="单位"></vxe-table-column>
        <vxe-table-column field="number" min-width="80" title="数量"> </vxe-table-column>
        <vxe-table-column field="unitPrice" min-width="120" title="单价(元)"> </vxe-table-column>
        <vxe-table-column field="negotiatePrice" min-width="120" title="协商定价(元)"> </vxe-table-column>
        <vxe-table-column field="notes" min-width="180" title="备注"> </vxe-table-column>
      </vxe-table>
      <el-row type="flex" align="middle" class="scheme_details_footer">
        <el-col :span="12">
          <span class="quality_time">预估工期：{{ schemeItem.estimateDuration || 0 }}天</span>
          <span class="quality_time">承诺质保时限：{{ schemeItem.warrantyPeriodDays || 0 }}天</span>
          <span class="quality_time">预估上门时间：{{ schemeItem.estimateDoorTime || '-' }}</span>
        </el-col>
        <el-col :span="12" align="end">
          <span class="quality_total"
            >方案总金额：<i>￥{{ schemeItem.schemeTotalAmount || 0 }}</i></span
          >
          <div class="quality_total">
            已优惠：<i>￥{{ schemeItem.discountAmount || 0 }}</i>
          </div>
        </el-col>
      </el-row>
    </el-collapse-item>
    <!-- 方案附件 -->
    <el-collapse-item name="3">
      <template #title>
        <h2 class="details_title"><span></span>方案附件</h2>
      </template>
      <vxe-table ref="xTableFile" :data="schemeFileList" border max-height="600px" min-height="0">
        <vxe-table-column field="name" min-width="180" title="附件名称"></vxe-table-column>
        <vxe-table-column width="180" title="操作">
          <template #default="{ row }">
            <el-button type="text" @click="handleViews(row)">查看</el-button>
            <el-button type="text" @click="handleDownload(row)">下载</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
    </el-collapse-item>
  </div>
</template>

<script>
import download from '@/utils/download.js';
import { isImage, isPdf } from '@/utils/file.js';
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 服务信息数据
      serveInfo: {},
      // 服务方案对象
      schemeItem: {},
      tableData: [],
      // 附件列表
      schemeFileList: [],
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.schemeItem = val?.schemeItem || {}
        this.serveInfo = val?.serveInfo || {}
        this.schemeFileList = val?.schemeFileList || []
        this.tableData = val?.schemeItem?.schemeItemList || []
      },
      deep: true
    }
  },
  methods: {
    // 下载附件
    handleDownload(file) {
      download(file.id, file.name);
    },
    // 查看附件
    handleViews(file) {
      if (isImage(file.url) || isPdf(file.url)) {
        window.open(file.url)
      } else {
        this.$message.error('暂不支持该格式的预览')
      }
    },
  }
}
</script>

<style lang='less' scoped>
.scheme_item {
  margin-bottom: 20px;
}
.second_title {
  font-size: 16px;
  font-weight: 700;
}
.quality_time {
  margin-right: 20px;
}
.scheme_details_footer {
  margin-top: 20px;
}
.quality_total {
  font-size: 18px;
  i {
    font-style: normal;
    color: #557efc;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>