var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menu.list && _vm.menu.list.length >= 1
    ? _c(
        "el-submenu",
        {
          attrs: {
            index: _vm.menu.menuId + "",
            "popper-class":
              "site-sidebar--" + _vm.sidebarLayoutSkin + "-popper",
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("icon-svg", {
                staticClass: "site-sidebar__menu-icon",
                attrs: { name: _vm.menu.icon || "" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
            ],
            1
          ),
          _vm._l(_vm.menu.list, function (item) {
            return _c("sub-menu", {
              key: item.menuId,
              attrs: { menu: item, dynamicMenuRoutes: _vm.dynamicMenuRoutes },
            })
          }),
        ],
        2
      )
    : _c(
        "el-menu-item",
        {
          attrs: { index: _vm.menu.menuId + "" },
          on: {
            click: function ($event) {
              return _vm.gotoRouteHandle(_vm.menu)
            },
          },
        },
        [
          _vm.menu.menuId == 142 && _vm.chatNumDrz != 0
            ? _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.chatNumDrz } },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              )
            : _vm.menu.menuId == 143 && _vm.chatNumDts != 0
            ? _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.chatNumDts } },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              )
            : _vm.menu.menuId == 132 && _vm.chatNumDsh != 0
            ? _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.chatNumDsh } },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              )
            : _vm.menu.menuId == 72 && _vm.chatNumDFw != 0
            ? _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.chatNumDFw } },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              )
            : _vm.menu.menuId == 41 && _vm.chatNumTs != 0
            ? _c(
                "el-badge",
                { staticClass: "item", attrs: { value: _vm.chatNumTs } },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "el-badge item" },
                [
                  _c("icon-svg", {
                    staticClass: "site-sidebar__menu-icon aaaaaaa",
                    attrs: { name: _vm.menu.icon || "" },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.menu.name))]),
                ],
                1
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }