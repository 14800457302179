<template>
  <Form
    ref="formRef"
    class="formRender-box"
    size="small"
    :rules="rules"
    :model="formDataIn"
    :label-width="labelWidth"
  >
    <el-row :gutter="10" style="flex-wrap: wrap; display: flex">
      <el-col :xl="6" :lg="8" v-for="item in formConfigIn.filter((item) => !item.hidden)" :key="item.prop">
        <FormItem :style="{ width: !inline && (item.width || '100%') }" :label="item.label" :prop="item.prop">
          <template v-if="item.type === 'input'">
            <Input
              v-model="formDataIn[item.prop]"
              :disabled="item.disabled"
              :clearable="item.clearable"
              :placeholder="item.placeholder || `请输入${item.label}`"
            ></Input>
          </template>
          <template v-else-if="item.type === 'select'">
            <Select
              v-model="formDataIn[item.prop]"
              :disabled="item.disabled"
              :clearable="item.clearable"
              class="inputwidth100"
              :placeholder="item.placeholder || `请选择${item.label}`"
            >
              <Option v-for="opt in item.dict" :key="opt.value" :label="opt.label" :value="opt.value"></Option>
            </Select>
          </template>
          <template v-else-if="item.type === 'switch'">
            <ElSwitch v-model="formDataIn[item.prop]" :disabled="item.disabled"></ElSwitch>
          </template>
          <template v-else-if="item.type === 'radio'">
            <RadioGroup v-model="formDataIn[item.prop]">
              <Radio v-for="ra in item.dict" :key="ra.value" :label="ra.value" :value="ra.value">{{ ra.label }}</Radio>
            </RadioGroup>
          </template>
          <template v-else-if="item.type === 'inputNumber'">
            <InputNumber
              v-model="formDataIn[item.prop]"
              :disabled="item.disabled"
              :clearable="item.clearable"
              controls-position="right"
              :min="item?.min"
              :max="item?.max"
            ></InputNumber>
          </template>
          <template v-else-if="item.type === 'textarea'">
            <Input
              v-model="formDataIn[item.prop]"
              :disabled="item.disabled"
              type="textarea"
              :clearable="item.clearable"
              :maxlength="item?.maxlength"
              :show-word-limit="item?.showWordLimit"
              :placeholder="item.placeholder || `请输入${item.label}`"
            ></Input>
          </template>
          <template v-else-if="item.type === 'datepicker'">
            <DatePicker
              v-model="formDataIn[item.prop]"
              class="inputwidth100"
              :clearable="item.clearable"
              :disabled="item.disabled"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :placeholder="item.placeholder || `请选择${item.label}`"
            >
            </DatePicker>
          </template>
          <template v-else-if="item.type === 'daterange'">
            <DatePicker
              v-model="formDataIn[item.prop]"
              class="inputwidth100"
              :disabled="item.disabled"
              :clearable="item.clearable"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </DatePicker>
          </template>
          <template v-else-if="item.type === 'slot'">
            <slot :name="item.prop"></slot>
          </template>
        </FormItem>
      </el-col>
    </el-row>
    <div style="text-align: right">
      <FormItem v-if="formType === 'search'">
        <Button type="primary" @click="onSearch">查询</Button>
        <Button type="primary" plain @click="onReset">重置</Button>
      </FormItem>
    </div>
  </Form>
</template>
<script>
import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Switch as ElSwitch,
  RadioGroup,
  Radio,
  InputNumber,
  DatePicker,
} from 'element-ui';
import { getSomeData } from '@/api/common';

export default {
  components: { Button, Form, FormItem, Input, Select, Option, ElSwitch, RadioGroup, Radio, InputNumber, DatePicker },
  props: {
    // 搜索栏search 提交表单 submit
    formType: {
      type: String,
      default: 'search',
    },
    labelWidth: {
      type: String,
      default: '',
    },
    formConfig: {
      type: Array,
      default: () => {
        return [];
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rules: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formDataIn: this.formData,
      formConfigIn: this.formConfig,
      dictDefaultProps: {
        label: 'label',
        value: 'value',
      },
    };
  },
  watch: {
    formData: {
      handler(val) {
        this.formDataIn = val;
      },
      deep: true,
      immediate: true,
    },
    formConfig: {
      handler(val) {
        const initConfig = JSON.parse(JSON.stringify(val));
        this.formConfigIn = initConfig;
        this.initDict();
      },
      deep: true,
      immediate: true,
    },
    formDataIn: {
      handler(val) {
        this.$emit('update:formData', val);
      },
      deep: true,
      immediate: true,
    },
  },
  // mounted() {
  //   this.initDict();
  // },
  methods: {
    initDict() {
      if (this.formConfigIn.some((i) => i.hasOwnProperty('dictUrl'))) {
        this.formConfigIn.forEach(async (item, index) => {
          if (item?.dictUrl) {
            const dict = await this.getDictData(item);
            this.$set(item, 'dict', dict);
          }
          this.$set(this.formConfigIn, index, item);
        });
      }
    },
    onSearch() {
      this.$emit('onSearch', this.formDataIn);
    },
    onReset() {
      this.$emit('onReset');
    },
    // 校验
    async validate() {
      let result;
      await this.$refs.formRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    // 获取字典
    /**
     * formConfig中请求字典需要的参数
     * dictUrl：请求地址
     * dictParams：请求参数
     * dictProps：字典对应的label value字段
     */
    async getDictData(item) {
      let dict = [];
      const props = item.dictProps || this.dictDefaultProps;
      await getSomeData(item.apiType, item.dictUrl, item.dictParams || {}).then((res) => {
        dict = res?.map((i) => {
          return {
            label: props.label ? i[props.label] : i,
            value: props.label ? i[props.value] : i,
          };
        });
      });
      return dict;
    },
  },
};
</script>
<style lang="less" scoped>
.formRender-box .inputwidth100 {
  width: 100%;
}
.formRender-box {
  ::v-deep(.el-form-item--small.el-form-item) {
    display: inline-block;
  }
}
</style>
