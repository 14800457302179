var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "4px" } },
    [
      _c(
        "el-collapse-item",
        {
          attrs: { name: "5" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "details_title" }, [
                    _c("span"),
                    _vm._v("验收记录"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.checkInfos?.length
            ? _c(
                "el-timeline",
                { attrs: { reverse: false } },
                _vm._l(_vm.checkInfos, function (item) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: item.id,
                      attrs: { timestamp: item.createTime, placement: "top" },
                    },
                    [
                      _c("div", { staticClass: "check_operate" }, [
                        _vm._v(_vm._s(item.nodeName)),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "check_user", attrs: { span: 4 } },
                            [
                              _c("span", [_vm._v(_vm._s(item.userTypeValue))]),
                              _c("span", [_vm._v(_vm._s(item.userName))]),
                            ]
                          ),
                          _c("el-col", { attrs: { span: 20 } }, [
                            _c("div", { staticClass: "check_content" }, [
                              _vm._v(_vm._s(item.contents)),
                            ]),
                            item?.imgList?.length
                              ? _c(
                                  "ul",
                                  { staticClass: "maintain_content" },
                                  _vm._l(item?.imgList, function (url) {
                                    return _c(
                                      "li",
                                      { key: url },
                                      [_c("el-image", { attrs: { src: url } })],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c("el-empty", { attrs: { "image-size": 160 } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }