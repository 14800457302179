<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h2 class="dispatch_title"><span></span>施工方案状态：{{ basicInfo.stateDesc }}</h2>
      </el-col>
      <el-col :span="12" align="end">
        <el-button @click="hanldeSave(0)">暂存</el-button>
        <el-button type="primary" @click="hanldeSave(1)">提交客户确认</el-button>
      </el-col>
    </el-row>
    <h2 class="details_title"><span></span>{{ basicInfo.serveOrderName }}</h2>
    <!-- 方案详情 -->
    <div>
      <el-row type="flex" align="middle" class="scheme_item">
        <el-col :span="12">
          <span class="details_title_span">方案清单</span>
        </el-col>
        <el-col :span="12" align="end">
          <el-button icon="el-icon-plus" @click="handleAddPlan">添加</el-button>
        </el-col>
      </el-row>
      <vxe-table
        ref="xTable"
        :data="tableData"
        align="center"
        border
        keep-source
        :edit-config="{ trigger: 'click', mode: 'cell', autoClear: false }"
        max-height="600px"
        min-height="200px"
      >
        <vxe-table-column field="name" min-width="180" title="科目" :edit-render="{}">
          <template #edit="{ row, rowIndex }">
            <el-autocomplete
              class="inline-input"
              v-model="row.name"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              :trigger-on-focus="false"
              @select="(e) => handleSelect(e, rowIndex)"
              value-key="name"
            ></el-autocomplete>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="content"
          min-width="500"
          title="具体内容描述"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        ></vxe-table-column>
        <vxe-table-column
          field="unit"
          min-width="80"
          title="单位"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        >
        </vxe-table-column>
        <vxe-table-column field="number" min-width="80" title="数量" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input @change="changeNumber" type="float" v-model="row.number" step="1"></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column field="unitPrice" min-width="120" title="单价(元)" :edit-render="{}">
          <template #edit="{ row, rowIndex }">
            <vxe-input
              type="float"
              @change="(e) => changeUnitPrice(e, rowIndex)"
              v-model="row.unitPrice"
              step="1"
            ></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column field="negotiatePrice" min-width="120" title="协商定价(元)" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input type="float" @change="changeNegotiatePrice" v-model="row.negotiatePrice" step="1"></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="notes"
          min-width="120"
          title="备注"
          :edit-render="{
            name: 'input',
            attrs: { type: 'text' },
          }"
        >
        </vxe-table-column>
        <vxe-table-column min-width="80" title="操作">
          <template #default="{ rowIndex }">
            <el-button type="text" style="color: red" @click="handleDelScheme(rowIndex)">删除</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
      <el-row type="flex" align="middle" class="scheme_details_footer">
        <el-col :span="20">
          <div style="display: flex">
            <span class="quality_time"
              >预估工期：<el-input-number
                v-model="basicInfo.estimateDuration"
                controls-position="right"
                style="width: 50%; margin: 0 8px"
                :precision='1'
                :min="0"
              ></el-input-number
              >天</span
            >
            <span class="quality_time"
              >承诺质保时限：<el-input-number
                v-model="basicInfo.warrantyPeriodDays"
                controls-position="right"
                style="width: 50%; margin: 0 8px"
                disabled
                :min="0"
              ></el-input-number
              >天</span
            >
            <span class="quality_time"
              >预估上门时间：<el-date-picker
                v-model="basicInfo.estimateDoorTime"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker
            ></span>
          </div>
        </el-col>
        <el-col :span="4" align="end">
          <span class="quality_total"
            >方案总金额：<i>￥{{ basicInfo.schemeTotal || 0 }}</i></span
          >
          <div class="quality_total">
            已优惠：<i>￥{{ basicInfo.discountTotal || 0 }}</i>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 方案附件 -->
    <div style="margin-top: 40px">
      <el-row type="flex" align="middle" class="scheme_item">
        <el-col :span="12">
          <span class="details_title_span">方案附件</span>
        </el-col>
        <el-col :span="12" align="end">
          <el-button icon="el-icon-plus" @click="handleAddFile">添加附件</el-button>
        </el-col>
      </el-row>
      <vxe-table ref="xTableFile" :data="tableDataFile" border max-height="600px" min-height="0">
        <vxe-table-column field="name" min-width="180" title="附件名称"></vxe-table-column>
        <vxe-table-column width="180" title="操作">
          <template #default="{ row, rowIndex }">
            <el-button v-if="row.id" type="text" @click="handleViews(row)">查看</el-button>
            <el-button v-if="row.id" type="text" @click="handleDownload(row)">下载</el-button>
            <el-button type="text" style="color: #f56c6c" @click="handleDelFile(rowIndex)">删除</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 新增附件 -->
    <AddFileDialog ref="addFileDialogRef" @handleOk="addFileTable"></AddFileDialog>
  </div>
</template>

<script>
import AddFileDialog from './components/addFileDialog.vue'
import { saveSchemeInfo, getSchemeDetailInfo, schemeDictList } from '@/api/maintenance/index.js'
import download from '@/utils/download.js';
import { isImage, isPdf } from '@/utils/file.js';
export default {
  components: {
    AddFileDialog
  },
  data() {
    return {
      // 详情数据
      basicInfo: {
        warrantyPeriodDays: 30
      },
      // 服务id
      serveOrderId: '',
      tableData: [],
      // 工序名称/科目下拉
      restaurants: [],
      // 质保时限
      timer: '',
      // 附件列表
      tableDataFile: []
    }
  },
  mounted() {
    this.serveOrderId = this.$route.query.id
    this.getSchemeDetailInfo()
    this.schemeDictList()
  },
  methods: {
    // 详情接口
    async getSchemeDetailInfo() {
      try {
        const res = await getSchemeDetailInfo({
          serveOrderId: this.serveOrderId
        })
        this.basicInfo = {
          ...res,
          warrantyPeriodDays: 30
        }
        this.tableDataFile = res.fileInfoList || []
        this.tableData = res.schemeItemList || []
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
    // 添加方案
    handleAddPlan() {
      this.tableData.push({})
    },
    // 获取方案标准库
    async schemeDictList() {
      try {
        const res = await schemeDictList()
        this.restaurants = res
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
    // 工序名称/科目匹配
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.name.toLowerCase().match(queryString.toLowerCase());
      };
    },
    handleSelect(row, i) {
      this.$set(this.tableData, i, {
        ...this.tableData[i],
        content: row.content,
        unit: row.unit,
        number: row.number,
        unitPrice: row.unitPrice,
        negotiatePrice: row.unitPrice,
        notes: row.notes,
      })
    },
    // 改变数量，总价、优惠也变
    changeNumber(e, i) {
      if (e.$event?.type === 'init') {
        return
      }
      this.changeNegotiatePrice()
    },
    // 改变定价，协商定价也变
    changeUnitPrice(e, i) {
      if (e.$event?.type === 'init') {
        return
      }
      this.$set(this.tableData[i], 'negotiatePrice', e.value)
      this.changeNegotiatePrice()
    },
    // 改变协商定价总金额也变
    changeNegotiatePrice() {
      const unitPriceTotal = this.tableData.map(item => (item.unitPrice || 0) * item.number).reduce((accumulator, currentValue) => accumulator + currentValue * 1, 0).toFixed(2)
      const schemeTotal = this.tableData.map(item => (item.negotiatePrice || 0) * item.number).reduce((accumulator, currentValue) => accumulator + currentValue * 1, 0).toFixed(2)
      this.$set(this.basicInfo, 'schemeTotal', schemeTotal)
      this.$set(this.basicInfo, 'discountTotal', (unitPriceTotal - schemeTotal).toFixed(2))
    },
    // 删除方案
    handleDelScheme(i) {
      this.tableData.splice(i, 1)
    },
    // 添加附件
    handleAddFile() {
      this.$refs.addFileDialogRef?.handleOpen();
    },
    // 新增附件
    addFileTable(list) {
      this.tableDataFile = this.tableDataFile.concat(list)
    },
    // 下载附件
    handleDownload(file) {
      download(file.id, file.name);
    },
    // 查看附件
    handleViews(file) {
      if (isImage(file.url) || isPdf(file.url)) {
        window.open(file.url)
      } else {
        this.$message.error('暂不支持该格式的预览')
      }
    },
    // 删除附件
    handleDelFile(i) {
      this.tableDataFile.splice(i, 1)
    },
    // 暂存
    async hanldeSave(operateState) {
      this.$refs.xTable.clearEdit()
      try {
        await saveSchemeInfo({
          ...this.basicInfo,
          fileInfoList: this.tableDataFile,
          schemeItemList: this.tableData,
          operateSourceType: 1,
          operateState,
          serveOrderId: this.serveOrderId
        })
        this.$message.success('操作成功!')
        this.$router.go(-1)
      } catch (error) {
        console.log(error);
        this.$message.error(error.msg)
      }
    },
  }
}
</script>

<style lang='less' scoped>
.dispatch_title {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #95a3fc;
  margin: 0 0 40px;
  span {
    display: inline-block;
    width: 6px;
    height: 30px;
    background-color: #3181ff;
    margin-right: 4px;
  }
}
.scheme_item {
  margin-bottom: 20px;
}
.details_title_span {
  font-size: 16px;
  font-weight: 700;
}
.quality_time {
  display: flex;
  align-items: center;
}
.scheme_details_footer {
  margin-top: 20px;
}
.quality_total {
  font-size: 18px;
  i {
    font-style: normal;
    color: #557efc;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>