<template>
  <el-dialog title="操作驳回" top="20vh" append-to-body :visible.sync="dialogVisible" @close="close" width="500px">
    <el-form :model="formModel" :rules="rules" ref="formRef" label-width="100px" class="demo-ruleForm">
      <el-form-item label="备注信息" prop="auditRemark">
        <el-input type="textarea" show-word-limit maxlength="300" rows="5" v-model="formModel.auditRemark"></el-input>
      </el-form-item>
      <div class="margin-auto mt-40" style="width: 90%;">
        <el-row gutter="60">
          <el-col :span="12">
            <el-button class="w-full" @click="close">取消</el-button>
          </el-col>
          <el-col :span="12">
            <el-button class="w-full" type="primary" @click="submitForm('formRef')">确定</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'RejectDialog',
  data: () => {
    return {
      dialogVisible: false,
      formModel: {
        auditRemark: ''
      },
      rules: {
        auditRemark: [
          { required: true, message: '请输入备注信息', trigger: 'blur' },
          { max: 300, message: '长度小于300个字符', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.$refs.formRef.resetFields();
      this.dialogVisible = false;
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$emit('submit', this.formModel.auditRemark);
          }else {
          }
      });
    }
  }
}
</script>

<style>

</style>