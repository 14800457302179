<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="4">
      <template #title>
        <h2 class="details_title"><span></span>服务进度</h2>
      </template>
      <el-timeline :reverse="false" v-if="schedules?.length">
        <el-timeline-item v-for="item in schedules" :key="item.id" :timestamp="item.createTime" placement="top">
          <h4>{{ item.contents }}</h4>
          <ul class="maintain_content" v-if="item?.imgList?.length">
            <li v-for="url in item?.imgList" :key="url">
              <el-image :src="url" />
            </li>
          </ul>
        </el-timeline-item>
      </el-timeline>
      <el-empty v-else :image-size="160"></el-empty>
    </el-collapse-item>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 服务进度数据
      schedules: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.schedules = val?.schedules || []
      },
      deep: true
    }
  },
}
</script>

<style lang='less' scoped>
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
</style>