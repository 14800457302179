<template>
  <div>
    <h2 class="details_title"><span></span>维修部位照片</h2>
    <ul class="maintain_content" v-if="!!fileList?.length">
      <li v-for="item in fileList" :key="item.id">
        <el-image :src="item.url" />
      </li>
    </ul>
    <el-empty v-else :image-size="160"></el-empty>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.fileList = val.fileInfoList
      },
      deep: true
    }
  },
  methods: {
  }
}
</script>

<style lang='less' scoped>
.maintain_content {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  border: 1px solid #d7d7d7;
  li {
    flex: 0 0 20%;
    padding: 10px;
  }
}
</style>