<template>
  <div style="margin-top: 4px">
    <el-collapse-item name="6">
      <template #title>
        <h2 class="details_title"><span></span>售后记录</h2>
      </template>
      <div class="assurance_box">
        <span class="assurance_day">质保期剩余{{ serveOrderAfterSaleInfoVO.periodDays || 0 }}天</span>
        <span v-if="serveOrderAfterSaleInfoVO.periodFinishTime"
          >{{ serveOrderAfterSaleInfoVO.periodFinishTime }}到期</span
        >
      </div>
      <vxe-table ref="xTableFile" :data="afterSales" border max-height="600px" min-height="0">
        <vxe-table-column field="createTime" min-width="180" title="售后申请日期"></vxe-table-column>
        <vxe-table-column field="contents" min-width="180" title="售后内容"></vxe-table-column>
        <vxe-table-column field="imgList" min-width="180" title="相关图片">
          <template #default="{ row }">
            <el-image v-if="row.imgList?.length" :src="row.imgList[0]" :preview-src-list="row.imgList" />
          </template>
        </vxe-table-column>
        <vxe-table-column field="expectDoorTime" min-width="180" title="预估上门时间"></vxe-table-column>
        <vxe-table-column field="estimateDuration" min-width="180" title="预估工期"></vxe-table-column>
        <vxe-table-column width="180" title="售后状态">
          <template #default="{ row }">
            <el-button type="text" @click="handleOpenAfterSaleDetails(row)">{{ row.stateValue }}</el-button>
          </template>
        </vxe-table-column>
      </vxe-table>
      <!-- 售后处理记录 -->
      <AfterSaleDetailsDialog ref="afterSaleDetailsDialogRef"></AfterSaleDetailsDialog>
    </el-collapse-item>
  </div>
</template>

<script>
import AfterSaleDetailsDialog from "../components/afterSaleDetailsDialog.vue";
export default {
  components: {
    AfterSaleDetailsDialog
  },
  props: {
    basicInfo: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    basicInfo: {
      handler(val) {
        this.serveOrderAfterSaleInfoVO = val?.serveOrderAfterSaleInfoVO || {}
        this.afterSales = val?.serveOrderAfterSaleInfoVO?.afterSales || []
      },
      deep: true
    }
  },
  data() {
    return {
      // 售后记录大对象
      serveOrderAfterSaleInfoVO: {},
      // 售后列表
      afterSales: []
    }
  },
  methods: {
    // 打开售后详情弹窗
    handleOpenAfterSaleDetails(row) {
      this.$refs.afterSaleDetailsDialogRef?.handleOpen(row)
    }
  }
}
</script>

<style lang='less' scoped>
.assurance_box {
  margin-bottom: 20px;
  .assurance_day {
    font-weight: 700;
    margin-right: 20px;
  }
}
</style>