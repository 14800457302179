<template>
	<el-tabs v-model="activeName" @tab-click="handleClick">
		<el-tab-pane label="位置列表" name="first">

			<div style="margin:5px;text-align:left;">
				<div style="position: relative;display: inline-block;">
					<span>师傅姓名：</span>
					<el-input style="width: 200px;" @keydown.enter.native="select" placeholder="请输入师傅姓名"
						v-model="artificerName">
					</el-input>&nbsp;&nbsp;
				</div>
				<div style="position: relative;display: inline-block;">
					<span>电话：</span>
					<el-input style="width: 200px;" @keydown.enter.native="select" placeholder="请输入电话" v-model="phone">
					</el-input>&nbsp;&nbsp;
				</div>
				<el-button style="margin-left:15px;" size="mini" type="primary" icon="document" @click="select">查询
				</el-button>
				<el-button style="margin-left:15px;" size="mini" type="primary" icon="document" @click="cleans">重置
				</el-button>
			</div>
			<el-table v-loading="tableDataLoading" :data="tableData.list">
				<el-table-column fixed prop="id" label="编号" width="80">
					<template slot-scope="scope">
						<span>
							{{scope.$index+1}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="masterName" label="师傅姓名" width="100">
					<template slot-scope="scope">
						<span style="color: #4f9dec;cursor: pointer;" @click="updates(scope.row.userId)">
							{{ scope.row.masterName?scope.row.masterName:'未绑定'}}
						</span>
					</template>
				</el-table-column>

				<el-table-column prop="avatar" label="头像">
					<template slot-scope="scope">
						<img v-if="scope.row.avatar==null" src="~@/assets/img/avatar.png" alt="" width="40"
							height="40">
						<img v-else :src="scope.row.avatar" alt="" width="40" height="40">
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="电话" width="160">
				</el-table-column>
				<el-table-column prop="address" label="地址" width="200"></el-table-column>
				<el-table-column prop="longitude" label="经度" width="160">
				</el-table-column>
				<el-table-column prop="latitude" label="纬度" width="160">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
				<!-- <el-table-column prop="createAt" label="处理状态" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.type=='1'" style="color: #13ce66;">已处理</span>
						<span v-else style="color: #ff4949;">待处理</span>
						<el-button  v-if="scope.row.type!=='1'" size="mini" type="danger" style="margin: 5px;" :disabled="!isAuth('emergencyHelp:chuli')"
							@click="classdelete(scope.row)">处理
						</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<div style="text-align: center;margin-top: 10px;float:right">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:page-sizes="[10, 20, 30, 40]" :page-size="limit" :current-page="page"
					layout="total,sizes, prev, pager, next,jumper" :total="tableData.totalCount">
				</el-pagination>
			</div>

		</el-tab-pane>
	</el-tabs>
</template>
<script>
	export default {
		data() {
			return {
				page: 1,
				limit: 10,
				classify: 1,
				indentNumber: '',
				imageUrl: '',
				url: '',
				complaintState: '',
				activeName: 'first',
				tableDataLoading: true,
				dialogFormVisible: false,
				dialogFormVisible2: false,
				tableData: [],
				checkBoxData: [], //多选框选择的值
				statesnum: [{
						label: '全部',
						value: ''
					},
					{
						label: '可申诉',
						value: 1
					},
					{
						label: '申诉中',
						value: 2
					},
					{
						label: '申诉未通过',
						value: 3
					},
					{
						label: '申诉通过',
						value: 4
					},
					{
						label: '待审核',
						value: 5
					},
					{
						label: '投诉未通过',
						value: 6
					},
				],
				content: '',
				status: 1,
				helpTakeId: '',
				indentNumbers: '',
				complaintId: '',
				message: '',
				title: '',
				type: 3,
				form: {
					id: '',
					illegal: '',
					forfeitMoney: '',
				},
				formLabelWidth: '200px',
				dialogFormVisible1: false,
				guangaoData: {},
				illegal: '',
				illegalId: '',
				forfeitMoney: '',
				illegalList: [],
				phone: '',
				artificerName: '',

			}
		},
		methods: {
			// 多选
			changeFun(val) {
				this.checkBoxData = val
			},
			//处理默认选中当前日期
			// getNowTime () {
			// 	var now = new Date()
			// 	var year = now.getFullYear() //得到年份
			// 	var month = now.getMonth() //得到月份
			// 	var date = now.getDate() //得到日期
			// 	var hh = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
			// 	var mm = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
			// 	var ss = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
			// 	month = month + 1
			// 	month = month.toString().padStart(2, '0')
			// 	date = date.toString().padStart(2, '0')
			// 	var defaultDate = `${year}-${month}-${date} ${hh}:${mm}:${ss}`
			// 	return defaultDate
			// 	this.$set(this.info, 'stockDate', defaultDate)
			// },
			// tabs切换
			handleClick(tab, event) {
				if (tab._props.label == '位置列表') {
					this.page = 1
					this.limit = 10
					this.dataSelect()
				}

			},
			handleSizeChange(val) {
				this.limit = val
				this.dataSelect()
			},
			handleCurrentChange(val) {
				this.page = val
				this.dataSelect()
			},

			// 查询资源列表
			select() {
				this.page = 1
				this.limit = 10

				this.dataSelect()
			},
			// 重置资源列表
			cleans() {
				this.phone = ''
				this.artificerName = ''
				this.illegalId = ''
				this.page = 1
				this.dataSelect()
			},
			// select选择事件
			animeDat(state) {
				this.page = 1
				this.complaintState = state
				console.log(state)
				this.dataSelect()
			},
			animeDat1(state) {
				this.page = 1
				this.illegalId = state
				this.dataSelect()
			},


			// 获取数据列表
			dataSelect() {
				this.tableDataLoading = true
				this.$http({
					url: this.$http.adornUrl('message/selectMasterLocation'),
					method: 'get',
					params: this.$http.adornParams({
						'page': this.page,
						'limit': this.limit,
						'masterName': this.artificerName,
						'phone': this.phone,
					})
				}).then(({
					data
				}) => {
					if (data && data.code === 0) {
						this.tableDataLoading = false
						let returnData = data.data
						this.tableData = returnData
					}
				})
			},
			// 详情跳转
			updates(userId) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: userId
					}
				})
			},
			// 处理
			classdelete(row) {
				this.$confirm(`确定处理该投诉?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: this.$http.adornUrl('message/auditMessage'),
						method: 'post',
						params: this.$http.adornParams({
							'ids': row.id,
							'status': 1,
							'auditContent': '',
						})
					}).then(({
						data
					}) => {
						if (data.code == 0) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.dataSelect()
								}
							})
						} else {
							this.$message({
								message: data.msg,
								type: 'warning',
								duration: 1500,
								onClose: () => {}
							})
						}

					})
				}).catch(() => {})
			},
		},
		mounted() {
			this.dataSelect()
		}
	}
</script>

<style>
	.customWidth {
		width: 80% !important;
	}
</style>